import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import { useGetCaptableQuery, useGetCompanyQuery } from 'lib/apollo/hooks'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { lenderName } from 'lib/formConfig'
import { FEATURE_FLAGS } from 'lib/config'
import { getHasUploadedRequiredFiles, getIsCapTableProcessing } from 'lib/data'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import theme from 'styles/customTheme'

import QueryBoundary from 'app/QueryBoundary'
import Greeting from 'components/Greeting'
import Layout from 'components/Layout/Layout'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import Stepper from 'components/mui/Stepper'
import Offerings from 'components/offerings/Offerings'
import OfferCard from 'components/offerings/OfferCard'
import OffersEmptyCard from 'components/offerings/OffersEmptyCard'

function CompanyOverview() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const companyQuery = useGetCompanyQuery()

  const company = companyQuery?.data?.getCompany
  const companyFiles = company?.files

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery?.data?.getCaptable

  const hasUploadedRequiredFiles = useMemo(
    () => companyFiles && getHasUploadedRequiredFiles(companyFiles),
    [companyFiles]
  )

  const capTableIsProcessing = useMemo(
    () => companyFiles && getIsCapTableProcessing(companyFiles),
    [companyFiles]
  )

  const capTableIsApproved = Boolean(
    captable?.isApprovedBy?.openstockAdmin &&
      captable?.isApprovedBy?.companyAdmin
  )

  const isCompleted = {
    isAdminsAndSignatoriesInvited: !FEATURE_FLAGS.DEMO_INVITE_USERS
      ? true
      : company?.users.length > 1 || company?.invitedUsers.length > 0,
    isAllRequiredDocumentsUploaded: hasUploadedRequiredFiles,
    isReceivedLenderApproval: Boolean(
      company?.isApproved && company?.financialTermsCurrent?._id
    ),
    isUploadedCapTable: Boolean(capTableIsProcessing || capTableIsApproved),
    isReviewedCapTable: capTableIsApproved,
  }

  const isAccountSetUpCompleted = !Object.values(isCompleted).includes(false)

  const setUpTasks = [
    {
      label: t('dashboard.overview.setUp.tasks.companySetup.label'),
      display: FEATURE_FLAGS.DEMO_INVITE_USERS,
      subTasks: [
        {
          label: t(
            'dashboard.overview.setUp.tasks.companySetup.subTasks.inviteOthers'
          ),
          isCompleted: isCompleted.isAdminsAndSignatoriesInvited,
          // TODO: add path/s (to company info tab / admins & signatories?) when user invites is functional and remove DEMO_INVITE_USERS feature flag
          // actionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/company-info/[path]`,
        },
      ],
    },
    {
      label: t('dashboard.overview.setUp.tasks.getFinancing.label'),
      subTasks: [
        {
          label: t(
            'dashboard.overview.setUp.tasks.getFinancing.subTasks.uploadDocuments'
          ),
          isCompleted: isCompleted.isAllRequiredDocumentsUploaded,
          actionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/company-info/financial-documents`,
        },
        {
          label: t(
            'dashboard.overview.setUp.tasks.getFinancing.subTasks.lenderApproval'
          ),
          isCompleted: isCompleted.isReceivedLenderApproval,
          status:
            !isCompleted.isAllRequiredDocumentsUploaded &&
            !isCompleted.isReceivedLenderApproval
              ? t('dashboard.overview.setUp.notYetAvailable')
              : !isCompleted.isReceivedLenderApproval
                ? t('dashboard.overview.setUp.awaitingResponse')
                : undefined,
          actionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/financing-info`,
        },
      ],
    },
    {
      label: t('dashboard.overview.setUp.tasks.createOffers.label'),
      subTasks: [
        {
          label: t(
            'dashboard.overview.setUp.tasks.createOffers.subTasks.importCapTable'
          ),
          isCompleted: isCompleted.isUploadedCapTable,
          actionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table/import-preview`,
          completedActionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table`,
        },
        {
          label: t(
            'dashboard.overview.setUp.tasks.createOffers.subTasks.reviewCapTable'
          ),
          isCompleted: isCompleted.isReviewedCapTable,
          status: Boolean(captable?.isApprovedBy?.openstockAdmin)
            ? undefined
            : t('dashboard.overview.setUp.notYetAvailable'),
          actionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table/review`,
          completedActionPath: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table`,
        },
      ],
    },
  ]

  const offers = company?.offers

  const activeOffers = useMemo(
    () => offers?.filter((offer: Offer) => offer.status === 'ACTIVE'),
    [offers]
  )

  const isActiveOffers = activeOffers && activeOffers.length >= 1

  const activeLoan = company?.isApproved ? company?.financialTermsCurrent : null

  const activeLoanDetails = [
    {
      label: t('loan.loanCreditAvailable'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: activeLoan?.maxCreditAvailable,
      }),
    },
    {
      label: t('loan.companyValuation'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: activeLoan?.companyValuation,
      }),
    },
    {
      label: t('loan.sharePrice'),
      value: t('common.pricePerShare', {
        value: activeLoan?.sharePrice,
      }),
    },
    {
      label: t('loan.loanToValueRatio'),
      value: t('common.percentage', {
        value: activeLoan?.loan?.valueRatio,
      }),
    },
    {
      label: t('loan.endDate'),
      value: dayjs(activeLoan?.expiryDate).format(
        monthDayFullYearSimplifiedFormat
      ),
    },
  ]

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <Layout maxWidth="lg">
        <Greeting
          greetName={company?.name}
          welcomeMessage={t('dashboard.overview.welcomeMessage')}
          greeting={t(
            isAccountSetUpCompleted
              ? 'dashboard.overview.greeting'
              : 'dashboard.overview.setUp.greeting'
          )}
          background={theme.palette.primary.main}
        />

        {!isAccountSetUpCompleted && (
          <>
            <Typography variant="h3" mb={2}>
              {t('dashboard.overview.setUp.heading')}
            </Typography>
            <Paper sx={{ boxShadow: 'none' }}>
              <Stepper tasks={setUpTasks} />
            </Paper>
          </>
        )}

        {/* Available offer */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mt={4}
        >
          <Grid item>
            <Typography variant="h3">
              {t('dashboard.overview.activeOffer.title')}
            </Typography>
          </Grid>
          {isActiveOffers && (
            <Link
              variant="subtitle2"
              to={`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers`}
            >
              {t('dashboard.overview.activeOffer.viewAll')}
            </Link>
          )}
        </Grid>

        <Grid mt={2}>
          {isActiveOffers ? (
            <Offerings offers={activeOffers} isDisplayHeading={false} />
          ) : isAccountSetUpCompleted ? (
            <OffersEmptyCard
              title={t('dashboard.overview.activeOffer.createFirstOffer')}
              action={() =>
                navigate(`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers`)
              }
              actionText={t('dashboard.overview.activeOffer.actionText')}
            />
          ) : (
            <OffersEmptyCard
              title={t('dashboard.overview.activeOffer.empty')}
              description={t('dashboard.overview.activeOffer.emptyDescription')}
            />
          )}
        </Grid>

        {/* Active loan */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={2}
          mt={5}
        >
          <Grid item>
            <Typography variant="h3">
              {t('dashboard.overview.activeFinancing.title')}
            </Typography>
          </Grid>
        </Grid>

        <Grid mt={2}>
          {activeLoan ? (
            <OfferCard
              contents={activeLoanDetails}
              name={lenderName}
              status="active"
              buttonNavigateTo={`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/financing-info`}
            />
          ) : (
            <OffersEmptyCard
              title={t('dashboard.overview.activeFinancing.empty')}
              description={t(
                'dashboard.overview.activeFinancing.emptyDescription'
              )}
            />
          )}
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyOverview

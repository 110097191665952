import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  Company,
  CompanyFinancialTerms,
  CreateOfferMultiForms,
  CaptableShareholderGroupedTable,
  DerivedShareclassGroupedTable,
  OfferTypeConfig,
  ShareholderShareInfo,
} from 'common/types'
import { getIneligibleShareholders, getShareholderShareInfo } from 'lib/data'
import { minimumLoanAmount } from 'lib/formConfig'
import IneligibleShareholdersTable from 'components/tables/IneligibleShareholdersTable'
import ReviewShareholdersTable from 'components/tables/ReviewShareholdersTable'
import OfferDetails from 'components/offerings/OfferDetails'
import OfferInformation from 'components/offerings/OfferInformation'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import { useFormikContext } from 'formik'

interface Props {
  company: Company
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  getTotalLiquidity: (shares: number) => void
  sharePrice: CompanyFinancialTerms['sharePrice']
  shareholderGroupedTable: CaptableShareholderGroupedTable
  selectedEligibleShareholders: ShareholderShareInfo
  handleBack: () => void
  isSubmitting: boolean
  values: CreateOfferMultiForms
  offerTypeConfig: OfferTypeConfig
}

function ReviewSubmit({
  company,
  derivedShareclassGroupedTable,
  getTotalLiquidity,
  sharePrice,
  shareholderGroupedTable,
  selectedEligibleShareholders,
  handleBack,
  isSubmitting,
  values,
  offerTypeConfig,
}: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const { offerTypeTranslationKey, type: offerType } = offerTypeConfig

  // Set form values to shareholders from previous step that are selected & eligible
  useEffect(() => {
    setFieldValue('shareholders', selectedEligibleShareholders)
  }, [selectedEligibleShareholders, setFieldValue])

  const allShareholders = useMemo(
    () =>
      getShareholderShareInfo({
        shareclassIds: values.shareclasses,
        shareholderGroupedTable,
        sharePrice,
      }),
    [sharePrice, shareholderGroupedTable, values.shareclasses]
  )

  const ineligibleShareholders = useMemo(
    () => getIneligibleShareholders(allShareholders, values.percentageOfAssets),
    [allShareholders, values.percentageOfAssets]
  )

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} lg={9} alignContent="start" rowGap={5}>
        {offerType === 'TENDER_OFFER' && (
          <Grid item xs={12}>
            <Typography variant="h3" mb={1}>
              {t('offer.review.subHeading1')}
            </Typography>
            <Typography>
              {t(`offer.review.${offerTypeTranslationKey}.offerType`)}
            </Typography>
          </Grid>
        )}
        <OfferDetails
          offerDetailsForm={values}
          offerTypeConfig={offerTypeConfig}
          shareholderGroupedTable={shareholderGroupedTable}
        />

        <Grid item xs={12} mb={4}>
          <Typography variant="h3" mb={3}>
            {t('offer.review.subHeading3')}
          </Typography>
          <ReviewShareholdersTable
            getTotalLiquidity={getTotalLiquidity}
            offerDetailsForm={values}
            offerTypeConfig={offerTypeConfig}
          />
        </Grid>
        {ineligibleShareholders.length > 0 && (
          <Grid item xs={12} mb={4}>
            <Typography variant="h3">
              {t('offer.review.subHeading4')}
            </Typography>
            <Typography my={3}>
              {t('offer.review.ineligibleShareholders.description', {
                minLoanAmount: minimumLoanAmount,
              })}
            </Typography>
            <IneligibleShareholdersTable
              offerTypeConfig={offerTypeConfig}
              shareholders={ineligibleShareholders}
              percentageOfAssets={values.percentageOfAssets}
            />
          </Grid>
        )}
      </Grid>

      {/* Sidebar information */}
      <OfferInformation
        company={company}
        derivedShareclassGroupedTable={derivedShareclassGroupedTable}
        hasSelectedShareClassInfo
        selectedShareClasses={values.shareclasses}
        offerTypeConfig={offerTypeConfig}
      />

      <Grid item container xs={12} lg={9}>
        <ResponsiveStepperButtons
          handleBack={handleBack}
          isSubmitting={isSubmitting}
          nextStepText={t('offer.review.button')}
          isLastStep
        />
      </Grid>
    </Grid>
  )
}

export default ReviewSubmit

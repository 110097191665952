import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useGetCompanyQuery, useGetCaptableQuery } from 'lib/apollo/hooks'
import {
  getFinancingAvailable,
  getHasUploadedRequiredFiles,
  getDerivedCompanyStatus,
} from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { getLabelByValue, getPercentageOfValueByTotal } from 'lib/helpers'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'

import QueryBoundary from 'app/QueryBoundary'
import Layout from 'components/Layout/Layout'
import Button from 'components/mui/Button'
import EmptyStateWithButton from 'components/EmptyStateWithButton'
import StatusIndicator from 'components/StatusIndicator'
import {
  lenderName,
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
} from 'lib/formConfig'
import Paper from 'components/mui/Paper/Paper'
import LinearProgress from 'components/mui/LinearProgress'
import { LabelValuePair } from 'common/types'

function CompanyLoanInfo() {
  const { t } = useTranslation()

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery?.data?.getCompany
  const companyFiles = company?.files

  const financialTerms = company?.financialTermsCurrent
  const offers = company?.offers

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery?.data?.getCaptable

  const isReceivedLenderApproval =
    company?.isApproved && company?.financialTermsCurrent

  const hasUploadedRequiredFiles = useMemo(
    () => companyFiles && getHasUploadedRequiredFiles(companyFiles),
    [companyFiles]
  )

  const { canCreateOffers } = useMemo(
    () =>
      getDerivedCompanyStatus(
        company?.financialTermsCurrent,
        company,
        captable
      ),
    [company, captable]
  )

  const config = useMemo(() => {
    let title = t('dashboard.financingInfo.noLoan.title')
    let description = t('dashboard.financingInfo.noLoan.description')
    let buttonLabel = t('dashboard.financingInfo.noLoan.button')
    const buttonNavigateTo = `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/company-info/financial-documents`

    if (hasUploadedRequiredFiles) {
      title = t('dashboard.financingInfo.awaitingResponse.title')
      description = t('dashboard.financingInfo.awaitingResponse.description')
      buttonLabel = t('dashboard.financingInfo.awaitingResponse.button')
    }

    return { title, description, buttonLabel, buttonNavigateTo }
  }, [hasUploadedRequiredFiles, t])

  //TODO: Get total liquidity offered for shareholders who subscribed to offers
  const liquidityAvailable = useMemo(
    () =>
      offers &&
      getFinancingAvailable(financialTerms?.maxCreditAvailable, offers),
    [financialTerms?.maxCreditAvailable, offers]
  )

  const percentageOfLoanCreditAvailable = useMemo(
    () =>
      getPercentageOfValueByTotal({
        value: liquidityAvailable,
        total: financialTerms?.maxCreditAvailable,
      }),
    [financialTerms?.maxCreditAvailable, liquidityAvailable]
  )

  const status = dayjs(financialTerms?.expiryDate).isBefore(dayjs())
    ? 'expired'
    : 'active'

  const loanDetails = [
    {
      label: t('loan.overview'),
      fields: [
        {
          label: t('loan.loanCreditAvailable'),
          value: t('common.intlCurrencySimplifiedFormat', {
            value: financialTerms?.maxCreditAvailable,
          }),
        },
        { label: t('loan.lender'), value: lenderName },
      ],
    },
    {
      label: t('loan.companyValuation'),
      fields: [
        {
          label: t('loan.companyValuation'),
          value: t('common.intlCurrencySimplifiedFormat', {
            value: financialTerms?.companyValuation,
          }),
        },
        {
          label: t('loan.sharePrice'),
          value: t('common.pricePerShare', {
            value: financialTerms?.sharePrice,
          }),
        },
        {
          label: t('loan.loanToValueRatio'),
          value: t('common.percentage', {
            value: financialTerms?.loan.valueRatio,
          }),
        },
      ],
    },
    {
      label: t('loan.duration'),
      fields: [
        {
          label: t('loan.term'),
          value: t('common.monthWithCount', {
            count: financialTerms?.loan.termInMonths,
          }),
        },
        {
          label: t('loan.startDate'),
          value: dayjs(financialTerms?.startDate).format(
            monthDayFullYearSimplifiedFormat
          ),
        },
        {
          label: t('loan.endDate'),
          value: dayjs(financialTerms?.expiryDate).format(
            monthDayFullYearSimplifiedFormat
          ),
        },
      ],
    },
    {
      label: t('loan.repayment'),
      fields: [
        {
          label: t('loan.type'),
          value: getLabelByValue(financialTerms?.loan.kind, loanTypes),
        },
        {
          label: t('loan.structure'),
          value: getLabelByValue(
            financialTerms?.loan.structure,
            loanStructures
          ),
        },
        {
          label: t('loan.repaymentSchedule'),
          value: getLabelByValue(
            financialTerms?.loan.repaymentSchedule,
            loanRepaymentSchedules
          ),
        },
      ],
    },
    {
      label: t('loan.feesAndInterest'),
      fields: [
        {
          label: t('loan.originationFee'),
          value: t('common.percentage', {
            value: financialTerms?.originationFee,
          }),
        },
        {
          label: t('loan.platformFee'),
          value: t('common.percentage', { value: financialTerms?.platformFee }),
        },
        {
          label: t('loan.interestRate'),
          value: t('loan.interestRateValue', {
            value: financialTerms?.interestRate,
          }),
        },
      ],
    },
  ]

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <Layout maxWidth="lg">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={4}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
            columnGap={{ xs: 3 }}
            rowGap={2}
            width={{ sm: 'auto' }}
          >
            <Grid item>
              <Typography variant="h1">
                {t('dashboard.financingInfo.heading')}
              </Typography>
            </Grid>
            {isReceivedLenderApproval && (
              <Grid item>
                <StatusIndicator status={status} category="loan" hasBorder />
              </Grid>
            )}
          </Grid>
          {canCreateOffers && (
            <Grid item>
              <Button
                mode="forward"
                to="../create-offer"
                size="small"
                sx={{
                  py: 0.6,
                }}
              >
                <Typography variant="button">
                  {t('dashboard.offers.createOfferButton')}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>

        {isReceivedLenderApproval ? (
          <Grid container rowGap={2}>
            <Typography variant="h3">{t('loan.details')}</Typography>
            {loanDetails.map((loanDetail) => (
              <Paper key={loanDetail.label} sx={{ width: 1 }}>
                <Grid container rowGap={3}>
                  <Grid item>
                    <Typography variant="h4" color="secondary.darkest">
                      {loanDetail.label}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {loanDetail.fields.map((field: LabelValuePair) => (
                      <Grid key={field.label} item xs={12} sm={4}>
                        <Typography variant="body2" color="secondary.dark">
                          {field.label}
                        </Typography>
                        {field.value && (
                          <Typography
                            color="secondary.darkest"
                            my={{ xs: 0.3, md: 1 }}
                          >
                            {field.value}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            ))}
            <Grid item xs={12}>
              <Typography variant="h3" mt={3}>
                {t('loan.utilization')}
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={6} md={4}>
              <Paper sx={{ width: 1 }}>
                <Grid container gap={1}>
                  <Typography variant="h4" color="secondary.darkest" mb={1.5}>
                    {t('loan.financingAvailable')}
                  </Typography>
                  <LinearProgress progress={percentageOfLoanCreditAvailable} />
                  <Typography color="secondary.darkest">
                    {t('common.percentageOutOf', {
                      value: t('common.intlCurrencySimplifiedFormat', {
                        value: liquidityAvailable,
                      }),
                      total: t('common.intlCurrencySimplifiedFormat', {
                        value: financialTerms?.maxCreditAvailable,
                      }),
                      percentage: percentageOfLoanCreditAvailable,
                    })}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <EmptyStateWithButton
            {...config}
            buttonProps={{
              variant: hasUploadedRequiredFiles ? 'outlined' : 'contained',
            }}
          />
        )}
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyLoanInfo

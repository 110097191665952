import { useTranslation } from 'react-i18next'
import i18n from 'locale/i18n'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import { OfferTypeConfig, ShareholderShareInfo } from 'common/types'
import {} from 'lib/data'
import ResponsiveTable, {
  HeadCells,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'

function IneligibleShareholdersTableRow({
  shareholder,
  percentageOfAssets,
}: {
  shareholder: ShareholderShareInfo[0]
  percentageOfAssets: number
}) {
  const { t } = useTranslation()

  return (
    <ResponsiveTableRow>
      <TableCell component="th" id={shareholder.shareholder._id} scope="row">
        <Typography>{shareholder.shareholder.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {shareholder.shareclasses
            .map((shareclass) => shareclass.name)
            .join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlNumberFormat', {
            value: shareholder.shares.maxAvailableShares,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlCurrencySimplifiedFormat', {
            value: Math.floor(
              shareholder?.shares.liquidityAvailable *
                (percentageOfAssets / 100)
            ),
          })}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

export default function IneligibleShareholdersTable({
  offerTypeConfig,
  shareholders,
  percentageOfAssets,
}: {
  offerTypeConfig: OfferTypeConfig
  shareholders: ShareholderShareInfo
  percentageOfAssets: number
}) {
  const { offerTypeTranslationKey } = offerTypeConfig

  const headCells: HeadCells = [
    {
      id: 'shareholders',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell1`
      ),
      width: '25%',
    },
    {
      id: 'shareClass',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell2`
      ),
      width: '30%',
    },
    {
      id: 'maxNumOfShares',
      label: i18n.t('offer.review.ineligibleShareholders.table.tableHeadCell3'),
      width: '20%',
    },
    {
      id: 'totalLiquidity',
      label: i18n.t('offer.review.ineligibleShareholders.table.tableHeadCell4'),
      width: '25%',
    },
  ]

  return (
    <ResponsiveTable
      ariaLabel={`offer.review.${offerTypeTranslationKey}.table.ariaLabel`}
      headCells={headCells}
    >
      <TableBody>
        {shareholders.map((shareholder: ShareholderShareInfo[0]) => (
          <IneligibleShareholdersTableRow
            key={shareholder.shareholder._id}
            shareholder={shareholder}
            percentageOfAssets={percentageOfAssets}
          />
        ))}
      </TableBody>
    </ResponsiveTable>
  )
}

import { Trans } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { UPLOAD_SHAREHOLDER_INFORMATION } from 'lib/formConfig'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import UploadFile from 'components/files/UploadFile'

interface Props {
  handleBack: () => void
  fileUploadMutation: any
}

function UploadShareholder({ handleBack, fileUploadMutation }: Props) {
  return (
    <Grid container rowSpacing={3}>
      <Typography>
        <Trans i18nKey="dashboard.capTable.import.uploadShareholder.description" />
      </Typography>

      <Grid item container mb={4}>
        <Grid item xs={12} sm={9.5}>
          <UploadFile document={UPLOAD_SHAREHOLDER_INFORMATION} />
        </Grid>
      </Grid>

      <ResponsiveStepperButtons
        handleBack={handleBack}
        isUploading={fileUploadMutation.isLoading}
        uploadProgress={fileUploadMutation.progress}
      />
    </Grid>
  )
}

export default UploadShareholder

import Paper from 'components/mui/Paper/Paper'
import Slider from 'components/mui/Slider'

interface Props {
  name: string
  value: number
  maxValue?: number
  minValue?: number
}

function PercentageSlider({
  name,
  value,
  maxValue = 100,
  minValue = 0,
  ...props
}: Props) {
  const handleSliderValueLabelFormat = (value: number) => `${value}%`

  return (
    <Paper>
      <Slider
        name={name}
        value={value}
        valueLabelFormat={handleSliderValueLabelFormat}
        marks={[
          {
            value: minValue,
            label: `${minValue}%`,
          },
          {
            value: maxValue,
            label: `${maxValue}%`,
          },
        ]}
        max={maxValue}
        sx={{ mx: 1.2 }}
        {...props}
      />
    </Paper>
  )
}

export default PercentageSlider

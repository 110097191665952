import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'locale/i18n'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import {
  CreateOfferMultiForms,
  OfferTypeConfig,
  ShareholderShareInfo,
} from 'common/types'
import ResponsiveTable, {
  HeadCells,
  ResponsiveTableFooter,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'

function ReviewShareholdersTableRow({
  getTotalLiquidity,
  shareholderRow,
}: {
  getTotalLiquidity: (shares: number) => void
  shareholderRow: ShareholderShareInfo[0]
}) {
  const { t } = useTranslation()
  const { shareholder, shareclasses, shares } = shareholderRow

  const totalLiquidity = useMemo(
    () => getTotalLiquidity(shares.maxAvailableShares),
    [getTotalLiquidity, shares.maxAvailableShares]
  )

  return (
    <ResponsiveTableRow>
      <TableCell component="th" id={shareholder._id} scope="row">
        <Typography>{shareholder.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {shareclasses.map((shareclass) => shareclass.name).join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlNumberFormat', {
            value: shares.maxAvailableShares,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlCurrencySimplifiedFormat', {
            value: totalLiquidity,
          })}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

export default function ReviewShareholdersTable({
  getTotalLiquidity,
  offerDetailsForm,
  offerTypeConfig,
}: {
  getTotalLiquidity: (shares: number) => void
  offerDetailsForm: CreateOfferMultiForms
  offerTypeConfig: OfferTypeConfig
}) {
  const { t } = useTranslation()
  const { shareholders } = offerDetailsForm
  const { offerTypeTranslationKey } = offerTypeConfig

  const {
    totalMaxSharesOfSelectedShareholders,
    totalLiquidityOfSelectedShareholders,
  } = useMemo(
    () =>
      offerDetailsForm.shareholders.reduce(
        (acc, value) => {
          acc.totalMaxSharesOfSelectedShareholders +=
            value.shares.maxAvailableShares
          acc.totalLiquidityOfSelectedShareholders += getTotalLiquidity(
            value.shares.maxAvailableShares
          )

          return acc
        },
        {
          totalMaxSharesOfSelectedShareholders: 0,
          totalLiquidityOfSelectedShareholders: 0,
        }
      ),
    [getTotalLiquidity, offerDetailsForm.shareholders]
  )

  const headCells: HeadCells = [
    {
      id: 'shareholders',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell1`
      ),
      width: '25%',
    },
    {
      id: 'shareClass',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell2`
      ),
      width: '30%',
    },
    {
      id: 'maxNumOfShares',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell3`
      ),
      width: '20%',
    },
    {
      id: 'totalLiquidity',
      label: i18n.t(
        `offer.review.${offerTypeTranslationKey}.table.tableHeadCell4`
      ),
      width: '25%',
    },
  ]

  return (
    <ResponsiveTable
      ariaLabel={`offer.review.${offerTypeTranslationKey}.table.ariaLabel`}
      headCells={headCells}
    >
      <TableBody>
        {shareholders.map((shareholderRow) => (
          <ReviewShareholdersTableRow
            key={shareholderRow.shareholder._id}
            getTotalLiquidity={getTotalLiquidity}
            shareholderRow={shareholderRow}
          />
        ))}
      </TableBody>

      {/* Table footer */}
      <ResponsiveTableFooter>
        <TableCell colSpan={2}>
          <Typography variant="body2" color="secondary.darkest">
            {t(`offer.review.${offerTypeTranslationKey}.table.tableFooter`)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary.darkest">
            {t('common.intlNumberFormat', {
              value: totalMaxSharesOfSelectedShareholders,
            })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary.darkest">
            {t('common.intlCurrencySimplifiedFormat', {
              value: totalLiquidityOfSelectedShareholders,
            })}
          </Typography>
        </TableCell>
      </ResponsiveTableFooter>
    </ResponsiveTable>
  )
}

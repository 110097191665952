import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Alert from 'components/mui/Alert'
import List from 'components/mui/List'
import DownloadStaticFile from 'components/files/DownloadStaticFile'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  handleBack: () => void
  handleNext: () => void
}

function DownloadIngestionTemplate({ handleBack, handleNext }: Props) {
  const { t } = useTranslation()

  // TODO: Add finalized file when spreadsheet is ready
  const capTableIngestionTemplate = {
    name: 'cap-table-ingestion-template.xlsx',
    path: '/templates/cap-table-ingestion-template.xlsx',
  }

  const instructions: string[] = t(
    'openstockAdmin.company.capTable.downloadIngestionTemplate.instructions',
    {
      returnObjects: true,
    }
  )

  const guidelines: string[] = t(
    'openstockAdmin.company.capTable.downloadIngestionTemplate.infoAlertGuidelines',
    {
      returnObjects: true,
    }
  )

  return (
    <Grid container rowGap={4}>
      {/* Instructions */}
      <Grid item>
        <Typography variant="h3">
          {t(
            'openstockAdmin.company.capTable.downloadIngestionTemplate.heading1'
          )}
        </Typography>
        <List listStyleType="number" list={instructions} />
        <Alert severity="info" variant="standard">
          <Typography>
            <Trans
              i18nKey={
                'openstockAdmin.company.capTable.downloadIngestionTemplate.infoAlert1'
              }
            />
          </Typography>
          <List list={guidelines} />
          <Typography>
            {t(
              'openstockAdmin.company.capTable.downloadIngestionTemplate.infoAlert2'
            )}
          </Typography>
        </Alert>
      </Grid>

      {/* Downloads */}
      <Grid item container rowGap={2}>
        <Grid item>
          <Typography variant="h3">
            {t(
              'openstockAdmin.company.capTable.downloadIngestionTemplate.heading2'
            )}
          </Typography>
        </Grid>
        <Grid item rowGap={1}>
          <DownloadStaticFile
            file={capTableIngestionTemplate}
            title={t('documents.capTableIngestionTemplate')}
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Grid item xs={12}>
        <ResponsiveStepperButtons
          handleBack={handleBack}
          handleForward={handleNext}
        />
      </Grid>
    </Grid>
  )
}

export default DownloadIngestionTemplate

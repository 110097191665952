import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'
import QueryBoundary from 'app/QueryBoundary'
import Alert from 'components/mui/Alert'
import InfoIcon from '@mui/icons-material/Info'
import Link from 'components/mui/Link'

import EmptyImage from 'assets/empty.svg'
import Button from 'components/mui/Button'
import Offerings from 'components/offerings/Offerings'
import Layout from 'components/Layout/Layout'
import TabBar from 'components/mui/TabBar'
import { Offer } from 'common/types'
import { useGetCompanyQuery, useGetCaptableQuery } from 'lib/apollo/hooks'
import { getDerivedCompanyStatus } from 'lib/data'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'

const filterOffers = (offers: Offer[]) => {
  const filteredOffers: { [s: string]: Offer[] } = {}

  offers?.forEach((offer: Offer) => {
    const offerStatusKey = offer.status.toLowerCase()
    if (!filteredOffers[offerStatusKey]) {
      filteredOffers[offerStatusKey] = []
      filteredOffers[offerStatusKey].push(offer)
    } else {
      filteredOffers[offerStatusKey].push(offer)
    }
  })

  return filteredOffers
}

const NoOffersWithSelectedStatus = ({
  status,
  canCreateOffers,
}: {
  status: string
  canCreateOffers: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      item
      xs={12}
      mt={4}
      mx="auto"
      sx={{
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" mb={2}>
        {t('dashboard.offers.noStatusOfferingsTitle', { value: status })}
      </Typography>
      <Typography mb={6}>
        {t(
          `dashboard.offers.${
            status === 'active'
              ? 'noVowelStatusOfferingsSubheading'
              : 'noStatusOfferingsSubheading'
          }`,
          { value: status }
        )}
      </Typography>
      <Box>
        <img src={EmptyImage} alt="" width="200" height="203" />
      </Box>
      {canCreateOffers && (
        <Button
          mode="forward"
          to={`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/create-offer`}
          sx={{ py: 0.6, width: 'fit-content', mx: 'auto', my: 5 }}
        >
          {t('dashboard.offers.createOfferButton')}
        </Button>
      )}
    </Grid>
  )
}

function CompanyAdminOfferings() {
  const { t } = useTranslation()

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery?.data?.getCompany

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery?.data?.getCaptable

  const offers = company?.offers

  const hasOfferings = offers && offers.length > 0

  const filteredOffers = useMemo(() => filterOffers(offers), [offers])

  const { canCreateOffers, termsIsExpired } = useMemo(
    () =>
      getDerivedCompanyStatus(
        company?.financialTermsCurrent,
        company,
        captable
      ),
    [captable, company]
  )

  const tabList = ['active', 'saved', 'completed', 'cancelled'].map(
    (status: string) => ({
      label: t(`dashboard.offers.sortOffersTabs.${status}`),
      path: status,
      element: filteredOffers?.[status] ? (
        <Offerings offers={filteredOffers?.[status]} isAllOffers={false} />
      ) : (
        <NoOffersWithSelectedStatus
          status={status}
          canCreateOffers={canCreateOffers}
        />
      ),
    })
  )

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <Layout maxWidth="lg">
        {/* Heading */}
        <Grid container rowSpacing={2} mb={4}>
          <Grid item xs={12} sm={7.5}>
            <Typography variant="h1">{t('dashboard.offers.title')}</Typography>
          </Grid>
          {hasOfferings && (
            <Grid item xs={12} sm={4.5}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { sm: 'flex-end' },
                }}
              >
                <Button
                  mode="forward"
                  to="../create-offer"
                  size="small"
                  sx={{
                    py: 0.6,
                  }}
                  disabled={!canCreateOffers}
                >
                  <Typography variant="button">
                    {t('dashboard.offers.createOfferButton')}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        {!canCreateOffers && termsIsExpired && (
          <Alert
            severity="info"
            variant="standard"
            icon={<InfoIcon />}
            sx={{ mb: 6 }}
          >
            <Typography>
              <Trans i18nKey="dashboard.offers.alerts.financingExpired">
                You can no longer create or publish offers because your
                financing term has ended. If you’re interested in getting
                additional financing, please contact
                <Link
                  href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                    'dashboard.offers.alerts.financingExpiredEmailSubject'
                  )}`}
                  variant="body2"
                >
                  partners@getopenstock.com
                </Link>
                .
              </Trans>
            </Typography>
          </Alert>
        )}
        <Grid item>
          {hasOfferings ? (
            <TabBar tabList={tabList} fallbackPath="active" />
          ) : (
            <Grid
              item
              container
              xs={12}
              sm={6}
              mt={4}
              mx="auto"
              sx={{
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">
                {t('dashboard.offers.noOfferingsTitle')}
              </Typography>
              <Typography my={2}>
                {!canCreateOffers
                  ? t('dashboard.offers.noOfferingsSubheadingUnapproved')
                  : t('dashboard.offers.noOfferingsSubheading')}
              </Typography>
              <Box>
                <img src={EmptyImage} alt="" width="200" height="203" />
              </Box>
              {!canCreateOffers ? (
                <Button
                  mode="forward"
                  to="../overview"
                  sx={{ py: 0.6, width: 'fit-content', mx: 'auto', my: 5 }}
                >
                  {t('dashboard.offers.goToOverviewButton')}
                </Button>
              ) : (
                <Button
                  mode="forward"
                  to="../create-offer"
                  sx={{ py: 0.6, width: 'fit-content', mx: 'auto', my: 5 }}
                >
                  <Typography variant="button">
                    {t('dashboard.offers.createOfferButton')}
                  </Typography>
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyAdminOfferings

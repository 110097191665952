import { Trans, useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Form, Formik } from 'lib/formik'
import { useUser } from 'app/User'
import { CapTableErrors } from 'common/types'
import { approveIngestedCapTableSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import Button from 'components/mui/Button'
import Container from 'components/mui/Container/Container'
import List from 'components/mui/List'
import RadioInputGroup from 'components/mui/RadioInputGroup'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import IngestedCapTable from 'components/tables/IngestedCapTable'
import Link from 'components/mui/Link'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'

const AdminErrorUI = ({ handleBack }: { handleBack: () => void }) => {
  const { t } = useTranslation()

  const fixErrorsSteps: string[] = t(
    'openstockAdmin.company.capTable.reviewIngestedCapTable.alert.fixErrorsSteps',
    {
      returnObjects: true,
    }
  )

  return (
    <>
      <Grid item>
        <Alert severity="warning" variant="standard">
          <Grid container rowGap={1.5}>
            <Grid item width={1}>
              <Typography>
                {t(
                  'openstockAdmin.company.capTable.reviewIngestedCapTable.alert.description'
                )}
              </Typography>
            </Grid>
            <Grid item width={1}>
              <Typography>
                {t(
                  'openstockAdmin.company.capTable.reviewIngestedCapTable.alert.fixErrors'
                )}
              </Typography>
              <List list={fixErrorsSteps} />
            </Grid>
            <Grid item width={1}>
              <Typography>
                {t(
                  'openstockAdmin.company.capTable.reviewIngestedCapTable.alert.nextSteps'
                )}
              </Typography>
            </Grid>
          </Grid>
        </Alert>
      </Grid>
      <Grid item>
        <Button onClick={handleBack}>
          {t(
            'openstockAdmin.company.capTable.reviewIngestedCapTable.errorAction'
          )}
        </Button>
      </Grid>
    </>
  )
}

const CompanyErrorUI = () => {
  const { t } = useTranslation()

  return (
    <Grid item>
      <Alert severity="warning" variant="standard">
        <Typography>
          <Trans i18nKey="dashboard.capTable.review.alert.description">
            You indicated that there are errors in the imported cap table;
            please contact us at
            <Link
              href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                'dashboard.capTable.review.alert.emailSubject'
              )}`}
              variant="body2"
              color="warning.dark"
            >
              partners@getopenstock.com
            </Link>
            . If the errors come from the cap table document that you uploaded,
            we suggest
            <Link to={'../cap-table/import-preview'} color="warning.dark">
              importing a new cap table
            </Link>
            to re-start the process.
          </Trans>
        </Typography>
      </Alert>
    </Grid>
  )
}

const initialValues = {
  isCapTableErrors: '',
} as CapTableErrors

interface Props {
  handleBack: () => void
  handleNext: () => void
  handleNavigate: () => void
  title: string
  description: string
  subHeading: string
  captableId?: string
}

function ReviewIngestedCapTableForm({
  handleBack,
  handleNext,
  handleNavigate,
  title,
  description,
  subHeading,
  captableId,
}: Props) {
  const { t } = useTranslation()
  const {
    roles: { isOpenstockAdmin },
  } = useUser()

  const radioInputOptions = [
    {
      value: 'no',
      label: t('ingestedCapTable.review.confirmation.noCapTableErrors'),
    },
    {
      value: 'yes',
      label: t('ingestedCapTable.review.confirmation.yesCapTableErrors'),
    },
  ]

  return (
    <Grid container flexDirection="column" rowGap={4}>
      {/* Heading */}
      <Grid item>
        <Container
          maxWidth="sm"
          sx={{ mx: 'auto', '&.MuiContainer-root': { px: 6 } }}
        >
          <Typography variant="h2" component="h1" mb={5}>
            {title}
          </Typography>
          <Typography>
            <Trans i18nKey={description} />
          </Typography>
        </Container>
      </Grid>

      {/* Cap table */}
      <Grid item xs={12}>
        <IngestedCapTable captableId={captableId} />
      </Grid>

      {/* Confirmation */}
      <Grid item>
        <Container
          maxWidth="sm"
          sx={{ mx: 'auto', '&.MuiContainer-root': { px: 6 } }}
        >
          <Grid container rowGap={2}>
            <Grid item>
              <Typography
                variant="h3"
                dangerouslySetInnerHTML={{
                  __html: subHeading,
                }}
              />
            </Grid>

            <Formik
              initialValues={initialValues}
              validationSchema={approveIngestedCapTableSchema}
              onSubmit={handleNext}
            >
              {({ values }) => (
                <Form>
                  <>
                    <Grid container rowGap={4}>
                      <Grid item>
                        <RadioInputGroup
                          name="isCapTableErrors"
                          id={t(
                            'ingestedCapTable.review.confirmation.radioInputId'
                          )}
                          options={radioInputOptions}
                        />
                      </Grid>
                      <Grid item container rowGap={6}>
                        {values.isCapTableErrors === 'no' ? (
                          <Grid item xs={12}>
                            {isOpenstockAdmin ? (
                              <ResponsiveStepperButtons
                                handleBack={handleBack}
                                handleForward={handleNext}
                              />
                            ) : (
                              <Button mode="forward" onClick={handleNext}>
                                {t('common.next')}
                              </Button>
                            )}
                          </Grid>
                        ) : (
                          values.isCapTableErrors === 'yes' && (
                            <>
                              {isOpenstockAdmin ? (
                                <AdminErrorUI handleBack={handleBack} />
                              ) : (
                                <>
                                  <CompanyErrorUI />
                                  <Button
                                    mode="forward"
                                    onClick={handleNavigate}
                                  >
                                    {t('dashboard.capTable.review.button')}
                                  </Button>
                                </>
                              )}
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </>
                </Form>
              )}
            </Formik>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ReviewIngestedCapTableForm

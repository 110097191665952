import { Trans, useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import Link from 'components/mui/Link'
import List from 'components/mui/List'
import Paper from 'components/mui/Paper/Paper'

interface SetupStepsProps {
  heading: string
  subHeading: string
  setupDescription: string
  setupSteps: { title: string; description: string }[]
  note?: string
}

export function SetupSteps({
  heading,
  subHeading,
  setupDescription,
  setupSteps,
  note,
}: SetupStepsProps) {
  return (
    <Grid item container justifyContent="space-between" rowGap={3}>
      <Grid item xs={12} smd={5}>
        <Typography variant="h3" mb={1.5}>
          {heading}
        </Typography>
        <Typography>{subHeading}</Typography>
      </Grid>
      <Grid item container xs={12} smd={6}>
        <Paper sx={{ width: 1 }}>
          <Grid item container rowGap={3}>
            <Grid item>
              <Typography variant="h6" color="secondary.dark">
                {setupDescription}
              </Typography>
            </Grid>
            <Grid item container gap={2}>
              {setupSteps.map(({ title, description }, index) => (
                <Grid key={title} item>
                  <Box display="flex">
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          backgroundColor: 'primary.main',
                          borderRadius: '50%',
                          marginRight: 1,
                          width: '20px',
                          height: '20px',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#fff"
                          alignSelf="center"
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body2">{title}</Typography>
                  </Box>
                  <Typography
                    ml={1}
                    mt={1}
                    pl={2.2}
                    pb={1.5}
                    borderLeft={setupSteps?.length - 1 === index ? 0 : 1}
                  >
                    {description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {note && (
              <Grid item>
                <Typography color="secondary.darkest">{note}</Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

interface PreviewDetailsProps {
  details: {
    heading: string
    subHeading: string
    items: {
      title: string
      description: string
      itemDetails?: string[]
    }[]
  }[]
  isCapTablePreview?: boolean
}

export function PreviewDetails({
  details,
  isCapTablePreview = false,
}: PreviewDetailsProps) {
  const { t } = useTranslation()
  return (
    <>
      {details.map(({ heading, subHeading, items }) => (
        <Grid
          key={heading}
          item
          container
          justifyContent="space-between"
          rowGap={2}
        >
          <Grid item xs={12} smd={5}>
            <Typography variant="h3" mb={1.5}>
              {heading}
            </Typography>
            <Typography>{subHeading}</Typography>
          </Grid>
          <Grid item container xs={12} smd={6} gap={2}>
            <Paper sx={{ width: 1 }}>
              <Grid item container>
                <Grid item container gap={3}>
                  {items.map(({ title, description, itemDetails }, index) => (
                    <Grid key={title} item>
                      <Typography variant="h6" color="secondary.dark" mb={2}>
                        {title}
                      </Typography>

                      {isCapTablePreview &&
                      heading === 'Frequently asked questions' &&
                      index === items.length - 1 ? (
                        <Typography>
                          <Trans i18nKey={description}>
                            The Openstock team manually reviews and processes
                            each cap table to ensure it is error-free. If errors
                            are found during processing, you will be contacted
                            by a member of our team who will help resolve the
                            errors as quickly as possible. And don't worry, you
                            can upload a new cap table at any time. If you run
                            into trouble while importing your cap table, contact
                            &nbsp;
                            <Link
                              href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                                'dashboard.capTable.import.emailSubject'
                              )}`}
                              variant="body2"
                            >
                              partners@getopenstock.com
                            </Link>
                            , and we'd be happy to help you out.
                          </Trans>
                        </Typography>
                      ) : (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      )}

                      {itemDetails && <List list={itemDetails} />}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default SetupSteps

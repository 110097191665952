import { Navigate, useParams } from 'react-router-dom'

import { DEFAULT_ROOT_ROUTE } from 'app/Routing'
import { useGetOfferQuery } from 'lib/apollo/hooks'
import CreateLoanOffer from './CreateLoanOffer'
import CreateTenderOffer from './CreateTenderOffer'

import QueryBoundary from 'app/QueryBoundary'

function EditOffer() {
  const { offerId } = useParams()

  const offerQuery = useGetOfferQuery(offerId)
  const offer = offerQuery?.data?.getOffer

  if (
    offer?.status === 'ACTIVE' ||
    offer?.status === 'COMPLETED' ||
    offer?.status === 'CANCELLED'
  ) {
    return <Navigate to={DEFAULT_ROOT_ROUTE} />
  }

  return (
    <QueryBoundary queries={[offerQuery]}>
      <>
        {offer?.kind === 'NON_RECOURSE_LOAN' && (
          <CreateLoanOffer editForm={offer} />
        )}
        {offer?.kind === 'TENDER_OFFER' && (
          <CreateTenderOffer editForm={offer} />
        )}
      </>
    </QueryBoundary>
  )
}

export default EditOffer

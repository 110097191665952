import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useMutation } from '@tanstack/react-query'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { verificationCodeDetailsSchema } from 'lib/validation'
import { useAuth } from 'app/Auth'

import Layout from 'components/Layout/Layout'
import Alert from 'components/mui/Alert'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'
import TextInput from 'components/mui/TextInput'

const initialValues = {
  code: '',
}

function VerificationCode() {
  const { sendCodeMutation } = useAuth()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const username = searchParams.get('username')

  const resendCodeMutation = useMutation((credentials: string) =>
    Auth.resendSignUp(credentials)
  )

  const handleSubmit = (values: typeof initialValues) => {
    sendCodeMutation.mutate({ username: username as string, code: values.code })
  }

  const handleResend = () => {
    resendCodeMutation.mutate(username as string)
  }

  return (
    <Layout>
      <Grid container spacing={2} columns={1}>
        <Grid item>
          <Typography variant="h2" component="h1">
            {t('account.verification.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('account.verification.subHeading', {
                email: username,
              }),
            }}
          />
        </Grid>

        <Grid item>
          <Formik
            initialValues={initialValues}
            validationSchema={verificationCodeDetailsSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={3} columns={1} my={1}>
                  <Grid item xs={1}>
                    <TextInput
                      id="code"
                      name="code"
                      placeholder={t(
                        'account.verification.verificationCodePlaceholder'
                      )}
                      maxLength={6}
                      required
                      inputMode="numeric"
                    />
                  </Grid>

                  {resendCodeMutation.isSuccess ? (
                    <Grid item xs={12}>
                      <Alert severity="success">
                        {t('account.verification.resent')}
                      </Alert>
                    </Grid>
                  ) : (
                    <Grid item xs={1}>
                      <Link
                        onClick={handleResend}
                        type="button"
                        variant="subtitle2"
                      >
                        {t('account.verification.resend')}
                      </Link>
                    </Grid>
                  )}
                  {resendCodeMutation.isError && (
                    <Grid item xs={12}>
                      <Alert title={t('common.error')}>
                        {(resendCodeMutation.error as Error).message ??
                          t('common.errorUnknown')}
                      </Alert>
                    </Grid>
                  )}
                  {sendCodeMutation.isError && (
                    <Grid item xs={12}>
                      <Alert title={t('common.error')}>
                        {(sendCodeMutation.error as Error).message ??
                          t('common.errorUnknown')}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      loading={
                        sendCodeMutation.isLoading ||
                        resendCodeMutation.isLoading ||
                        sendCodeMutation.isSuccess
                      }
                      type="submit"
                    >
                      {t('account.verification.logIn')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default VerificationCode

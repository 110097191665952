import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { useUser } from 'app/User'
import { ReactComponent as CalculatorIcon } from 'assets/calculator.svg'
import { createCalculatorUserDetailsAirtableRecord } from 'lib/airtable'
import { KEY_CALCULATOR_USER_DETAILS } from 'lib/config'
import { financialProductsList } from 'lib/formConfig'
import { Form, Formik, useFormikSync } from 'lib/formik'
import { calculatorUserDetailsSchema } from 'lib/validation'
import theme from 'styles/customTheme'

import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { CalculatorUser } from 'views/CalculatorPage'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import Mask from 'components/Mask'
import Paper from 'components/mui/Paper/Paper'
import TextInput from 'components/mui/TextInput'

function CalculatorUserDetails({
  isCalculatorLocked,
  setCalculatorUser,
}: {
  isCalculatorLocked: boolean
  setCalculatorUser: (values: CalculatorUser) => void
}) {
  const { t } = useTranslation()
  const [isOpenDetails, setIsOpenDetails] = useState(false)

  const { data } = useUser()

  const initialValues = {
    email: data?.emailAddress ?? '',
    company: data?.company?.name ?? '',
    financialProducts: [],
  }

  const { syncedFormikValues, SyncFormik } = useFormikSync(initialValues)

  const selectedFinancialProducts: string[] =
    syncedFormikValues.financialProducts

  const airtableMutation = useMutation((values: typeof initialValues) =>
    createCalculatorUserDetailsAirtableRecord({
      ...values,
    })
  )

  const handleCloseDialog = () => setIsOpenDetails(false)

  const handleChange = (product: string, setFieldValue: any) => {
    const selectedIndex = selectedFinancialProducts?.indexOf(product)

    let newSelected: string[] = []

    if (
      selectedIndex === -1 &&
      selectedFinancialProducts &&
      selectedFinancialProducts.length > 0
    ) {
      newSelected = [...selectedFinancialProducts, product]
    } else if (selectedIndex === -1) {
      newSelected.push(product)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFinancialProducts?.slice(1))
    } else if (
      selectedFinancialProducts?.length &&
      selectedIndex === selectedFinancialProducts.length - 1
    ) {
      newSelected = newSelected.concat(selectedFinancialProducts?.slice(0, -1))
    } else if (selectedIndex && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFinancialProducts?.slice(0, selectedIndex),
        selectedFinancialProducts?.slice(selectedIndex + 1)
      )
    }
    setFieldValue('financialProducts', newSelected)
  }

  const handleSubmit = (values: typeof initialValues) => {
    airtableMutation.mutate({
      ...values,
    })

    const userDetails = {
      email: values.email,
      company: values.company,
    }

    localStorage.setItem(
      KEY_CALCULATOR_USER_DETAILS,
      JSON.stringify(userDetails)
    )
    setCalculatorUser(userDetails)

    return handleCloseDialog()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={calculatorUserDetailsSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue, errors }) => (
        <>
          <Dialog
            open={isOpenDetails}
            onClose={handleCloseDialog}
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '6px',
                maxWidth: '500px',
                padding: '1.2rem',
              },
            }}
          >
            <Form>
              <SyncFormik values={values} />

              {/* Form dialog calculator user detail  */}
              <Grid item container>
                <Grid container alignItems="center" mb={2}>
                  <Grid item xs="auto">
                    <CalculatorIcon />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography variant="h2" ml={1}>
                      {t('calculator.details.title')}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography>{t('calculator.details.body')}</Typography>
                <Grid item container rowSpacing={3} mt={1} xs={12} sm={9}>
                  <Grid item xs={12}>
                    <TextInput
                      id="email"
                      name="email"
                      label={t('calculator.details.form.emailLabel')}
                      placeholder={t(
                        'calculator.details.form.emailPlaceholder'
                      )}
                      type="email"
                      inputMode="email"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      id="company"
                      name="company"
                      label={t('calculator.details.form.companyLabel')}
                      placeholder={t(
                        'calculator.details.form.companyPlaceholder'
                      )}
                      required
                    />
                  </Grid>

                  <Grid item container xs={12}>
                    <Typography
                      variant="subtitle2"
                      color={
                        errors.financialProducts && theme.palette.error.main
                      }
                      mb={1}
                      dangerouslySetInnerHTML={{
                        __html: t('calculator.details.form.productsLabel'),
                      }}
                    />

                    {financialProductsList.map((product) => (
                      <Grid item xs={12} key={product}>
                        <FormControlLabel
                          label={product}
                          control={
                            <Checkbox
                              checked={selectedFinancialProducts.includes(
                                product
                              )}
                              disableTouchRipple
                              onChange={() =>
                                handleChange(product, setFieldValue)
                              }
                            />
                          }
                        />
                      </Grid>
                    ))}
                    {errors.financialProducts && (
                      <FormHelperText sx={{ color: theme.palette.error.main }}>
                        {t('common.validation.checkboxRequired')}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item my={2}>
                    <Typography>
                      <Trans i18nKey="account.profile.agreement">
                        By continuing, you agree to our
                        <Link href="/terms" target="_blank">
                          Terms &nbsp; Conditions
                        </Link>
                        and
                        <Link href="/privacy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                  rowGap={2}
                >
                  <DialogActions>
                    <Button
                      onClick={() => setIsOpenDetails(false)}
                      variant="outlined"
                      sx={{ mr: 0.5 }}
                    >
                      {t('common.cancel')}
                    </Button>

                    <Button type="submit" autoFocus sx={{ ml: 0.5 }}>
                      {t('calculator.details.form.button')}
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </Dialog>
          {/* Bottom blocker - click to unlock calculator */}
          <Mask open={isCalculatorLocked && !isOpenDetails} zIndex={2}>
            <Grid container alignSelf="end">
              <Paper
                sx={{
                  borderRadius: '0px',
                  width: 1,
                }}
              >
                <Grid container justifyContent="center" py={1}>
                  <Grid item container maxWidth="380px">
                    <Grid container alignItems="center" mb={2}>
                      <Grid item xs="auto">
                        <CalculatorIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="h2" ml={1}>
                          {t('calculator.explore.title')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography>{t('calculator.explore.body')}</Typography>
                    <Grid item container justifyContent="flex-end" mt={4}>
                      <Button
                        mode="forward"
                        onClick={() => setIsOpenDetails(true)}
                      >
                        {t('calculator.explore.button')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Mask>
        </>
      )}
    </Formik>
  )
}

export default CalculatorUserDetails

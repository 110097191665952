import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'

import { CREATE_COMPANY_ROOT_ROUTE } from 'views/companyAdmin/createCompanyAccount/CreateCompanyAccount'
import Login from 'views/user/Login/Login'

function InviteCompany() {
  const { isLoggedIn, redirectPathOnAuth, dispatch } = useAuth()
  const user = useUser()

  useEffect(() => {
    if (!isLoggedIn && redirectPathOnAuth !== CREATE_COMPANY_ROOT_ROUTE) {
      return dispatch({
        type: 'set_redirect',
        payload: `${CREATE_COMPANY_ROOT_ROUTE}?status=invite`,
      })
    }
  }, [dispatch, isLoggedIn, redirectPathOnAuth])

  if (
    isLoggedIn &&
    user.data.roles.includes('COMPANY_ADMIN') &&
    user.data.company
  ) {
    return <Navigate to="/" replace />
  }

  if (!isLoggedIn) {
    return <Login />
  }

  return <Navigate to={CREATE_COMPANY_ROOT_ROUTE} replace />
}

export default InviteCompany

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import SetupSteps, { PreviewDetails } from 'components/Preview'

function ImportCapTablePreview() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const setupSteps: { title: string; description: string }[] = t(
    'dashboard.capTable.import.setup.description.steps',
    {
      returnObjects: true,
    }
  )

  const details: {
    heading: string
    subHeading: string
    items: { title: string; description: string }[]
  }[] = t('dashboard.capTable.import.details', {
    returnObjects: true,
  })

  const handleClick = () => navigate('../cap-table/import')

  return (
    <Layout maxWidth="lg">
      <Grid container justifyContent="space-between" mb={5}>
        <Typography variant="h1" mb={2}>
          {t('dashboard.capTable.import.title')}
        </Typography>
        <Box>
          <Button isLightButton mode="forward" onClick={handleClick}>
            {t('dashboard.capTable.import.button')}
          </Button>
        </Box>
      </Grid>
      <Grid container justifyContent="flex-end" rowGap={5}>
        <SetupSteps
          heading={t('dashboard.capTable.import.setup.heading')}
          subHeading={t('dashboard.capTable.import.setup.subHeading')}
          setupDescription={t(
            'dashboard.capTable.import.setup.description.label'
          )}
          setupSteps={setupSteps}
          note={t('dashboard.capTable.import.setup.description.note')}
        />

        <PreviewDetails details={details} isCapTablePreview />

        <Grid item container xs={12} smd={6}>
          <Button mode="forward" onClick={handleClick}>
            {t('dashboard.capTable.import.button')}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ImportCapTablePreview

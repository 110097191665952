import {
  CaptableShareclass,
  CaptableShareholderGroupedTable,
  CompanyFile,
  CompanyFinancialTerms,
  Offer,
  ShareholderShareInfo,
  Offers,
  OfferTo,
  User,
  Captable,
  Company,
} from 'common/types'
import dayjs from 'lib/dayjs'
import {
  calcChartLoopIteration,
  minimumLoanAmount,
  offerFixedTimelineDays,
  UPLOAD_DOCUMENTS_LIST_BY_REQUIRED,
} from 'lib/formConfig'
import { ApproveShareholderOffer } from 'views/shareholder/ShareholderReviewAcceptOffer'

export function sanitizeResultsFormData(data?: Offer) {
  if (!data) return null

  const parsedData = JSON.parse(JSON.stringify(data))

  return {
    ...parsedData,
    shareclasses: data.shareclasses.map(
      (shareclass: CaptableShareclass) => shareclass._id
    ),
    shareholders: data.offerTo.map((shareholder) => ({
      ...shareholder,
      shares: {
        maxAvailableShares: shareholder.maxAvailableShares,
        liquidityAvailable: shareholder.liquidityAvailable,
      },
    })),
    type: data.kind,
    percentageOfAssets: data.liquidityPercentPerShareholder,
    totalLiquidity: data.liquidityAvailable,
    totalShares: data.maxAvailableShares,
  }
}

export const getDerivedShareclassGroupedTable = ({
  shareholderGroupedTable,
  sharePrice = 0,
}: {
  shareholderGroupedTable: CaptableShareholderGroupedTable
  sharePrice?: number
}) =>
  shareholderGroupedTable.shareclasses.map((shareclass, shareclassI) => {
    const numberOfShares = shareholderGroupedTable.rows
      .map((currRow) =>
        currRow.columns[shareclassI] === null
          ? 0
          : currRow.columns[shareclassI]?.number ?? 0
      )
      .reduce((acc, curr) => acc + curr, 0)

    const numberOfShareholders = shareholderGroupedTable.rows.filter(
      (currRow) => currRow.columns[shareclassI] !== null
    ).length

    const shareholders = shareholderGroupedTable.rows
      .map((currRow) =>
        currRow.columns[shareclassI] !== null ? currRow.shareholder : null
      )
      .filter((x) => x !== null)

    return {
      _id: shareclass._id,
      name: shareclass.name,
      numberOfShareholders,
      numberOfShares,
      shareholders,
      totalValueOfShares: numberOfShares * sharePrice,
    }
  })

//Gets a list of shareholder share info by selected shareclassIds
export const getShareholderShareInfo = ({
  shareclassIds,
  shareholderGroupedTable,
  sharePrice = 0,
}: {
  shareclassIds: CaptableShareclass['_id'][]
  shareholderGroupedTable: CaptableShareholderGroupedTable
  sharePrice?: CompanyFinancialTerms['sharePrice']
}) => {
  const shareclasses = shareholderGroupedTable.shareclasses
    .map((shareclassRow: CaptableShareclass, shareclassRowIndex: number) => [
      shareclassRow,
      shareclassRowIndex,
    ])
    .filter((shareclassArray: (CaptableShareclass | number)[]) =>
      shareclassIds.includes((shareclassArray[0] as CaptableShareclass)._id)
    )
    .map((currShareclassArray) => ({
      info: currShareclassArray[0],
      colIndex: currShareclassArray[1],
    }))

  return shareholderGroupedTable.rows
    .map((row) => {
      const shareholderShareclasses = shareclasses
        .filter(
          (shareclasses) =>
            row.columns[shareclasses.colIndex as keyof typeof row.columns] !==
            null
        )
        .map((currShareclassArray) => currShareclassArray.info)
      const sharesTotal = shareclasses.reduce(
        (acc, curr) =>
          acc +
          (row.columns[curr.colIndex as keyof typeof row.columns] === null
            ? 0
            : (row.columns[curr.colIndex as keyof typeof row.columns] as any)
                ?.number),
        0
      )

      return {
        shareholder: row.shareholder,
        shareclasses: shareholderShareclasses as CaptableShareclass[],
        shares: {
          maxAvailableShares: sharesTotal,
          liquidityAvailable: sharesTotal * sharePrice,
        },
      }
    })
    .filter((x) => x.shareclasses.length)
}

export const getShareclassesByIds = ({
  shareclasses,
  shareclassIds,
}: {
  shareclasses: CaptableShareclass[]
  shareclassIds: CaptableShareclass['_id'][]
}) =>
  shareclasses.filter(
    (shareclass) => shareclassIds.indexOf(shareclass._id) !== -1
  )

export const getOfferExpiryDate = ({
  expiryDate,
}: {
  expiryDate: CompanyFinancialTerms['expiryDate']
}) => {
  const fixedTimelineExpiryDate = dayjs()
    .add(dayjs.duration({ days: offerFixedTimelineDays }))
    .endOf('day')

  const offerExpiryDate = fixedTimelineExpiryDate.isBefore(dayjs(expiryDate))
    ? fixedTimelineExpiryDate
    : expiryDate

  return dayjs(offerExpiryDate).toISOString()
}

export const calculateLoanDetails = ({
  requestedLoanAmount,
  loanDetails,
  offer,
  shareholderOffer,
  sofrRate,
}: {
  requestedLoanAmount: number
  loanDetails: ApproveShareholderOffer['shareholderLoanOfferDetails']
  offer: Offer
  shareholderOffer: OfferTo
  sofrRate: number
}): ApproveShareholderOffer['shareholderLoanOfferDetails'] => {
  const calculatedLoanDetails = { ...loanDetails }

  const originationFee =
    (requestedLoanAmount / 100) * offer?.financialTerms?.originationFee
  const totalInterest =
    ((requestedLoanAmount / 100) *
      (sofrRate +
        offer?.financialTerms?.interestRate +
        offer?.financialTerms?.platformFee) *
      offer?.financialTerms?.loan.termInMonths) /
    12
  const quarterlyPayments =
    totalInterest / ((offer?.financialTerms?.loan.termInMonths / 12) * 4)

  calculatedLoanDetails.stakedShares = shareholderOffer?.maxAvailableShares
  calculatedLoanDetails.originationFee = originationFee
  calculatedLoanDetails.loanAmountReceived =
    requestedLoanAmount - originationFee
  calculatedLoanDetails.totalInterest = totalInterest

  calculatedLoanDetails.quarterlyPayments = quarterlyPayments
  calculatedLoanDetails.principalRepayment =
    requestedLoanAmount + quarterlyPayments

  return calculatedLoanDetails
}

export const getUploadedRequiredFiles = (files: CompanyFile[]) =>
  files.filter(({ label }) =>
    UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.required.find(
      ({ name }: { name: string }) => name === label
    )
  )

export const getHasUploadedRequiredFiles = (files: CompanyFile[]) =>
  getUploadedRequiredFiles(files).length ===
  UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.required.length

export const getUploadedFilesByLabel = (files: CompanyFile[]) =>
  files.reduce(
    (acc, cur) => {
      acc[cur.label] = cur

      return acc
    },
    {} as { [key: string]: CompanyFile }
  )

export const getIsCapTableProcessing = (companyFiles: CompanyFile[]) =>
  companyFiles?.filter(
    (file: CompanyFile) =>
      file.label === 'capitalizationTable' ||
      file.label === 'shareholderInformation'
  ).length === 2

export const getFinancingAvailable = (
  maxCreditAvailable: CompanyFinancialTerms['maxCreditAvailable'],
  offers: Offers
) => {
  const totalLiquidityOffered = offers
    .filter((offer) => offer.status === 'ACTIVE')
    .reduce((acc, value) => acc + value.liquidityAvailable, 0)
  const liquidityAvailable = maxCreditAvailable - totalLiquidityOffered
  return Math.max(0, liquidityAvailable)
}

export const getIsShareholderIneligible = (
  shareholder: ShareholderShareInfo[0],
  loanPercentage: number
) => {
  const maxProposedLiquidity = Math.floor(
    shareholder?.shares.liquidityAvailable * (loanPercentage / 100)
  )

  return maxProposedLiquidity < minimumLoanAmount
}

export const getIneligibleShareholders = (
  shareholders: ShareholderShareInfo,
  loanPercentage: number
) =>
  shareholders.filter((shareholder) =>
    getIsShareholderIneligible(shareholder, loanPercentage)
  )

// We accept these props separately (instead of just a company object) so that data points can be inherited from being attached to other pieces of data (such as financial terms being attached to an offer)
export const getDerivedCompanyStatus = (
  financialTerms: CompanyFinancialTerms,
  company: Company,
  captable?: Captable
) => {
  const termChecks = {
    termsIsStarted: Boolean(
      financialTerms && dayjs(financialTerms.startDate).isBefore(dayjs())
    ),
    termsIsExpired: Boolean(
      financialTerms && dayjs(financialTerms.expiryDate).isBefore(dayjs())
    ),
    termsIsActive: Boolean(financialTerms) && company?.isApproved,
    capTableExists: Boolean(company?.captableCurrent),
    capTableIsValid: Boolean(
      captable?.isApprovedBy?.openstockAdmin &&
        captable?.isApprovedBy?.companyAdmin
    ),
    termsIsValid: true,
    canCreateOffers: true,
  }

  termChecks.termsIsValid =
    termChecks.termsIsStarted &&
    !termChecks.termsIsExpired &&
    termChecks.termsIsActive

  // Offers can be created before terms have started, just not published, so this is different than termsIsValid
  termChecks.canCreateOffers =
    termChecks.termsIsActive &&
    !termChecks.termsIsExpired &&
    termChecks.capTableIsValid

  return termChecks
}

export const getInterestAndPlatformFee = (
  financialTerms: Offer['financialTerms']
) =>
  financialTerms
    ? (financialTerms?.interestRate + financialTerms?.platformFee).toFixed(2)
    : 0

export const getShareholderOffer = (
  offer: Offer,
  userEmail: User['emailAddress']
) =>
  offer?.offerTo?.find(
    (to: OfferTo) => to.shareholder?.emailAddress === userEmail
  )

export const getChartScalingArray = (value: number) => {
  // First element of the array should always be 0
  const data = [0, 0, value, 0, 0, 0, 0]

  if (value === 0) return data

  // No rounding for values lower than 2
  const halfValue = value < 2 ? value / 2 : Math.floor(value / 2)

  // Decrease scale of value by its 50%
  for (let i = 1; i > 0; i--) {
    data[i] = data[i + 1] - halfValue
  }

  // Increase scale of value by its 50%
  for (let i = 3; i < calcChartLoopIteration; i++) {
    data[i] = data[i - 1] + halfValue
  }

  return data
}

import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DateRangeIcon from '@mui/icons-material/DateRange'
import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import QueryBoundary from 'app/QueryBoundary'
import useSofrRate from 'hooks/useSofrRate'
import { useGetOfferQuery } from 'lib/apollo/hooks'
import { getInterestAndPlatformFee, getShareholderOffer } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import {
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
} from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'

import BackLink from 'components/BackLink'
import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import LoanOfferDetailsCard, {
  LoanDetails,
} from 'components/shareholderOffer/LoanOfferDetailsCard'

function ReviewOffer() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { offerId } = useParams()

  const {
    data: { emailAddress: userEmail },
  } = useUser()

  const handleNavigate = () => navigate(`../offer/${offerId}/explore`)

  const sofrRateQuery = useSofrRate()
  const sofrRate = sofrRateQuery.data?.percentRate

  const offerQuery = useGetOfferQuery(offerId)
  const offer = offerQuery?.data?.getOffer

  const financialTerms = offer?.financialTerms

  const shareholderOffer = useMemo(
    () => offer && getShareholderOffer(offer, userEmail),
    [offer, userEmail]
  )

  const interestAndPlatformFee = useMemo(
    () => financialTerms && getInterestAndPlatformFee(financialTerms),
    [financialTerms]
  )

  const loanDetails: LoanDetails = [
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.eligibleLoanAmount.label'
      ),
      isLargeHeading: true,
      data: t('common.intlCurrencySimplifiedFormat', {
        value: shareholderOffer?.liquidityAvailable,
      }),
      notesElement: (
        <Box alignContent="center" display="flex">
          <DateRangeIcon sx={{ mr: 1.5 }} />
          <Typography
            pt={0.25}
            dangerouslySetInnerHTML={{
              __html: t(
                'shareholder.offer.nonRecourseLoan.details.eligibleLoanAmount.notes',
                {
                  value: dayjs(offer?.expiryDate).format(
                    monthDayFullYearSimplifiedFormat
                  ),
                }
              ),
            }}
          />
        </Box>
      ),
    },
    {
      heading: t('shareholder.offer.nonRecourseLoan.details.loanType.label'),
      data:
        financialTerms?.loan?.kind &&
        getLabelByValue(financialTerms?.loan?.kind, loanTypes),
      notes: t('shareholder.offer.nonRecourseLoan.details.loanType.notes'),
    },
    {
      heading: t('shareholder.offer.nonRecourseLoan.details.loanTerm.label'),
      data: t('common.monthWithCount', {
        count: financialTerms?.loan?.termInMonths,
      }),
      notes: `${dayjs(financialTerms?.startDate).format(
        monthDayFullYearSimplifiedFormat
      )} - ${dayjs(financialTerms?.expiryDate).format(
        monthDayFullYearSimplifiedFormat
      )}`,
    },
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.stakedShares.label'
      ),
      data: t('shareholder.offer.nonRecourseLoan.details.stakedShares.value', {
        value: '100%',
      }),
      notes: t('shareholder.offer.nonRecourseLoan.details.stakedShares.notes'),
    },
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.label'
      ),
      data: `${financialTerms?.originationFee}%`,
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.notes'
      ),
    },
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanInterestRate.label'
      ),
      data: t(
        'shareholder.offer.nonRecourseLoan.details.loanInterestRate.options.variable.value',
        {
          sofrRate,
          interestRate: interestAndPlatformFee,
        }
      ),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanInterestRate.notes'
      ),
    },
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanRepaymentPlan.label'
      ),
      data:
        financialTerms?.loan?.structure &&
        getLabelByValue(financialTerms?.loan?.structure, loanStructures),
      notesElement: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: t(
              'shareholder.offer.nonRecourseLoan.details.loanRepaymentPlan.notes',
              {
                value: dayjs(financialTerms?.expiryDate).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }
            ),
          }}
        />
      ),
    },
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanRepaymentSchedule.label'
      ),
      data:
        financialTerms?.loan?.repaymentSchedule &&
        getLabelByValue(
          financialTerms?.loan?.repaymentSchedule,
          loanRepaymentSchedules
        ),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanRepaymentSchedule.notes'
      ),
    },
  ]

  return (
    <QueryBoundary queries={[offerQuery, sofrRateQuery]}>
      <Layout maxWidth="lg">
        <BackLink to={`${DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE}`} />
        <Typography variant="h1" mb={5}>
          {t('shareholder.offer.nonRecourseLoan.title')}
        </Typography>
        <Grid container justifyContent="space-between" rowGap={5}>
          {/* Details */}
          <Grid
            item
            container
            alignContent="flex-start"
            xs={12}
            smd={5}
            rowGap={5}
          >
            <Grid item container rowGap={1.5}>
              <Typography variant="h3">
                {t('shareholder.offer.nonRecourseLoan.review.subHeading1')}
              </Typography>
              <Typography>
                {t('shareholder.offer.nonRecourseLoan.review.description1', {
                  company: offer?.company.name,
                })}
              </Typography>
            </Grid>
            <Grid item container rowGap={1.5}>
              <Typography variant="h3">
                {t('shareholder.offer.nonRecourseLoan.review.subHeading2')}
              </Typography>
              <Typography>
                {t('shareholder.offer.nonRecourseLoan.review.description2')}
              </Typography>
            </Grid>
          </Grid>

          {/* Review loan offer */}
          <Grid item container xs={12} smd={5.5} alignContent="flex-start">
            <Grid item>
              <LoanOfferDetailsCard loanDetails={loanDetails} />
            </Grid>
            <Grid item container rowGap={5}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" mt={2} color="secondary.dark">
                  {t('shareholder.offer.sofrDisclaimer')}
                </Typography>
              </Grid>
              <Button onClick={handleNavigate} mode="forward">
                {t('shareholder.offer.nonRecourseLoan.review.exploreLoan')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default ReviewOffer

import { gql } from '@apollo/client'

export const SUBMIT_COMPANY_SIGNUP_DETAILS_MUTATION = gql`
  mutation SUBMIT_COMPANY_SIGNUP_DETAILS_MUTATION(
    $userId: ID!
    $companyProperties: SubmitCompanySignupDetails_Company_Properties_Input!
    $userProperties: SubmitCompanySignupDetails_User_Properties_Input!
  ) {
    submitCompanySignupDetails(
      userId: $userId
      companyProperties: $companyProperties
      userProperties: $userProperties
    ) {
      # firstName
      # lastName
      # emailAddress
      # phoneNumber
      # address {
      #   streetAddress
      #   state
      #   country
      #   zipCode
      # }
      # roles
      # company {
      #   _id
      # }
      # userpoolSub
      # createdAt
      # updatedAt
      _id
    }
  }
`

export const SIGN_COMPANY_AGREEMENT_MUTATION = gql`
  mutation SIGN_COMPANY_AGREEMENT_MUTATION(
    $version: Int!
    $userInfo: SignCompanyAgreement_User_Input!
    $companyInfo: SignCompanyAgreement_Company_Input!
    $adminInfo: SignCompanyAgreement_Admin_Input!
    $incorporationInfo: SignCompanyAgreement_IncorporationInfo_Input
    $userSignature: String!
    $submissionDate: AWSDateTime!
  ) {
    signCompanyAgreement(
      version: $version
      userInfo: $userInfo
      companyInfo: $companyInfo
      adminInfo: $adminInfo
      incorporationInfo: $incorporationInfo
      userSignature: $userSignature
      submissionDate: $submissionDate
    ) {
      _id
    }
  }
`

export const RESET_COMPANY_INCORPORATION_DETAILS_MUTATION = gql`
  mutation RESET_COMPANY_INCORPORATION_DETAILS_MUTATION($companyId: ID!) {
    resetCompanyIncorporationDetails(companyId: $companyId)
  }
`

export const CREATE_COMPANY_AGREEMENT_DOWNLOAD_LINK = gql`
  mutation CREATE_COMPANY_AGREEMENT_DOWNLOAD_LINK($id: ID!) {
    createCompanyAgreementDownloadLink(id: $id) {
      url
    }
  }
`

export const CREATE_OFFER_MUTATION = gql`
  mutation CREATE_OFFER_MUTATION(
    $captableId: ID!
    $offerTo: [CreateOffer_Shareholder!]!
    $properties: CreateOffer_Properties!
  ) {
    createOffer(
      captableId: $captableId
      offerTo: $offerTo
      properties: $properties
    ) {
      _id
    }
  }
`

export const GET_USER_BY_USER_POOL_SUB_QUERY = gql`
  query GET_USER_BY_USER_POOL_SUB_QUERY($sub: ID!) {
    getUserByUserPoolSub(sub: $sub) {
      firstName
      lastName
      emailAddress
      phoneNumber
      # address {
      # streetAddress
      # state
      # country
      # zipCode
      # }
      roles
      offers {
        _id
      }
      company {
        _id
        name
        isApproved
        countryOfIncorporation
        stateOfIncorporation
        companyAgreementCurrent {
          # version
          # submissionDate
          # createdAt
          _id
        }
        companyAgreements {
          # version
          # submissionDate
          # createdAt
          _id
        }
        # offers {
        #   kind
        #   subKind
        #   startDate
        #   expiryDate
        #  liquidityToOffer
        #  liquidityPerValuePerShareholder
        #  liquidityPercentPerShareholder
        #  status
        #  createdAt
        #  updatedAt
        #  _id
        # }
        # files {
        #   _id
        # }
      }
      userpoolSub
      # createdAt
      # updatedAt
      _id
    }
  }
`

export const GET_USER_QUERY = gql`
  query GET_USER_QUERY($id: ID!) {
    getUser(id: $id) {
      offers {
        _id
        kind
        expiryDate
        liquidityAvailable
        status
        financialTerms {
          originationFee
          platformFee
          interestRate
          loan {
            kind
          }
        }
        offerTo {
          shareholder {
            emailAddress
          }
          liquidityAvailable
        }
      }
      loans {
        _id
        loanAmountRequested
        # stakedShares
        # originationFee
        # loanAmountReceived
        # interestTotal
        # quarterlyInterestOnlyPayments
        # principalRepayment
        # sofr
        # loanUserData {
        #   ssn
        #   _id
        # }
        offer {
          kind
          expiryDate
          financialTerms {
            originationFee
            interestRate
            loan {
              structure
              termInMonths
              repaymentSchedule
            }
            expiryDate
          }
        }
      }
    }
  }
`

export const GET_OFFER_QUERY = gql`
  query GET_OFFER_QUERY($id: ID!) {
    getOffer(id: $id) {
      kind
      startDate
      expiryDate
      company {
        _id
        name
        isApproved
      }
      offerTo {
        shareholder {
          name
          _id
          emailAddress
        }
        shareclasses {
          name
          _id
        }
        maxAvailableShares
        liquidityAvailable
      }
      liquidityAvailable
      liquidityPercentPerShareholder
      maxAvailableShares
      status
      captable {
        createdAt
        _id
      }
      financialTerms {
        companyValuation
        interestRate
        loan {
          kind
          repaymentSchedule
          structure
          termInMonths
          valueRatio
        }
        maxCreditAvailable
        originationFee
        platformFee
        sharePrice
        startDate
        expiryDate
        createdAt
        updatedAt
        _id
      }
      shareclasses {
        name
        _id
      }
      createdAt
      updatedAt
      _id
    }
  }
`

export const GET_COMPANIES_QUERY = gql`
  query GET_COMPANIES_QUERY {
    getCompanies {
      name
      _id
      # address {
      #   country
      #   state
      #   streetAddress
      #   city
      #   zipCode
      # }
      websiteAddress
      offers {
        _id
        status
      }
      # hasLawsuitsPendingRegulatoryAction
      # lawsuitsOrPendingRegulatoryActionDescription
      # hasPriorAuditIssues
      # priorAuditIssuesDescription
      # jurisdictionOfIncorporation
      # numberOfEmployees {
      #   start
      #   end
      # }
      # valuationRange {
      #   start
      #   end
      # }
      # signingOfficers {
      #   firstName
      #   lastName
      #   emailAddress
      #   phoneNumber
      #   address {
      #     country
      #     state
      #     streetAddress
      #     zipCode
      #   }
      #   roles
      #   # offers
      #   # company
      #   userpoolSub
      #   createdAt
      #   updatedAt
      #   _id
      # }
      # invitedUsers {
      #   fullName
      #   emailAddress
      #   acceptedInvite
      #   acceptedInviteDate
      #   roles
      #   # invitedBy
      #   # user
      #   # company
      #   _id
      # }
      # files {
      # fileType
      # label
      # nameOriginal
      # size
      # _id
      # }
    }
  }
`

export const GET_COMPANY_QUERY = gql`
  query GET_COMPANY_QUERY($id: ID!) {
    getCompany(id: $id) {
      name
      ein
      address {
        country
        state
        streetAddress
        streetAddress2
        city
        zipCode
      }
      websiteAddress
      emailDomain
      incorporationDetailsProvided
      countryOfIncorporation
      stateOfIncorporation
      # isActive
      isApproved
      # hasLawsuitsPendingRegulatoryAction
      # lawsuitsOrPendingRegulatoryActionDescription
      # hasPriorAuditIssues
      # priorAuditIssuesDescription
      # jurisdictionOfIncorporation
      # numberOfEmployees {
      #   start
      #   end
      # }
      # valuationRange {
      #   start
      #   end
      # }
      # signingOfficers {
      #   _id
      #   firstName
      #   lastName
      #   emailAddress
      #   roles
      # }
      financialTermsCurrent {
        companyValuation
        interestRate
        loan {
          kind
          repaymentSchedule
          structure
          termInMonths
          valueRatio
        }
        maxCreditAvailable
        originationFee
        platformFee
        sharePrice
        startDate
        expiryDate
        createdAt
        updatedAt
        _id
      }
      captableCurrent {
        _id
      }
      invitedUsers {
        _id
      }
      users {
        _id
      }
      offers {
        kind
        startDate
        expiryDate
        offerTo {
          shareholder {
            name
          }
          # shareclasses {
          #   name
          # }
          # maxAvailableShares
          # liquidityAvailable
        }
        liquidityAvailable
        # liquidityPercentPerShareholder
        # maxAvailableShares
        status
        # captable {
        #   createdAt
        #   _id
        # }
        # financialTerms {
        #   _id
        # }
        shareclasses {
          name
          _id
        }
        # createdAt
        # updatedAt
        _id
      }
      files {
        _id
        label
        # versions
        current {
          fileType
          nameOriginal
          size
          uploadedBy {
            _id
            firstName
            lastName
            emailAddress
            phoneNumber
          }
          createdAt
          updatedAt
          _id
        }
      }
      createdAt
      updatedAt
      _id
    }
  }
`

export const SET_COMPANY_FINANCIAL_TERMS_MUTATION = gql`
  mutation SET_COMPANY_FINANCIAL_TERMS_MUTATION(
    $companyId: ID!
    $properties: SetCompanyFinancialTerms_Properties!
  ) {
    setCompanyFinancialTerms(companyId: $companyId, properties: $properties) {
      company {
        name
        _id #To join
      }
      # companyValuation
      # interestRate
      # loan {
      #   kind
      #   repaymentSchedule
      #   structure
      #   termInMonths
      #   valueRatio
      # }
      # maxCreditAvailable
      # originationFee
      # platformFee
      # sharePrice
      # startDate
      # expiryDate

      # createdAt
      # updatedAt
      # _id
    }
  }
`

export const GET_CAPTABLE_QUERY = gql`
  query GET_CAPTABLE_QUERY($id: ID!) {
    getCaptable(id: $id) {
      shareholderGroupedTable {
        shareclasses {
          _id
          name
          # isPreferred
          kind
        }
        rows {
          shareholder {
            name
            emailAddress
            isEmployee
            isCompany

            _id
          }
          columns {
            number
            # typename
            _id
          }
        }
      }
      isApprovedBy {
        companyAdmin
        openstockAdmin
      }
      _id
      createdAt
    }
  }
`

export const GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_QUERY = gql`
  query GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_QUERY($offerId: ID!) {
    getOfferCaptableShareholders(offerId: $offerId) {
      name
      shareclasses {
        name
      }
      maxAvailableShares
      liquidityAvailable
      subscriptionStatuses
      loanAmountRequested
    }
  }
`

export const GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_DATA_QUERY = gql`
  query GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_DATA_QUERY($offerId: ID!) {
    getOfferCaptableShareholdersData(offerId: $offerId) {
      name
      shareclasses {
        name
      }
      maxAvailableShares
      liquidityAvailable
      subscriptionStatuses
      loanAmountRequested
      emailAddress
      phoneNumber
      loanUserData {
        address {
          country
          state
          city
          streetAddress
          streetAddress2
          zipCode
        }
        bankingInfo {
          accountNumber
          routingNumber
        }
        ssn
        _id
      }
    }
  }
`

export const SET_CAPTABLE_IS_APPROVED_BY_MUTATION = gql`
  mutation SET_CAPTABLE_IS_APPROVED_BY_MUTATION(
    $captableId: ID!
    $approvedBy: setCaptableIsApprovedBy_ApprovedBy_Kind!
    $isApproved: Boolean!
  ) {
    setCaptableIsApprovedBy(
      captableId: $captableId
      approvedBy: $approvedBy
      isApproved: $isApproved
    ) {
      _id
    }
  }
`

export const CREATE_COMPANY_FILE_DOWNLOAD_LINK = gql`
  mutation CREATE_COMPANY_FILE_DOWNLOAD_LINK(
    $companyFileId: ID!
    $version: Int
  ) {
    createCompanyFileDownloadLink(
      companyFileId: $companyFileId
      version: $version
    ) {
      url
    }
  }
`

export const COMPANY_SET_IS_APPROVED_MUTATION = gql`
  mutation COMPANY_SET_IS_APPROVED_MUTATION(
    $companyId: ID!
    $isApproved: Boolean!
  ) {
    companySetIsApproved(companyId: $companyId, isApproved: $isApproved) {
      _id
    }
  }
`

export const SET_OFFER_STATUS_MUTATION = gql`
  mutation SET_OFFER_STATUS_MUTATION($offerId: ID!, $status: Offer_Status!) {
    setOfferStatus(offerId: $offerId, status: $status) {
      _id
    }
  }
`

export const SET_OFFER_DATE_RANGE_MUTATION = gql`
  mutation SET_OFFER_DATE_RANGE_MUTATION(
    $offerId: ID!
    $startDate: AWSDateTime!
    $expiryDate: AWSDateTime!
  ) {
    setOfferDateRange(
      offerId: $offerId
      startDate: $startDate
      expiryDate: $expiryDate
    ) {
      _id
    }
  }
`

export const CREATE_LOAN_MUTATION = gql`
  mutation CREATE_LOAN_MUTATION(
    $userId: ID!
    $offerId: ID!
    $loanInfo: CreateLoan_Loan_Info_Input!
    $userInfo: CreateLoan_User_Data_Input!
  ) {
    createLoan(
      userId: $userId
      offerId: $offerId
      loanInfo: $loanInfo
      userInfo: $userInfo
    ) {
      _id
    }
  }
`

export const RESET_COMPANY_OFFERS_MUTATION = gql`
  mutation RESET_COMPANY_OFFERS_MUTATION($companyId: ID!) {
    resetCompanyOffers(companyId: $companyId)
  }
`

import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import LockIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'

import { useUser } from 'app/User'
import { CompanyDocumentUploads, CompanyFile } from 'common/types'
import useFileUpload from 'hooks/useFileUpload'
import useRefetchQueries from 'hooks/useRefetchQueries'
import { createUnderwritingDocumentsAirtableRecord } from 'lib/airtable'
import { getHasUploadedRequiredFiles, getUploadedFilesByLabel } from 'lib/data'
import { UPLOAD_DOCUMENTS_LIST } from 'lib/formConfig'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import { uploadDocumentsSchema } from 'lib/validation'
import Alert from 'components/mui/Alert'
import AlertDialog from 'components/AlertDialog'
import UploadDocumentsForm from './UploadDocumentsForm'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

const initialFormData = {
  financialStatementsFile: null,
  recent409AValuationFile: null,
  companyOverviewFile: null,
  historyOfValuationsFile: null,
  listOfPrimaryInvestorsFile: null,
  employeeOutstandingFile: null,
} as CompanyDocumentUploads

interface Props {
  files: CompanyFile[]
}

function FinancialDocuments({ files }: Props) {
  const { t } = useTranslation()
  const fileUploadMutation = useFileUpload()
  const { isRefetching, refetchQueries } = useRefetchQueries()
  const {
    data: { company },
  } = useUser()

  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const hasUploadedRequiredFiles = useMemo(
    () => files && getHasUploadedRequiredFiles(files),
    [files]
  )

  const uploadedFilesByLabel = useMemo(
    () => files && getUploadedFilesByLabel(files),
    [files]
  )

  const handleSubmit = async (
    values: CompanyDocumentUploads,
    formik?: FormikHelpers<CompanyDocumentUploads>
  ) => {
    const filesToUpload = UPLOAD_DOCUMENTS_LIST.filter(
      (document) =>
        values.hasOwnProperty(document.name) &&
        values[document.name as keyof typeof values]
    ).map((document) => ({
      file: values[document.name as keyof typeof values][0],
      name: document.name,
    }))

    await fileUploadMutation.mutateAsync({
      endpoint: 'companyfilesupload',
      documents: filesToUpload,
    })

    createUnderwritingDocumentsAirtableRecord(
      company?.name,
      filesToUpload,
      company?._id
    )

    formik?.setTouched({}, false)
    formik?.resetForm()

    await refetchQueries()

    return window.scrollTo(0, 0)
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    fileUploadMutation.reset()
  }

  useEffect(() => {
    if (fileUploadMutation.isError) {
      setShowAlertDialog(true)
    }
  }, [fileUploadMutation.isError])

  return (
    <Grid container rowSpacing={2} flexDirection="column">
      <Grid item maxWidth="380px">
        <Grid item mb={3}>
          <Typography variant="h3">
            {t('dashboard.companyInfo.financialDocuments.heading')}
          </Typography>
          <Typography my={3}>
            {t('dashboard.companyInfo.financialDocuments.subHeading')}
          </Typography>
          <Alert severity="info" variant="standard" icon={<LockIcon />}>
            <Typography>
              {t('dashboard.companyInfo.financialDocuments.note')}
            </Typography>
          </Alert>
        </Grid>
      </Grid>

      <Grid item xs={12} md={10} lg={8} mb={2}>
        <Formik
          initialValues={initialFormData}
          validationSchema={
            hasUploadedRequiredFiles ? null : uploadDocumentsSchema
          }
          onSubmit={handleSubmit}
        >
          {({ submitForm, dirty, resetForm }) => (
            <>
              <Form promptOnLeave>
                <UploadDocumentsForm
                  uploadedFilesByLabel={uploadedFilesByLabel}
                />

                <Grid item xs={12} sm={6} mt={4}>
                  <ResponsiveStepperButtons
                    previousStepText={t('common.cancel')}
                    isForwardDisabled={!dirty}
                    isBackwardDisabled={!dirty}
                    handleBack={resetForm}
                    nextStepText={t('common.saveChanges')}
                    isUploading={fileUploadMutation.isLoading || isRefetching}
                    uploadProgress={fileUploadMutation.progress}
                    isLastStep
                  />
                </Grid>
              </Form>

              {/* Shows mutation error when submitting form */}
              <AlertDialog
                isOpen={showAlertDialog}
                onClose={() => setShowAlertDialog(false)}
                primaryButtonAction={() => {
                  submitForm()
                  setShowAlertDialog(false)
                }}
                secondaryButtonAction={handleResetMutation}
              />
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default FinancialDocuments

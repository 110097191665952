import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { CompanyFile } from 'common/types'
import { UPLOAD_DOCUMENTS_LIST } from 'lib/formConfig'
import DownloadFile from 'components/files/DownloadFile'

function Documents({ files }: { files: CompanyFile[] }) {
  const { t } = useTranslation()
  const listOfDocuments = UPLOAD_DOCUMENTS_LIST

  const { uploadedFilesCount, totalFilesCount } = useMemo(
    () => ({
      uploadedFilesCount: listOfDocuments.filter(
        (doc) => files.map((file) => file.label).indexOf(doc.name) >= 0
      ).length,
      totalFilesCount: listOfDocuments.length,
    }),
    [files, listOfDocuments]
  )

  return (
    <>
      <Typography variant="h3">
        {t('openstockAdmin.documents.title')}
      </Typography>
      <Typography variant="body2" my={3}>
        {t('openstockAdmin.documents.description', {
          count: uploadedFilesCount,
          total: totalFilesCount,
        })}
      </Typography>
      <Grid container spacing={2}>
        {listOfDocuments.map(({ name, title }) => {
          const companyFile = files.find((file) => file.label === name)

          return (
            <Grid
              key={name}
              container
              item
              xs={12}
              sm={6}
              md={4}
              gap={1}
              alignContent="flex-start"
            >
              <DownloadFile title={title} file={companyFile} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default Documents

import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useGetCompanyQuery } from 'lib/apollo/hooks'

import QueryBoundary from 'app/QueryBoundary'
import FinancialDocuments from 'views/companyAdmin/companyInfo/FinancialDocuments'
import Layout from 'components/Layout/Layout'
import TabBar from 'components/mui/TabBar'

function CompanyInfoTabs() {
  const { t } = useTranslation()
  const companyQuery = useGetCompanyQuery()
  const { data } = companyQuery
  const companyData = data?.getCompany

  const tabList = [
    {
      label: t('dashboard.companyInfo.tabs.financialDocuments'),
      path: 'financial-documents',
      element: <FinancialDocuments files={companyData?.files} />,
    },
    // TODO: When admins & signatories tab is added address outstanding todo in CompanyOverview > setupTasks > invite admins and signatories
  ]

  return (
    <QueryBoundary queries={[companyQuery]}>
      <Layout maxWidth="lg">
        <Grid container rowSpacing={4}>
          <Grid item>
            <Typography variant="h1">
              {t('dashboard.pages.companyInfo')}
            </Typography>
          </Grid>
          {/* TODO: Remove hidden prop entirely once more tabs are added */}
          <TabBar tabList={tabList} fallbackPath="financial-documents" hidden />
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyInfoTabs

import { useTranslation } from 'react-i18next'

import { useUser } from 'app/User'
import { Offer } from 'common/types'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { getOfferTypeConfig } from 'lib/formConfig'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'

import OfferCard from './OfferCard'

function CompanyOfferCard({ offer }: { offer: Offer }) {
  const { t } = useTranslation()
  const {
    roles: { isOpenstockAdmin },
  } = useUser()

  const { status } = offer

  const buttonNavigateTo = isOpenstockAdmin
    ? `offer/${offer._id}`
    : `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/offer/${offer._id}`

  const name = getOfferTypeConfig(offer.kind).label

  const offerDetails = [
    {
      label: t('dashboard.offers.offer.date'),
      value:
        offer.startDate && offer.expiryDate && offer.status !== 'SAVED'
          ? `${dayjs(offer.startDate).format(
              monthDayFullYearSimplifiedFormat
            )} -\n${dayjs(offer.expiryDate).format(
              monthDayFullYearSimplifiedFormat
            )}`
          : t('common.notApplicable'),
    },
    {
      label: t('dashboard.offers.offer.totalLiquidity'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: offer?.liquidityAvailable,
      }),
    },
    {
      label: t('dashboard.offers.offer.shareClasses'),
      values: offer?.shareclasses,
    },
    {
      label: t('dashboard.offers.offer.shareholders'),
      value: offer?.offerTo?.length,
    },
  ]

  return (
    <OfferCard
      contents={offerDetails}
      name={name}
      status={status}
      buttonNavigateTo={buttonNavigateTo}
    />
  )
}

export default CompanyOfferCard

import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useUser } from 'app/User'
import { CompanyDetails } from 'common/types'
import { createCompanyAirtableRecord } from 'lib/airtable'
import { useSubmitCompanySignupDetails } from 'lib/apollo/hooks'
import { OPENSTOCK_SUPPORT_EMAIL, FEATURE_FLAGS } from 'lib/config'
import { defaultWebsiteProtocol } from 'lib/formConfig'
import { Form, Formik } from 'lib/formik'
import { companyDetailsSchema } from 'lib/validation'

import AccountDetailsForm from 'views/companyAdmin/createCompanyAccount/AccountDetailsForm'
import Alert from 'components/mui/Alert'
import AlertDialog from 'components/AlertDialog'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Link from 'components/mui/Link'

export const CREATE_COMPANY_ROOT_ROUTE = '/account/company'

const initialFormData = {
  isSigningOfficer: true,
  name: '',
  websiteAddress: '',
  streetAddress: '',
  streetAddress2: '',
  city: '',
  zipCode: '',
  country: '',
  state: '',
  ein: '',
  countryOfIncorporation: '',
  stateOfIncorporation: '',
} as CompanyDetails

function CreateCompanyAccount() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: { emailAddress: userEmail, _id, firstName, lastName, company },
  } = useUser()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [submitSignUpDetails, submitSignUpDetailsMutation] =
    useSubmitCompanySignupDetails()

  const isSubmitting = submitSignUpDetailsMutation.loading
  const isEinError = submitSignUpDetailsMutation.error?.message?.includes('ein')

  const handleNavigate = useCallback(
    () =>
      navigate(
        FEATURE_FLAGS.SIGN_COMPANY_AGREEMENT_LETTER
          ? '../agreement'
          : '../questions'
      ),
    [navigate]
  )

  const handleSubmit = async (values: CompanyDetails) => {
    const {
      isSigningOfficer,
      name,
      websiteAddress,
      streetAddress,
      streetAddress2,
      city,
      country,
      state,
      zipCode,
      ein,
      countryOfIncorporation,
      stateOfIncorporation,
    } = values

    // Replaces any websiteAddress protocol with just https://, returns null if there is no websiteAddress
    const customWebsiteAddress = websiteAddress
      ? websiteAddress.includes(defaultWebsiteProtocol)
        ? websiteAddress
        : defaultWebsiteProtocol + websiteAddress?.replace(/(^\w+:|^)\/\//, '')
      : null

    const signUpDetails = {
      userId: _id,
      companyProperties: {
        name,
        address: {
          country,
          state,
          streetAddress,
          streetAddress2: streetAddress2 ? streetAddress2 : null,
          city,
          zipCode,
        },
        invitedUserEmails: [],
        signingOfficerEmails: userEmail,
        websiteAddress: customWebsiteAddress,
        ein: ein ? ein : null,
        countryOfIncorporation,
        stateOfIncorporation,
      },
      userProperties: {
        isSigningOfficer,
      },
    }

    if (!submitSignUpDetailsMutation.data) {
      const { errors, data } = await submitSignUpDetails({
        variables: signUpDetails,
      })

      if (errors) return

      createCompanyAirtableRecord(
        name,
        userEmail,
        data.submitCompanySignupDetails._id,
        `${firstName} ${lastName}`
      )
    }
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    submitSignUpDetailsMutation.reset()
  }

  useEffect(() => {
    if (company) {
      navigate('/', {
        replace: true,
      })
    }
    // Only run on mount to prevent redirect on confirmation page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (submitSignUpDetailsMutation.error && !isEinError) {
      setShowAlertDialog(true)
    }
  }, [isEinError, submitSignUpDetailsMutation.error])

  useEffect(() => {
    if (
      submitSignUpDetailsMutation.data &&
      !submitSignUpDetailsMutation.error
    ) {
      handleNavigate()
    }
  }, [
    handleNavigate,
    submitSignUpDetailsMutation.data,
    submitSignUpDetailsMutation.error,
  ])

  return (
    <Layout sx={{ px: 0 }}>
      <Typography variant="h2" component="h1" mb={3}>
        {t('account.company.title')}
      </Typography>
      <Typography mb={3}>{t('account.company.description')}</Typography>

      <Formik
        initialValues={initialFormData}
        validationSchema={companyDetailsSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form>
            <AccountDetailsForm />

            {/* EIN error alert message */}
            {isEinError && (
              <Grid mt={1}>
                <Alert severity="error" variant="standard">
                  <Typography>
                    <Trans i18nKey="account.company.einErrorMessage">
                      A company with this EIN has already been created on
                      Openstock. If you think this is a mistake, please contact
                      Openstock support at
                      <Link
                        href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                          'account.company.einSupportEmailSubject'
                        )}`}
                        color="error.dark"
                        variant="body2"
                      >
                        {OPENSTOCK_SUPPORT_EMAIL}
                      </Link>
                    </Trans>
                  </Typography>
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} mt={6}>
              <LoadingButton
                type="submit"
                mode="forward"
                loading={isSubmitting}
              >
                {t('common.next')}
              </LoadingButton>
            </Grid>

            <AlertDialog
              isOpen={showAlertDialog}
              onClose={() => setShowAlertDialog(false)}
              primaryButtonAction={() => {
                submitForm()
                setShowAlertDialog(false)
              }}
              secondaryButtonAction={handleResetMutation}
            />
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateCompanyAccount

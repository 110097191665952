import { useEffect, useState } from 'react'
import { useField } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, {
  BaseTextFieldProps,
} from '@mui/material/TextField/TextField'
import theme from 'styles/customTheme'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

type Props = Omit<
  BaseTextFieldProps,
  'value' | 'onChange' | 'onBlur' | 'onFocus'
> & {
  onChangeCallback?: () => void
  options: string[]
  name: string
  label?: string
  disableClearable?: boolean
}

function OutlinedAutocompleteInput({
  id,
  disabled = false,
  disableClearable = true,
  onChangeCallback,
  label,
  name,
  options,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(name)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isAutocomplete, setIsAutocomplete] = useState(false)

  const { value } = field
  const { setValue } = helpers

  const config = { ...props }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  const handleOnChange = (_event: any, newValue: any) => {
    onChangeCallback?.()

    setValue(newValue)
  }

  const handleInputValueChange = (_event: any, newValue: any) => {
    // Trigger autocomplete side effect
    // This will capture most autofill's, but likely not all
    if (value === '' && inputValue === '' && options.includes(newValue)) {
      setValue(newValue)
      setIsAutocomplete(true)
    }

    setInputValue(newValue)
  }

  useEffect(() => {
    // By default inputValue changes will open the autocomplete
    // Override this when autocomplete happened
    if (isAutocomplete && isOpen) {
      setIsAutocomplete(false)
      setIsOpen(false)
    }
  }, [isAutocomplete, isOpen])

  return (
    <Grid>
      {label && (
        <Typography variant="h4" mb={1}>
          {label}
        </Typography>
      )}

      <Autocomplete
        open={isOpen && !isAutocomplete}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        autoHighlight
        autoSelect
        disableClearable={disableClearable}
        disabled={disabled}
        id={id}
        options={options}
        // value and onChange represent actual form values
        value={value || null}
        onChange={handleOnChange}
        // inputValue and onInputChange represent temporary state (typing autocomplete)
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        renderInput={(params) => (
          <TextField
            {...config}
            {...params}
            hiddenLabel
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
            }}
            sx={{
              mb: 1,
              '.MuiInputAdornment-root .MuiTypography-root': {
                fontSize: theme.typography.h2,
                color: 'secondary.darkest',
              },
              '.MuiAutocomplete-endAdornment': {
                top: 'unset',
              },
              '.MuiInputBase-root': {
                borderRadius: '6px',
                fontSize: theme.typography.h2,
                color: 'secondary.darkest',
              },
              '.MuiFormHelperText-root': {
                fontSize: theme.typography.subtitle1,
                mx: 0,
              },
              '.MuiOutlinedInput-input': {
                padding: 1,
              },
              ...props.sx,
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  )
}

export default OutlinedAutocompleteInput

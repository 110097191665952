import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import { Loans, Offers } from 'common/types'
import { useGetUserQuery } from 'lib/apollo/hooks'

import QueryBoundary from 'app/QueryBoundary'
import Layout from 'components/Layout/Layout'
import OffersEmptyCard from 'components/offerings/OffersEmptyCard'
import Greeting from 'components/Greeting'
import ShareholderOfferCard from 'components/shareholderOffer/ShareholderOfferCard'

function ShareholderOverview() {
  const { t } = useTranslation()

  const {
    data: { _id, firstName: name, emailAddress: userEmail },
  } = useUser()

  const userQuery = useGetUserQuery(_id)
  const user = userQuery?.data?.getUser

  const offers: Offers = user?.offers
  const loans: Loans = user?.loans

  const availableOffers = useMemo(
    () => offers?.filter((offer) => offer.status === 'ACTIVE'),
    [offers]
  )

  return (
    <QueryBoundary queries={[userQuery]}>
      <Layout maxWidth="lg">
        <Greeting
          greetName={t('common.greeting.greetName', { name })}
          welcomeMessage={t('common.greeting.welcome')}
          greeting={t('shareholder.dashboard.greeting')}
          background="linear-gradient(266.56deg, #012B58 2.64%, #011D3A 94.91%)"
        />
        {/* Available offers */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Grid item>
            <Typography variant="h3">
              {t('shareholder.dashboard.availableOffer.title')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={2} mt={2}>
          {availableOffers?.length > 0 ? (
            availableOffers.map((offer) => (
              <Grid item key={offer?._id} xs={12}>
                <ShareholderOfferCard offer={offer} userEmail={userEmail} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <OffersEmptyCard
                title={t('shareholder.dashboard.availableOffer.empty')}
                description={t(
                  'shareholder.dashboard.availableOffer.emptyDescription'
                )}
              />
            </Grid>
          )}
        </Grid>

        {/* Requested offers */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={2}
          mt={5}
        >
          <Grid item>
            <Typography variant="h3">
              {t('shareholder.dashboard.activeLoan.title')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={2} mt={2}>
          {loans?.length > 0 ? (
            loans.map((loan) => (
              <Grid item key={loan._id} xs={12}>
                <ShareholderOfferCard loan={loan} userEmail={userEmail} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <OffersEmptyCard
                title={t('shareholder.dashboard.activeLoan.empty')}
                description={t(
                  'shareholder.dashboard.activeLoan.emptyDescription'
                )}
              />
            </Grid>
          )}
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default ShareholderOverview

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Loan, Offer, OfferTo, User } from 'common/types'
import { getInterestAndPlatformFee } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import {
  getOfferTypeConfig,
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
} from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'
import OfferCard from 'components/offerings/OfferCard'

function ShareholderOfferCard({
  offer,
  loan,
  userEmail,
}: {
  offer?: Offer
  loan?: Loan
  userEmail: User['emailAddress']
}) {
  const { t } = useTranslation()

  const shareholderOffer = useMemo(
    () =>
      offer?.offerTo?.find(
        (to: OfferTo) => to?.shareholder?.emailAddress === userEmail
      ),
    [offer, userEmail]
  )

  const offerFinancialTerms = offer?.financialTerms
  const loanFinancialTerms = loan?.offer.financialTerms

  const interestAndPlatformFee = useMemo(
    () => offerFinancialTerms && getInterestAndPlatformFee(offerFinancialTerms),
    [offerFinancialTerms]
  )

  const loanTermEndDate = useMemo(
    () =>
      dayjs(loan?.offer?.expiryDate).add(
        loanFinancialTerms?.loan?.termInMonths ?? 0,
        'month'
      ),
    [loan?.offer?.expiryDate, loanFinancialTerms?.loan?.termInMonths]
  )

  const availableOfferDetails = [
    {
      label: t('shareholder.dashboard.availableOffer.accessUpTo'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: shareholderOffer?.liquidityAvailable,
      }),
    },
    {
      label: t('shareholder.dashboard.availableOffer.loanType'),
      value:
        offerFinancialTerms?.loan.kind &&
        getLabelByValue(offerFinancialTerms?.loan.kind, loanTypes),
    },
    {
      label: t('shareholder.dashboard.availableOffer.originationFee'),
      value: `${t('common.intlNumberFormat', {
        value: offerFinancialTerms?.originationFee,
      })}%`,
    },
    {
      label: t('shareholder.dashboard.availableOffer.interestRate'),
      value: t('shareholder.dashboard.availableOffer.interestRateValue', {
        value: interestAndPlatformFee,
      }),
    },
    {
      label: t('shareholder.dashboard.availableOffer.offerEndDate'),
      value: dayjs(offer?.expiryDate).format(monthDayFullYearSimplifiedFormat),
    },
  ]

  const activeLoanDetails = [
    {
      label: t('shareholder.dashboard.activeLoan.amountReceived'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: loan?.loanAmountRequested,
      }),
    },
    {
      label: t('shareholder.dashboard.activeLoan.loanStructure'),
      value:
        loanFinancialTerms?.loan.structure &&
        getLabelByValue(loanFinancialTerms?.loan.structure, loanStructures),
    },
    {
      label: t('shareholder.dashboard.activeLoan.repaymentSchedule'),
      value:
        loanFinancialTerms?.loan.repaymentSchedule &&
        getLabelByValue(
          loanFinancialTerms?.loan.repaymentSchedule,
          loanRepaymentSchedules
        ),
    },
    {
      label: t('shareholder.dashboard.activeLoan.loanTerm'),
      value: t('common.monthWithCount', {
        count: loanFinancialTerms?.loan.termInMonths,
      }),
    },
    {
      label: t('shareholder.dashboard.activeLoan.loanTermEndDate'),
      value: dayjs(loanTermEndDate).format(monthDayFullYearSimplifiedFormat),
    },
  ]

  const contents = loan ? activeLoanDetails : availableOfferDetails

  const buttonNavigateTo = `${DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE}/offers/offer/${offer?._id}/review`

  const statusLabel = loan
    ? t('common.requested')
    : t('shareholder.dashboard.availableOffer.status')

  const offerName = useMemo(
    () =>
      offer
        ? getOfferTypeConfig(offer.kind).label
        : t('offers.createOffer.nonRecourseLoan.title'),
    [offer, t]
  )

  const loanName = useMemo(
    () =>
      loan
        ? getOfferTypeConfig(loan.offer.kind).label
        : t('offers.createOffer.nonRecourseLoan.title'),
    [loan, t]
  )

  return (
    <OfferCard
      contents={contents}
      name={loan ? loanName : offerName}
      status={loan ? 'active' : 'info'}
      statusLabel={statusLabel}
      showButton={offer !== undefined}
      buttonNavigateTo={buttonNavigateTo}
    />
  )
}

export default ShareholderOfferCard

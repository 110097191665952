import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Form, Formik, useFormikSync } from 'lib/formik'
import {
  CreateOfferMultiForms,
  Offer,
  ShareholderShareInfo,
} from 'common/types'
import { useSteps } from 'hooks/useSteps'
import { useGetCaptableQuery, useGetCompanyQuery } from 'lib/apollo/hooks'
import { getOfferTypeConfig } from 'lib/formConfig'
import {
  calculateLiquiditySchema,
  selectedShareClassSchema,
} from 'lib/validation'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'

import QueryBoundary from 'app/QueryBoundary'
import AlertDialog from 'components/AlertDialog'
import BackLink from 'components/BackLink'
import Layout from 'components/Layout/Layout'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import CalculateLiquidity from 'views/companyAdmin/createOffer/CalculateLiquidity'
import SelectShareClasses from 'views/companyAdmin/createOffer/SelectShareClasses'
import ReviewSubmit from 'views/companyAdmin/createOffer/ReviewSubmit'
import theme from 'styles/customTheme'

import {
  getDerivedShareclassGroupedTable,
  sanitizeResultsFormData,
} from 'lib/data'

function CreateTenderOffer({ editForm }: { editForm?: Offer }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeStep, handleBack, handleNext } = useSteps()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  // Track shareholders that are both eligible and are selected by the user
  const [selectedEligible, setSelectedEligible] =
    useState<ShareholderShareInfo>([])

  // TODO: To be replaced once integrated
  const mockMutation = useMutation(
    (_data: any) => new Promise((resolve) => setTimeout(resolve, 1000)),
    {
      onSuccess: () => {
        handleNavigate(true)
      },
    }
  )

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery?.data?.getCompany

  const captableQuery = useGetCaptableQuery(editForm?.captable?._id)
  const captable = captableQuery?.data?.getCaptable

  const shareholderGroupedTable = captable?.shareholderGroupedTable
  const sharePrice = company?.financialTermsCurrent?.sharePrice
  const offerSharePrice = editForm?.financialTerms.sharePrice

  const initialFormData = {
    shareclasses: [],
    shareholders: [],
    type: 'TENDER_OFFER',
    percentageOfAssets: company?.financialTermsCurrent?.loan?.valueRatio,
    totalLiquidity: 0,
    totalShares: 0,
  } as CreateOfferMultiForms

  const isEditing = editForm !== undefined
  const sanitizedEditForm = sanitizeResultsFormData(editForm)
  const derivedInitialValue = sanitizedEditForm ?? initialFormData
  const { syncedFormikValues, SyncFormik } = useFormikSync(derivedInitialValue)

  const validationSchema = [selectedShareClassSchema, calculateLiquiditySchema]

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const isSubmitting = mockMutation.isLoading

  const setSelectedEligibleShareholders = (
    shareholders: ShareholderShareInfo
  ) => setSelectedEligible(shareholders)

  const offerTypeConfig = getOfferTypeConfig(syncedFormikValues.type)

  const handleNavigate = useCallback(
    (unblockPrompt = false) =>
      navigate(`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/saved`, {
        replace: true,
        state: {
          unblockPrompt,
        },
      }),
    [navigate]
  )

  const handleAction = () =>
    isEditing
      ? navigate(
          `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/offer/${editForm._id}`
        )
      : navigate(`../create-offer/preview/${offerTypeConfig.pathname}`)

  const handleSubmit = async (values: CreateOfferMultiForms) => {
    if (activeStep === 0) setSelectedEligibleShareholders(values.shareholders)

    if (activeStep !== 2) {
      return handleNext()
    }

    // TODO: To be replaced once integrated
    mockMutation.mutate({ variables: [''] })
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    mockMutation.reset()
  }

  const handleOnClose = () => setShowAlertDialog(false)

  useEffect(() => {
    if (mockMutation.isError) {
      setShowAlertDialog(true)
    }
  }, [handleNavigate, mockMutation.isError])

  const derivedShareclassGroupedTable = useMemo(
    () =>
      shareholderGroupedTable &&
      getDerivedShareclassGroupedTable({
        shareholderGroupedTable,
        sharePrice,
      }).filter(
        (shareclass) =>
          shareclass.numberOfShareholders !== 0 ||
          shareclass.totalValueOfShares !== 0
      ),
    [sharePrice, shareholderGroupedTable]
  )

  const pages: string[] = t('offer.pages', {
    returnObjects: true,
  })

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <Grid container sx={{ flex: 1 }}>
        {isMediumUp && (
          <Grid item xs={12} md={3} lg={2.5}>
            <SideBarStepper
              activeStep={activeStep}
              steps={pages}
              title={isEditing ? t('offer.editTitle') : t('offer.title')}
              action={handleAction}
              actionTitle={t('common.back')}
            />
          </Grid>
        )}

        <Grid item xs={12} md={9} lg={9.5}>
          <Layout maxWidth="lg" isSideBarStepper>
            {isMediumDown && <BackLink onClick={handleAction} />}

            <Box>
              <Typography variant="h2" component="h1" mb={5}>
                {pages[activeStep]}
              </Typography>
            </Box>

            <Formik
              initialValues={derivedInitialValue}
              validationSchema={validationSchema[activeStep]}
              onSubmit={handleSubmit}
            >
              {({ values, submitForm }) => {
                const getTotalLiquidity = (shares: number) =>
                  shares *
                  (offerSharePrice ?? sharePrice) *
                  (values.percentageOfAssets / 100)

                const formProps = {
                  company,
                  derivedShareclassGroupedTable,
                  getTotalLiquidity,
                  sharePrice,
                  shareholderGroupedTable,
                  selectedEligibleShareholders: selectedEligible,
                  setSelectedEligibleShareholders,
                  handleBack,
                  handleNavigate,
                  handleNext,
                  isSubmitting,
                  offerTypeConfig,
                  values,
                }

                return (
                  <Form promptOnLeave>
                    <SyncFormik values={values} />
                    {(() => {
                      switch (activeStep) {
                        case 0:
                          return <SelectShareClasses {...formProps} />
                        case 1:
                          return <CalculateLiquidity {...formProps} />
                        case 2:
                          return <ReviewSubmit {...formProps} />
                        default:
                          return
                      }
                    })()}
                    {/* Shows mutation error when submitting form  */}
                    <AlertDialog
                      isOpen={showAlertDialog}
                      onClose={handleOnClose}
                      description={t('common.errorSubmitForm')}
                      primaryButtonAction={() => {
                        submitForm()
                        setShowAlertDialog(false)
                      }}
                      secondaryButtonAction={handleResetMutation}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Layout>
        </Grid>
      </Grid>
    </QueryBoundary>
  )
}

export default CreateTenderOffer

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'

import BackupTableIcon from '@mui/icons-material/BackupTable'
import Box from '@mui/material/Box'
import DownloadIcon from '@mui/icons-material/Download'
import Grid from '@mui/material/Grid'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import {
  OfferTypeConfig,
  Offer,
  OfferCaptableShareholder,
  OfferCaptableShareholdersData,
} from 'common/types'
import Button from 'components/mui/Button'
import ResponsiveTable, {
  HeadCells,
  ResponsiveTableFooter,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'
import StatusIndicator from 'components/StatusIndicator'
import LoadingButton from 'components/mui/LoadingButton'
import { useLazyGetOfferCaptableShareholdersData } from 'lib/apollo/hooks'

function EligibleShareholdersTableRow({
  shareholderRow,
  offer,
}: {
  shareholderRow: OfferCaptableShareholder
  offer: Offer
}) {
  const { t } = useTranslation()

  const {
    liquidityAvailable,
    loanAmountRequested,
    maxAvailableShares,
    name,
    shareclasses,
    subscriptionStatuses,
  } = shareholderRow

  return (
    <ResponsiveTableRow>
      <TableCell component="th" id={name} scope="row">
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {shareclasses.map((shareclass) => shareclass.name).join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlNumberFormat', {
            value: maxAvailableShares,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlCurrencySimplifiedFormat', {
            value: liquidityAvailable,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        {offer.status === 'SAVED' ? (
          <Typography>-</Typography>
        ) : (
          <Box display="flex">
            <StatusIndicator
              status={
                subscriptionStatuses.includes('SUBSCRIBED' as any)
                  ? 'SUBSCRIBED'
                  : 'NOT_SUBSCRIBED'
              }
              category="shareholder"
            />
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Typography>
          {loanAmountRequested
            ? t('common.intlCurrencySimplifiedFormat', {
                value: loanAmountRequested,
              })
            : '-'}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

export default function EligibleShareholdersTable({
  offer,
  eligibleShareholders,
  isOpenstockAdmin = false,
  offerTypeConfig,
  totalLiquidityRequested = 0,
  totalMaxAvailableShares = 0,
  totalLiquidityOffered = 0,
}: {
  offer: Offer
  eligibleShareholders: OfferCaptableShareholder[]
  isOpenstockAdmin?: boolean
  offerTypeConfig: OfferTypeConfig
  totalLiquidityRequested?: number
  totalMaxAvailableShares?: number
  totalLiquidityOffered?: number
}) {
  const { t } = useTranslation()

  const { offerTypeTranslationKey } = offerTypeConfig

  const [
    getShareholderData,
    { loading: shareholderLoading, data: shareholderData },
  ] = useLazyGetOfferCaptableShareholdersData(offer._id)

  // Sort shareholders by status order
  const getSortedShareholders = (
    eligibleShareholders: OfferCaptableShareholder[]
  ) => {
    const shareholderStatusOrder = ['SUBSCRIBED', 'NOT_SUBSCRIBED']

    let newSorted: OfferCaptableShareholder[] = []

    shareholderStatusOrder.forEach((status) => {
      const filteredShareholders = eligibleShareholders?.filter((shareholder) =>
        shareholder.subscriptionStatuses.includes(status as any)
      )

      newSorted = [...newSorted, ...filteredShareholders]
    })

    return newSorted
  }
  const sortedEligibleShareholders = useMemo(
    () => getSortedShareholders(eligibleShareholders),
    [eligibleShareholders]
  )

  const sortedEligibleShareholdersCsvData = useMemo(
    () =>
      shareholderData &&
      getSortedShareholders(shareholderData?.getOfferCaptableShareholdersData),
    [shareholderData]
  )

  const headCells: HeadCells = useMemo(
    () => [
      {
        id: 'shareholders',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell1`
        ),
        width: '20%',
      },
      {
        id: 'shareClasses',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell2`
        ),
        width: '20%',
      },
      {
        id: 'maxNumOfShares',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell3`
        ),
        width: '12%',
      },
      {
        id: 'totalLiquidity',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell4`
        ),
        width: '18%',
      },
      {
        id: 'subscriptionStatus',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell5`
        ),
        width: '15%',
      },
      {
        id: 'liquidityRequested',
        label: t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadCell6`
        ),
        width: '15%',
      },
    ],
    [offerTypeTranslationKey, t]
  )

  const payment: string[] = useMemo(
    () =>
      t(
        `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableHeadLoanCells`,
        { returnObjects: true }
      ),
    [offerTypeTranslationKey, t]
  )

  const csvHeaders = useMemo(() => {
    const headers = headCells.map(({ label }) => label)

    return [...headers, ...payment]
  }, [headCells, payment])

  const csvData = useMemo(() => {
    const totalRow = [
      t(
        `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.tableFooter`
      ),
      [],
      totalMaxAvailableShares,
      totalLiquidityOffered,
      '',
      totalLiquidityRequested,
    ]

    return shareholderData
      ? sortedEligibleShareholdersCsvData
          .map((shareholder: OfferCaptableShareholdersData) => {
            const data = Object.values(shareholder)
            // Remove first/last column data, not needed
            data.shift()
            data.pop()

            const shareclasses = shareholder.shareclasses.map(
              ({ name }) => name
            )

            const address = shareholder?.loanUserData
              ? Object.values(shareholder?.loanUserData?.address)
              : []
            address.shift()

            const bankingInfo = shareholder?.loanUserData
              ? Object.values(shareholder?.loanUserData?.bankingInfo)
              : []
            bankingInfo.shift()

            const shareholderOffer = data.map((item, index) =>
              index === 1 ? shareclasses : item
            )

            return [
              ...shareholderOffer,
              ...address,
              ...bankingInfo,
              shareholder.loanUserData?.ssn,
            ]
          })
          // Last row to show columns total
          .concat([totalRow])
      : []
  }, [
    offerTypeTranslationKey,
    shareholderData,
    sortedEligibleShareholdersCsvData,
    t,
    totalLiquidityOffered,
    totalLiquidityRequested,
    totalMaxAvailableShares,
  ])

  return (
    <Grid item container>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item>
          <Typography variant="h3">
            {t(
              `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.title`
            )}
          </Typography>
        </Grid>

        {isOpenstockAdmin && (
          <Grid item>
            {csvData.length > 0 ? (
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={t(
                  `dashboard.offers.manageOffers.${offerTypeTranslationKey}.csvFilename`
                )}
                target="_blank"
              >
                <Button startIcon={<DownloadIcon />} variant="outlined">
                  {t('common.downloadCsv')}
                </Button>
              </CSVLink>
            ) : (
              <LoadingButton
                loading={shareholderLoading}
                onClick={() => {
                  getShareholderData()
                }}
                startIcon={<BackupTableIcon />}
                variant="outlined"
              >
                {t('common.requestCsv')}
              </LoadingButton>
            )}
          </Grid>
        )}
      </Grid>

      <ResponsiveTable
        ariaLabel={t(
          `dashboard.offers.manageOffers.${offerTypeTranslationKey}.table.ariaLabel`
        )}
        headCells={headCells}
      >
        <TableBody>
          {sortedEligibleShareholders.map((shareholderRow) => (
            <EligibleShareholdersTableRow
              key={shareholderRow.name}
              offer={offer}
              shareholderRow={shareholderRow}
            />
          ))}
        </TableBody>

        {/* Table footer */}
        <ResponsiveTableFooter>
          <TableCell colSpan={2}>
            <Typography variant="body2" color="secondary.darkest">
              {t(`offer.review.${offerTypeTranslationKey}.table.tableFooter`)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="secondary.darkest">
              {t('common.intlNumberFormat', {
                value: totalMaxAvailableShares,
              })}
            </Typography>
          </TableCell>
          <TableCell colSpan={2}>
            <Typography variant="body2" color="secondary.darkest">
              {t('common.intlCurrencySimplifiedFormat', {
                value: totalLiquidityOffered,
              })}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="secondary.darkest">
              {t('common.intlCurrencySimplifiedFormat', {
                value: totalLiquidityRequested,
              })}
            </Typography>
          </TableCell>
        </ResponsiveTableFooter>
      </ResponsiveTable>
    </Grid>
  )
}

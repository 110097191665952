import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'react-use'

import useSendCalculatorEmailLink from 'hooks/useSendCalculatorEmailLink'
import { createCalculatorUserLinkInfoAirtableRecord } from 'lib/airtable'
import {
  optionsCalculatorTypeOptions,
  unitedStatesRegions,
} from 'lib/formConfig'
import { Form, Formik, useFormikSync } from 'lib/formik'
import { optionsCalculatorSchema } from 'lib/validation'
import { CalculatorUser } from 'views/CalculatorPage'

import { ReactComponent as CalculatorIcon } from 'assets/calculator.svg'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import AlertDialog from 'components/AlertDialog'
import CalculatorInformation from 'components/calculator/CalculatorInformation'
import OutlinedAutocompleteInput from 'components/mui/OutlinedAutocompleteInput'
import OptionsChartTable from 'components/calculator/OptionsChartTable'
import OutlinedInput from 'components/mui/OutlinedInput'
import OutlinedSelectInput from 'components/mui/OutlinedSelectInput'
import Paper from 'components/mui/Paper/Paper'
import SendCalculatorInfo from 'components/calculator/SendCalculatorInfo'

function Options({
  calculatorUser,
  handleSearchParams,
}: {
  calculatorUser: CalculatorUser
  handleSearchParams: (values: any, handleResetMutation: any) => void
}) {
  const { t } = useTranslation()
  const ref = useRef<null | HTMLDivElement>(null)
  const [searchParams] = useSearchParams()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const initialValues = {
    options: searchParams.get('options') ?? 100000,
    type: searchParams.get('type') ?? 'NSO',
    strikePrice: searchParams.get('strikePrice') ?? 0.05,
    fmvAtExercise: searchParams.get('fmvAtExercise') ?? 2,
    marketValueOfCommon: searchParams.get('marketValueOfCommon') ?? 3,
    sharePriceAtSale: searchParams.get('sharePriceAtSale') ?? 10,
    state: searchParams.get('state') ?? 'California',
  }

  const { syncedFormikValues, SyncFormik } = useFormikSync(initialValues)
  const sendMutation = useSendCalculatorEmailLink()

  const handleResetMutation = useCallback(() => {
    setShowAlertDialog(false)
    sendMutation.reset()
  }, [sendMutation])

  const handleOnClose = () => setShowAlertDialog(false)

  const handleLink = () => ref.current?.scrollIntoView({ behavior: 'smooth' })

  const handleSubmit = () => {
    const details = {
      email: calculatorUser.email,
      company: calculatorUser.company,
      link: `${window.location.href}&email=${calculatorUser.email}&company=${calculatorUser.company}`,
    }

    sendMutation.mutate(details)

    createCalculatorUserLinkInfoAirtableRecord({
      ...details,
      type: 'Options',
    })
  }

  useDebounce(
    () => handleSearchParams({ ...syncedFormikValues }, handleResetMutation),
    10,
    [syncedFormikValues]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={optionsCalculatorSchema}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm }) => (
        <Form>
          <SyncFormik values={values} />
          <Grid container justifyContent="space-between" rowGap={6}>
            {/* Options calculator */}
            <Grid item container xs={12} sm={9} md={5}>
              <Grid container alignItems="center" mb={2}>
                <Grid item xs="auto">
                  <CalculatorIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h2" ml={1}>
                    {t('calculator.tab.options.form.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Paper sx={{ mb: 3 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <OutlinedInput
                      id="options"
                      name="options"
                      label={t('calculator.tab.options.form.optionsLabel')}
                      helperText={t(
                        'calculator.tab.options.form.optionsHelperText'
                      )}
                      placeholder={t('common.numberPlaceholder')}
                      hasDecimalScale={false}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedSelectInput
                      id="type"
                      name="type"
                      label={t('calculator.tab.options.form.optionsTypeLabel')}
                      helperText={t(
                        'calculator.tab.options.form.optionsTypeHelperText'
                      )}
                      options={optionsCalculatorTypeOptions}
                      value={values.type}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      id="strikePrice"
                      name="strikePrice"
                      delimiter="$"
                      isNumericFormat={false}
                      label={t('calculator.tab.options.form.strikePriceLabel')}
                      helperText={t(
                        'calculator.tab.options.form.strikePriceHelperText'
                      )}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      id="fmvAtExercise"
                      name="fmvAtExercise"
                      delimiter="$"
                      label={t(
                        'calculator.tab.options.form.fmvAtExerciseLabel'
                      )}
                      helperText={t(
                        'calculator.tab.options.form.fmvAtExerciseHelperText'
                      )}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      id="marketValueOfCommon"
                      name="marketValueOfCommon"
                      delimiter="$"
                      label={t(
                        'calculator.tab.options.form.marketValueOfCommonLabel'
                      )}
                      helperText={t(
                        'calculator.tab.options.form.marketValueOfCommonHelperText'
                      )}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      id="sharePriceAtSale"
                      name="sharePriceAtSale"
                      delimiter="$"
                      label={t(
                        'calculator.tab.options.form.sharePriceAtSaleLabel'
                      )}
                      helperText={t(
                        'calculator.tab.options.form.sharePriceAtSaleHelperText'
                      )}
                    />
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedAutocompleteInput
                      id="state"
                      name="state"
                      label={t('calculator.tab.options.form.stateLabel')}
                      helperText={t(
                        'calculator.tab.options.form.stateHelperText'
                      )}
                      placeholder={t('common.selectPlaceholder')}
                      options={unitedStatesRegions}
                      inputProps={{
                        autoComplete: 'address-level1',
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item container xs={12} sm={9} md={6}>
              <CalculatorInformation name="options" handleLink={handleLink} />
            </Grid>

            <Grid item container xs={12}>
              <OptionsChartTable values={values} />
            </Grid>

            <Grid item container xs={12} ref={ref}>
              <SendCalculatorInfo
                name="options"
                sendInfoMutation={sendMutation}
              />
            </Grid>
          </Grid>

          {/* Shows mutation error when submitting form  */}
          <AlertDialog
            isOpen={showAlertDialog}
            onClose={handleOnClose}
            description={t('common.errorSubmitForm')}
            primaryButtonAction={() => {
              submitForm()
              setShowAlertDialog(false)
            }}
            secondaryButtonAction={handleResetMutation}
          />
        </Form>
      )}
    </Formik>
  )
}

export default Options

import { useTranslation } from 'react-i18next'

import Checkbox from '@mui/material/Checkbox/Checkbox'
import Divider from '@mui/material/Divider/Divider'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { OfferingsInterestsSchema } from 'common/types'
import { productOfferings } from 'lib/formConfig'
import { useFormikContext } from 'lib/formik'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  handleNext: () => void
  values: OfferingsInterestsSchema
}

const allProductOfferings = productOfferings.map((offering) => offering.label)

function ProductOfferings({ handleNext, values }: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const selectedProductOfferings = values.productOfferings

  const handleChange = (offering: string) => {
    const selectedIndex = selectedProductOfferings?.indexOf(offering)

    let newSelected: OfferingsInterestsSchema['productOfferings'] = []

    if (
      selectedIndex === -1 &&
      selectedProductOfferings &&
      selectedProductOfferings.length > 0
    ) {
      newSelected = [...selectedProductOfferings, offering]
    } else if (selectedIndex === -1) {
      newSelected.push(offering)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProductOfferings?.slice(1))
    } else if (
      selectedProductOfferings?.length &&
      selectedIndex === selectedProductOfferings.length - 1
    ) {
      newSelected = newSelected.concat(selectedProductOfferings?.slice(0, -1))
    } else if (selectedIndex && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProductOfferings?.slice(0, selectedIndex),
        selectedProductOfferings?.slice(selectedIndex + 1)
      )
    }
    setFieldValue('productOfferings', newSelected)
  }

  const isAllSelected =
    productOfferings.length === values.productOfferings?.length

  const handleSelectAll = () => {
    if (isAllSelected) {
      setFieldValue('productOfferings', [])
    } else {
      setFieldValue('productOfferings', allProductOfferings)
    }
  }

  return (
    <Grid container rowGap={3}>
      <Grid item container xs={12} rowGap={3} mb={3}>
        {/* Select all */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleSelectAll}
                disableTouchRipple
                checked={isAllSelected}
              />
            }
            label={t('common.selectAll')}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Product offerings form */}
        <Grid item container xs={12} rowGap={3}>
          {productOfferings.map((productOffering) => (
            <Grid item xs={12} key={productOffering.label}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedProductOfferings?.includes(
                      productOffering.label
                    )}
                    onChange={() => handleChange(productOffering.label)}
                    disableTouchRipple
                  />
                }
                label={productOffering.label}
              />
              <Typography>{productOffering.description}</Typography>
              <Divider sx={{ mt: 3 }} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveStepperButtons handleForward={handleNext} />
      </Grid>
    </Grid>
  )
}

export default ProductOfferings

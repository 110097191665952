import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { useField } from 'formik'

import { FormHelperText } from '@mui/material'
import Box from '@mui/material/Box'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  GenericObject,
  UploadDocumentsListItem,
  CompanyFile,
} from 'common/types'
import { ALL_SUPPORTED_FILE_TYPES, MAX_FILE_SIZE } from 'lib/config'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import theme from 'styles/customTheme'

import styles from './uploadFile.module.scss'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

interface Props {
  document: UploadDocumentsListItem
  onRemoveCallback?: () => void
  supportedFileTypes?: GenericObject
  showDescription?: boolean
  uploadToReplace?: CompanyFile
}

function UploadFile({
  document,
  onRemoveCallback,
  supportedFileTypes,
  showDescription = false,
  uploadToReplace,
}: Props) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState('')

  const { name, title, description, required } = document

  const [, meta, helpers] = useField(name)

  const config = {
    error: meta.touched && meta.error,
    helperText: meta.error,
  }

  const showReplace = uploadToReplace && !meta.value

  // Check if a file has already been uploaded to form data field
  const uploadedFileName = meta.value?.[0].name

  const onDrop = useCallback(
    (acceptedFiles: any[], fileRejections: any[]) => {
      if (acceptedFiles.length > 0) {
        helpers.setValue(acceptedFiles)
        setErrors('')
      }

      fileRejections.forEach((file) => {
        file.errors.forEach((err: { code: string; message: string }) => {
          switch (err.code) {
            case 'file-too-large': {
              setErrors(t('documents.fileTooLargeError'))
              console.error(err.message)
              break
            }
            case 'file-invalid-type': {
              setErrors(t('documents.fileInvalidTypeError'))
              console.error(err.message)
              break
            }

            default: {
              setErrors(t('documents.fileError'))
              console.error(err.message)
            }
          }
        })
      })
    },
    [helpers, t]
  )

  const { getRootProps, getInputProps, isDragActive, open, inputRef } =
    useDropzone({
      accept:
        supportedFileTypes ?? document.fileTypes ?? ALL_SUPPORTED_FILE_TYPES,
      maxSize: MAX_FILE_SIZE,
      maxFiles: 1,
      multiple: false,
      noClick: true,
      onDrop,
    })

  const removeFile = () => {
    helpers.setValue(null)
    onRemoveCallback?.()

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const UploadCard = ({
    color,
    label,
    isFocused = false,
  }: {
    color: string
    label: string
    isFocused?: boolean
  }) => {
    const UploadFileStatusIcon =
      uploadedFileName || showReplace ? CloudDoneIcon : CloudUploadIcon
    const Icon =
      isFocused && !uploadedFileName ? EmojiPeopleIcon : UploadFileStatusIcon
    return (
      <>
        <Grid item xs={1} className={styles.uploadCard}>
          <Icon fontSize="medium" sx={{ color }} />
        </Grid>
        <Grid
          item
          container
          xs={11}
          display="flex"
          alignItems="center"
          justifyItems="space-between"
          className={styles.uploadCard}
        >
          <Typography noWrap variant="subtitle1" sx={{ px: 2, py: 0.4 }}>
            {label}
          </Typography>
        </Grid>
      </>
    )
  }

  const renderUploadCard = () => {
    let color = 'secondary.dark'
    let label = t('documents.instructions')

    if (showReplace) {
      color = 'primary.main'
      label = uploadToReplace.current?.nameOriginal ?? ''
    }

    if (errors) {
      color = 'error.main'
      label = errors
    }

    if (uploadedFileName) {
      color = 'success.main'
      label = uploadedFileName
    }

    if (isDragActive && !uploadedFileName) {
      color = 'secondary'
      label = t('documents.dropFilesHere')
    }

    return <UploadCard isFocused={isDragActive} color={color} label={label} />
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box my={1}>
          <Typography variant="h6">
            {title}
            {required && (
              <span style={{ color: theme.palette.error.main }}> *</span>
            )}
          </Typography>
          {showDescription && description && (
            <Typography variant="subtitle1" my={1}>
              {description}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} mt={1}>
        <div {...getRootProps()} className={styles.root}>
          <input
            name={document.name}
            {...getInputProps()}
            // Override dropzone default (display: none style)
            style={{}}
            className={styles.input}
            // Dropzone only fills value on click, so for html validation to block this, only "required" if value is not already set
            required={required && !meta.value}
          />
          <Grid container>
            <Paper sx={{ width: 1 }}>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
                rowGap={1}
              >
                <Grid item container xs={9}>
                  {renderUploadCard()}
                </Grid>
                <Grid item container xs="auto" justifyContent="flex-end">
                  {uploadedFileName ? (
                    <Link
                      onClick={() => removeFile()}
                      color="error.main"
                      underline="none"
                      type="button"
                      variant="subtitle2"
                    >
                      {t('documents.remove')}
                    </Link>
                  ) : (
                    <Link
                      onClick={open}
                      underline="none"
                      type="button"
                      variant="subtitle2"
                    >
                      {t(`documents.${showReplace ? 'replace' : 'upload'}`)}
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        {showReplace ? (
          <Typography
            variant="subtitle1"
            color="secondary.dark"
            mt={1.5}
            dangerouslySetInnerHTML={{
              __html: t('documents.uploadedOn', {
                date: dayjs(uploadToReplace?.current?.createdAt).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }),
            }}
          />
        ) : (
          <Typography variant="subtitle1" color="secondary.dark" mt={1.5}>
            {t(`documents.${name}.fileType`)}
            <br />
            {t('documents.fileSize')}
          </Typography>
        )}
      </Grid>
      {config.error && (
        <FormHelperText error>{config.helperText}</FormHelperText>
      )}
    </Grid>
  )
}

export default UploadFile

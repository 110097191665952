import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useAuth } from 'app/Auth'
import { Form, Formik } from 'lib/formik'
import { profileSchema } from 'lib/validation'
import { CREATE_COMPANY_ROOT_ROUTE } from 'views/companyAdmin/createCompanyAccount/CreateCompanyAccount'

import Alert from 'components/mui/Alert'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Link from 'components/mui/Link'
import PhoneInput from 'components/mui/PhoneInput'
import TextInput from 'components/mui/TextInput'
import RequiredNote from 'components/RequiredNote'

const initialValues = {
  firstName: '',
  lastName: '',
  profilePassword: '',
  profileConfirmPassword: '',
  workEmail: '',
  mobileNumber: '',
}

function CreateProfile() {
  const { t } = useTranslation()
  const { createProfileMutation, redirectPathOnAuth } = useAuth()

  const companyInvite =
    redirectPathOnAuth === `${CREATE_COMPANY_ROOT_ROUTE}?status=invite`

  const handleSubmit = (values: typeof initialValues) => {
    createProfileMutation.mutate({
      username: values.workEmail,
      password: values.profilePassword,
      attributes: {
        email: values.workEmail,
        given_name: values.firstName,
        family_name: values.lastName,
        phone_number: values.mobileNumber,
        'custom:companyInvite': companyInvite.toString(),
      },
      autoSignIn: {
        enabled: true,
      },
    })
  }

  return (
    <Layout sx={{ px: 0 }}>
      <Typography variant="h2" component="h1" mb={3}>
        {t('account.profile.title')}
      </Typography>

      <RequiredNote />

      <Formik
        initialValues={initialValues}
        validationSchema={profileSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Grid container rowSpacing={3} mt={1}>
              <Grid item xs={6} pr={1}>
                <TextInput
                  id="first-name"
                  name="firstName"
                  label={t('account.profile.labels.firstName')}
                  placeholder={t('account.profile.labels.firstNamePlaceholder')}
                  autoFocus
                  required
                />
              </Grid>
              <Grid item xs={6} pl={1}>
                <TextInput
                  id="last-name"
                  name="lastName"
                  label={t('account.profile.labels.lastName')}
                  placeholder={t('account.profile.labels.lastNamePlaceholder')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="work-email"
                  name="workEmail"
                  label={t('account.profile.labels.workEmail')}
                  placeholder={t('account.profile.labels.workEmailPlaceholder')}
                  type="email"
                  inputMode="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="profile-password"
                  name="profilePassword"
                  label={t('account.profile.labels.password')}
                  placeholder={t('account.profile.labels.passwordPlaceholder')}
                  helperText={
                    <Typography
                      variant="subtitle1"
                      component={'span'}
                      dangerouslySetInnerHTML={{
                        __html: t('common.passwordHelperText'),
                      }}
                    />
                  }
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="profile-confirm-password"
                  name="profileConfirmPassword"
                  label={t('account.profile.labels.confirmPassword')}
                  placeholder={t('account.profile.labels.passwordPlaceholder')}
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  id="mobile-number"
                  name="mobileNumber"
                  label={t('account.profile.labels.mobileNumber')}
                  required
                />
              </Grid>

              <Grid item xs={12} my={2}>
                <Typography>
                  <Trans i18nKey="account.profile.agreement">
                    By clicking “Next”, you are agreeing to our
                    <Link href="/terms" target="_blank">
                      Terms &nbsp; Conditions
                    </Link>
                    and
                    <Link href="/privacy" target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </Trans>
                </Typography>
              </Grid>
              {createProfileMutation.isError && (
                <Grid item xs={12}>
                  <Alert title={t('common.error')}>
                    {(createProfileMutation.error as Error).message ??
                      t('common.errorUnknown')}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  mode="forward"
                  loading={createProfileMutation.isLoading}
                >
                  {t('common.next')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateProfile

import { Route, Routes } from 'react-router-dom'
import { FEATURE_FLAGS } from 'lib/config'

import CompanyAgreementLetter from 'views/companyAdmin/CompanyAgreementLetter'
import CreateCompanyAccount from 'views/companyAdmin/createCompanyAccount/CreateCompanyAccount'
import OfferingsInterestsQuestions from 'views/companyAdmin/offeringsInterestsQuestions/OfferingsInterestsQuestions'
import PageNotFound from 'views/PageNotFound'

function Account() {
  return (
    <Routes>
      <Route path="company" element={<CreateCompanyAccount />} />
      <Route path="questions" element={<OfferingsInterestsQuestions />} />
      {FEATURE_FLAGS.SIGN_COMPANY_AGREEMENT_LETTER && (
        <Route path="agreement" element={<CompanyAgreementLetter />} />
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default Account

import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import { useSetOfferStatus } from 'lib/apollo/hooks'
import MutationModal from 'components/MutationModal'

interface Props {
  isOpen: boolean
  closeModal: () => void
  offerId: Offer['_id']
}

function CancelOfferModal({ isOpen, closeModal, offerId }: Props) {
  const { t } = useTranslation()
  const [setOfferStatus, setOfferStatusMutation] = useSetOfferStatus(offerId)

  const handleCancel = () =>
    setOfferStatus({
      variables: {
        offerId: offerId,
        status: 'CANCELLED',
      },
    })

  const handleClose = useCallback(() => {
    setOfferStatusMutation.reset()
    closeModal()
  }, [closeModal, setOfferStatusMutation])

  useEffect(() => {
    if (setOfferStatusMutation.data && !setOfferStatusMutation.error) {
      handleClose()
    }
  }, [handleClose, setOfferStatusMutation.data, setOfferStatusMutation.error])

  return (
    <MutationModal
      icon={CloseIcon}
      color={'error'}
      title={t('offer.manage.cancel.title')}
      primaryButtonAction={handleCancel}
      primaryButtonText={t('offer.manage.cancel.cancelOffer')}
      secondaryButtonAction={handleClose}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={setOfferStatusMutation.loading}
      isError={setOfferStatusMutation.error !== undefined}
    >
      <Typography>{t('offer.manage.cancel.description')}</Typography>
    </MutationModal>
  )
}

export default CancelOfferModal

import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { Company } from 'common/types'
import Link from 'components/mui/Link'
import { REGEX_LINK_PROTOCOL } from 'lib/config'

function CompanyInfo({ company }: { company: Company }) {
  const { name, websiteAddress, address, ein } = company

  const { t } = useTranslation()

  return (
    <Grid container sx={{ wordBreak: 'break-all' }}>
      <Grid item xs={12} smd={8} md={6} mb={4}>
        <Typography variant="h3" mb={3}>
          {t('openstockAdmin.company.companyInfo.companyInfo')}
        </Typography>
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" mb={0.5}>
              {t('openstockAdmin.company.companyInfo.companyName')}
            </Typography>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" mb={0.5}>
              {t('openstockAdmin.company.companyInfo.companyWebsite')}
            </Typography>
            {websiteAddress ? (
              <Link
                href={websiteAddress}
                target="_blank"
                alignItems="center"
                sx={{ display: 'flex' }}
                rel="noopener noreferrer"
              >
                <Typography variant="body2" pr={2}>
                  {websiteAddress.replace(REGEX_LINK_PROTOCOL, '')}
                </Typography>
                <OpenInNewIcon />
              </Link>
            ) : (
              <Typography>-</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" mb={0.5}>
              {t('openstockAdmin.company.companyInfo.companyAddress')}
            </Typography>
            <Typography>
              {address.streetAddress}
              {address.streetAddress2 && `, ${address.streetAddress2}`}
            </Typography>
            <Typography>{`${address.city}, ${address.state}`}</Typography>
            <Typography>{address.zipCode}</Typography>
            <Typography>{address.country}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" mb={0.5}>
              {t('openstockAdmin.company.companyInfo.ein')}
            </Typography>
            <Typography>{ein ?? '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompanyInfo

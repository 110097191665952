import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'react-use'

import useSendCalculatorEmailLink from 'hooks/useSendCalculatorEmailLink'
import { createCalculatorUserLinkInfoAirtableRecord } from 'lib/airtable'
import { sharesAssumedValues, unitedStatesRegions } from 'lib/formConfig'
import { Form, Formik, useFormikSync } from 'lib/formik'
import { sharesCalculatorSchema } from 'lib/validation'
import { CalculatorUser } from 'views/CalculatorPage'

import { ReactComponent as CalculatorIcon } from 'assets/calculator.svg'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import CalculatorInformation from 'components/calculator/CalculatorInformation'
import OutlinedAutocompleteInput from 'components/mui/OutlinedAutocompleteInput'
import OutlinedInput from 'components/mui/OutlinedInput'
import Paper from 'components/mui/Paper/Paper'
import PercentValueSlider from 'components/PercentValueSlider'
import SendCalculatorInfo from 'components/calculator/SendCalculatorInfo'
import AlertDialog from 'components/AlertDialog'
import SharesChartTable from 'components/calculator/SharesChartTable'

function Shares({
  calculatorUser,
  handleSearchParams,
}: {
  calculatorUser: CalculatorUser
  handleSearchParams: (values: any, handleResetMutation: any) => void
}) {
  const { t } = useTranslation()
  const ref = useRef<null | HTMLDivElement>(null)
  const [searchParams] = useSearchParams()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const initialValues = {
    shares: searchParams.get('shares') ?? 4000,
    sharePrice: searchParams.get('sharePrice') ?? 10,
    growthRate: searchParams.get('growthRate') ?? 15,
    percentValueOfShares: searchParams.get('percentValueOfShares') ?? 25,
    state: searchParams.get('state') ?? 'California',
  }

  const { syncedFormikValues, SyncFormik } = useFormikSync(initialValues)
  const sendMutation = useSendCalculatorEmailLink()

  const handleResetMutation = useCallback(() => {
    setShowAlertDialog(false)
    sendMutation.reset()
  }, [sendMutation])

  const handleOnClose = () => setShowAlertDialog(false)

  const handleLink = () => ref.current?.scrollIntoView({ behavior: 'smooth' })

  const handleSubmit = () => {
    const details = {
      email: calculatorUser.email,
      company: calculatorUser.company,
      link: `${window.location.href}&email=${calculatorUser.email}&company=${calculatorUser.company}`,
    }

    sendMutation.mutate(details)

    createCalculatorUserLinkInfoAirtableRecord({
      ...details,
      type: 'Shares',
    })
  }

  useDebounce(
    () => handleSearchParams({ ...syncedFormikValues }, handleResetMutation),

    10,
    [syncedFormikValues]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={sharesCalculatorSchema}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm }) => {
        const valueOfShares =
          Number(values.shares ?? 0) * Number(values.sharePrice ?? 0)

        const liquidValueShares =
          valueOfShares * (Number(values.percentValueOfShares) / 100)

        return (
          <Form>
            <SyncFormik values={values} />
            <Grid container justifyContent="space-between" rowGap={6}>
              {/* Shares calculator */}
              <Grid item container xs={12} sm={9} md={5}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Grid item xs="auto">
                    <CalculatorIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h2">
                      {t('calculator.tab.shares.form.title')}
                    </Typography>
                  </Grid>
                </Grid>
                <Paper sx={{ mb: 3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <OutlinedInput
                        id="shares"
                        name="shares"
                        label={t('calculator.tab.shares.form.sharesLabel')}
                        helperText={t(
                          'calculator.tab.shares.form.sharesHelperText'
                        )}
                        placeholder={t('common.numberPlaceholder')}
                        hasDecimalScale={false}
                      />
                      <Divider sx={{ mt: 2, mb: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedInput
                        id="sharePrice"
                        name="sharePrice"
                        delimiter="$"
                        label={t('calculator.tab.shares.form.sharePriceLabel')}
                        helperText={t(
                          'calculator.tab.shares.form.sharePriceHelperText'
                        )}
                      />
                      <Divider sx={{ mt: 2, mb: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedInput
                        id="growthRate"
                        name="growthRate"
                        label={t('calculator.tab.shares.form.growthRateLabel')}
                        helperText={t(
                          'calculator.tab.shares.form.growthRateHelperText'
                        )}
                        placeholder={t('common.numberPlaceholder')}
                        hasDecimalScale={false}
                      />
                      <Divider sx={{ mt: 2, mb: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedAutocompleteInput
                        id="state"
                        name="state"
                        label={t('calculator.tab.shares.form.stateLabel')}
                        helperText={t(
                          'calculator.tab.shares.form.stateHelperText'
                        )}
                        placeholder={t('common.selectPlaceholder')}
                        options={unitedStatesRegions}
                        inputProps={{
                          autoComplete: 'address-level1',
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                </Paper>

                {/* Estimated value of shares */}
                <Paper sx={{ mb: 3 }}>
                  <Grid container>
                    <Typography variant="h4">
                      {t('calculator.tab.shares.form.valueOfSharesLabel')}
                    </Typography>
                    <Grid item container my={0.5}>
                      <Typography variant="h1">
                        {t('common.intlCurrencySimplifiedFormat', {
                          value: valueOfShares,
                        })}
                      </Typography>
                    </Grid>

                    <Typography>
                      {t('calculator.tab.shares.form.valueOfSharesHelperText')}
                    </Typography>
                  </Grid>
                </Paper>

                {/* Percentage slider - Values of shares to make liquid */}
                <Paper>
                  <Grid container>
                    <PercentValueSlider
                      fieldName="percentValueOfShares"
                      minValue={1}
                      maxValue={sharesAssumedValues.maxPercentageOfShares}
                      label={t('calculator.tab.shares.form.liquidValueLabel')}
                      value={liquidValueShares}
                      sliderValue={Number(values.percentValueOfShares)}
                      helperText={t(
                        'calculator.tab.shares.form.liquidValueHelperText'
                      )}
                      isTextInput={false}
                    />
                  </Grid>
                </Paper>
              </Grid>

              <Grid item container xs={12} sm={9} md={6}>
                <CalculatorInformation name="shares" handleLink={handleLink} />
              </Grid>

              <Grid item container xs={12}>
                <SharesChartTable values={values} />
              </Grid>

              <Grid item container xs={12} ref={ref}>
                <SendCalculatorInfo
                  name="shares"
                  sendInfoMutation={sendMutation}
                />
              </Grid>
            </Grid>

            {/* Shows mutation error when submitting form  */}
            <AlertDialog
              isOpen={showAlertDialog}
              onClose={handleOnClose}
              description={t('common.errorSubmitForm')}
              primaryButtonAction={() => {
                submitForm()
                setShowAlertDialog(false)
              }}
              secondaryButtonAction={handleResetMutation}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default Shares

import Paper from 'components/mui/Paper/Paper'
import EmptyImage from 'assets/emptySmall.svg'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Button from 'components/mui/Button'
import Box from '@mui/system/Box/Box'

function OffersEmptyCard({
  action,
  actionText,
  title,
  description,
}: {
  action?: () => void
  actionText?: string
  title: string
  description?: string
}) {
  return (
    <Paper sx={{ boxShadow: 'none' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item display="flex" alignItems="center">
          <img src={EmptyImage} width={72} height={72} alt="" />
          <Box ml={4}>
            <Typography variant="h4" mb={1}>
              {title}
            </Typography>
            {description && <Typography>{description}</Typography>}
          </Box>
        </Grid>
        {action && (
          <Grid item my={1}>
            <Button onClick={action} mode="forward">
              {actionText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default OffersEmptyCard

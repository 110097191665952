import { Navigate, Route, Routes } from 'react-router-dom'

import CompanyInfoTabs from 'views/companyAdmin/companyInfo/CompanyInfoTabs'
import CreateOfferPage from 'components/offerings/CreateOfferPage'
import EditOffer from 'views/companyAdmin/createOffer/EditOffer'
import ManageOffer from 'views/companyAdmin/manageOffer/ManageOffer'
import CompanyCapTable from 'views/companyAdmin/capTable/CompanyCapTable'
import CreateLoanOffer from 'views/companyAdmin/createOffer/CreateLoanOffer'
import CreateTenderOffer from 'views/companyAdmin/createOffer/CreateTenderOffer'
import CompanyAdminOfferings from 'views/companyAdmin/CompanyAdminOfferings'
import PageNotFound from 'views/PageNotFound'
import CompanyOverview from 'views/companyAdmin/CompanyOverview'
import ImportCapTable from 'views/companyAdmin/capTable/ImportCapTable'
import ImportCapTablePreview from 'views/companyAdmin/capTable/ImportCapTablePreview'
import ReviewApproveCapTable from 'views/companyAdmin/capTable/ReviewApproveCapTable'
import CompanyLoanInfo from 'views/companyAdmin/CompanyLoanInfo'
import CreateOfferPreview from 'views/companyAdmin/createOffer/CreateOfferPreview'
import DataInsights from 'views/companyAdmin/DataInsights/DataInsights'
import { FEATURE_FLAGS } from 'lib/config'
import TenderOfferLearnMore from 'views/companyAdmin/createOffer/TenderOfferLearnMore/TenderOfferLearnMore'

export const DEFAULT_COMPANY_DASHBOARD_ROUTE = '/company/dashboard'

function Dashboard() {
  return (
    <Routes>
      <Route path="overview" element={<CompanyOverview />} />
      {FEATURE_FLAGS.DEMO_DATA_INSIGHTS && (
        <Route path="data-insights" element={<DataInsights />} />
      )}
      <Route path="offers/*" element={<CompanyAdminOfferings />} />
      <Route path="offers/offer/:offerId" element={<ManageOffer />} />
      <Route path="offers/edit-offer/:offerId" element={<EditOffer />} />
      <Route path="create-offer/*" element={<CreateOfferPage />} />
      <Route
        path="create-offer/preview/:offerType"
        element={<CreateOfferPreview />}
      />

      <Route
        path="create-offer/non-recourse-loan"
        element={<CreateLoanOffer />}
      />
      {FEATURE_FLAGS.DEMO_TENDER_OFFER && (
        <Route
          path="create-offer/tender-offer"
          element={<CreateTenderOffer />}
        />
      )}
      {!FEATURE_FLAGS.DEMO_TENDER_OFFER && (
        <Route
          path="create-offer/learn-more/tender-offer"
          element={<TenderOfferLearnMore />}
        />
      )}
      <Route path="cap-table/*" element={<CompanyCapTable />} />
      <Route
        path="cap-table/import-preview"
        element={<ImportCapTablePreview />}
      />
      <Route path="cap-table/import" element={<ImportCapTable />} />
      <Route path="cap-table/review" element={<ReviewApproveCapTable />} />
      <Route path="company-info/*" element={<CompanyInfoTabs />} />
      <Route path="financing-info" element={<CompanyLoanInfo />} />
      <Route path="/" element={<Navigate to="overview" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default Dashboard

import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid/Grid'
import Layout from 'components/Layout/Layout'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import useFileUploadMutation from 'hooks/useFileUpload'
import useRefetchQueries from 'hooks/useRefetchQueries'
import { useSteps } from 'hooks/useSteps'
import { useGetCompanyQuery } from 'lib/apollo/hooks'
import { UPLOAD_INGESTION_SPREADSHEET } from 'lib/formConfig'
import { DEFAULT_ADMIN_DASHBOARD_ROUTE } from 'routes/AdminDashboard'
import theme from 'styles/customTheme'
import { UploadDocuments } from 'common/types'

import QueryBoundary from 'app/QueryBoundary'
import CheckUploadedDocuments from './CheckUploadedDocuments'
import DownloadIngestionTemplate from './DownloadIngestionTemplate'
import UploadSpreadsheet from './UploadSpreadsheet'
import BackLink from 'components/BackLink'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import ApproveIngestedCapTable from 'views/ApproveIngestedCapTable'
import ReviewIngestedCapTableForm from 'views/ReviewIngestedCapTable'

function IngestCapTable() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeStep, handleBack, handleNext } = useSteps()
  const { companyId } = useParams()
  const { refetchQueries } = useRefetchQueries()
  const companyQuery = useGetCompanyQuery(companyId)
  const { data } = companyQuery
  const company = data?.getCompany

  const fileUploadMutation = useFileUploadMutation(true, companyId)

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleNavigate = () =>
    navigate(`${DEFAULT_ADMIN_DASHBOARD_ROUTE}/${companyId}/cap-table`)

  const handleCheckFilesForErrors = async (values: UploadDocuments) => {
    if (!values.ingestionSpreadsheet?.[0]) {
      throw Error('File missing')
    }

    fileUploadMutation.setUploadCaptableErrors([])

    await fileUploadMutation.mutateAsync({
      endpoint: 'uploadcaptabletemplate',
      documents: [
        {
          file: values.ingestionSpreadsheet[0],
          name: UPLOAD_INGESTION_SPREADSHEET.name,
        },
      ],
    })

    await refetchQueries()
  }

  const pages: { title: string; description: string }[] = t(
    'openstockAdmin.company.capTable.pages',
    {
      returnObjects: true,
    }
  )

  const steps: string[] = pages.map(({ title }) => title)

  return (
    <QueryBoundary queries={[companyQuery]}>
      <Grid container sx={{ flex: 1 }}>
        {isMediumUp && (
          <Grid item xs={12} md={3.5} lg={2.5}>
            <SideBarStepper
              activeStep={activeStep}
              steps={steps}
              title={t('openstockAdmin.company.capTable.ingestCapTable')}
              action={handleNavigate}
              actionTitle={t('common.back')}
            />
          </Grid>
        )}

        <Grid item xs={12} md={8.5} lg={9.5}>
          <Layout maxWidth={activeStep === 3 ? 'lg' : 'sm'} isSideBarStepper>
            {isMediumDown && <BackLink onClick={handleNavigate} />}

            {activeStep !== 3 && (
              <>
                <Typography variant="h2" component="h1" mb={5}>
                  {pages[activeStep].title}
                </Typography>
                {pages[activeStep].description && (
                  <Typography mb={1}>
                    <Trans i18nKey={pages[activeStep].description} />
                  </Typography>
                )}
              </>
            )}

            {(() => {
              const caseProps = {
                captableId: company?.captableCurrent?._id,
                company,
                fileUploadMutation,
                handleNext,
                handleBack,
                handleCheckFilesForErrors,
                handleNavigate,
              }

              switch (activeStep) {
                case 0:
                  return <CheckUploadedDocuments {...caseProps} />
                case 1:
                  return <DownloadIngestionTemplate {...caseProps} />
                case 2:
                  return <UploadSpreadsheet {...caseProps} />
                case 3:
                  return (
                    <ReviewIngestedCapTableForm
                      {...caseProps}
                      title={pages[activeStep].title}
                      description={pages[activeStep].description}
                      subHeading={t(
                        'openstockAdmin.company.capTable.reviewIngestedCapTable.subHeading'
                      )}
                      captableId={(fileUploadMutation.data as any)?.data?._id}
                    />
                  )
                case 4:
                  return (
                    <ApproveIngestedCapTable
                      {...caseProps}
                      captableId={(fileUploadMutation.data as any)?.data?._id}
                    />
                  )

                default:
                  return
              }
            })()}
          </Layout>
        </Grid>
      </Grid>
    </QueryBoundary>
  )
}

export default IngestCapTable

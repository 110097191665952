import { Trans, useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography/Typography'

import ConfirmationImage from 'assets/confirmation.svg'
import { Role } from 'common/types'
import Layout from 'components/Layout/Layout'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'
import styles from './accountCreationConfirmation.module.scss'

interface Props {
  role: Role
}

function AccountCreationConfirmation({ role }: Props) {
  const { t } = useTranslation()

  const config = {
    shareholder: {
      title: t('account.shareholder.confirmation.title'),
      description: (
        <Typography>
          <Trans i18nKey="account.shareholder.confirmation.description">
            Thank you for setting up your Openstock account. If you are eligible
            for any offers, these will be available to view in your Openstock
            portal. If you currently have no offers available, keep an eye on
            your inbox! We'll keep you in the loop as soon as you are eligible
            for an offer. In the meantime, if you need support, please reach out
            to us any time at
            <Link
              href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                'account.shareholder.confirmation.supportEmailSubject'
              )}`}
              variant="body2"
            >
              {OPENSTOCK_SUPPORT_EMAIL}
            </Link>
          </Trans>
        </Typography>
      ),
      buttonLabel: t('account.shareholder.confirmation.button'),
      buttonNavigateTo: DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE,
    },
    company_admin: {
      title: t('account.company.confirmation.title'),
      description: (
        <Typography>
          <Trans i18nKey="account.company.confirmation.description">
            Thank you for setting up your company profile. You can now initiate
            the underwriting process and import your cap table. Once you've been
            approved for a loan, you'll be able to create offers for your
            shareholders. Remember, if you need support, please reach out to us
            any time at
            <Link
              href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                'account.company.confirmation.supportEmailSubject'
              )}`}
              variant="body2"
            >
              {OPENSTOCK_SUPPORT_EMAIL}
            </Link>
          </Trans>
        </Typography>
      ),
      buttonLabel: t('account.company.confirmation.button'),
      buttonNavigateTo: DEFAULT_COMPANY_DASHBOARD_ROUTE,
    },
  } as any

  const roleConfig = config[role.toLowerCase()]

  return (
    <Layout>
      <Typography variant="h2" component="h1" mb={2}>
        {roleConfig.title}
      </Typography>

      {roleConfig.description}

      <Button
        to={{
          pathname: roleConfig.buttonNavigateTo,
          replace: true,
        }}
        mode="forward"
        sx={{ my: 4 }}
      >
        {roleConfig.buttonLabel}
      </Button>

      <img
        src={ConfirmationImage}
        alt=""
        width="375"
        height="370"
        className={styles.image}
      />
    </Layout>
  )
}

export default AccountCreationConfirmation

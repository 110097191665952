import * as Sentry from '@sentry/react'

import { SENTRY_DSN } from 'lib/config'

export function initializeSentry(dsn = SENTRY_DSN) {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (!dsn) {
    return console.warn('No Sentry DSN found')
  }

  Sentry.init({
    dsn: SENTRY_DSN,
  })
}

export function captureError(error: Error) {
  Sentry.captureException(error)
}

export function captureWarning(error: Error) {
  Sentry.withScope((scope) => {
    scope.setLevel('warning')
    Sentry.captureException(error)
  })
}

export function setSentryUser(value: Sentry.User | null) {
  Sentry.setUser(value)
}

export const setErrorContext = Sentry.setContext

export const ErrorBoundary = Sentry.ErrorBoundary

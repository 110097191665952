import { Navigate, Route, Routes } from 'react-router-dom'

import PageNotFound from 'views/PageNotFound'
import ShareholderOffers from 'views/shareholder/ShareholderOffers'
import ShareholderOverview from 'views/shareholder/ShareholderOverview'

export const DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE = '/shareholder/dashboard'

function Dashboard() {
  return (
    <Routes>
      <Route path="/overview/*" element={<ShareholderOverview />} />
      <Route path="/offers/*" element={<ShareholderOffers />} />
      <Route path="/" element={<Navigate to="overview" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default Dashboard

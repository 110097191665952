import i18n from 'locale/i18n'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import TextInput, { TextInputProps } from 'components/mui/TextInput'

import theme from 'styles/customTheme'

type Props = TextInputProps & {
  required?: boolean
  label?: string
}

function OutlinedInput({
  placeholder = i18n.t('common.decimalPlaceholder'),
  isNumericFormat = true,
  required = true,
  label,
  ...props
}: Props) {
  return (
    <Grid>
      {label && (
        <Typography variant="h4" mb={1}>
          {label}
        </Typography>
      )}
      <TextInput
        hiddenLabel
        variant="outlined"
        placeholder={placeholder}
        required={required}
        showErrorHelperText={false}
        showErrorOnTouchedOnly={false}
        isNumericFormat={isNumericFormat}
        sx={{
          mb: 1,
          '.MuiInputAdornment-root .MuiTypography-root': {
            fontSize: theme.typography.h1,
            color: 'secondary.darkest',
          },
          '.MuiInputBase-root': {
            borderRadius: '6px',
            fontSize: theme.typography.h1,
            color: 'secondary.darkest',
          },
          '.MuiFormHelperText-root': {
            fontSize: theme.typography.subtitle1,
            mx: 0,
          },
          '.MuiOutlinedInput-input': {
            padding: 1,
          },
          ...props.sx,
        }}
        {...props}
      />
    </Grid>
  )
}

export default OutlinedInput

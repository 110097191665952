import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { REGEX_LINK_PROTOCOL } from 'lib/config'
import { Company } from 'common/types'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'

interface Props {
  company: Company
}

function CompanyCard({ company }: Props) {
  const { t } = useTranslation()
  const { name, offers, websiteAddress } = company

  const totalOffers = offers.length

  return (
    <Grid container item xs={12} sm={6} md={4}>
      <Paper sx={{ width: 1, wordBreak: 'break-all' }}>
        <Grid container item gap={2}>
          {/* Company name and site */}
          <Grid item>
            <Typography variant="h4">{name}</Typography>
            {websiteAddress && (
              <Link
                href={websiteAddress}
                target="_blank"
                variant="subtitle2"
                rel="noopener noreferrer"
              >
                {websiteAddress.replace(REGEX_LINK_PROTOCOL, '')}
              </Link>
            )}
          </Grid>

          {/* Pending offers */}
          <Grid item container justifyContent="space-between">
            <Grid item xs="auto">
              <Typography variant="body2">
                {t('openstockAdmin.companies.card.totalOffers')}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">{totalOffers}</Typography>
            </Grid>
          </Grid>

          {/*Manage Button */}
          <Grid container alignSelf="end" justifyContent="end">
            <Button to={`${company._id}/offers`} variant="outlined">
              {t('openstockAdmin.companies.card.button')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default CompanyCard

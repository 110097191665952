import { Trans, useTranslation } from 'react-i18next'

import Grid, { GridProps } from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { optionsAssumedValues, sharesAssumedValues } from 'lib/formConfig'
import { ReactComponent as CoinsIcon } from 'assets/coins.svg'
import { ReactComponent as GraphCalculatorIcon } from 'assets/graphCalculator.svg'
import List from 'components/mui/List'
import Link from 'components/mui/Link'

function BorderContainer({
  children,
  ...props
}: GridProps & {
  children: React.ReactNode
}) {
  return (
    <Grid
      item
      container
      p={2}
      sx={{
        border: 1,
        borderRadius: '6px',
        borderColor: 'secondary.light',
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}

function CalculatorInformation({
  name,
  handleLink,
}: {
  name: string
  handleLink: () => void
}) {
  const { t } = useTranslation()

  const actionList: string[] = t(
    `calculator.tab.${name}.information.body2List`,
    {
      returnObjects: true,
    }
  )

  return (
    <Grid container alignContent="flex-start" mt={{ xs: 4, md: 0 }}>
      <Grid item>
        <Grid container alignItems="center" mb={2}>
          <Grid item xs="auto">
            <CoinsIcon />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h3" ml={1}>
              {t(`calculator.tab.${name}.information.title`)}
            </Typography>
          </Grid>
        </Grid>

        <BorderContainer>
          <Grid item xs={12}>
            <Typography>
              <Trans i18nKey={`calculator.tab.${name}.information.body`} />
            </Typography>
          </Grid>
        </BorderContainer>

        <BorderContainer mt={2} mb={5}>
          <Grid item xs={12}>
            <Typography>
              <Trans i18nKey={`calculator.tab.${name}.information.body2`} />
            </Typography>
            <List list={actionList} />
          </Grid>
        </BorderContainer>
      </Grid>

      {/* Assumed for calculation */}
      <Grid item container xs={12} sm={10}>
        <Grid container alignItems="center" mb={2}>
          <Grid item xs="auto">
            <GraphCalculatorIcon />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h3" ml={1}>
              {t(`calculator.tab.${name}.information.term.title`)}
            </Typography>
          </Grid>
        </Grid>

        <BorderContainer pr={10}>
          <Grid item container xs={12}>
            <Typography variant="subtitle1">
              {t(`calculator.tab.${name}.information.term.label`)}
            </Typography>
            <Grid container my={0.5}>
              <Typography variant="h3">
                {t('common.yearWithCount', {
                  count: (name === 'shares'
                    ? sharesAssumedValues
                    : optionsAssumedValues
                  ).termInYears,
                })}
              </Typography>
            </Grid>
            <Typography>
              {t(`calculator.tab.${name}.information.term.description`)}
            </Typography>
          </Grid>
        </BorderContainer>

        <Grid item ml={2} mt={1}>
          <Link type="button" variant="subtitle2" onClick={handleLink}>
            {t(`calculator.tab.${name}.information.term.link`)}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CalculatorInformation

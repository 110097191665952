import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { captureWarning } from 'lib/monitoring'
import { SOFR_RATE_API_URL } from 'lib/config'

const useSofrRate = () =>
  useQuery(
    ['sofrRate'],
    () => axios.get(SOFR_RATE_API_URL).then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60 * 6,
      cacheTime: Infinity,
      refetchInterval: 1000 * 60 * 60 * 12,
      onError: (e: Error) => captureWarning(e),
    }
  )

export default useSofrRate

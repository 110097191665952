import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import { useSetOfferStatus } from 'lib/apollo/hooks'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import MutationModal from 'components/MutationModal'

interface Props {
  isOpen: boolean
  closeModal: () => void
  offerId: Offer['_id']
}

function DeleteOfferModal({ isOpen, closeModal, offerId }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [setOfferStatus, setOfferStatusMutation] = useSetOfferStatus(offerId)

  const handleDelete = () =>
    setOfferStatus({
      variables: {
        offerId: offerId,
        status: 'DELETED',
      },
    })

  const handleClose = () => {
    setOfferStatusMutation.reset()
    closeModal()
  }

  useEffect(() => {
    if (setOfferStatusMutation.data) {
      setOfferStatusMutation.reset()
      navigate(`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/saved`)
    }
  }, [navigate, setOfferStatusMutation, setOfferStatusMutation.data])

  return (
    <MutationModal
      icon={DeleteOutlineIcon}
      color={'error'}
      title={t('offer.manage.delete.title')}
      primaryButtonAction={handleDelete}
      primaryButtonText={t('offer.manage.delete.deleteOffer')}
      secondaryButtonAction={handleClose}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={setOfferStatusMutation.loading}
      isError={setOfferStatusMutation.error !== undefined}
    >
      <Typography>{t('offer.manage.delete.description')}</Typography>
    </MutationModal>
  )
}

export default DeleteOfferModal

import { useRef } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'
import GridOnIcon from '@mui/icons-material/GridOn'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { Captable, RecentCapTable } from 'common/types'
import { useGetCaptableQuery, useGetCompanyQuery } from 'lib/apollo/hooks'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { getOfferTypeConfig, offerFixedTimelineDays } from 'lib/formConfig'
import { Form, Formik } from 'lib/formik'
import { recentCapTableSchema } from 'lib/validation'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'

import BackLink from 'components/BackLink'
import Button from 'components/mui/Button'
import CheckboxInput from 'components/mui/CheckboxInput'
import Link from 'components/mui/Link'
import Layout from 'components/Layout/Layout'
import SetupSteps, { PreviewDetails } from 'components/Preview'
import QueryBoundary from 'app/QueryBoundary'
import { FEATURE_FLAGS } from 'lib/config'

const ActiveCapTable = ({ captable }: { captable: Captable }) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      sx={{
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'secondary.main',
        p: 2,
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item container xs="auto" columnGap={1.5} my={1}>
        <Grid item>
          <GridOnIcon sx={{ color: 'secondary.darkest' }} />
        </Grid>
        <Grid item>
          <Typography mb={0.5}>
            {t('dashboard.offers.createOffer.setup.activeCapTable')}
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary.dark"
            dangerouslySetInnerHTML={{
              __html: t('common.processedDate', {
                date: dayjs(captable?.createdAt).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }),
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          endIcon={<OpenInNewIcon />}
          variant="text"
          onClick={() =>
            window.open(
              `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table`,
              '_blank',
              'popup'
            )
          }
          sx={{ ml: 2 }}
        >
          {t('dashboard.offers.createOffer.setup.viewCapTable')}
        </Button>
      </Grid>
    </Grid>
  )
}

const initialValues = { isRecentCapTable: false } as RecentCapTable

function CreateOfferPreview() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref = useRef<null | HTMLDivElement>(null)

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery.data?.getCompany

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery.data?.getCaptable

  const financialTerms = company?.financialTermsCurrent

  const { offerType } = useParams()

  const offerTypeConfig = offerType && getOfferTypeConfig(offerType)

  const { offerTypeTranslationKey }: any = offerTypeConfig

  const setupSteps: { title: string; description: string }[] = t(
    `dashboard.offers.createOffer.${offerTypeTranslationKey}.setup.description.steps`,
    {
      returnObjects: true,
    }
  )

  const details: {
    heading: string
    subHeading: string
    items: { title: string; description: string }[]
  }[] = t(`dashboard.offers.createOffer.${offerTypeTranslationKey}.details`, {
    offerFixedTimelineDays,
    interestRate: financialTerms?.interestRate,
    loanTerm: financialTerms?.loan.termInMonths,
    returnObjects: true,
  })

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSubmit = () => navigate(`../create-offer/${offerType}`)

  if (offerType === 'tender-offer' && !FEATURE_FLAGS.DEMO_TENDER_OFFER) {
    return <Navigate to={`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/create-offer`} />
  }

  return (
    <QueryBoundary queries={[captableQuery, companyQuery]}>
      <Formik
        initialValues={initialValues}
        validationSchema={recentCapTableSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Layout maxWidth="lg">
              <BackLink to="../create-offer" />
              <Grid container justifyContent="space-between" mb={5}>
                <Typography variant="h1" mb={2}>
                  {t(
                    `dashboard.offers.createOffer.${offerTypeTranslationKey}.title`
                  )}
                </Typography>
                <Box>
                  {/* This button has unique design, onClick scrolls user down below to confirm details */}
                  <Button
                    mode="forward"
                    sx={{
                      backgroundColor: 'primary.lightest',
                      color: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                      },
                    }}
                    type="submit"
                    onClick={handleClick}
                  >
                    {t('dashboard.offers.createOffer.createOfferButton')}
                  </Button>
                </Box>
              </Grid>
              <Grid container justifyContent="flex-end" rowGap={5}>
                <SetupSteps
                  heading={t(
                    `dashboard.offers.createOffer.${offerTypeTranslationKey}.setup.heading`
                  )}
                  subHeading={t(
                    `dashboard.offers.createOffer.${offerTypeTranslationKey}.setup.subHeading`
                  )}
                  setupDescription={t(
                    `dashboard.offers.createOffer.${offerTypeTranslationKey}.setup.description.label`
                  )}
                  setupSteps={setupSteps}
                />

                <PreviewDetails details={details} />

                <Grid item container xs={12} smd={6} rowGap={2}>
                  <Grid item>
                    <Typography variant="body2">
                      {t('dashboard.offers.createOffer.setup.note')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ActiveCapTable captable={captable} />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxInput
                      name="isRecentCapTable"
                      label={t('dashboard.offers.createOffer.setup.checkbox')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Trans i18nKey="dashboard.offers.createOffer.setup.importNewLink">
                        If this cap table is out of date, visit the
                        <Link
                          to={'../cap-table/import-preview'}
                          variant="body2"
                        >
                          cap table tab
                        </Link>
                        to import a new cap table.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item mt={3.5}>
                    <Button type="submit" mode="forward">
                      {t(
                        `dashboard.offers.createOffer.${offerTypeTranslationKey}.button`
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Layout>
          </Form>
        )}
      </Formik>
    </QueryBoundary>
  )
}

export default CreateOfferPreview

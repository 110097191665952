import Box from '@mui/material/Box'
import Button from 'components/mui/Button'
import Grid from '@mui/material/Grid'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import Typography from '@mui/material/Typography'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'

import i18n from 'locale/i18n'
import LoadingButton from 'components/mui/LoadingButton'
import Modal from 'components/mui/Modal'
import { GenericObject } from 'common/types'

export interface AlertDialogProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  mode?: 'error' | 'success' | 'warning' | 'info'
  title?: string
  description?: string
  positionBottom?: boolean
  primaryButtonAction: () => void
  primaryButtonText?: string
  secondaryButtonAction?: () => void
  secondaryButtonText?: string
  icon?: OverridableComponent<SvgIconTypeMap<GenericObject, 'svg'>> & {
    muiName: string
  }
  iconColor?: string
  color?: string
  children?: React.ReactNode
}

function AlertDialog({
  isOpen,
  isLoading = false,
  onClose,
  mode = 'error',
  title = i18n.t('common.somethingWentWrong'),
  description = i18n.t('common.errorSubmitForm'),
  primaryButtonAction,
  primaryButtonText = i18n.t('common.tryAgain'),
  secondaryButtonAction,
  secondaryButtonText = i18n.t('common.cancel'),
  icon,
  iconColor,
  color,
  children,
}: AlertDialogProps) {
  const modeConfig = {
    error: {
      icon: WarningAmberIcon,
    },
    success: {
      icon: TaskAltIcon,
    },
    warning: {
      icon: WarningAmberIcon,
    },
    info: {
      icon: undefined,
    },
  } as any

  const Icon = icon ?? modeConfig[mode].icon
  const dominantColor = color ?? modeConfig[mode].label ?? mode

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Grid container>
        <Grid item container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {Icon && (
              <Icon color={iconColor ?? dominantColor} sx={{ mr: 1.5 }} />
            )}
            <Typography variant="h3" color="secondary.darkest">
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item my={2}>
          {children ?? (
            <Typography color="secondary.darkest">{description}</Typography>
          )}
        </Grid>
        <Grid
          item
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            marginTop: 1,
          }}
        >
          <LoadingButton
            variant="contained"
            color={dominantColor}
            sx={{
              color: 'white',
              letterSpacing: '0.46px',
            }}
            loading={isLoading}
            onClick={primaryButtonAction}
          >
            {primaryButtonText}
          </LoadingButton>

          {secondaryButtonAction && (
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                color: 'secondary.darkest',
                letterSpacing: '0.46px',
                mr: 1,
              }}
              onClick={secondaryButtonAction}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Modal>
  )
}

export default AlertDialog

import { useField } from 'formik'

import FormControl, {
  FormControlProps,
} from '@mui/material/FormControl/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Radio from '@mui/material/Radio/Radio'
import RadioGroup, {
  RadioGroupProps,
} from '@mui/material/RadioGroup/RadioGroup'
import FormHelperText from '@mui/material/FormHelperText/FormHelperText'

import { LabelValuePair } from 'common/types'

type Props = RadioGroupProps & {
  name: string
  id: string
  options: LabelValuePair[]
}

function RadioInputGroup({ name, id, options, ...props }: Props) {
  const [field, meta] = useField(name)

  const configFormControl = {
    ...props,
  } as FormControlProps

  if (meta.touched && meta.error) {
    configFormControl.error = true
  }

  return (
    <FormControl {...configFormControl}>
      <RadioGroup aria-labelledby={id} name={name}>
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            control={<Radio />}
            {...field}
            value={option.value}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {configFormControl.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default RadioInputGroup

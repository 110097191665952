import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { getChartScalingArray } from 'lib/data'
import { sharesAssumedValues } from 'lib/formConfig'
import { getCompoundedInterestValue } from 'lib/helpers'
import statesTaxTable from 'lib/taxTable.json'
import ResponsiveTable, { HeadCells } from 'components/mui/ResponsiveTable'
// import { sharesCalculatorSchema } from 'lib/validation'
import Chart from 'components/Chart'
import { GenericObject } from 'common/types'

const calculateData = (values: any) => {
  const data: any[] = []
  const { termInYears, federalLtCapitalGains } = sharesAssumedValues

  const { capitalGains: stateLtCapitalGains } = (
    statesTaxTable.data as GenericObject
  )[values.state]

  const growthRateArray = getChartScalingArray(Number(values.growthRate))

  growthRateArray.forEach((growthRate, i) => {
    // Take a non-recourse loan today
    const valueOfSharesBySharePrice = values.shares * values.sharePrice
    const costOfNonRecourseLoan = getCompoundedInterestValue(
      valueOfSharesBySharePrice,
      growthRate,
      termInYears
    )

    const valueOfSharesToLiquid =
      valueOfSharesBySharePrice * (values.percentValueOfShares / 100)

    // Sell percentage of shares in tender today
    const capitalGainsTax = federalLtCapitalGains + stateLtCapitalGains

    // Back into value of shares that needs to be sold to get to the value of loan ($100K cash)
    const valueOfSharesSold =
      valueOfSharesToLiquid / ((100 - capitalGainsTax) / 100)

    const valueOfUnsoldSharesToday =
      valueOfSharesBySharePrice - valueOfSharesSold

    const valueOfUnsoldSharesEndOfTerm = getCompoundedInterestValue(
      valueOfUnsoldSharesToday,
      growthRate,
      termInYears
    )

    const sellPercentageOfSharesInTender =
      valueOfSharesToLiquid + valueOfUnsoldSharesEndOfTerm

    data[i] = {
      id: 'shares-row' + i,
      col1: growthRate.toFixed(1),
      col2: costOfNonRecourseLoan.toFixed(2),
      col3: sellPercentageOfSharesInTender.toFixed(2),
      col4: (costOfNonRecourseLoan - sellPercentageOfSharesInTender).toFixed(2),
    }
  })

  return data
}

function SharesChartTable({
  values,
}: {
  values: any //typeof sharesCalculatorSchema
}) {
  const { t } = useTranslation()

  const calculateDataRows = useMemo(() => calculateData(values), [values])

  const xAxisData = useMemo(
    () => calculateDataRows.map(({ col1 }) => col1),
    [calculateDataRows]
  )
  const series1Data = useMemo(
    () => calculateDataRows.map(({ col2 }) => col2),
    [calculateDataRows]
  )
  const series2Data = useMemo(
    () => calculateDataRows.map(({ col3 }) => col3),
    [calculateDataRows]
  )

  // Whichever data has the highest values, that will be the y axis max value
  const yAxisMaxValue =
    series1Data[series1Data.length - 1] > series2Data[series2Data.length - 1]
      ? series1Data[series1Data.length - 1]
      : series2Data[series2Data.length - 1]

  const headCells: HeadCells = useMemo(
    () => [
      {
        id: 'shares-col1',
        label: t('calculator.tab.shares.table.tableHeadCell1'),
        width: '25%',
      },
      {
        id: 'shares-col2',
        label: t('calculator.tab.shares.table.tableHeadCell2'),
        width: '25%',
        headCellBorderColor: '#4964D2',
      },
      {
        id: 'shares-col3',
        label: t('calculator.tab.shares.table.tableHeadCell3'),
        width: '25%',
        headCellBorderColor: '#D69886',
      },
      {
        id: 'shares-col4',
        label: t('calculator.tab.shares.table.tableHeadCell4'),
        width: '25%',
      },
    ],
    [t]
  )

  return (
    <Grid
      item
      container
      justifyContent="center"
      sx={{ border: 1, borderColor: 'secondary.light' }}
    >
      <Grid
        item
        xs={12}
        mb={2}
        bgcolor="#fff"
        sx={{ borderBottom: 1, borderColor: 'secondary.light' }}
      >
        <Chart
          id="options-liquidity-calculator-chart"
          chartTitle={t('calculator.tab.shares.chart.title')}
          colors={['#4964D2', '#D69886']}
          series={[
            {
              name: t('calculator.tab.shares.chart.seriesName1'),
              data: series1Data,
            },
            {
              name: t('calculator.tab.shares.chart.seriesName2'),
              data: series2Data,
            },
          ]}
          xAxisData={xAxisData}
          xAxisLabel={t('calculator.tab.shares.chart.xAxis')}
          xAxisLabelFormatter={(value: number) =>
            t('common.percentage', { value: value.toFixed(1) })
          }
          yAxisLabel={t('calculator.tab.shares.chart.yAxis')}
          yAxisLabelFormatter={(value: number) =>
            t('common.intlCurrencySimplifiedFormat', {
              value,
            })
          }
          yAxisMaxValue={Number(yAxisMaxValue)}
        />
      </Grid>

      <ResponsiveTable
        ariaLabel={t('calculator.tab.shares.table.ariaLabel')}
        headCells={headCells}
        isPaperComponent={false}
      >
        <TableBody>
          {calculateDataRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Typography>
                  {t('common.percentage', { value: row.col1 })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col3,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col4,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ResponsiveTable>
    </Grid>
  )
}

export default SharesChartTable

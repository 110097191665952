import { ButtonProps } from '@mui/material'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'

import NoCapTableImage from 'assets/noCapTable.svg'
import EmptyInfoImage from 'assets/EmptyInfoImage.svg'
import Button from 'components/mui/Button'
import Container from 'components/mui/Container/Container'

interface Props {
  title: string
  description: string
  buttonLabel: string
  buttonNavigateTo: string
  buttonProps?: ButtonProps
  note?: string
  imageReference?: 'table'
}

function EmptyStateWithButton({
  title,
  description,
  buttonLabel,
  buttonNavigateTo,
  note,
  buttonProps,
  imageReference,
}: Props) {
  return (
    <Container isCentered sx={{ mt: 4 }}>
      <Typography variant="h3">{title}</Typography>
      <Typography my={2} textAlign="center">
        {description}
      </Typography>
      <Box>
        <img
          src={imageReference === 'table' ? NoCapTableImage : EmptyInfoImage}
          alt=""
          width="200"
          height="200"
        />
      </Box>
      <Button
        mode="forward"
        size="small"
        sx={{ my: 2 }}
        to={buttonNavigateTo}
        {...buttonProps}
      >
        {buttonLabel}
      </Button>
      {note && (
        <Typography
          color="secondary.dark"
          maxWidth={300}
          textAlign="center"
          variant="subtitle1"
        >
          {note}
        </Typography>
      )}
    </Container>
  )
}

export default EmptyStateWithButton

import { useTranslation } from 'react-i18next'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Button, { ButtonProps } from 'components/mui/Button'
import Paper from 'components/mui/Paper/Paper'

interface Props {
  label: string
  definition: string
  disabled?: boolean
  to: string
  buttonText: string
  buttonVariant: ButtonProps['variant']
}

function CreateOfferCard({
  label,
  definition,
  disabled = false,
  to,
  buttonText,
  buttonVariant,
}: Props) {
  const { t } = useTranslation()

  return (
    <Paper>
      <Grid container textAlign="center">
        <Grid item mb={4}>
          <Typography variant="h3" mb={2}>
            {label}
          </Typography>
          <Typography>{definition}</Typography>
        </Grid>
        <Grid item width={1} alignSelf="end">
          {disabled ? (
            <Button
              fullWidth
              startIcon={<AccessTimeIcon />}
              disabled={disabled}
              to={to}
            >
              {t('dashboard.offers.createOffer.buttonDisabled')}
            </Button>
          ) : (
            <Button
              variant={buttonVariant}
              fullWidth
              mode="forward"
              disabled={disabled}
              to={to}
            >
              {buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CreateOfferCard

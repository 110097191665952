import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  Company,
  CreateOfferMultiForms,
  OfferTypeConfig,
  CaptableShareholderGroupedTable,
  DerivedShareclassGroupedTable,
} from 'common/types'
import OfferInformation from 'components/offerings/OfferInformation'
import SelectShareTable from 'components/tables/SelectShareClassTable'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  company: Company
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  shareholderGroupedTable: CaptableShareholderGroupedTable
  offerTypeConfig: OfferTypeConfig
  values: CreateOfferMultiForms
}

function SelectShareClasses({
  company,
  derivedShareclassGroupedTable,
  shareholderGroupedTable,
  offerTypeConfig,
  values,
}: Props) {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item container spacing={2}>
        <Grid item container xs={12} lg={9} mb={4} alignContent="start">
          <Grid item mb={6}>
            <Typography> {t('offer.selectShareClass.heading')} </Typography>
          </Grid>
          <SelectShareTable
            company={company}
            shareholderGroupedTable={shareholderGroupedTable}
            derivedShareclassGroupedTable={derivedShareclassGroupedTable}
            values={values}
          />
        </Grid>

        {/* Sidebar information */}
        <OfferInformation
          company={company}
          derivedShareclassGroupedTable={derivedShareclassGroupedTable}
          offerTypeConfig={offerTypeConfig}
        />
      </Grid>

      <ResponsiveStepperButtons
        isForwardDisabled={values.shareclasses.length === 0}
      />
    </Grid>
  )
}

export default SelectShareClasses

import Box from '@mui/material/Box'
import MuiLinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'

type Props = LinearProgressProps & {
  progress: number
}

function LinearProgress({ progress, ...props }: Props) {
  return (
    <Box sx={{ width: 1 }}>
      <MuiLinearProgress
        variant="determinate"
        value={progress}
        sx={{
          borderRadius: 5,
          height: 8,
          '&.MuiLinearProgress-determinate': {
            backgroundColor: 'primary.lightest',
          },
        }}
        {...props}
      />
    </Box>
  )
}

export default LinearProgress

import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { LabelValuePair } from 'common/types'

interface Props {
  totalShares: number
  totalLiquidity: number
}

function Container({
  labelValuePair,
  isLongValue,
}: {
  labelValuePair: LabelValuePair
  isLongValue: boolean
}) {
  return (
    <Grid
      item
      container
      xs={12}
      sm={5}
      rowGap={1}
      flexDirection="column"
      justifyContent="space-between"
      textAlign="center"
    >
      <Typography color="white" variant="h3">
        {labelValuePair.label}
      </Typography>
      <Typography
        color="white"
        component="h1"
        variant={isLongValue ? 'h2' : 'h1'}
      >
        {labelValuePair.value}
      </Typography>
    </Grid>
  )
}

function TenderOfferAmount({ totalShares, totalLiquidity }: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const isLongValue = totalLiquidity.toString().length > 7

  useEffect(() => {
    setFieldValue('totalShares', totalShares)
  }, [setFieldValue, totalShares])

  return (
    <Grid
      item
      container
      gap={2}
      sx={{
        backgroundColor: 'primary.light',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
      }}
      justifyContent="center"
      px={5}
      py={3}
    >
      <Container
        labelValuePair={{
          label: t(
            'offer.calculateLiquidity.tenderOffer.loanAmount.maximumNumber'
          ),
          value: t('common.intlNumberFormat', {
            value: totalShares,
          }),
        }}
        isLongValue={isLongValue}
      />

      <Grid item container xs={1} justifyContent="center" alignContent="end">
        <Typography
          component="h1"
          variant={isLongValue ? 'h2' : 'h1'}
          color="white"
          alignSelf="center"
        >
          =
        </Typography>
      </Grid>

      <Container
        labelValuePair={{
          label: t(
            'offer.calculateLiquidity.tenderOffer.loanAmount.totalLiquidity'
          ),
          value: t('common.intlCurrencySimplifiedFormat', {
            value: totalLiquidity,
          }),
        }}
        isLongValue={isLongValue}
      />
    </Grid>
  )
}

export default TenderOfferAmount

import { Trans, useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { UPLOAD_CAPITALIZATION_TABLE } from 'lib/formConfig'
import Accordion from 'components/mui/Accordion'
import List from 'components/mui/List'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import UploadFile from 'components/files/UploadFile'

function UploadCapTable() {
  const { t } = useTranslation()

  const document = UPLOAD_CAPITALIZATION_TABLE

  const items: string[] = t(
    'dashboard.capTable.import.uploadCapTable.description.items',
    {
      returnObjects: true,
    }
  )

  const softwareList: { label: string; steps: string[]; note?: string }[] = t(
    'dashboard.capTable.import.uploadCapTable.exportCapTable.list',
    {
      returnObjects: true,
    }
  )

  return (
    <Grid container rowSpacing={4}>
      <Grid item>
        <Typography>
          <Trans i18nKey="dashboard.capTable.import.uploadCapTable.heading" />
        </Typography>
        <Typography>
          {t('dashboard.capTable.import.uploadCapTable.description.label')}
        </Typography>
        <List list={items} />
      </Grid>

      <Grid item container rowGap={2}>
        <Typography variant="h3">
          {t('dashboard.capTable.import.uploadCapTable.exportCapTable.title')}
        </Typography>
        <Typography>
          {t(
            'dashboard.capTable.import.uploadCapTable.exportCapTable.description'
          )}
        </Typography>
        <Box width={1} mt={1}>
          {softwareList.map((item) => (
            <Accordion key={item.label} {...item} />
          ))}
        </Box>
      </Grid>

      <Grid item container mb={4}>
        <Typography variant="h3" mb={2}>
          {t('dashboard.capTable.import.uploadCapTable.subHeading2')}
        </Typography>
        <Grid item xs={12} sm={9.5}>
          <UploadFile document={document} />
        </Grid>
      </Grid>

      <ResponsiveStepperButtons />
    </Grid>
  )
}

export default UploadCapTable

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Offerings from 'components/offerings/Offerings'
import { Company, Offer } from 'common/types'

const sortOffers = (offers: Offer[]) => {
  const sortedOffers: { [key in Offer['status']]: Offer[] } = {
    ACTIVE: [],
    SAVED: [],
    COMPLETED: [],
    CANCELLED: [],
    DELETED: [],
  }

  offers.forEach((offer) => {
    sortedOffers[offer.status].push(offer)
  })

  Object.values(sortedOffers).forEach((offer: Offer[]) =>
    offer.sort((a, b) =>
      a?.startDate === null || b?.startDate === null
        ? 0
        : (a.startDate ?? '').localeCompare(b.startDate ?? '')
    )
  )

  return [
    ...sortedOffers.ACTIVE,
    ...sortedOffers.SAVED,
    ...sortedOffers.COMPLETED,
    ...sortedOffers.CANCELLED,
  ]
}

function AdminCompanyOfferings({ company }: { company: Company }) {
  const { t } = useTranslation()

  const offers = company?.offers

  const hasOffers = offers?.length

  const sortedOffers = useMemo(() => sortOffers(offers), [offers])

  return (
    <Grid item xs={12}>
      {hasOffers ? (
        <Offerings offers={sortedOffers} />
      ) : (
        <>
          <Typography variant="h3" mb={2}>
            {t('openstockAdmin.company.offers.noOffers')}
          </Typography>
          <Typography>
            {t('openstockAdmin.company.offers.noOffersSubtitle')}
          </Typography>
        </>
      )}
    </Grid>
  )
}

export default AdminCompanyOfferings

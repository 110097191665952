import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import Typography from '@mui/material/Typography'

import Alert from 'components/mui/Alert'
import DownloadStaticFile from 'components/files/DownloadStaticFile'
import List from 'components/mui/List'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  handleBack: () => void
}

function DownloadShareholder({ handleBack }: Props) {
  const { t } = useTranslation()

  const shareholderInformationTemplate = {
    name: 'Openstock Shareholder Information Template.xlsx',
    path: '/templates/Openstock Shareholder Information Template.xlsx',
  }

  const steps: string[] = t(
    'dashboard.capTable.import.downloadShareholder.steps',
    {
      returnObjects: true,
    }
  )

  return (
    <Grid container rowSpacing={4}>
      <Typography
        dangerouslySetInnerHTML={{
          __html: t(
            'dashboard.capTable.import.downloadShareholder.description'
          ),
        }}
      />
      <Grid item>
        <Typography variant="h3">
          {t('dashboard.capTable.import.downloadShareholder.stepsLabel')}
        </Typography>
        <List list={steps} listStyleType="decimal" listItemStyles={{ ml: 1 }} />

        <Alert severity="info" variant="standard" icon={<LightbulbIcon />}>
          <Typography>
            {t('dashboard.capTable.import.downloadShareholder.tip')}
          </Typography>
        </Alert>
      </Grid>

      <Grid item container mb={4}>
        <Typography variant="h3" mb={3}>
          {t('dashboard.capTable.import.downloadShareholder.templateHeading')}
        </Typography>
        <DownloadStaticFile
          file={shareholderInformationTemplate}
          title={t('documents.shareholderInformationTemplate')}
        />
      </Grid>

      <ResponsiveStepperButtons handleBack={handleBack} />
    </Grid>
  )
}

export default DownloadShareholder

import { OfferTypeConfig } from 'common/types'
import i18n from 'locale/i18n'
import {
  DOC_SUPPORTED_FILE_TYPES,
  SPREADSHEET_SUPPORTED_FILE_TYPES,
} from 'lib/config'
import statesTaxTable from 'lib/taxTable.json'

//COMMON
export const yesNoOptions = [
  { label: i18n.t('common.yes'), value: true },
  { label: i18n.t('common.no'), value: false },
]

export const defaultCountry = 'us'
export const defaultWebsiteProtocol = 'https://'

// CREATE OFFER
export const offerTypes = [
  {
    label: i18n.t('dashboard.offers.createOffer.nonRecourseLoan.title'),
    definition: i18n.t(
      'dashboard.offers.createOffer.nonRecourseLoan.definition'
    ),
    pathname: 'non-recourse-loan',
    offerTypeTranslationKey: 'nonRecourseLoan',
    type: 'NON_RECOURSE_LOAN',
    showLoanInformation: true,
  },
  {
    label: i18n.t('dashboard.offers.createOffer.stockOptionFinancing.title'),
    definition: i18n.t(
      'dashboard.offers.createOffer.stockOptionFinancing.definition'
    ),
    pathname: 'stock-option',
    offerTypeTranslationKey: 'stockOption',
    type: 'STOCK_OPTION_FINANCING_LOAN',
    disabled: true,
  },
  {
    label: i18n.t('dashboard.offers.createOffer.tenderOffer.title'),
    definition: i18n.t('dashboard.offers.createOffer.tenderOffer.definition'),
    pathname: 'tender-offer',
    offerTypeTranslationKey: 'tenderOffer',
    type: 'TENDER_OFFER',
  },
]

export const getOfferTypeConfig = (field: string): OfferTypeConfig => {
  const configObject = offerTypes.find((type) =>
    Object.values(type).includes(field)
  )

  if (!configObject) {
    throw new Error('Configuration for offer type not found')
  } else {
    return configObject as OfferTypeConfig
  }
}

export const uploadDocumentsFields = [
  'capitalizationTable',
  'companyOverviewFile',
  'financialStatementsFile',
  'historyOfValuationsFile',
  'listOfPrimaryInvestorsFile',
  'employeeOutstandingFile',
  'recent409AValuationFile',
]

export const UPLOAD_DOCUMENTS_LIST = [
  {
    name: 'financialStatementsFile',
    title: i18n.t('documents.financialStatementsFile.title'),
    description: i18n.t('documents.financialStatementsFile.description'),
    required: true,
  },
  {
    name: 'recent409AValuationFile',
    title: i18n.t('documents.recent409AValuationFile.title'),
    required: true,
  },
  {
    name: 'companyOverviewFile',
    title: i18n.t('documents.companyOverviewFile.title'),
    description: i18n.t('documents.companyOverviewFile.description'),
    required: false,
  },
  {
    name: 'historyOfValuationsFile',
    title: i18n.t('documents.historyOfValuationsFile.title'),
    required: false,
  },
  {
    name: 'listOfPrimaryInvestorsFile',
    title: i18n.t('documents.listOfPrimaryInvestorsFile.title'),
    required: false,
  },
  {
    name: 'employeeOutstandingFile',
    title: i18n.t('documents.employeeOutstandingFile.title'),
    fileTypes: {
      ...SPREADSHEET_SUPPORTED_FILE_TYPES,
      ...DOC_SUPPORTED_FILE_TYPES,
    },
    required: false,
  },
]

export const UPLOAD_DOCUMENTS_LIST_BY_REQUIRED = UPLOAD_DOCUMENTS_LIST.reduce(
  (acc: any, file: any) => {
    if (file.required) {
      acc.required.push(file)
    } else {
      acc.optional.push(file)
    }

    return acc
  },
  {
    required: [],
    optional: [],
  }
)

export const OFFER_UPLOAD_DOCUMENTS_LIST = UPLOAD_DOCUMENTS_LIST.map(
  (document) => ({ ...document, required: false })
)

export const UPLOAD_CAPITALIZATION_TABLE = {
  name: 'capitalizationTable',
  title: i18n.t('documents.capitalizationTable.title'),
  required: true,
}

export const UPLOAD_SHAREHOLDER_INFORMATION = {
  name: 'shareholderInformation',
  title: i18n.t('documents.shareholderInformation.title'),
  required: true,
}

export const UPLOAD_INGESTION_SPREADSHEET = {
  name: 'ingestionSpreadsheet',
  title: i18n.t('documents.ingestionSpreadsheet.title'),
  required: true,
}

// OFFER
export const offerFixedTimelineDays = 60
export const minimumLoanAmount = 25000
export const maximumLoanAmount = 1000000

// OPENSTOCK ADMIN - LOAN INFO
export const lenderName = 'Citibank'

export const loanTypes = [
  {
    value: 'VARIABLE_RATE_LOAN',
    label: i18n.t(
      'openstockAdmin.company.loanInfo.inputs.loanType.options.variable'
    ),
  },
]

export const loanStructures = [
  {
    value: 'INTEREST_ONLY_PAYMENTS',
    label: i18n.t(
      'openstockAdmin.company.loanInfo.inputs.loanStructure.options.interest'
    ),
  },
]

export const loanRepaymentSchedules = [
  {
    value: 'QUARTERLY',
    label: i18n.t(
      'openstockAdmin.company.loanInfo.inputs.loanRepaymentSchedule.options.quarterly'
    ),
  },
]

export const defaultInterestRate = 8
export const defaultLoanSettings = {
  loanType: 'VARIABLE_RATE_LOAN',
  loanStructure: 'INTEREST_ONLY_PAYMENTS',
  loanRepaymentSchedule: 'QUARTERLY',
}

export const loanApprovalOptions = [
  {
    label: i18n.t(
      'openstockAdmin.company.loanInfo.inputs.approval.options.yes'
    ),
    value: 'true',
  },
  {
    label: i18n.t('openstockAdmin.company.loanInfo.inputs.approval.options.no'),
    value: 'false',
  },
]

// COMPANY REGISTRATION PRODUCT OFFERINGS
export const productOfferings = [
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.nonRecourseLoan.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.nonRecourseLoan.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.stockOptionFinancing.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.stockOptionFinancing.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.tenderOffer.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.tenderOffer.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.shareBuyback.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.shareBuyback.description'
    ),
  },
]

// Liquidity Calculator
export const financialProductsList: string[] = [
  i18n.t('calculator.details.form.nonRecourseLoan'),
  i18n.t('calculator.details.form.tenderOffer'),
  i18n.t('calculator.details.form.options'),
  i18n.t('calculator.details.form.data'),
  i18n.t('calculator.details.form.education'),
  i18n.t('calculator.details.form.haventDecided'),
]

export const calcChartLoopIteration = 7
export const unitedStatesRegions = Object.keys(statesTaxTable.data)

export const sharesAssumedValues = {
  termInYears: 3,
  annualInterestRate: 15,
  originationFee: 5,
  maxPercentageOfShares: 25,
  maxPercentageValueOfShares: 25,
  commission: 5,
  federalLtCapitalGains: 20,
}

export const optionsAssumedValues = {
  termInYears: 3,
  grossAnnualIncome: 150000,
  annualInterestRate: 15,
  originationFee: 5,
  federalTax: 37,
  federalLtCapitalGains: 20,
  alternativeMinTax: 35, //Alternative Minimum Tax (Fed & State) %
}

export const optionsCalculatorTypeOptions = [
  {
    label: i18n.t('calculator.tab.options.form.optionsTypeOptions.iso'),
    value: 'ISO',
  },
  {
    label: i18n.t('calculator.tab.options.form.optionsTypeOptions.nso'),
    value: 'NSO',
  },
]

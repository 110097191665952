import { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography/Typography'

import Button from './Button'
import i18n from 'locale/i18n'

const config = {
  cancel: {
    color: 'error',
    label: i18n.t('common.cancel'),
    icon: <CloseIcon color="error" />,
  },
  delete: {
    color: 'error',
    label: i18n.t('common.delete'),
    icon: <DeleteOutlineIcon color="error" />,
  },
  edit: {
    color: 'inherit',
    label: i18n.t('common.edit'),
    icon: <EditIcon />,
  },
  extend: {
    color: 'inherit',
    label: i18n.t('common.extend'),
    icon: <MoreTimeIcon />,
  },
}

export interface MenuItem {
  mode: keyof typeof config
  label?: string
  action: () => void
}

interface Props {
  buttonLabel: string
  menuItems: MenuItem[]
}

export default function ButtonMenu({ buttonLabel, menuItems }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        endIcon={<ExpandMoreRoundedIcon />}
      >
        {buttonLabel}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 180,
            mt: 1,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
          },
          '& .MuiMenu-list': {
            padding: '8px 0',
          },
        }}
      >
        {menuItems.map((menuItem: MenuItem) => (
          <MuiMenuItem
            key={menuItem.mode}
            onClick={() => {
              handleClose()
              menuItem.action()
            }}
          >
            {config[menuItem.mode].icon}
            <Typography
              variant="body2"
              sx={{ ml: 1.5 }}
              color={config[menuItem.mode].color}
            >
              {menuItem.label ?? config[menuItem.mode].label}
            </Typography>
          </MuiMenuItem>
        ))}
      </Menu>
    </>
  )
}

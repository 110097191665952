import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Paper from 'components/mui/Paper/Paper'

export type LoanDetails = {
  heading: string
  isLargeHeading?: boolean
  data: any
  notes?: string
  notesElement?: React.ReactNode
}[]

function LoanOfferDetailsCard({ loanDetails }: { loanDetails: LoanDetails }) {
  return (
    <Paper>
      <Grid container>
        {loanDetails.map((detail, index) => (
          <Grid item container xs={12} rowGap={0.5} key={detail.heading}>
            <Grid item xs={12}>
              <Typography variant="h4">{detail.heading}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant={detail.isLargeHeading ? 'h1' : 'h3'}
                mb={detail.isLargeHeading && detail.notesElement ? 2 : 0}
              >
                {detail.data}
              </Typography>
            </Grid>
            {detail.notesElement && (
              <Grid item xs={12}>
                {detail.notesElement}
              </Grid>
            )}
            {detail.notes && (
              <Grid item xs={12}>
                <Typography>{detail.notes}</Typography>
              </Grid>
            )}
            {index !== loanDetails.length - 1 && (
              <Divider
                variant="middle"
                sx={{ width: 1, mx: 'auto', my: 2.5 }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default LoanOfferDetailsCard

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
// TODO: Issue with current @aws-amplify/auth types, CognitoUser does not expose attributes properly
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Auth, Hub } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router-dom'
import { SignUpParams } from '@aws-amplify/auth'
import { usePrevious } from 'react-use'

import { createUserAirtableRecord, AirtableUser } from 'lib/airtable'
import { LoginDetails, GenericObject } from 'common/types'
import LoadingIndicator from 'components/LoadingIndicator'
import { CREATE_COMPANY_ROOT_ROUTE } from 'views/companyAdmin/createCompanyAccount/CreateCompanyAccount'
import { setSentryUser } from 'lib/monitoring'

type AuthProviderProps = { children: React.ReactNode }

interface AuthStateReducer {
  user: CognitoUserInterface | null | undefined
  redirectPathOnAuth: string | null
  sessionStatus: 'expired' | null
}

type Actions =
  | { type: 'set_user'; payload: AuthStateReducer['user'] }
  | { type: 'set_redirect'; payload: AuthStateReducer['redirectPathOnAuth'] }
  | { type: 'set_session_status'; payload: AuthStateReducer['sessionStatus'] }

interface AuthState extends AuthStateReducer {
  isLoggedIn: boolean
  isAuthenticated: boolean
  isInitialized: boolean
  dispatch: (action: Actions) => void
  sendCodeMutation: any // TODO: Fix types
  loginMutation: any
  logoutMutation: any
  createProfileMutation: any
  forgotPasswordMutation: any
  forgotPasswordSubmitMutation: any
  changePasswordMutation: any
  getCurrentUserQuery: any
}

const AuthStateContext = createContext(({} as AuthState) || undefined)

function authReducer(state: AuthStateReducer, action: Actions) {
  switch (action.type) {
    case 'set_user':
      return { ...state, user: action.payload }

    case 'set_redirect':
      return { ...state, redirectPathOnAuth: action.payload }

    case 'set_session_status':
      return { ...state, sessionStatus: action.payload }

    default: {
      throw new Error('Unhandled action type in authReducer')
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, {
    user: undefined,
    redirectPathOnAuth: null,
    sessionStatus: null,
  })

  const location = useLocation()
  const navigate = useNavigate()
  const previousPathname = usePrevious(location.pathname)

  // User is authenticated with Cognito, but may be be logged into the app depending on business logic
  const isAuthenticated = typeof state.user === 'object' && state.user !== null
  const isInitialized = state.user !== undefined

  // User meets all requirements to be considered logged in
  const isLoggedIn = isAuthenticated && isInitialized

  const handleSessionExpired = () => {
    // Save previous pathname for when they log back in
    dispatch({ type: 'set_redirect', payload: location.pathname })

    // Set status for login to display message
    dispatch({ type: 'set_session_status', payload: 'expired' })

    // Logout user
    dispatch({ type: 'set_user', payload: null })
    navigate('/login')
  }

  // Set user in sentry
  useEffect(() => {
    const email = state.user?.attributes?.email
    if (email) {
      setSentryUser({ email })
    } else {
      setSentryUser(null)
    }
  }, [state.user])

  // Event listener for auth, not needed for most events as we handle them directly
  useEffect(() => {
    // TODO: Fix type
    const handleAuthEvent = ({ payload }: GenericObject) => {
      const { event } = payload

      if (event === 'autoSignIn') {
        // Auto set user if auto sign in success (this event happens after verification is sent)
        const user = payload.data
        dispatch({ type: 'set_user', payload: user })
      } else if (event === 'autoSignIn_failure') {
        // Redirect to login if auto sign in fail
        navigate('/login', {
          replace: true,
        })
      } else if (event === 'tokenRefresh_failure') {
        // Session expired
        handleSessionExpired()
      }
    }

    Hub.listen('auth', handleAuthEvent)

    return () => {
      Hub.remove('auth', handleAuthEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const sendCodeMutation = useMutation(
    ({ username, code }: { username: string; code: string }) =>
      Auth.confirmSignUp(username, code)
  )

  const forgotPasswordMutation = useMutation(
    (username: string) => Auth.forgotPassword(username),
    {
      onSuccess: (_data, variables) => {
        navigate(`/login/reset?username=${encodeURIComponent(variables)}`)
      },
    }
  )

  const forgotPasswordSubmitMutation = useMutation(
    ({
      username,
      code,
      password,
    }: {
      username: string
      code: string
      password: string
    }) => Auth.forgotPasswordSubmit(username, code, password),
    {
      onSuccess: (_data, variables) => {
        loginMutation.mutate({
          email: variables.username,
          password: variables.password,
        })
      },
    }
  )

  const changePasswordMutation = useMutation(
    ({
      currentPassword,
      password,
    }: {
      currentPassword: string
      password: string
    }) =>
      Auth.currentAuthenticatedUser().then((user) =>
        Auth.changePassword(user, currentPassword, password)
      )
  )

  const loginMutation = useMutation(
    (credentials: LoginDetails) =>
      Auth.signIn(credentials.email, credentials.password),
    {
      onSuccess: (data, _variables) => {
        dispatch({ type: 'set_user', payload: data })
        loginMutation.reset()
      },
      onError: (error: Error, variables) => {
        const verificationPath = '/login/verification'

        // If already on verification page, send to login
        if (location.pathname.includes(verificationPath)) {
          navigate('/login', {
            replace: true,
          })
        } else if (error.name === 'UserNotConfirmedException') {
          // Bring to verification page if unverified
          navigate(
            `/login/verification?username=${encodeURIComponent(
              variables.email
            )}`
          )
        } else if (error.name === 'PasswordResetRequiredException') {
          // If user has password reset by admin, they are forced to reset through this flow
          navigate(
            `/login/reset?username=${encodeURIComponent(
              variables.email
            )}&status=forced`
          )
        }
      },
    }
  )

  const createProfileMutation = useMutation(
    (newUser: SignUpParams) => Auth.signUp(newUser),
    {
      onSuccess: (data, variables) => {
        navigate(
          `/login/verification?username=${encodeURIComponent(
            variables.username
          )}`,
          {
            replace: true,
          }
        )

        variables.attributes &&
          createUserAirtableRecord(variables.attributes as AirtableUser)

        if (!state.redirectPathOnAuth?.includes(CREATE_COMPANY_ROOT_ROUTE)) {
          return dispatch({
            type: 'set_redirect',
            payload: '/account-confirmation',
          })
        }
      },
    }
  )

  const logoutMutation = useMutation(() => Auth.signOut(), {
    onSettled: () => {
      dispatch({ type: 'set_user', payload: null })
      navigate('/login')
    },
  })

  // This query is exposed for refetching, etc
  // Get this data from state.user, not from this query
  const getCurrentUserQuery = useQuery(
    ['cognitoUser'],
    () => Auth.currentAuthenticatedUser(),
    {
      retry: false,
      onSuccess: (data) => dispatch({ type: 'set_user', payload: data }),
      onError: () => {
        if (state.user) {
          return handleSessionExpired()
        }

        dispatch({ type: 'set_user', payload: null })
      },
    }
  )

  // Reset mutations when pathname changes
  useEffect(() => {
    if (previousPathname === location.pathname) return

    const defaultState = 'idle'

    sendCodeMutation.status !== defaultState && sendCodeMutation.reset()
    loginMutation.status !== defaultState && loginMutation.reset()
    createProfileMutation.status !== defaultState &&
      createProfileMutation.reset()
    logoutMutation.status !== defaultState && logoutMutation.reset()
    forgotPasswordMutation.status !== defaultState &&
      forgotPasswordMutation.reset()
    forgotPasswordSubmitMutation.status !== defaultState &&
      forgotPasswordSubmitMutation.reset()
    changePasswordMutation.status !== defaultState &&
      changePasswordMutation.reset()
  }, [
    createProfileMutation,
    forgotPasswordMutation,
    forgotPasswordSubmitMutation,
    changePasswordMutation,
    location.pathname,
    loginMutation,
    logoutMutation,
    previousPathname,
    sendCodeMutation,
  ])

  const contextValue = useMemo(
    () => ({
      ...state,
      dispatch,
      isLoggedIn,
      isAuthenticated,
      isInitialized,
      sendCodeMutation,
      loginMutation,
      createProfileMutation,
      logoutMutation,
      forgotPasswordMutation,
      forgotPasswordSubmitMutation,
      changePasswordMutation,
      getCurrentUserQuery,
    }),
    [
      getCurrentUserQuery,
      createProfileMutation,
      forgotPasswordMutation,
      forgotPasswordSubmitMutation,
      changePasswordMutation,
      isAuthenticated,
      isInitialized,
      isLoggedIn,
      loginMutation,
      logoutMutation,
      sendCodeMutation,
      state,
    ]
  )

  return (
    <AuthStateContext.Provider value={contextValue}>
      {/* Initializing first to prevent incorrect redirects */}
      {!isInitialized ? <LoadingIndicator /> : children}

      {/* This loader can be used for async auth tasks where buttons do not have an obvious loader builtin, such as logging out */}
      {logoutMutation.isLoading && (
        <LoadingIndicator
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: (theme: { palette: { primary: { light: any } } }) =>
              theme.palette.primary.light,
            zIndex: (theme: { zIndex: { drawer: number } }) =>
              theme.zIndex.drawer + 1,
          }}
        />
      )}
    </AuthStateContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthStateContext)

  if (context === undefined)
    throw new Error('useAuth must be used with AuthProvider')

  return context
}

export { AuthProvider, useAuth }

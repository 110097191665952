import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { LabelValuePair } from 'common/types'

interface Props {
  totalShares: number
  totalLiquidity: number
}

function Container({
  labelValuePair,
  isLongValue,
}: {
  labelValuePair: LabelValuePair
  isLongValue: boolean
}) {
  return (
    <Grid
      item
      container
      xs={12}
      sm={5.8}
      sx={{
        backgroundColor: 'primary.light',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
      }}
      flexDirection="column"
      justifyContent="space-between"
      textAlign="center"
      rowGap={1}
      px={1}
      py={3}
    >
      <Typography color="white" variant="h3" my="auto">
        {labelValuePair.label}
      </Typography>
      <Typography
        color="white"
        my="auto"
        component="h1"
        variant={isLongValue ? 'h2' : 'h1'}
      >
        {labelValuePair.value}
      </Typography>
    </Grid>
  )
}

function LoanOfferAmount({ totalShares, totalLiquidity }: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const isLongValue = totalLiquidity.toString().length > 7

  useEffect(() => {
    setFieldValue('totalShares', totalShares)
  }, [setFieldValue, totalShares])

  return (
    <Grid
      container
      gap={2}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
    >
      <Container
        labelValuePair={{
          label: t(
            'offer.calculateLiquidity.nonRecourseLoan.loanAmount.maximumNumber'
          ),
          value: t('common.intlNumberFormat', {
            value: totalShares,
          }),
        }}
        isLongValue={isLongValue}
      />
      <Container
        labelValuePair={{
          label: t(
            'offer.calculateLiquidity.nonRecourseLoan.loanAmount.totalLiquidity'
          ),
          value: t('common.intlCurrencySimplifiedFormat', {
            value: totalLiquidity,
          }),
        }}
        isLongValue={isLongValue}
      />
    </Grid>
  )
}

export default LoanOfferAmount

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import SendIcon from '@mui/icons-material/Send'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import { createPublishedOfferAirtableRecord } from 'lib/airtable'
import { useSetOfferDateRange, useSetOfferStatus } from 'lib/apollo/hooks'
import MutationModal from 'components/MutationModal'
import dayjs, { monthDayYearTimeTimezoneFormat } from 'lib/dayjs'
import { getOfferExpiryDate } from 'lib/data'

interface Props {
  isOpen: boolean
  closeModal: () => void
  offer: Offer
}

function PublishOfferModal({ isOpen, closeModal, offer }: Props) {
  const { t } = useTranslation()

  const [setOfferStatus, setOfferStatusMutation] = useSetOfferStatus(offer?._id)
  const [setOfferDateRange, setOfferDateRangeMutation] = useSetOfferDateRange(
    offer?._id
  )

  const expiryDate = useMemo(
    () =>
      getOfferExpiryDate({
        expiryDate: offer?.financialTerms.expiryDate,
      }),
    [offer?.financialTerms.expiryDate]
  )

  const formattedExpiryDate = dayjs(expiryDate).format(
    monthDayYearTimeTimezoneFormat
  )

  const handlePublish = async () => {
    if (!setOfferStatusMutation.data) {
      const { errors } = await setOfferStatus({
        variables: {
          offerId: offer?._id,
          status: 'ACTIVE',
        },
      })

      if (errors) return

      createPublishedOfferAirtableRecord({
        companyName: offer?.company?.name,
        kind: offer?.kind,
        status: 'ACTIVE',
        companyId: offer?.company?._id,
        offerId: offer?._id,
      })
    }

    if (!setOfferDateRangeMutation.data) {
      const { errors } = await setOfferDateRange({
        variables: {
          offerId: offer?._id,
          startDate: dayjs(),
          expiryDate,
        },
      })

      if (errors) return
    }
    return closeModal()
  }

  const handleClose = () => {
    setOfferStatusMutation.reset()
    setOfferDateRangeMutation.reset()
    closeModal()
  }

  return (
    <MutationModal
      icon={SendIcon}
      color={'primary'}
      title={t('offer.manage.publish.publishOffer')}
      primaryButtonAction={handlePublish}
      primaryButtonText={t('offer.manage.publish.publishOffer')}
      secondaryButtonAction={handleClose}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={
        setOfferStatusMutation.loading || setOfferDateRangeMutation.loading
      }
      isError={
        setOfferStatusMutation.error !== undefined ||
        setOfferDateRangeMutation.error !== undefined
      }
    >
      <Typography>{t('offer.manage.publish.description')}</Typography>
      <Typography variant="h6" width={1} mt={2}>
        {t('offer.manage.publish.offerEndDate')}
      </Typography>
      <Typography>{formattedExpiryDate}</Typography>
    </MutationModal>
  )
}

export default PublishOfferModal

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { useUser } from 'app/User'
import { Captable, Company, Offer } from 'common/types'
import { captureWarning } from 'lib/monitoring'
import {
  CREATE_OFFER_MUTATION,
  GET_COMPANIES_QUERY,
  GET_COMPANY_QUERY,
  GET_OFFER_QUERY,
  GET_USER_BY_USER_POOL_SUB_QUERY,
  SUBMIT_COMPANY_SIGNUP_DETAILS_MUTATION,
  SIGN_COMPANY_AGREEMENT_MUTATION,
  CREATE_COMPANY_AGREEMENT_DOWNLOAD_LINK,
  GET_CAPTABLE_QUERY,
  GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_QUERY,
  GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_DATA_QUERY,
  CREATE_COMPANY_FILE_DOWNLOAD_LINK,
  SET_COMPANY_FINANCIAL_TERMS_MUTATION,
  COMPANY_SET_IS_APPROVED_MUTATION,
  SET_CAPTABLE_IS_APPROVED_BY_MUTATION,
  SET_OFFER_STATUS_MUTATION,
  SET_OFFER_DATE_RANGE_MUTATION,
  CREATE_LOAN_MUTATION,
  GET_USER_QUERY,
  RESET_COMPANY_OFFERS_MUTATION,
  RESET_COMPANY_INCORPORATION_DETAILS_MUTATION,
} from 'lib/apollo/schema'

const useGetUserByUserPoolSubQuery = (sub: string) =>
  useQuery(GET_USER_BY_USER_POOL_SUB_QUERY, {
    variables: { sub },
    notifyOnNetworkStatusChange: true,
    skip: !sub,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetUserQuery = (id: string) =>
  useQuery(GET_USER_QUERY, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetOfferQuery = (id?: string) =>
  useQuery(GET_OFFER_QUERY, {
    variables: { id },
    skip: !id,
    onError: (e: Error) => captureWarning(e),
  })

const useGetCompanyQuery = (companyId?: string) => {
  const {
    data: { company },
  } = useUser()

  const id = companyId ?? company?._id

  return useQuery(GET_COMPANY_QUERY, {
    variables: { id },
    skip: !id,
    onError: (e: Error) => captureWarning(e),
  })
}

const useGetCompaniesQuery = () =>
  useQuery(GET_COMPANIES_QUERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSubmitCompanySignupDetails = () => {
  const { subId } = useUser()

  return useMutation(SUBMIT_COMPANY_SIGNUP_DETAILS_MUTATION, {
    refetchQueries: [
      { query: GET_USER_BY_USER_POOL_SUB_QUERY, variables: { sub: subId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useSignCompanyAgreement = () => {
  const { subId } = useUser()

  return useMutation(SIGN_COMPANY_AGREEMENT_MUTATION, {
    refetchQueries: [
      { query: GET_USER_BY_USER_POOL_SUB_QUERY, variables: { sub: subId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useResetCompanyIncorporationDetails = () => {
  const {
    data: { company },
  } = useUser()

  return useMutation(RESET_COMPANY_INCORPORATION_DETAILS_MUTATION, {
    refetchQueries: [
      { query: GET_COMPANY_QUERY, variables: { id: company._id } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useCreateCompanyAgreementDownloadLinkQuery = () =>
  useMutation(CREATE_COMPANY_AGREEMENT_DOWNLOAD_LINK, {
    onError: (e: Error) => captureWarning(e),
  })

// If passing captableId you must fallback to false or it will fallback to the company automatically which is likely incorrect if the ID is being passed
const useGetCaptableQuery = (captableId?: Captable['_id'] | false) => {
  const { data } = useGetCompanyQuery()

  let id = data?.getCompany?.captableCurrent?._id

  if (captableId || captableId === false) {
    id = captableId
  }

  return useQuery(GET_CAPTABLE_QUERY, {
    variables: { id },
    skip: !id,
    onError: (e: Error) => captureWarning(e),
  })
}

const useGetOfferCaptableShareholders = (offerId?: Offer['_id']) =>
  useQuery(GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_QUERY, {
    variables: { offerId },
    skip: !offerId,
    onError: (e: Error) => captureWarning(e),
  })

const useLazyGetOfferCaptableShareholdersData = (offerId?: Offer['_id']) =>
  useLazyQuery(GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_DATA_QUERY, {
    variables: { offerId },
    onError: (e: Error) => captureWarning(e),
  })

const useCompanySetIsApproved = (companyId: Company['_id']) =>
  useMutation(COMPANY_SET_IS_APPROVED_MUTATION, {
    refetchQueries: [
      { query: GET_COMPANY_QUERY, variables: { id: companyId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => captureWarning(e),
  })

const useSetCaptableIsApprovedBy = () => {
  const { data } = useGetCompanyQuery()

  const captableId = data?.getCompany?.captableCurrent?._id

  return useMutation(SET_CAPTABLE_IS_APPROVED_BY_MUTATION, {
    refetchQueries: [
      { query: GET_CAPTABLE_QUERY, variables: { id: captableId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => captureWarning(e),
  })
}

const useSetCompanyFinancialTermsMutation = (companyId: Company['_id']) =>
  useMutation(SET_COMPANY_FINANCIAL_TERMS_MUTATION, {
    refetchQueries: [
      { query: GET_COMPANY_QUERY, variables: { id: companyId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useCreateOfferMutation = () => {
  const {
    data: { company },
  } = useUser()

  return useMutation(CREATE_OFFER_MUTATION, {
    refetchQueries: [
      { query: GET_COMPANY_QUERY, variables: { id: company._id } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useCreateLoan = (offerId?: Offer['_id']) => {
  const {
    data: { _id },
  } = useUser()

  if (!offerId) throw Error('Offer ID not defined')

  return useMutation(CREATE_LOAN_MUTATION, {
    refetchQueries: [
      { query: GET_USER_QUERY, variables: { id: _id } },
      {
        query: GET_OFFER_ELIGIBLE_CAPTABLE_SHAREHOLDERS_QUERY,
        variables: { offerId },
      },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useSetOfferStatus = (offerId?: Offer['_id']) => {
  const {
    data: { _id: userId },
  } = useUser()

  return useMutation(SET_OFFER_STATUS_MUTATION, {
    refetchQueries: [
      { query: GET_OFFER_QUERY, variables: { id: offerId } },
      { query: GET_USER_QUERY, variables: { id: userId } },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => captureWarning(e),
  })
}

const useResetCompanyOffers = () => useMutation(RESET_COMPANY_OFFERS_MUTATION)

const useSetOfferDateRange = (offerId?: Offer['_id']) =>
  useMutation(SET_OFFER_DATE_RANGE_MUTATION, {
    refetchQueries: [{ query: GET_OFFER_QUERY, variables: { id: offerId } }],
    awaitRefetchQueries: true,
    onError: (e: Error) => captureWarning(e),
  })

const useCreateDownloadLinkQuery = () =>
  useMutation(CREATE_COMPANY_FILE_DOWNLOAD_LINK, {
    onError: (e: Error) => captureWarning(e),
  })

export {
  useGetUserByUserPoolSubQuery,
  useGetUserQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetOfferQuery,
  useSubmitCompanySignupDetails,
  useSignCompanyAgreement,
  useResetCompanyIncorporationDetails,
  useCreateCompanyAgreementDownloadLinkQuery,
  useGetCaptableQuery,
  useGetOfferCaptableShareholders,
  useLazyGetOfferCaptableShareholdersData,
  useCompanySetIsApproved,
  useSetCaptableIsApprovedBy,
  useSetCompanyFinancialTermsMutation,
  useCreateOfferMutation,
  useCreateLoan,
  useSetOfferStatus,
  useSetOfferDateRange,
  useCreateDownloadLinkQuery,
  useResetCompanyOffers,
}

import { Trans, useTranslation } from 'react-i18next'

import MoreTimeIcon from '@mui/icons-material/MoreTime'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import Link from 'components/mui/Link'
import AlertDialog from 'components/AlertDialog'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'

interface Props {
  isOpen: boolean
  closeModal: () => void
  offerId: Offer['_id']
}

function ExtendOfferModal({ isOpen, closeModal, offerId }: Props) {
  const { t } = useTranslation()

  return (
    <AlertDialog
      icon={MoreTimeIcon}
      color="primary"
      iconColor="secondary.dark"
      title={t('offer.manage.extend.title')}
      primaryButtonAction={closeModal}
      primaryButtonText={t('common.close')}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Typography>
        <Trans i18nKey="offer.manage.extend.description">
          {
            'If you wish to extend this offer, please reach out to an Openstock admin at '
          }
          <Link
            href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
              'offer.manage.extend.emailSubject',
              { offerId: offerId }
            )}`}
          >
            partners@getopenstock.com
          </Link>
          {' and they will be able to assist you in setting a new end date.'}
        </Trans>
      </Typography>
    </AlertDialog>
  )
}

export default ExtendOfferModal

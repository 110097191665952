import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Offer, OfferCaptableShareholder } from 'common/types'
import { getPercentageOfValueByTotal } from 'lib/helpers'
import LinearProgress from 'components/mui/LinearProgress'
import Paper from 'components/mui/Paper/Paper'

function OfferProgress({
  offer,
  eligibleShareholders,
  totalNumberOfStakedShares,
  totalLiquidityRequested,
}: {
  offer: Offer
  eligibleShareholders: OfferCaptableShareholder[]
  totalNumberOfStakedShares: number
  totalLiquidityRequested: number
}) {
  const { t } = useTranslation()

  const numOfShareholdersSubscribed = useMemo(
    () =>
      eligibleShareholders.filter((shareholder) =>
        shareholder.subscriptionStatuses.includes('SUBSCRIBED' as any)
      ).length,
    [eligibleShareholders]
  )

  const percentageOfShareholdersSubscribed = useMemo(
    () =>
      getPercentageOfValueByTotal({
        value: numOfShareholdersSubscribed,
        total: eligibleShareholders.length,
      }),
    [eligibleShareholders.length, numOfShareholdersSubscribed]
  )

  const percentageOfLiquidityRequested = useMemo(
    () =>
      getPercentageOfValueByTotal({
        value: totalLiquidityRequested,
        total: offer.liquidityAvailable,
      }),
    [offer.liquidityAvailable, totalLiquidityRequested]
  )

  const detailsWithLinearProgress = [
    {
      title: t(
        'dashboard.offers.manageOffers.offerProgress.shareholdersSubscribed'
      ),
      subtitle: t(
        'dashboard.offers.manageOffers.offerProgress.percentProgress',
        {
          value: numOfShareholdersSubscribed,
          total: eligibleShareholders.length,
          progress: percentageOfShareholdersSubscribed,
        }
      ),
      progress: percentageOfShareholdersSubscribed,
    },
    {
      title: t(
        'dashboard.offers.manageOffers.offerProgress.liquidityRequested'
      ),
      subtitle: t(
        'dashboard.offers.manageOffers.offerProgress.percentProgress',
        {
          value: t('common.intlCurrencySimplifiedFormat', {
            value: totalLiquidityRequested,
          }),
          total: t('common.intlCurrencySimplifiedFormat', {
            value: offer.liquidityAvailable,
          }),
          progress: percentageOfLiquidityRequested,
        }
      ),
      progress: percentageOfLiquidityRequested,
    },
  ]

  return (
    <Grid container rowGap={2}>
      <Typography variant="h3" mb={1}>
        {t('dashboard.offers.manageOffers.offerProgress.title')}
      </Typography>

      <Grid container spacing={2}>
        {detailsWithLinearProgress.map(({ title, subtitle, progress }) => (
          <Grid key={title} item container xs={12} smd={4}>
            <Paper sx={{ width: 1 }}>
              <Grid container gap={1}>
                <Typography variant="h4" color="secondary.darkest" mb={1.5}>
                  {title}
                </Typography>
                <LinearProgress progress={progress} />
                <Typography color="secondary.darkest">{subtitle}</Typography>
              </Grid>
            </Paper>
          </Grid>
        ))}

        {/* Number of staked shares */}
        <Grid item container xs={12} smd={4}>
          <Paper sx={{ width: 1 }}>
            <Grid container gap={1} flexDirection="column">
              <Typography variant="h4" color="secondary.darkest" mb={1.5}>
                {t(
                  'dashboard.offers.manageOffers.offerProgress.numOfStakedShares'
                )}
              </Typography>

              <Typography
                sx={{ fontSize: '2.5rem', fontWeight: 600, lineHeight: '3rem' }}
                color="secondary.darkest"
              >
                {t('common.intlNumberFormat', {
                  value: totalNumberOfStakedShares,
                })}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OfferProgress

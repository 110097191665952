import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Link from '@mui/material/Link/Link'
import Typography from '@mui/material/Typography/Typography'

import { FEATURE_FLAGS, OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import { offerTypes } from 'lib/formConfig'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import { GenericObject } from 'common/types'
import CreateOfferCard from 'components/offerings/CreateOfferCard'
import Layout from 'components/Layout/Layout'

function CreateOfferPage() {
  const { t } = useTranslation()

  const buttonConfig: GenericObject = {
    nonRecourseLoan: {
      buttonVariant: 'contained',
      buttonText: t('dashboard.offers.createOffer.createOfferButton'),
      to: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/create-offer/preview/non-recourse-loan`,
    },
    tenderOffer: FEATURE_FLAGS.DEMO_TENDER_OFFER
      ? {
          buttonVariant: 'contained',
          buttonText: t('dashboard.offers.createOffer.learnMoreButton'),
          to: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/create-offer/preview/tender-offer`,
        }
      : {
          buttonVariant: 'outlined',
          buttonText: t('dashboard.offers.createOffer.learnMoreButton'),
          to: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/create-offer/learn-more/tender-offer`,
        },
  }

  return (
    <Layout maxWidth="lg">
      <Typography variant="h1" mb={4}>
        {t('dashboard.offers.createOffer.title')}
      </Typography>
      <Typography mb={5}>
        {t('dashboard.offers.createOffer.subHeading')}
      </Typography>

      <Grid container columnSpacing={2} rowGap={2}>
        {offerTypes.map((offerType) => (
          <Grid key={offerType.pathname} container item xs={12} smd={4}>
            <CreateOfferCard
              {...offerType}
              {...buttonConfig[offerType.offerTypeTranslationKey]}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" mt={5} textAlign="center">
        <Trans i18nKey="dashboard.offers.createOffer.contact">
          Want to learn more about our offer types? Contact us at
          <Link
            href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
              'dashboard.offers.createOffer.emailSubject'
            )}`}
          >
            partners@getopenstock.com
          </Link>
        </Trans>
      </Typography>
    </Layout>
  )
}

export default CreateOfferPage

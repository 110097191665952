import Grid from '@mui/material/Grid'
import Table, { TableProps } from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter, {
  TableFooterProps,
} from '@mui/material/TableFooter/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'

import Paper from 'components/mui/Paper/Paper'
import theme from 'styles/customTheme'

export interface HeadCell {
  id: string
  label: string
  width?: string
  backgroundColor?: string
  headCellBorderColor?: string
}

export type HeadCells = HeadCell[]

export const responsiveCheckboxPadding = {
  ml: { md: 2 },
  p: 0,
}

type Props = TableProps & {
  ariaLabel: string
  headCells: HeadCell[]
  isCheckbox?: boolean
  isPaperComponent?: boolean
  maxHeight?: number
}

export function ResponsiveTableRow({ children, ...props }: TableRowProps) {
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        borderBottom: { xs: 0.5, smd: 'none' },
        borderColor: { xs: 'secondary.light' },
      }}
      {...props}
    >
      {children}
    </TableRow>
  )
}

export function ResponsiveTableFooter({
  children,
  ...props
}: TableFooterProps) {
  return (
    <TableFooter
      sx={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        '.MuiTableCell-root': {
          borderTop: 0.5,
          borderBottom: 'none',
          borderColor: 'secondary.light',
        },
      }}
      {...props}
    >
      <TableRow>{children}</TableRow>
    </TableFooter>
  )
}

export default function ResponsiveTable({
  ariaLabel,
  children,
  headCells,
  isCheckbox = false,
  isPaperComponent = true,
  maxHeight = 750,
  ...props
}: Props) {
  const isSmdDown = useMediaQuery(theme.breakpoints.down('smd'))

  const WrapperComponent = isPaperComponent ? Paper : Grid

  return (
    <WrapperComponent
      sx={{
        p: 0,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <TableContainer sx={{ maxHeight }}>
        <Table
          aria-label={ariaLabel}
          stickyHeader
          size={isSmdDown ? 'small' : 'medium'}
          {...props}
        >
          <TableHead>
            <TableRow
              sx={{
                verticalAlign: 'top',
                '.MuiTableCell-root': {
                  borderBottom: 0.5,
                  borderColor: 'secondary.light',
                },
              }}
            >
              {isCheckbox && (
                <TableCell sx={{ ...responsiveCheckboxPadding }} />
              )}
              {headCells?.map((headCell: HeadCell) => (
                <TableCell
                  key={headCell.id}
                  style={{
                    borderBottom:
                      headCell.headCellBorderColor &&
                      `4px solid ${headCell.headCellBorderColor}`,
                  }}
                  sx={{
                    minWidth: '120px',
                    width: headCell.width,
                    backgroundColor: isPaperComponent
                      ? headCell.backgroundColor
                      : 'transparent',
                  }}
                >
                  <Typography variant="body2">{headCell.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </WrapperComponent>
  )
}

import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useAuth } from 'app/Auth'
import { CompanyDetails } from 'common/types'
import { useFormikContext } from 'lib/formik'
import { getRegionByCountry, priorityCountryList } from 'lib/countryRegion'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'
import Alert from 'components/mui/Alert'
import AutocompleteInput from 'components/mui/AutocompleteInput'
import Link from 'components/mui/Link'
import TextInput from 'components/mui/TextInput'
import RequiredNote from 'components/RequiredNote'

function AccountDetailsForm() {
  const { user } = useAuth()
  const { values, setFieldValue } = useFormikContext<CompanyDetails>()
  const { t } = useTranslation()
  const regions = getRegionByCountry(values.country)
  const regionsOfIncorporation = getRegionByCountry(
    values.countryOfIncorporation
  )

  const userInviteCompanyNavigateTo =
    user?.attributes['custom:companyInvite'] === 'true'
      ? '/account-confirmation'
      : DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE

  return (
    <Grid container rowSpacing={3}>
      <Grid mt={3}>
        <Link to={userInviteCompanyNavigateTo} variant="subtitle2">
          {t('account.company.notCompanyAdmin')}
        </Link>
      </Grid>

      <Grid item xs={12}>
        <RequiredNote />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          id="name"
          name="name"
          label={t('account.company.labels.name')}
          placeholder={t('account.company.labels.namePlaceholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="website-address"
          name="websiteAddress"
          label={t('account.company.labels.websiteAddress')}
          placeholder={t('account.company.labels.websiteAddressPlaceholder')}
          inputMode="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="street-address"
          name="streetAddress"
          label={t('account.company.labels.streetAddress')}
          placeholder={t('account.company.labels.streetAddressPlaceholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="street-address2"
          name="streetAddress2"
          label={t('account.company.labels.streetAddress2')}
          placeholder={t('account.company.labels.streetAddress2Placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="city"
          name="city"
          label={t('account.company.labels.city')}
          placeholder={t('account.company.labels.cityPlaceholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="country"
          name="country"
          label={t('account.company.labels.country')}
          placeholder={t('common.selectPlaceholder')}
          options={priorityCountryList}
          onChangeCallback={() => {
            setFieldValue('states', '')
          }}
          inputProps={{
            autoComplete: 'country-name',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="state"
          name="state"
          label={t('account.company.labels.state')}
          placeholder={t('common.selectPlaceholder')}
          options={regions}
          disabled={!values.country}
          inputProps={{
            autoComplete: 'address-level1',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="zip-code"
          name="zipCode"
          label={t('account.company.labels.zipCode')}
          placeholder={t('account.company.labels.zipCodePlaceholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="ein"
          name="ein"
          label={t('account.company.labels.ein')}
          placeholder={t('account.company.labels.einPlaceholder')}
        />
      </Grid>
      <Grid mt={1}>
        <Alert icon={false} severity="info" variant="standard">
          <Typography variant="subtitle1">
            {t('account.company.labels.einNote')}
          </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="countryOfIncorporation"
          name="countryOfIncorporation"
          label={t('account.company.labels.countryOfIncorporation')}
          placeholder={t('common.selectPlaceholder')}
          options={priorityCountryList}
          onChangeCallback={() => {
            setFieldValue('states', '')
          }}
          inputProps={{
            autoComplete: 'country-name',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="stateOfIncorporation"
          name="stateOfIncorporation"
          label={t('account.company.labels.stateOfIncorporation')}
          placeholder={t('common.selectPlaceholder')}
          options={regionsOfIncorporation}
          disabled={!values.countryOfIncorporation}
          inputProps={{
            autoComplete: 'address-level1',
          }}
          required
        />
      </Grid>
    </Grid>
  )
}

export default AccountDetailsForm

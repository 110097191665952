import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'
import { DEFAULT_ROOT_ROUTE } from 'app/Routing'
import { FEATURE_FLAGS } from 'lib/config'
import { ResponsiveBarMenuItem, ResponsiveBarMenuItems } from 'common/types'
import {
  FullLogoLinkButton,
  SymbolLogoLinkButton,
} from 'components/LogoLinkButtons'
import Button from 'components/mui/Button'
import UserAvatarMenu from 'components/headerBar/UserAvatarMenu'
import theme from 'styles/customTheme'
import styles from './responsiveHeaderBar.module.scss'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'

type Props = {
  children: JSX.Element
  showNavItems?: boolean
}

const HeaderBar = ({ children, showNavItems = true }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isLoggedIn } = useAuth()
  const {
    hasSignedCompanyAgreement,
    roles: { activeRole, isShareholder, isCompanyAdmin },
  } = useUser()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const createProfilePath = '/login/create'
  const inviteCompanyPath = '/invite/company'

  const isSignUpButtonHidden =
    location.pathname.includes(createProfilePath) ||
    location.pathname.includes(inviteCompanyPath)

  const smallScreenBreakpoint = 'lg'
  const isSmallScreenBreakpoint = useMediaQuery(
    theme.breakpoints.down(smallScreenBreakpoint)
  )

  const navMenuItemsConfig: { [s: string]: ResponsiveBarMenuItems } =
    useMemo(() => {
      const items = {
        COMPANY_ADMIN: [
          {
            path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/overview`,
            text: t('dashboard.pages.overview'),
          },
          {
            path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers`,
            text: t('dashboard.pages.offers'),
          },
          {
            path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table`,
            text: t('dashboard.pages.capTable'),
          },
          {
            path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/company-info`,
            text: t('dashboard.pages.companyInfo'),
          },
          {
            path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/financing-info`,
            text: t('dashboard.pages.financingInfo'),
          },
        ],
        SHAREHOLDER: [
          {
            path: `${DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE}/overview`,
            text: t('shareholder.dashboard.pages.overview'),
          },
          // TODO: Readd when offers view is ready, may need to hide based on FEATURE FLAG
          // {
          //   path: `${DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE}/offers`,
          //   text: t('shareholder.dashboard.pages.offers'),
          // },
        ],
      }

      if (FEATURE_FLAGS.DEMO_DATA_INSIGHTS) {
        items['COMPANY_ADMIN'].push({
          path: `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/data-insights`,
          text: t('dashboard.pages.dataInsights'),
        })
      }

      return items
    }, [t])

  const menuItems = activeRole && navMenuItemsConfig[activeRole]

  const isMenuItems =
    menuItems &&
    menuItems.length >= 1 &&
    (hasSignedCompanyAgreement || !FEATURE_FLAGS.SIGN_COMPANY_AGREEMENT_LETTER)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const FullScreenNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <NavLink
      to={path}
      style={{ margin: '0 0.8rem' }}
      className={({ isActive }) =>
        classNames(styles.navLink, { [styles.linkActive]: isActive })
      }
    >
      <Typography variant="body2">{text}</Typography>
    </NavLink>
  )

  const MobileNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <MenuItem onClick={handleCloseNavMenu} sx={{ p: 0 }}>
      <NavLink
        to={path}
        className={({ isActive }) =>
          classNames(styles.mobileNavLink, {
            [styles.mobileLinkActive]: isActive,
          })
        }
      >
        <Typography>{text}</Typography>
      </NavLink>
    </MenuItem>
  )

  const LogoButton = () =>
    isSmallScreenBreakpoint ? (
      <SymbolLogoLinkButton path={DEFAULT_ROOT_ROUTE} />
    ) : (
      <FullLogoLinkButton
        path={DEFAULT_ROOT_ROUTE}
        sx={{ m: 0, mr: 1, p: 0 }}
        isWhiteLogo
      />
    )

  return (
    <div className={styles.root}>
      <AppBar
        position="static"
        sx={{
          '&.MuiAppBar-colorPrimary': {
            backgroundColor: 'primary.dark',
          },
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container alignItems="center">
              <Grid item width="auto">
                <LogoButton />
              </Grid>
              {showNavItems && (
                <>
                  <Grid item flex={1}>
                    {isMenuItems && (
                      <>
                        {isSmallScreenBreakpoint ? (
                          <Box sx={{ flexGrow: 1 }}>
                            <IconButton
                              size="large"
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={handleOpenNavMenu}
                              color="inherit"
                            >
                              <MenuIcon />
                            </IconButton>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorElNav}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              open={Boolean(anchorElNav)}
                              onClose={handleCloseNavMenu}
                              sx={{
                                display: {
                                  xs: 'block',
                                  [smallScreenBreakpoint]: 'none',
                                },
                              }}
                            >
                              {menuItems.map((menuItem) => (
                                <MobileNavLink
                                  key={menuItem.text}
                                  path={menuItem.path}
                                  text={menuItem.text}
                                />
                              ))}
                            </Menu>
                          </Box>
                        ) : (
                          <Box sx={{ flexGrow: 1, mt: 1, display: 'flex' }}>
                            {menuItems.map((menuItem) => (
                              <FullScreenNavLink
                                key={menuItem.text}
                                path={menuItem.path}
                                text={menuItem.text}
                              />
                            ))}
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item width="auto">
                    {isLoggedIn ? (
                      <Grid
                        container
                        columnGap={{ xs: 2, sm: 6 }}
                        my={{ xs: 1, sm: 0 }}
                      >
                        {(isShareholder || isCompanyAdmin) && (
                          <Button
                            href="/calculator"
                            isLightHover
                            size={isSmallScreenBreakpoint ? 'small' : 'medium'}
                          >
                            {t('calculator.button')}
                          </Button>
                        )}
                        <UserAvatarMenu />
                      </Grid>
                    ) : (
                      <>
                        {!isSignUpButtonHidden && (
                          <Button
                            isLightHover
                            to={createProfilePath}
                            sx={{ ml: 2 }}
                          >
                            {t('user.login.signUp')}
                          </Button>
                        )}
                        <Button
                          isLightHover
                          variant="text"
                          to="/login"
                          sx={{ ml: 2, color: 'white' }}
                        >
                          {t('user.login.logIn')}
                        </Button>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </div>
  )
}
export default HeaderBar

import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider/Divider'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useFormikContext } from 'lib/formik'
import { investors } from 'lib/investorLogos'
import {
  OfferingsInterestsSchema,
  TenderOfferWaitlistSchema,
} from 'common/types'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import styles from './financialPartneres.module.scss'
import TextInput from 'components/mui/TextInput'

type Investor = {
  name: string
  logo: string
  isBank?: boolean
  isFund?: boolean
}

interface Props {
  handleBack?: () => void
  values: OfferingsInterestsSchema | TenderOfferWaitlistSchema
  isSubmitting: boolean
  isTenderOfferLogos?: boolean
}

const bankPartners = investors.filter((investor) =>
  investor.category.includes('bank')
)
const fundPartners = investors.filter((investor) =>
  investor.category.includes('fund')
)

const bankPartnerNames = bankPartners.map((partner) => partner.name)
const fundPartnerNames = fundPartners.map((partner) => partner.name)

const LogoCheckbox = ({
  investor,
  partnerKind,
  selectedFinancialPartners,
  isItemSelected,
  isTenderOfferLogos = false,
}: {
  investor: Investor
  partnerKind: keyof OfferingsInterestsSchema['financialPartners']
  values: OfferingsInterestsSchema | TenderOfferWaitlistSchema
  selectedFinancialPartners: OfferingsInterestsSchema['financialPartners']
  isItemSelected: boolean
  isTenderOfferLogos: boolean
}) => {
  const { setFieldValue } = useFormikContext()
  const { name, logo } = investor
  const selectedFinancialPartnersByKind = selectedFinancialPartners[partnerKind]

  const handleChange = () => {
    const selectedIndex = selectedFinancialPartnersByKind?.indexOf(name)

    const newSelected: OfferingsInterestsSchema['financialPartners'] = {
      bank: [],
      fund: [],
    }

    if (partnerKind === 'bank' && selectedFinancialPartners.fund)
      newSelected.fund = [...selectedFinancialPartners.fund]
    if (partnerKind === 'fund' && selectedFinancialPartners.bank)
      newSelected.bank = [...selectedFinancialPartners.bank]

    if (selectedIndex === -1) {
      newSelected[partnerKind] = newSelected[partnerKind]?.concat(
        selectedFinancialPartners[partnerKind],
        name
      )
    } else if (selectedIndex === 0) {
      newSelected[partnerKind] = newSelected[partnerKind]?.concat(
        selectedFinancialPartnersByKind?.slice(1)
      )
    } else if (
      selectedFinancialPartnersByKind &&
      selectedIndex === selectedFinancialPartnersByKind.length - 1
    ) {
      newSelected[partnerKind] = newSelected[partnerKind]?.concat(
        selectedFinancialPartnersByKind?.slice(0, -1)
      )
    } else if (selectedIndex && selectedIndex > 0) {
      newSelected[partnerKind] = newSelected[partnerKind]?.concat(
        selectedFinancialPartnersByKind?.slice(0, selectedIndex),
        selectedFinancialPartnersByKind?.slice(selectedIndex + 1)
      )
    }

    setFieldValue('financialPartners', newSelected)
  }

  return (
    <Grid item container p={1} xs={isTenderOfferLogos ? 4 : 6} rowGap={0.5}>
      <Grid item xs={12}>
        <Button
          onClick={handleChange}
          sx={{ p: 0 }}
          disableTouchRipple
          tabIndex={-1}
        >
          <Paper className={styles.paper}>
            <img src={logo} alt={name} className={styles.logo} />
          </Paper>
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={isItemSelected}
              onChange={handleChange}
              disableTouchRipple
            />
          }
          label={name}
          sx={{
            width: 1,
            ml: 0,
            alignItems: 'flex-start',
            '& .MuiTypography-root': { mt: 1.25 },
          }}
        />
      </Grid>
    </Grid>
  )
}

function FinancialPartners({
  handleBack,
  values,
  isSubmitting,
  isTenderOfferLogos = false,
}: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const selectedFinancialPartners = values.financialPartners

  const isAllSelected =
    bankPartners.length === values.financialPartners.bank?.length &&
    fundPartners.length === values.financialPartners.fund?.length

  const handleSelectAll = () => {
    if (isAllSelected) {
      setFieldValue('financialPartners', { bank: [], fund: [] })
    } else {
      setFieldValue('financialPartners', {
        bank: bankPartnerNames,
        fund: fundPartnerNames,
      })
    }
  }

  return (
    <Grid container rowGap={3}>
      <Grid item container xs={12} rowGap={2}>
        {/* Select all */}
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleSelectAll}
              disableTouchRipple
              checked={isAllSelected}
            />
          }
          label={t(
            'account.company.offeringsInterestsQuestions.financialPartners.selectAll'
          )}
        />
        <Grid item xs={12} mb={1}>
          <Divider />
        </Grid>

        {/* Banks */}
        {bankPartners.length > 0 && (
          <Grid item container rowGap={1}>
            <Typography variant="h3">
              {t(
                'account.company.offeringsInterestsQuestions.financialPartners.banks'
              )}
            </Typography>
            <Grid container>
              {bankPartners.map((investor) => {
                const isItemSelected =
                  selectedFinancialPartners.bank?.includes(investor.name) ??
                  false

                return (
                  <LogoCheckbox
                    key={investor.name}
                    investor={investor}
                    values={values}
                    partnerKind="bank"
                    selectedFinancialPartners={selectedFinancialPartners}
                    isItemSelected={isItemSelected}
                    isTenderOfferLogos={isTenderOfferLogos}
                  />
                )
              })}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 3, mb: 1 }} />
            </Grid>
          </Grid>
        )}

        {/* Funds */}
        {fundPartners.length > 0 && (
          <Grid item container rowGap={1}>
            <Typography variant="h3">
              {t(
                'account.company.offeringsInterestsQuestions.financialPartners.funds'
              )}
            </Typography>
            <Grid container>
              {fundPartners.map((investor) => {
                const isItemSelected =
                  selectedFinancialPartners.fund?.includes(investor.name) ??
                  false

                return (
                  <LogoCheckbox
                    key={investor.name}
                    investor={investor}
                    values={values}
                    partnerKind="fund"
                    selectedFinancialPartners={selectedFinancialPartners}
                    isItemSelected={isItemSelected}
                    isTenderOfferLogos={isTenderOfferLogos}
                  />
                )
              })}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 3 }} />
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Other field */}
      <Grid item container rowGap={3} mb={3}>
        <Grid item>
          <Typography>{t('common.other')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {t(
              'account.company.offeringsInterestsQuestions.financialPartners.otherPartners.description'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            multiline
            id="other-partners"
            name="otherPartners"
            placeholder={t(
              'account.company.offeringsInterestsQuestions.financialPartners.otherPartners.placeholder'
            )}
            helperText={t(
              'account.company.offeringsInterestsQuestions.financialPartners.otherPartners.helperText'
            )}
          />
        </Grid>
      </Grid>

      {handleBack && (
        <Grid item xs={12}>
          <ResponsiveStepperButtons
            handleBack={handleBack}
            previousStepText={t('common.back')}
            isSubmitting={isSubmitting}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default FinancialPartners

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'

import { useUser } from 'app/User'
import { createApprovedCaptableAirtableRecord } from 'lib/airtable'
import {
  useGetCaptableQuery,
  useSetCaptableIsApprovedBy,
} from 'lib/apollo/hooks'
import Button from 'components/mui/Button'
import AlertDialog from 'components/AlertDialog'
import LoadingButton from 'components/mui/LoadingButton'
import QueryBoundary from 'app/QueryBoundary'

interface Props {
  handleBack: () => void
  handleNavigate: () => void
  captableId?: string
}

function ApproveIngestedCapTable({
  handleBack,
  handleNavigate,
  captableId,
}: Props) {
  const { t } = useTranslation()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const {
    data: { company },
    roles: { activeRole },
  } = useUser()

  const [setCaptableIsApprovedBy, setCaptableIsApprovedByMutation] =
    useSetCaptableIsApprovedBy()

  const captableQuery = useGetCaptableQuery(captableId)
  const captable = captableQuery?.data?.getCaptable

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    setCaptableIsApprovedByMutation.reset()
  }

  const handleApprove = async () => {
    if (!setCaptableIsApprovedByMutation.data) {
      const { errors } = await setCaptableIsApprovedBy({
        variables: {
          captableId: captable?._id,
          approvedBy: activeRole,
          isApproved: true,
        },
      })

      if (errors) return

      if (activeRole === 'COMPANY_ADMIN') {
        createApprovedCaptableAirtableRecord(company?.name)
      }
    }

    return handleNavigate()
  }

  const handleTryAgain = () => {
    handleResetMutation()
    handleApprove()
  }

  useEffect(() => {
    if (setCaptableIsApprovedByMutation.error) {
      setShowAlertDialog(true)
    }
  }, [setCaptableIsApprovedByMutation.error])

  return (
    <QueryBoundary queries={[captableQuery]}>
      <>
        <Grid container rowGap={4} mt={5}>
          {/* Buttons */}
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={handleBack}>
                {t('common.previous')}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={setCaptableIsApprovedByMutation.loading}
                onClick={handleApprove}
              >
                {t('ingestedCapTable.approveCapTable')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Shows mutation error when approving table  */}
        <AlertDialog
          isOpen={showAlertDialog}
          onClose={() => setShowAlertDialog(false)}
          description={t('common.errorSubmitForm')}
          primaryButtonAction={handleTryAgain}
          secondaryButtonAction={handleResetMutation}
        />
      </>
    </QueryBoundary>
  )
}

export default ApproveIngestedCapTable

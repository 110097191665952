import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import i18n from 'locale/i18n'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import { CreateOfferMultiForms, ShareholderShareInfo } from 'common/types'
import ResponsiveTable, {
  HeadCells,
  responsiveCheckboxPadding,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'

const headCells: HeadCells = [
  {
    id: 'shareholders',
    label: i18n.t('offer.calculateLiquidity.table.tableHeadCell1'),
    width: '25%',
  },
  {
    id: 'shareClass',
    label: i18n.t('offer.calculateLiquidity.table.tableHeadCell2'),
    width: '35%',
  },
  {
    id: 'numOfShares',
    label: i18n.t('offer.calculateLiquidity.table.tableHeadCell3'),
    width: '20%',
  },
  {
    id: 'valueOfShares',
    label: i18n.t('offer.calculateLiquidity.table.tableHeadCell4'),
    width: '20%',
  },
]

function SelectShareholdersTableRow({
  handleClick,
  isItemSelected,
  isIneligible,
  row,
}: {
  handleClick: (row: ShareholderShareInfo[0]) => void
  isItemSelected: boolean
  isIneligible: boolean
  row: ShareholderShareInfo[0]
}) {
  const { t } = useTranslation()

  const { shareholder, shareclasses, shares } = row

  const onClick = (_: React.MouseEvent<unknown>) => handleClick(row)

  return (
    <ResponsiveTableRow
      hover={false}
      onClick={isIneligible ? () => null : onClick}
      role="checkbox"
      aria-checked={isItemSelected}
      selected={isItemSelected}
      sx={{
        backgroundColor: isIneligible ? 'warning.light' : 'inherit',
        '>*': { color: isIneligible ? 'warning.dark' : 'inherit' },
        height: '4rem',
      }}
    >
      <TableCell>
        {isIneligible ? (
          <HighlightOffIcon
            color="warning"
            sx={{ ...responsiveCheckboxPadding }}
          />
        ) : (
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': shareholder._id,
            }}
            sx={{ ...responsiveCheckboxPadding }}
          />
        )}
      </TableCell>
      <TableCell component="th" id={shareholder._id} scope="row">
        <Typography>{shareholder.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {shareclasses
            .map(
              (shareclass: ShareholderShareInfo[0]['shareclasses'][0]) =>
                shareclass.name
            )
            .join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlNumberFormat', {
            value: shares.maxAvailableShares,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlCurrencySimplifiedFormat', {
            value: shares.liquidityAvailable,
          })}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

export default function SelectShareholdersTable({
  shareholderShareInfo,
  ineligibleShareholderIds,
  values,
}: {
  shareholderShareInfo: ShareholderShareInfo
  ineligibleShareholderIds: ShareholderShareInfo[0]['shareholder']['_id'][]
  values: CreateOfferMultiForms
}) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const selectedShareholders: ShareholderShareInfo = values.shareholders

  const handleClick = (row: ShareholderShareInfo[0]) => {
    const selectedIndex = selectedShareholders.findIndex(
      (selectedShareholder) =>
        selectedShareholder.shareholder._id === row.shareholder._id
    )
    const newSelected: ShareholderShareInfo = [...selectedShareholders]

    if (selectedIndex === -1) {
      newSelected.push(row)
    } else if (selectedIndex === 0) {
      newSelected.shift()
    } else if (selectedIndex === selectedShareholders.length - 1) {
      newSelected.pop()
    } else if (selectedIndex > 0) {
      newSelected.splice(selectedIndex, 1)
    }

    setFieldValue('shareholders', newSelected)
  }

  return (
    <ResponsiveTable
      ariaLabel={t('offer.calculateLiquidity.table.ariaLabel')}
      isCheckbox
      headCells={headCells}
    >
      <TableBody>
        {shareholderShareInfo.map((row: ShareholderShareInfo[0]) => {
          const isIneligible = ineligibleShareholderIds.includes(
            row.shareholder._id
          )

          const isItemSelected =
            !isIneligible &&
            selectedShareholders.findIndex(
              (checkedShareholder: ShareholderShareInfo[0]) =>
                checkedShareholder.shareholder._id === row.shareholder._id
            ) !== -1

          return (
            <SelectShareholdersTableRow
              key={row.shareholder._id}
              isItemSelected={isItemSelected}
              handleClick={handleClick}
              row={row}
              isIneligible={isIneligible}
            />
          )
        })}
      </TableBody>
    </ResponsiveTable>
  )
}

import { useTranslation } from 'react-i18next'

import { Divider, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { UPLOAD_DOCUMENTS_LIST_BY_REQUIRED } from 'lib/formConfig'
import { UploadDocumentsListItem, CompanyFile } from 'common/types'
import UploadFile from 'components/files/UploadFile'

import theme from 'styles/customTheme'

interface Props {
  uploadedFilesByLabel?: { [s: string]: CompanyFile }
}

function UploadDocumentsForm({ uploadedFilesByLabel }: Props) {
  const { t } = useTranslation()
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'))

  const requiredDocuments = UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.required
  const optionalDocuments = UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.optional

  return (
    <Grid container rowSpacing={2}>
      <Grid item>
        <Grid item xs={12} sm={8} md={6}>
          <Typography variant="body2" mb={2}>
            {t('dashboard.companyInfo.financialDocuments.requiredTitle')}
          </Typography>
          <Typography variant="body1">
            {t('dashboard.companyInfo.financialDocuments.requiredDescription')}
          </Typography>
        </Grid>
        {requiredDocuments.map((document: UploadDocumentsListItem) => (
          <Grid
            container
            item
            xs={12}
            my={4}
            columnSpacing={2}
            key={document.name}
          >
            <Grid item xs={12} sm={6}>
              <UploadFile
                document={document}
                showDescription={isSmallDown}
                uploadToReplace={uploadedFilesByLabel?.[document.name]}
              />
            </Grid>
            {!isSmallDown && document.description && (
              <Grid item xs={12} sm={6} mt={5}>
                <Typography variant="subtitle1">
                  {document.description}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Divider variant="fullWidth" sx={{ mb: 4 }} />
      </Grid>

      <Grid item>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" mb={2}>
            {t('dashboard.companyInfo.financialDocuments.optionalTitle')}
          </Typography>
          <Typography variant="body1">
            {t('dashboard.companyInfo.financialDocuments.optionalDescription')}
          </Typography>
        </Grid>
        {optionalDocuments.map((document: UploadDocumentsListItem) => (
          <Grid
            container
            item
            xs={12}
            my={4}
            columnSpacing={2}
            key={document.name}
          >
            <Grid item xs={12} sm={6}>
              <UploadFile
                document={document}
                showDescription={isSmallDown}
                uploadToReplace={uploadedFilesByLabel?.[document.name]}
              />
            </Grid>
            {!isSmallDown && document.description && (
              <Grid item xs={12} sm={6} mt={5}>
                <Typography variant="subtitle1">
                  {document.description}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default UploadDocumentsForm

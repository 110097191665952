import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useGetCompanyQuery } from 'lib/apollo/hooks'
import { DEFAULT_ADMIN_DASHBOARD_ROUTE } from 'routes/AdminDashboard'

import QueryBoundary from 'app/QueryBoundary'
import Documents from 'views/openstockAdmin/company/Documents'
import BackLink from 'components/BackLink'
import Layout from 'components/Layout/Layout'
import LoanInfo from './LoanInfo'
import TabBar from 'components/mui/TabBar'
import CapTable from './capTable/CapTable'
import AdminCompanyOfferings from './CompanyOfferings'
import CompanyInfo from './CompanyInfo'

function Company() {
  const { t } = useTranslation()
  const { companyId } = useParams()
  const companyQuery = useGetCompanyQuery(companyId)
  const { data } = companyQuery
  const company = data?.getCompany

  const tabList = [
    {
      label: t('openstockAdmin.company.tabs.offers'),
      path: 'offers',
      element: <AdminCompanyOfferings company={company} />,
    },
    {
      label: t('openstockAdmin.company.tabs.capTable'),
      path: 'cap-table',
      element: <CapTable />,
    },
    {
      label: t('openstockAdmin.company.tabs.documents'),
      path: 'documents',
      element: <Documents files={company?.files} />,
    },
    {
      label: t('openstockAdmin.company.tabs.companyInfo'),
      path: 'company-info',
      element: <CompanyInfo company={company} />,
    },
    {
      label: t('openstockAdmin.company.tabs.loanInfo'),
      path: 'financing-info',
      element: <LoanInfo company={company} />,
    },
  ]

  return (
    <QueryBoundary queries={[companyQuery]}>
      <Layout maxWidth="lg">
        <BackLink to={DEFAULT_ADMIN_DASHBOARD_ROUTE} sx={{ mb: 3 }} />
        <Grid container rowSpacing={4}>
          <Grid item>
            <Typography variant="h1" mb={1}>
              {company?.name}
            </Typography>
          </Grid>
          <TabBar tabList={tabList} fallbackPath="offers" />
        </Grid>
      </Layout>
    </QueryBoundary>
  )
}

export default Company

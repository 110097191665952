import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import i18n from 'locale/i18n'

import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import {
  CaptableShareclass,
  CaptableShareholderGroupedTable,
  Company,
  CreateOfferMultiForms,
  DerivedShareclassGroupedTable,
} from 'common/types'
import { getShareholderShareInfo } from 'lib/data'
import ResponsiveTable, {
  HeadCells,
  responsiveCheckboxPadding,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'

const headCells: HeadCells = [
  {
    id: 'shareClass',
    label: i18n.t('offer.selectShareClass.table.tableHeadCell1'),
    width: '30%',
  },
  {
    id: 'numShareholders',
    label: i18n.t('offer.selectShareClass.table.tableHeadCell2'),
    width: '20%',
  },
  {
    id: 'outstanding',
    label: i18n.t('offer.selectShareClass.table.tableHeadCell3'),
    width: '25%',
  },
  {
    id: 'totalValue',
    label: i18n.t('offer.selectShareClass.table.tableHeadCell4'),
    width: '25%',
  },
]

function SelectShareClassTableRow({
  column,
  handleClick,
  isItemSelected,
}: {
  column: DerivedShareclassGroupedTable[0]
  handleClick: (shareclassId: CaptableShareclass['_id']) => void
  isItemSelected: boolean
}) {
  const { t } = useTranslation()

  const onClick = (_: React.MouseEvent<unknown>) => handleClick(column._id)

  return (
    <ResponsiveTableRow
      hover
      onClick={onClick}
      role="checkbox"
      aria-checked={isItemSelected}
      selected={isItemSelected}
    >
      <TableCell>
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': column.name,
          }}
          sx={{ ...responsiveCheckboxPadding }}
        />
      </TableCell>
      <TableCell component="th" id={column.name} scope="row">
        <Typography>{column.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{column.numberOfShareholders}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlNumberFormat', {
            value: column.numberOfShares,
          })}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('common.intlCurrencySimplifiedFormat', {
            value: column.totalValueOfShares,
          })}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

export default function SelectShareClassTable({
  company,
  derivedShareclassGroupedTable,
  shareholderGroupedTable,
  values,
}: {
  company: Company
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  shareholderGroupedTable: CaptableShareholderGroupedTable
  values: CreateOfferMultiForms
}) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const selectedShareclasses: CaptableShareclass['_id'][] = values.shareclasses

  const handleClick = (shareclassId: CaptableShareclass['_id']) => {
    const selectedIndex = selectedShareclasses.indexOf(shareclassId)

    let newSelected: CaptableShareclass['_id'][] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedShareclasses, shareclassId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedShareclasses.slice(1))
    } else if (selectedIndex === selectedShareclasses.length - 1) {
      newSelected = newSelected.concat(selectedShareclasses.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedShareclasses.slice(0, selectedIndex),
        selectedShareclasses.slice(selectedIndex + 1)
      )
    }

    // Checks if a shareclass has been added/selected
    const isNewShareclassSelected = newSelected.some(
      (newSelectedShareClassId) => newSelectedShareClassId === shareclassId
    )

    // New shareholders list by new selected shareclasses
    const newShareholdersByNewSelectedShareclasses = getShareholderShareInfo({
      shareclassIds: newSelected,
      shareholderGroupedTable,
      sharePrice: company?.financialTermsCurrent?.sharePrice,
    })

    // New shareholders list by selecting new shareClass
    const newShareholdersByAddingShareclass = getShareholderShareInfo({
      shareclassIds: [shareclassId],
      shareholderGroupedTable,
      sharePrice: company?.financialTermsCurrent?.sharePrice,
    })

    // Combine new and existing selected shareholders
    const concatSelectedShareholdersByShareclass = [
      ...values.shareholders,
      ...newShareholdersByAddingShareclass,
    ]

    // Filter shareholders of selected/added shareclass
    const filteredShareholdersByAddingShareclass =
      newShareholdersByNewSelectedShareclasses.filter((newShareholderRow) =>
        // Filter shareholders with updated shares
        concatSelectedShareholdersByShareclass.some(
          (currShareholderRow) =>
            currShareholderRow.shareholder._id ===
            newShareholderRow.shareholder._id
        )
      )

    // Filter shareholders of deselected/removed shareclass
    const filteredShareholdersByRemovingShareclass =
      newShareholdersByNewSelectedShareclasses.filter((newShareholderRow) => {
        // Filter existing shareholders by removing selected shareclasses
        const filteredShareholders = values.shareholders.filter((shareholder) =>
          shareholder.shareclasses.some(
            (shareclass: CaptableShareclass) => shareclass._id !== shareclassId
          )
        )
        // Filter shareholders with more than one shareclass and updated shares
        return filteredShareholders.some(
          (currShareholderRow) =>
            currShareholderRow.shareholder._id ===
            newShareholderRow.shareholder._id
        )
      })

    const newSelectedShareholdersByShareclass = isNewShareclassSelected
      ? filteredShareholdersByAddingShareclass
      : filteredShareholdersByRemovingShareclass

    setFieldValue('shareclasses', newSelected)
    setFieldValue('shareholders', newSelectedShareholdersByShareclass)
  }

  return (
    <ResponsiveTable
      ariaLabel={t('offer.selectShareClass.table.ariaLabel')}
      headCells={headCells}
      isCheckbox
    >
      <TableBody>
        {derivedShareclassGroupedTable?.map((column) => {
          const isItemSelected = selectedShareclasses.includes(column._id)

          return (
            <SelectShareClassTableRow
              key={column.name}
              column={column}
              handleClick={handleClick}
              isItemSelected={isItemSelected}
            />
          )
        })}
      </TableBody>
    </ResponsiveTable>
  )
}

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

interface Props {
  greetName: string
  welcomeMessage: string
  greeting: string
  background: string
}

function Greeting({ greetName, welcomeMessage, greeting, background }: Props) {
  return (
    <Grid
      container
      px={{ xs: 6, smd: 11 }}
      py={{ xs: 5, smd: 10 }}
      sx={{
        background: background,
        borderRadius: '20px',
      }}
      mb={5}
    >
      <Grid item container>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            color="secondary.contrastText"
            sx={{
              fontSize: { smd: '2.5rem' },
              lineHeight: { smd: '3.75rem' },
              textTransform: 'capitalize',
              wordBreak: 'break-word',
            }}
            mb={4}
          >
            {greetName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            fontWeight={500}
            color="secondary.contrastText"
            mb={2}
            dangerouslySetInnerHTML={{
              __html: welcomeMessage,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          <Typography
            variant="h4"
            fontWeight={400}
            color="secondary.contrastText"
          >
            {greeting}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Greeting

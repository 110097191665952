import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'
import { useGetCompanyQuery, useGetCaptableQuery } from 'lib/apollo/hooks'
import QueryBoundary from 'app/QueryBoundary'
import Button from 'components/mui/Button'
import EmptyStateWithButton from 'components/EmptyStateWithButton'
import IngestedCapTable from 'components/tables/IngestedCapTable'
import StatusIndicator from 'components/StatusIndicator'

function CapTable() {
  const { t } = useTranslation()
  const { companyId } = useParams()

  const companyQuery = useGetCompanyQuery(companyId)
  const { data } = companyQuery
  const company = data?.getCompany

  const captableQuery = useGetCaptableQuery(
    company.captableCurrent?._id ?? false
  )
  const captable = captableQuery?.data?.getCaptable

  const hasApprovedCapTable = captable?.isApprovedBy?.openstockAdmin
  const isAwaitingApproval =
    captable?.isApprovedBy?.openstockAdmin &&
    !captable.isApprovedBy?.companyAdmin

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          columnGap={{ xs: 3 }}
          rowGap={2}
          mb={2}
        >
          <Grid
            item
            container
            alignItems="center"
            columnGap={{ xs: 3 }}
            rowGap={2}
            width={{ sm: 'auto' }}
          >
            <Grid item>
              <Typography variant="h3">
                {t('openstockAdmin.company.capTable.capTable')}
              </Typography>
            </Grid>
            <Grid item>
              {hasApprovedCapTable && (
                <StatusIndicator
                  hasBorder
                  label={t(
                    `openstockAdmin.company.capTable.status.${
                      isAwaitingApproval
                        ? 'awaitingApprovalByCompanyAdmin'
                        : 'active'
                    }`
                  )}
                  isCapitalizeLabel={false}
                  status={isAwaitingApproval ? 'SAVED' : 'ACTIVE'}
                />
              )}
            </Grid>
          </Grid>
          <Grid item>
            {hasApprovedCapTable && (
              <Button
                mode="forward"
                size="small"
                variant="outlined"
                to="ingest"
                sx={{ my: { xs: 2, sm: 0 } }}
              >
                {t('openstockAdmin.company.capTable.ingestNewCapTable')}
              </Button>
            )}
          </Grid>
        </Grid>
        {hasApprovedCapTable ? (
          <IngestedCapTable />
        ) : (
          <EmptyStateWithButton
            imageReference="table"
            title={
              isAwaitingApproval
                ? t('openstockAdmin.company.capTable.awaitingApproval')
                : t('openstockAdmin.company.capTable.noCapTable')
            }
            description={t(
              'openstockAdmin.company.capTable.noCapTableDescription'
            )}
            buttonLabel={
              isAwaitingApproval
                ? t('openstockAdmin.company.capTable.ingestNewCapTable')
                : t('openstockAdmin.company.capTable.ingestCapTable')
            }
            buttonNavigateTo="ingest"
          />
        )}
      </>
    </QueryBoundary>
  )
}

export default CapTable

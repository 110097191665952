import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import LoadingButton from 'components/mui/LoadingButton'

function SendCalculatorInfo({
  name,
  sendInfoMutation,
}: {
  name: string
  sendInfoMutation: any
}) {
  const { t } = useTranslation()

  return (
    <Grid container justifyContent="space-between" mt={5}>
      <Grid item xs={12} sm={5}>
        <Typography variant="h3">
          {t(
            `calculator.tab.${name}.sendInfo.${
              sendInfoMutation.isSuccess ? 'sentTitle' : 'title'
            }`
          )}
        </Typography>
        <Typography mt={2} mb={5}>
          {t(
            `calculator.tab.${name}.sendInfo.${
              sendInfoMutation.isSuccess ? 'sentSubTitle' : 'subTitle'
            }`
          )}
        </Typography>
        {!sendInfoMutation.isSuccess && (
          <LoadingButton
            mode="forward"
            type="submit"
            loading={sendInfoMutation.isLoading}
          >
            {t(`calculator.tab.${name}.sendInfo.buttonLabel`)}
          </LoadingButton>
        )}
      </Grid>

      <Grid item container xs={12} sm={6} mt={{ xs: 6, sm: 0 }}>
        <Typography variant="subtitle1">
          {t(`calculator.tab.${name}.sendInfo.subText`)}
        </Typography>
        <Typography variant="h6" mt={2} mb={1}>
          {t(`calculator.tab.${name}.sendInfo.subTextLabel`)}
        </Typography>
        <Typography variant="subtitle1">
          {t(`calculator.tab.${name}.sendInfo.subTextDescription`)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SendCalculatorInfo

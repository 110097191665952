import Paper from '@mui/material/Paper/Paper'
import styles from './paper.module.scss'

export const paperShape = {
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  padding: '1.5rem',
}

function PaperWrapper({ sx = {}, ...props }) {
  return (
    <Paper
      {...props}
      className={`${styles.paper} ${props.className}`}
      sx={{ ...paperShape, ...sx }}
    >
      {props.children}
    </Paper>
  )
}

export default PaperWrapper

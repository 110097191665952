import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import calculateLiquidityPreview from 'assets/calculateLiquidityPreview.png'
import { createTenderOfferWaitlistAirtableRecord } from 'lib/airtable'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import styles from './tenderOfferLearnMore.module.scss'

import BackLink from 'components/BackLink'
import Layout from 'components/Layout/Layout'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'
import FinancialPartners from 'views/companyAdmin/offeringsInterestsQuestions/financialPartners/FinancialPartners'
import { Form, Formik } from 'lib/formik'
import { tenderOfferWaitlistSchema } from 'lib/validation'
import { GenericObject, TenderOfferWaitlistSchema } from 'common/types'
import { investors } from 'lib/investorLogos'
import Paper from 'components/mui/Paper/Paper'

function TenderOfferLearnMore() {
  const { t } = useTranslation()
  const { data } = useUser()
  const [hasAccess, setHasAccess] = useState(
    localStorage.getItem('TENDER_OFFERS_ACCESS_STATUS') === 'true'
  )

  const initialValues = {
    financialPartners: { bank: [], fund: [] },
    otherPartners: '',
  }

  const airtableMutation = useMutation(
    ({
      name,
      email,
      company,
      formData,
    }: {
      name: string
      email: string
      company: string
      formData: GenericObject
    }) =>
      createTenderOfferWaitlistAirtableRecord(name, email, company, formData)
  )

  const handleWaitlistSubmit = (values: TenderOfferWaitlistSchema) =>
    airtableMutation.mutate({
      name: `${data.firstName} ${data.lastName}`,
      email: data.emailAddress,
      company: data.company.name,
      formData: values,
    })

  useEffect(() => {
    // Set  local storage access to true
    if (airtableMutation.isSuccess) {
      localStorage.setItem('TENDER_OFFERS_ACCESS_STATUS', JSON.stringify(true))
      setHasAccess(true)
      window.scrollTo(0, 0)
    }
  }, [airtableMutation.isSuccess])

  return (
    <Layout maxWidth="lg">
      <BackLink to="../create-offer" />
      <Grid container gap={4}>
        <Grid item xs={12} mb={2}>
          <Typography variant="h1" mb={2}>
            {t('dashboard.offers.createOffer.tenderOffer.title')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            item
            container
            xs={12}
            sm={7}
            md={5}
            m="auto"
            textAlign="center"
            rowGap={2}
          >
            {hasAccess ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.heading'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <Trans i18nKey="dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.description">
                      The Openstock team has been notified of your interest in
                      our tender offer program and will be in contact. In the
                      meantime, if you need support, please reach out to us any
                      time at
                      <Link
                        href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                          'dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.supportEmailSubject'
                        )}`}
                        variant="body2"
                      >
                        {OPENSTOCK_SUPPORT_EMAIL}
                      </Link>
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button mode="forward" to="/" variant="outlined">
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.button'
                    )}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.heading1'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.subheading1'
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {hasAccess ? (
          <>
            {/* Company logos */}
            <Grid
              item
              container
              justifyContent="center"
              rowGap={{ xs: 0, sm: 2 }}
            >
              {investors.map((investor) => (
                <Grid
                  item
                  key={investor.name}
                  px={1}
                  py={2}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <Paper className={styles.paper}>
                    <img
                      src={investor.logo}
                      alt={investor.name}
                      className={styles.logo}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} sm={7} md={5} m="auto" textAlign="center">
              <Typography>
                <Trans i18nKey="dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.suggestNewInvestors">
                  *If there are other investment partners you have worked with
                  in the past that you want to see on this list, let us know at
                  <Link
                    href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.accessRequested.newInvestorsEmailSubject'
                    )}`}
                    variant="body2"
                  >
                    {OPENSTOCK_SUPPORT_EMAIL}
                  </Link>
                </Trans>
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            {/* Calculate Liquidity Screenshot */}
            <img
              src={calculateLiquidityPreview}
              alt=""
              width={1022}
              height={718}
              className={styles.image}
            />

            <Grid item xs={12}>
              <Grid item container xs={12} sm={7} md={5} m="auto" rowGap={2}>
                <Grid item xs={12} textAlign="center" mb={4}>
                  <Typography variant="h3">
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.heading2'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.subheading2'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={4}>
                  <Typography variant="body2">
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.instructionHeading'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {t(
                      'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.instructionDescription'
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={7}
              md={5}
              m="auto"
              textAlign="center"
            >
              <Formik
                initialValues={initialValues}
                validationSchema={tenderOfferWaitlistSchema}
                onSubmit={handleWaitlistSubmit}
              >
                {({ values }) => {
                  const caseProps = {
                    values,
                    isTenderOfferLogos: true,
                    isSubmitting: airtableMutation.isLoading,
                  }
                  return (
                    <Form>
                      <FinancialPartners {...caseProps} />
                      <Grid item xs={12} display="flex" justifyContent="center">
                        <LoadingButton
                          type="submit"
                          mode="forward"
                          loading={airtableMutation.isLoading}
                        >
                          {t(
                            'dashboard.offers.createOffer.tenderOffer.learnMore.getAccess.button'
                          )}
                        </LoadingButton>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  )
}

export default TenderOfferLearnMore

import { GenericObject, LabelValuePair } from 'common/types'
import dayjs from 'lib/dayjs'

export const resetFormByKeys = ({
  keys,
  form,
  value = null,
}: {
  keys: string[]
  form: GenericObject
  value?: null
}) => {
  const newFormData = { ...form }
  keys.forEach((key) => (newFormData[key] = value))

  return newFormData
}

export const getDayDifference = (start: string, end: string) => {
  const date1 = dayjs(start)
  const date2 = dayjs(end)
  const hours = date2.diff(date1, 'hours')

  return Math.floor(hours / 24).toString()
}

export const getPercentageOfValueByTotal = ({
  value,
  total,
}: {
  value: number
  total: number
}) => Math.round((value / total) * 100)

export const getLabelByValue = (
  value: string | number,
  list: LabelValuePair[]
) => list.find((item) => item.value === value)?.label as string | number

export const getCompoundedInterestValue = (
  principal: number,
  rate: number,
  time: number
) => {
  const value = principal * Math.pow(1 + rate / 100, time)
  return value
}

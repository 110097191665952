import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import DownloadIcon from '@mui/icons-material/Download'
import Grid from '@mui/material/Grid/Grid'
import ListItem from '@mui/material/ListItem/ListItem'
import Typography from '@mui/material/Typography/Typography'

import { Form, Formik } from 'lib/formik'
import { Company } from 'common/types'
import DownloadFile from 'components/files/DownloadFile'
import Alert from 'components/mui/Alert'
import List from 'components/mui/List'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import RadioInputGroup from 'components/mui/RadioInputGroup'
import { companyUploadedDocumentsSchema } from 'lib/validation'
import { DEFAULT_ADMIN_DASHBOARD_ROUTE } from 'routes/AdminDashboard'

interface CompanyUploadedDocuments {
  documentsComplete?: string
}

const initialValues = {} as CompanyUploadedDocuments

interface Props {
  company: Company
  handleNext: () => void
}

function CheckUploadedDocuments({ company, handleNext }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { companyId } = useParams()

  const handleExit = () =>
    navigate(`${DEFAULT_ADMIN_DASHBOARD_ROUTE}/${companyId}/cap-table`, {
      replace: true,
    })

  const capTableFile = useMemo(
    () =>
      company.files.find(
        (file: { label: string }) => file.label === 'capitalizationTable'
      ),
    [company.files]
  )

  const shareholderInformationFile = useMemo(
    () =>
      company.files.find(
        (file: { label: string }) => file.label === 'shareholderInformation'
      ),
    [company.files]
  )

  const instructions: { step: string; subSteps: string[] }[] = t(
    'openstockAdmin.company.capTable.checkUploadedDocuments.instructions',
    {
      returnObjects: true,
    }
  )

  const radioInputOptions = [
    {
      value: true,
      label: t(
        'openstockAdmin.company.capTable.checkUploadedDocuments.documentsComplete'
      ),
    },
    {
      value: false,
      label: t(
        'openstockAdmin.company.capTable.checkUploadedDocuments.documentsIncomplete'
      ),
    },
  ]

  const companyContact = useMemo(() => {
    if (!capTableFile?.current?.uploadedBy) return null

    const { firstName, lastName, phoneNumber, emailAddress } =
      capTableFile.current.uploadedBy

    return {
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      adminName: `${firstName} ${lastName}`,
    }
  }, [capTableFile])

  return (
    <Grid container rowGap={4}>
      {/* Instructions */}
      <Grid item>
        <Typography variant="h3">
          {t('openstockAdmin.company.capTable.checkUploadedDocuments.heading1')}
        </Typography>
        <List listStyleType="number">
          <ListItem key={instructions[0].step}>
            <Typography>
              <Trans i18nKey={instructions[0].step} />
            </Typography>
            {instructions[0].subSteps && (
              <List listStyleType="lower-alpha">
                {instructions[0].subSteps.map((subStep) => (
                  <ListItem key={subStep}>
                    <Typography>{subStep}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
          <ListItem key={instructions[1].step}>
            <Typography>
              <Trans i18nKey={instructions[1].step} />
            </Typography>
            {instructions[1].subSteps && (
              <List listStyleType="disc">
                {instructions[1].subSteps.map((subStep) => (
                  <ListItem key={subStep}>
                    <Typography>{subStep}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        </List>

        <Alert severity="info" variant="standard">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t(
                'openstockAdmin.company.capTable.checkUploadedDocuments.infoAlert'
              ),
            }}
          />
        </Alert>
      </Grid>

      {/* Downloads */}
      <Grid item container rowGap={2}>
        <Grid item>
          <Typography variant="h3">
            {t(
              'openstockAdmin.company.capTable.checkUploadedDocuments.heading2'
            )}
          </Typography>
        </Grid>
        <Grid container item xs={12} rowGap={1}>
          <DownloadFile
            icon={<DownloadIcon />}
            file={capTableFile}
            title={t('openstockAdmin.company.capTable.capTable')}
          />
        </Grid>
        <Grid container item xs={12} rowGap={1}>
          <DownloadFile
            icon={<DownloadIcon />}
            file={shareholderInformationFile}
            title={t(
              'openstockAdmin.company.capTable.checkUploadedDocuments.shareholderInformation'
            )}
          />
        </Grid>
      </Grid>

      {/* Confirmation */}
      <Grid item container rowGap={2}>
        <Grid item>
          <Typography
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: t(
                'openstockAdmin.company.capTable.checkUploadedDocuments.heading3'
              ),
            }}
          />
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={companyUploadedDocumentsSchema}
          onSubmit={() => undefined}
        >
          {({ values }) => (
            <Form>
              <>
                <Grid container rowGap={4}>
                  <Grid item>
                    <RadioInputGroup
                      name="documentsComplete"
                      id={t(
                        'openstockAdmin.company.capTable.checkUploadedDocuments.radioInputId'
                      )}
                      options={radioInputOptions}
                    />
                  </Grid>
                  <Grid item container rowGap={6}>
                    {values.documentsComplete === 'true' ? (
                      <Grid item>
                        <Button mode="forward" onClick={handleNext}>
                          {t('common.next')}
                        </Button>
                      </Grid>
                    ) : (
                      values.documentsComplete === 'false' && (
                        <>
                          <Grid item>
                            <Alert severity="warning" variant="standard">
                              <Typography>
                                {companyContact ? (
                                  <Trans
                                    i18nKey={t(
                                      'openstockAdmin.company.capTable.checkUploadedDocuments.documentsIncompleteAlert',
                                      {
                                        adminName: companyContact.adminName,
                                        emailAddress:
                                          companyContact.emailAddress,
                                        phoneNumber: companyContact.phoneNumber,
                                      }
                                    )}
                                  >
                                    {`Please contact the Company Admin and have them send completed documents by email or other secure SOC2-compliant file-sharing platform:<br /><br />${companyContact.adminName}<br />`}
                                    <Link
                                      variant="body2"
                                      color="warning.dark"
                                      href={`mailto:${
                                        companyContact.emailAddress
                                      }?subject=${t(
                                        'openstockAdmin.company.capTable.checkUploadedDocuments.emailSubject'
                                      )}`}
                                    >
                                      {companyContact.emailAddress}
                                    </Link>
                                    {`<br />${companyContact.phoneNumber}<br /><br />Once you have received the completed documents, you may return to this process.`}
                                  </Trans>
                                ) : (
                                  t(
                                    'openstockAdmin.company.capTable.checkUploadedDocuments.documentsIncompleteAlertEmpty'
                                  )
                                )}
                              </Typography>
                            </Alert>
                          </Grid>
                          <Grid item>
                            <Button onClick={handleExit}>
                              {t('common.exit')}
                            </Button>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>
                </Grid>
              </>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default CheckUploadedDocuments

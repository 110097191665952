import { useField } from 'formik'

import { BaseTextFieldProps } from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { GenericObject } from 'common/types'
import TextInput from 'components/mui/TextInput'
import theme from 'styles/customTheme'

type Props = BaseTextFieldProps & {
  options: GenericObject[]
  name: string
  label: string
  onChangeCallback?: (value: any) => void
}

function OutlinedSelectInput({
  name,
  label,
  options,
  onChangeCallback,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: any) => {
    const { value } = e.target
    onChangeCallback?.(value)
    helpers.setValue(value)
  }

  const config = {
    ...field,
    ...props,
    value: field.value ?? '',
  }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  return (
    <Grid>
      {label && (
        <Typography variant="h4" mb={1}>
          {label}
        </Typography>
      )}
      <TextInput
        {...config}
        select
        variant="outlined"
        onChange={handleChange}
        sx={{
          mb: 1,
          '.MuiInputBase-root': {
            borderRadius: '6px',
            fontSize: theme.typography.h2,
            color: 'secondary.darkest',
          },
          '.MuiFormHelperText-root': {
            fontSize: theme.typography.subtitle1,
            mx: 0,
          },
          '.MuiOutlinedInput-input': {
            padding: 2,
          },
          ...props.sx,
        }}
      >
        <MenuItem disabled value={''}>
          <Typography variant="placeholder">{props.placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextInput>
    </Grid>
  )
}

export default OutlinedSelectInput

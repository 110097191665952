import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  CaptableShareclass,
  Company,
  OfferTypeConfig,
  DerivedShareclassGroupedTable,
  LabelValuePair,
} from 'common/types'
import { getFinancingAvailable, getInterestAndPlatformFee } from 'lib/data'

export interface LabelValueContainerProps {
  title: string
  fields: LabelValuePair[]
}

export const LabelValueContainer = ({
  details,
  children,
}: {
  details: LabelValueContainerProps
  children?: React.ReactNode
}) => (
  <Grid
    container
    p={2}
    rowGap={2}
    sx={{
      borderRadius: '6px',
      border: 1,
      borderColor: 'secondary.light',
    }}
  >
    <Grid item xs={12}>
      <Typography variant="body2">{details.title}</Typography>
    </Grid>
    <Grid item container rowGap={2} xs={12}>
      {details.fields.map(({ label, value }) => (
        <Grid item key={label} xs={12}>
          <Typography variant="h6" mb={0.5}>
            {label}
          </Typography>
          <Typography>{value}</Typography>
        </Grid>
      ))}
    </Grid>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
)

export const SelectedShareClassInfo = ({
  derivedShareclassGroupedTable,
  selectedShareClasses,
}: {
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  selectedShareClasses: CaptableShareclass['_id'][]
}) => {
  const { t } = useTranslation()

  const filteredShareclassGroupedTable = useMemo(
    () =>
      derivedShareclassGroupedTable.filter(
        (row) => selectedShareClasses.indexOf(row._id) !== -1
      ),
    [derivedShareclassGroupedTable, selectedShareClasses]
  )

  const { totalOutstandingShares, totalValueOfShares, totalShareholders } =
    useMemo(
      () =>
        filteredShareclassGroupedTable.reduce(
          (acc, row) => {
            acc.totalOutstandingShares += row.numberOfShares
            acc.totalValueOfShares += row.totalValueOfShares
            acc.totalShareholders += row.numberOfShareholders
            return acc
          },
          {
            totalOutstandingShares: 0,
            totalValueOfShares: 0,
            totalShareholders: 0,
          }
        ),
      [filteredShareclassGroupedTable]
    )

  return (
    <Box
      p={2}
      sx={{
        border: 1,
        borderRadius: '6px',
        borderColor: 'secondary.light',
      }}
    >
      <Typography variant="body2" mb={1}>
        {t('offer.information.selectedShareInfo.title')}
      </Typography>
      <Typography variant="h6" mb={0.3}>
        {t('offer.information.selectedShareInfo.numOfShareHolders')}
      </Typography>

      {filteredShareclassGroupedTable.map(
        ({ _id, name, numberOfShareholders }) => (
          <Typography key={_id} mb={0.3}>
            {`${name}: ${numberOfShareholders}`}
          </Typography>
        )
      )}

      <Typography variant="body2" mb={3} mt={1}>
        {t('offer.information.selectedShareInfo.total', {
          value: t('common.intlNumberFormat', {
            value: totalShareholders,
          }),
        })}
      </Typography>
      <Typography variant="h6" mb={0.3}>
        {t('offer.information.selectedShareInfo.totalOutstanding')}
      </Typography>

      {filteredShareclassGroupedTable.map(({ _id, name, numberOfShares }) => (
        <Typography key={_id} mb={0.3}>
          {`${name}: ${t('common.intlNumberFormat', {
            value: numberOfShares,
          })}`}
        </Typography>
      ))}

      <Typography variant="body2" mb={3} mt={1}>
        {t('offer.information.selectedShareInfo.total', {
          value: t('common.intlNumberFormat', {
            value: totalOutstandingShares,
          }),
        })}
      </Typography>
      <Typography variant="h6" mb={0.3}>
        {t('offer.information.selectedShareInfo.totalValue')}
      </Typography>

      {filteredShareclassGroupedTable.map(
        ({ _id, name, totalValueOfShares }) => (
          <Typography key={_id} mb={0.3}>
            {`${name}: ${t('common.intlCurrencySimplifiedFormat', {
              value: totalValueOfShares,
            })}`}
          </Typography>
        )
      )}

      <Typography variant="body2" mt={1}>
        {t('offer.information.selectedShareInfo.total', {
          value: t('common.intlCurrencySimplifiedFormat', {
            value: totalValueOfShares,
          }),
        })}
      </Typography>
    </Box>
  )
}

interface Props {
  company: Company
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  hasSelectedShareClassInfo?: boolean
  selectedShareClasses?: CaptableShareclass['_id'][]
  offerTypeConfig: OfferTypeConfig
}

function OfferInformation({
  company,
  derivedShareclassGroupedTable,
  hasSelectedShareClassInfo = false,
  selectedShareClasses,
  offerTypeConfig,
}: Props) {
  const { t } = useTranslation()
  const { showLoanInformation } = offerTypeConfig

  const { financialTermsCurrent } = company

  const interestRate = useMemo(
    () =>
      financialTermsCurrent && getInterestAndPlatformFee(financialTermsCurrent),
    [financialTermsCurrent]
  )

  const totalOutstandingShares = useMemo(
    () =>
      derivedShareclassGroupedTable?.reduce(
        (acc, curr) => acc + curr.numberOfShares,
        0
      ),
    [derivedShareclassGroupedTable]
  )

  const liquidityAvailable = useMemo(
    () =>
      financialTermsCurrent &&
      getFinancingAvailable(
        financialTermsCurrent?.maxCreditAvailable,
        company?.offers
      ),
    [company?.offers, financialTermsCurrent]
  )

  const companyValuation = {
    title: t('offer.information.companyValuationInfo.title'),
    fields: [
      {
        label: t('offer.information.companyValuationInfo.companyValuation'),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: financialTermsCurrent?.companyValuation,
        }),
      },
      {
        label: t('offer.information.companyValuationInfo.totalOutstanding'),
        value: t('common.intlNumberFormat', {
          value: totalOutstandingShares,
        }),
      },
      {
        label: t('offer.information.companyValuationInfo.sharePrice'),
        value: t('offer.information.companyValuationInfo.pricePerShare', {
          value: financialTermsCurrent?.sharePrice,
        }),
      },
    ],
  }

  const loanInformation = {
    title: t('offer.information.loanInfo.title'),
    fields: [
      {
        label: t('offer.information.loanInfo.loanCredit'),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: liquidityAvailable,
        }),
      },
      {
        label: t('offer.information.loanInfo.loanTerm'),
        value: t('common.monthWithCount', {
          count: financialTermsCurrent?.loan.termInMonths,
        }),
      },
      {
        label: t('offer.information.loanInfo.originationFee'),
        value: `${financialTermsCurrent?.originationFee}%`,
      },
      {
        label: t('offer.information.loanInfo.interestRate'),
        value: t('offer.information.loanInfo.interestRateValue', {
          value: interestRate,
        }),
      },
    ],
  }

  return (
    <Grid item xs={12} lg={3} gap={2} mb={4}>
      <Grid item container gap={2}>
        <Grid item xs={12}>
          <LabelValueContainer details={companyValuation} />
        </Grid>
        {showLoanInformation && (
          <Grid item xs={12}>
            <LabelValueContainer details={loanInformation} />
          </Grid>
        )}

        {hasSelectedShareClassInfo && selectedShareClasses && (
          <Grid item xs={12}>
            <SelectedShareClassInfo
              selectedShareClasses={selectedShareClasses}
              derivedShareclassGroupedTable={derivedShareclassGroupedTable}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default OfferInformation

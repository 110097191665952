import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import {
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
} from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'
import { Company } from 'common/types'
import Button from 'components/mui/Button'
import LoanInfoForm from './LoanInfoForm'
import StatusIndicator from 'components/StatusIndicator'

function LoanInfo({ company }: { company: Company }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { isApproved, financialTermsCurrent } = company

  const queryParams = searchParams.get('status')
  const isUpdating = queryParams === 'updating'

  const financialTerms = company.financialTermsCurrent

  const inputs = [
    {
      label: t('openstockAdmin.company.loanInfo.inputs.companyValuation'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: financialTerms?.companyValuation,
      }),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanAmount'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: financialTerms?.maxCreditAvailable,
      }),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanValue'),
      value: `${t('common.intlNumberFormat', {
        value: financialTerms?.loan.valueRatio,
      })}%`,
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.sharePrice'),
      value: t('common.pricePerShare', {
        value: financialTerms?.sharePrice,
      }),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.originationFee'),
      value: `${t('common.intlNumberFormat', {
        value: financialTerms?.originationFee,
      })}%`,
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.platformFee'),
      value: `${t('common.intlNumberFormat', {
        value: financialTerms?.platformFee,
      })}%`,
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanTerm'),
      value: t('common.monthWithCount', {
        count: financialTerms?.loan.termInMonths,
      }),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.startDate'),
      value: dayjs(financialTerms?.startDate).format(
        monthDayFullYearSimplifiedFormat
      ),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.endDate'),
      value: dayjs(financialTerms?.expiryDate).format(
        monthDayFullYearSimplifiedFormat
      ),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanType.label'),
      value:
        financialTerms?.loan.kind &&
        getLabelByValue(financialTerms?.loan.kind, loanTypes),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanStructure.label'),
      value:
        financialTerms?.loan.structure &&
        getLabelByValue(financialTerms?.loan.structure, loanStructures),
    },
    {
      label: t('openstockAdmin.company.loanInfo.inputs.loanInterestRate.label'),
      value: t(
        'openstockAdmin.company.loanInfo.inputs.loanInterestRate.value',
        {
          interestRate: financialTerms?.interestRate,
        }
      ),
    },
    {
      label: t(
        'openstockAdmin.company.loanInfo.inputs.loanRepaymentSchedule.label'
      ),
      value:
        financialTerms?.loan.repaymentSchedule &&
        getLabelByValue(
          financialTerms?.loan.repaymentSchedule,
          loanRepaymentSchedules
        ),
    },
  ]

  const isCompanyApproved = isApproved && financialTermsCurrent

  if (isUpdating) {
    return <LoanInfoForm company={company} />
  }

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid
        item
        container
        columnSpacing={4}
        rowSpacing={{ xs: 2, sm: 0 }}
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h3">
            {t('openstockAdmin.company.loanInfo.heading')}
          </Typography>
        </Grid>
        <Grid item>
          <StatusIndicator
            hasBorder
            label={t(
              `openstockAdmin.company.loanInfo.${
                isCompanyApproved ? 'activeStatus' : 'pendingStatus'
              }`
            )}
            isCapitalizeLabel={false}
            status={isCompanyApproved ? 'ACTIVE' : 'SAVED'}
          />
        </Grid>
        <Grid item ml={{ xs: 0, sm: 3 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate('?status=updating')}
          >
            {t('openstockAdmin.company.loanInfo.button')}
          </Button>
        </Grid>
      </Grid>

      {isCompanyApproved ? (
        <Grid container rowSpacing={2} mt={2}>
          {inputs.map(({ label, value }) => (
            <Grid key={label} item xs={12}>
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item container maxWidth="370px">
          <Typography variant="body2" mb={2}>
            {t('openstockAdmin.company.loanInfo.pending.title')}
          </Typography>
          <Typography>
            {t('openstockAdmin.company.loanInfo.pending.description')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default LoanInfo

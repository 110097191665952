import { useId } from 'react'
import { Trans } from 'react-i18next'

import AccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'

import List from 'components/mui/List'

type Props = AccordionSummaryProps & {
  children?: React.ReactNode
  label: string
  description?: string
  steps?: string[]
  note?: string
}

function Accordion({
  children,
  label,
  description,
  steps,
  note,
  ...props
}: Props) {
  const id = useId()

  return (
    <MuiAccordion
      disableGutters
      sx={{
        '&.Mui-expanded:not(:first-of-type)': {
          borderTop: 0.2,
          borderColor: 'secondary.light',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
        {...props}
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {steps ? (
          <List list={steps} listStyleType="decimal" />
        ) : (
          <Typography>{description}</Typography>
        )}
        {note && (
          <Typography>
            <Trans i18nKey={note} />
          </Typography>
        )}
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion

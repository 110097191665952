import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { LabelValuePair } from 'common/types'
import Paper from 'components/mui/Paper/Paper'

interface Props {
  details: LabelValuePair[]
  title?: string
}

function LabelValueCard({ details, title }: Props) {
  return (
    <Grid item container flexDirection="column" rowSpacing={3}>
      {title && (
        <Grid item>
          <Typography variant="body2">{title}</Typography>
        </Grid>
      )}
      <Grid item>
        <Paper>
          <Grid container rowSpacing={2.5}>
            {details.map(({ label, value }) => (
              <Grid key={label} item xs={12}>
                <Typography variant="h6">{label}</Typography>
                <Typography>{value}</Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default LabelValueCard

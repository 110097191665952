import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import { useSteps } from 'hooks/useSteps'
import { Form, Formik } from 'lib/formik'
import { offeringsInterestsSchema } from 'lib/validation'
import Layout from 'components/Layout/Layout'
import ProductOfferings from './ProductOfferings'
import FinancialPartners from './financialPartners/FinancialPartners'
import { createOfferingsSurveyAirtableRecord } from 'lib/airtable'

const initialValues = {
  productOfferings: [],
  financialPartners: { bank: [], fund: [] },
  otherPartners: '',
}

function OfferingsInterestsQuestions() {
  const { t } = useTranslation()
  const { data } = useUser()
  const navigate = useNavigate()
  const { activeStep, handleBack, handleNext } = useSteps()

  const handleNavigate = () => navigate('/confirmation')

  const airtableMutation = useMutation(
    (values: any) => createOfferingsSurveyAirtableRecord(values),
    { onSettled: () => handleNavigate() }
  )

  const handleSubmit = (values: any) => {
    airtableMutation.mutate({
      userName: `${data.firstName} ${data.lastName}`,
      email: data.emailAddress,
      company: data.company.name,
      formData: values,
    })
  }

  const pages: {
    title: string
    description: string
    instructionHeading: string
    instructionDescription: string
  }[] = t('account.company.offeringsInterestsQuestions.pages', {
    returnObjects: true,
  })

  return (
    <Layout>
      <Grid container rowGap={2} mb={3}>
        <Grid item>
          <Typography variant="h2" component="h1">
            {pages[activeStep].title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography mb={1}>{pages[activeStep].description}</Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography variant="body2">
            {pages[activeStep].instructionHeading}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{pages[activeStep].instructionDescription}</Typography>
        </Grid>
      </Grid>

      <Formik
        initialValues={initialValues}
        validationSchema={offeringsInterestsSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          const caseProps = {
            handleBack,
            handleNext,
            values,
            isSubmitting: airtableMutation.isLoading,
          }
          return (
            // TODO: set condition to prompt on leave if !airtableMutation.isSuccess
            <Form>
              {(() => {
                switch (activeStep) {
                  case 0:
                    return <ProductOfferings {...caseProps} />
                  case 1:
                    return <FinancialPartners {...caseProps} />
                  default:
                    return
                }
              })()}
            </Form>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default OfferingsInterestsQuestions

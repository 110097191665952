import ApexChart from 'react-apexcharts'

import theme from 'styles/customTheme'

interface Props {
  id: string
  chartTitle: string
  colors: string[]
  series: ApexAxisChartSeries
  type?: 'line'
  xAxisData: number[]
  xAxisLabel: string
  xAxisLabelFormatter: (values: number) => string | string[]
  yAxisLabel: string
  yAxisLabelFormatter: (values: number) => string | string[]
  yAxisMaxValue: number
  yAxisMinValue?: number
}

function Chart({
  id,
  chartTitle,
  colors,
  series,
  type = 'line',
  xAxisData,
  xAxisLabel,
  xAxisLabelFormatter,
  yAxisLabel,
  yAxisLabelFormatter,
  yAxisMaxValue,
  yAxisMinValue = 0,
}: Props) {
  return (
    <ApexChart
      options={{
        chart: {
          id,
          background: '#fff',
          fontFamily: theme.typography.fontFamily,
          foreColor: 'secondary.darkest',
          toolbar: {
            show: false,
          },
        },
        colors,
        grid: {
          padding: {
            right: 40,
            left: 40,
          },
        },
        legend: {
          height: 50,
          horizontalAlign: 'center',
          itemMargin: {
            horizontal: 50,
          },
        },
        title: {
          margin: 80,
          offsetX: 50,
          offsetY: 30,
          text: chartTitle,
          style: {
            fontSize: `${theme.typography.h3.fontSize}`,
          },
        },
        tooltip: {
          style: {
            fontSize: `${theme.typography.body1.fontSize}`,
          },
          fixed: {
            enabled: false,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories: xAxisData,
          labels: {
            formatter: (value) => xAxisLabelFormatter(Number(value)),
          },
          tickAmount: 7,
          title: {
            text: xAxisLabel,
            style: {
              fontSize: `${theme.typography.h5.fontSize}`,
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: (value) => yAxisLabelFormatter(value),
            offsetX: 25,
          },
          min: yAxisMinValue,
          max: yAxisMaxValue,
          tickAmount: 6,
          title: {
            offsetX: -15,
            text: yAxisLabel,
            style: {
              fontSize: `${theme.typography.h5.fontSize}`,
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              title: {
                offset: -10,
              },
            },
          },
          {
            breakpoint: 600,
            options: {
              legend: {
                height: 0,
              },
              title: {
                offsetX: 10,
                style: {
                  fontSize: `${theme.typography.h6.fontSize}`,
                },
              },
              tooltip: {
                enabled: false,
              },
              xaxis: {
                title: {
                  style: { fontSize: `${theme.typography.h6.fontSize}` },
                },
              },
              yaxis: {
                labels: {
                  formatter: (value: number) => yAxisLabelFormatter(value),
                  offsetX: 10,
                },
                title: {
                  offsetX: -5,
                  text: yAxisLabel,
                  style: {
                    fontSize: `${theme.typography.h6.fontSize}`,
                  },
                },
              },
            },
          },
          {
            breakpoint: 725,
            options: {
              legend: {
                horizontalAlign: 'left',
                itemMargin: {
                  horizontal: 50,
                },
              },
              title: {
                offsetX: 20,
                style: {
                  fontSize: `${theme.typography.h4.fontSize}`,
                },
              },
              tooltip: {
                style: {
                  fontSize: `${theme.typography.subtitle1.fontSize}`,
                },
                fixed: {
                  enabled: true,
                  position: 'topLeft',
                },
              },
            },
          },
        ],
      }}
      series={series}
      type={type}
      width="99%"
      height="600px"
    />
  )
}

export default Chart

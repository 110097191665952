import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useUser } from 'app/User'
import dataInsightsPreview from 'assets/dataInsightsPreview.png'
import { createDataInsightsWaitlistAirtableRecord } from 'lib/airtable'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import styles from './dataInsights.module.scss'

import AlertDialog from 'components/AlertDialog'
import Layout from 'components/Layout/Layout'
import Button from 'components/mui/Button'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'

function DataInsights() {
  const { t } = useTranslation()
  const { data } = useUser()
  const [hasAccess, setHasAccess] = useState(
    localStorage.getItem('ACCESS_STATUS') === 'true'
  )

  const airtableMutation = useMutation(
    ({
      name,
      email,
      company,
    }: {
      name: string
      email: string
      company: string
    }) => createDataInsightsWaitlistAirtableRecord(name, email, company)
  )

  const handleWaitlistSubmit = () => {
    airtableMutation.mutate({
      name: `${data.firstName} ${data.lastName}`,
      email: data.emailAddress,
      company: data.company.name,
    })
  }

  useEffect(() => {
    // Set  local storage access to true
    if (airtableMutation.isSuccess) {
      localStorage.setItem('ACCESS_STATUS', JSON.stringify(true))
      setHasAccess(true)
    }
  }, [airtableMutation.isSuccess])

  return (
    <Layout maxWidth="lg">
      <Grid container gap={4}>
        <Grid item xs={12} mb={2}>
          <Typography variant="h1">
            {t('dashboard.dataInsights.title')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={7}
          md={5}
          m="auto"
          textAlign="center"
          rowGap={2}
        >
          {hasAccess ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {t('dashboard.dataInsights.hasAccess.heading')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('dashboard.dataInsights.hasAccess.subheading')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Trans i18nKey="dashboard.dataInsights.hasAccess.description">
                    In the meantime, if you need support, please reach out to us
                    any time at
                    <Link
                      href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                        'dashboard.dataInsights.hasAccess.supportEmailSubject'
                      )}`}
                      variant="body2"
                    >
                      {OPENSTOCK_SUPPORT_EMAIL}
                    </Link>
                  </Trans>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button mode="forward" variant="outlined" to="/">
                  {t('dashboard.dataInsights.hasAccess.button')}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {t('dashboard.dataInsights.awaitingAccess.heading')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('dashboard.dataInsights.awaitingAccess.subheading')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t('dashboard.dataInsights.awaitingAccess.description')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={airtableMutation.isLoading}
                  onClick={handleWaitlistSubmit}
                  mode="forward"
                >
                  {t('dashboard.dataInsights.awaitingAccess.button')}
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
        <img
          src={dataInsightsPreview}
          alt=""
          width={1022}
          height={672}
          className={styles.image}
        />
      </Grid>

      <AlertDialog
        isOpen={airtableMutation.isError}
        onClose={() => airtableMutation.reset()}
        description={t('common.errorSubmitForm')}
        primaryButtonAction={() => {
          handleWaitlistSubmit()
        }}
        secondaryButtonAction={() => airtableMutation.reset()}
      />
    </Layout>
  )
}

export default DataInsights

import { Route, Routes } from 'react-router-dom'

import PageNotFound from 'views/PageNotFound'
import ReviewLoanOffer from './ReviewLoanOffer'
import ShareholderReviewAcceptOffer from './ShareholderReviewAcceptOffer'
import ViewAllOffers from './ViewAllOffers'

function ShareholderOffers() {
  return (
    <Routes>
      <Route path="/" element={<ViewAllOffers />} />
      <Route path="/offer/:offerId/review" element={<ReviewLoanOffer />} />
      <Route
        path="/offer/:offerId/explore"
        element={<ShareholderReviewAcceptOffer />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default ShareholderOffers

import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import { forgotPasswordSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Link from 'components/mui/Link'
import TextInput from 'components/mui/TextInput'

import { useAuth } from 'app/Auth'

const initialValues = {
  email: '',
}

function ForgotPassword() {
  const { forgotPasswordMutation } = useAuth()
  const { t } = useTranslation()

  const handleSubmit = (values: typeof initialValues) => {
    forgotPasswordMutation.mutate(values.email)
  }

  return (
    <Layout>
      <Grid container spacing={2} columns={1}>
        <Grid item>
          <Typography align="center" variant="h2" component="h1">
            {t('user.forgot.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={3} columns={1} my={1}>
                  <Grid item xs={1}>
                    <TextInput
                      id="email"
                      name="email"
                      label={t('user.forgot.email')}
                      placeholder={t('user.forgot.emailPlaceholder')}
                      type="email"
                      required
                      inputMode="email"
                    />
                  </Grid>
                  {forgotPasswordMutation.isError && (
                    <Grid item>
                      <Alert title={t('common.error')}>
                        {(forgotPasswordMutation.error as Error).message ??
                          t('common.errorUnknown')}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      loading={
                        forgotPasswordMutation.isLoading ||
                        forgotPasswordMutation.isSuccess
                      }
                      type="submit"
                      mode="forward"
                    >
                      {t('user.forgot.resetPassword')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {t('user.forgot.havingTrouble')}
          </Typography>
          <Typography variant="subtitle2">
            <Trans i18nKey="common.contact">
              Contact us at
              <Link
                href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                  'common.resetEmailSubject'
                )}`}
              >
                partners@getopenstock.com
              </Link>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ForgotPassword

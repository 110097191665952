import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Company } from 'common/types'
import { useGetCompaniesQuery } from 'lib/apollo/hooks'
import EmptyImage from 'assets/empty.svg'
import QueryBoundary from 'app/QueryBoundary'
import CompanyCard from 'components/CompanyCard'
import Layout from 'components/Layout/Layout'

function Companies() {
  const { t } = useTranslation()
  const companiesQuery = useGetCompaniesQuery()
  const { data } = companiesQuery
  const companies = data?.getCompanies

  return (
    <QueryBoundary queries={[companiesQuery]}>
      <Layout maxWidth="lg">
        {/* Heading */}
        <Typography variant="h1" mb={4}>
          {t('openstockAdmin.companies.heading')}
        </Typography>
        {companies?.length > 0 ? (
          <Grid container mb={4}>
            <Typography variant="h5" mb={2}>
              {t('openstockAdmin.companies.subHeading1')}
            </Typography>
            <Grid item container spacing={{ xs: 1, sm: 2 }}>
              {companies.map((company: Company) => (
                <CompanyCard key={company._id} company={company} />
              ))}
            </Grid>
          </Grid>
        ) : (
          <Box
            mt={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" mb={4}>
              {t('openstockAdmin.companies.emptyPageTitle')}
            </Typography>
            <Box>
              <img src={EmptyImage} alt="" width={180} />
            </Box>
          </Box>
        )}
      </Layout>
    </QueryBoundary>
  )
}

export default Companies

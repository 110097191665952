import { useTranslation } from 'react-i18next'

import { ShareholderPaymentInfo } from 'common/types'
import { useFormikContext } from 'lib/formik'
import { getRegionByCountry, priorityCountryList } from 'lib/countryRegion'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { ApproveShareholderOffer } from '../ShareholderReviewAcceptOffer'
import { LabelValueContainer } from 'components/offerings/OfferInformation'
import AutocompleteInput from 'components/mui/AutocompleteInput'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import TextInput from 'components/mui/TextInput'

export const RequestedLoanDetails = () => {
  const { values } = useFormikContext<ApproveShareholderOffer>()
  const { t } = useTranslation()

  const details = {
    title: t(
      'shareholder.offer.nonRecourseLoan.accept.requestedLoanDetails.title'
    ),
    fields: [
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.requestedLoan.label'
        ),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: values.requestedLoanAmount,
        }),
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.stakedShares.label'
        ),
        value: t(
          'shareholder.offer.nonRecourseLoan.details.stakedShares.value',
          {
            value: t('common.intlNumberFormat', {
              value: values.shareholderLoanOfferDetails.stakedShares,
            }),
          }
        ),
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.label'
        ),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: values.shareholderLoanOfferDetails.originationFee,
        }),
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanAmountReceived.label'
        ),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: values.shareholderLoanOfferDetails.loanAmountReceived,
        }),
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.totalInterest.label'
        ),
        value: `${t('common.intlCurrencySimplifiedFormat', {
          value: values.shareholderLoanOfferDetails.totalInterest,
        })}*`,
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.quarterlyInterestOnlyPayments.label'
        ),
        value: `${t('common.intlCurrencySimplifiedFormat', {
          value: values.shareholderLoanOfferDetails.quarterlyPayments,
        })}*`,
      },
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.principalRepayment.label'
        ),
        value: `${t('common.intlCurrencySimplifiedFormat', {
          value: values.shareholderLoanOfferDetails.principalRepayment,
        })}*`,
      },
    ],
  }

  return (
    <LabelValueContainer details={details}>
      <Typography variant="subtitle1" color="secondary.dark">
        {t('shareholder.offer.sofrDisclaimer')}
      </Typography>
    </LabelValueContainer>
  )
}

const ShareholderIdentificationInfoForm = () => {
  const { values, setFieldValue } = useFormikContext<ShareholderPaymentInfo>()
  const { t } = useTranslation()
  const regions = getRegionByCountry(values.country)

  return (
    <Grid item container rowSpacing={3}>
      <Typography variant="h3">
        {t(
          'shareholder.offer.nonRecourseLoan.accept.paymentInformation.identificationInformation'
        )}
      </Typography>
      <Grid item xs={12}>
        <RequiredNote />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="streetAddress"
          name="streetAddress"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.shareholderAddress'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.shareholderAddressPlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="streetAddress2"
          name="streetAddress2"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.apartment'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.apartmentPlaceholder'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="city"
          name="city"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.city'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.cityPlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="country"
          name="country"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.country'
          )}
          placeholder={t('common.selectPlaceholder')}
          options={priorityCountryList}
          onChangeCallback={() => {
            setFieldValue('state', '')
          }}
          inputProps={{
            autoComplete: 'country-name',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="state"
          name="state"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.state'
          )}
          placeholder={t('common.selectPlaceholder')}
          options={regions}
          disabled={!values.country}
          inputProps={{
            autoComplete: 'address-level1',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="zipCode"
          name="zipCode"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.zipCode'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.zipCodePlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="socialSecurityNumber"
          name="socialSecurityNumber"
          type="tel"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.socialSecurityNumber'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.socialSecurityNumberPlaceholder'
          )}
          required
        />
      </Grid>
    </Grid>
  )
}

const BankingInformationForm = () => {
  const { t } = useTranslation()

  return (
    <Grid item container rowSpacing={3}>
      <Grid item mt={4}>
        <Typography variant="h3">
          {t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.heading'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RequiredNote />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          id="bankAccount"
          name="bankAccount"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.bankAccount'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.bankAccountPlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="routingNumber"
          name="routingNumber"
          label={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.routing'
          )}
          placeholder={t(
            'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.routingPlaceholder'
          )}
          required
        />
      </Grid>
    </Grid>
  )
}

interface Props {
  handleBack: () => void
}

function PaymentInformation({ handleBack }: Props) {
  return (
    <>
      <Grid container justifyContent="space-between" rowGap={3}>
        {/* Forms */}
        <Grid
          item
          container
          alignContent="flex-start"
          xs={12}
          smd={6}
          rowGap={3}
        >
          <Grid item container rowSpacing={2} mt={3}>
            <ShareholderIdentificationInfoForm />
            <BankingInformationForm />
          </Grid>
        </Grid>
        {/* Requested loan details */}
        <Grid
          item
          container
          xs={12}
          smd={4}
          mt={{ xs: 4, smd: 8 }}
          alignContent="flex-start"
        >
          <RequestedLoanDetails />
        </Grid>
      </Grid>
      <Grid container smd={6} rowGap={5} mt={5}>
        <Grid item xs={12}>
          <ResponsiveStepperButtons handleBack={handleBack} />
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentInformation

import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid/Grid'
import Layout from 'components/Layout/Layout'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useSteps } from 'hooks/useSteps'
import { useGetCaptableQuery } from 'lib/apollo/hooks'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import theme from 'styles/customTheme'

import BackLink from 'components/BackLink'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import ApproveIngestedCapTable from 'views/ApproveIngestedCapTable'
import ReviewIngestedCapTableForm from 'views/ReviewIngestedCapTable'

function ReviewApproveCapTable() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeStep, handleBack, handleNext } = useSteps()

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery?.data?.getCaptable

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleNavigate = () => navigate(DEFAULT_COMPANY_DASHBOARD_ROUTE)

  const pages: { title: string; description: string }[] = t(
    'dashboard.capTable.review.pages',
    {
      returnObjects: true,
    }
  )

  const steps: string[] = pages.map(({ title }) => title)

  return (
    <Grid container sx={{ flex: 1 }}>
      {isMediumUp && (
        <Grid item xs={12} md={3.5} lg={2.5}>
          <SideBarStepper
            activeStep={activeStep}
            steps={steps}
            title={t('dashboard.capTable.review.title')}
            action={handleNavigate}
            actionTitle={t('common.back')}
          />
        </Grid>
      )}

      <Grid item xs={12} md={8.5} lg={9.5}>
        <Layout maxWidth={activeStep === 0 ? 'lg' : 'sm'} isSideBarStepper>
          {isMediumDown && <BackLink onClick={handleNavigate} />}

          {activeStep === 1 && (
            <>
              <Typography variant="h2" component="h1" mb={5}>
                {pages[activeStep].title}
              </Typography>
              {pages[activeStep].description && (
                <Typography mb={1}>
                  <Trans i18nKey={pages[activeStep].description} />
                </Typography>
              )}
            </>
          )}

          {(() => {
            const caseProps = {
              captableId: captable?._id,
              handleNext,
              handleBack,
              handleNavigate,
            }

            switch (activeStep) {
              case 0:
                return (
                  <ReviewIngestedCapTableForm
                    {...caseProps}
                    title={pages[activeStep].title}
                    description={pages[activeStep].description}
                    subHeading={t('dashboard.capTable.review.subHeading')}
                  />
                )
              case 1:
                return <ApproveIngestedCapTable {...caseProps} />

              default:
                return
            }
          })()}
        </Layout>
      </Grid>
    </Grid>
  )
}

export default ReviewApproveCapTable

import { getAccessTokenJWT } from 'lib/amplify'

const awsEnvironments = {
  be: {
    amplify: {
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_JDeTUxb44',
      aws_user_pools_web_client_id: '32svvu93iq18h6974sl6jen9ku',
    },
    appSync: {
      url: 'https://be.openstockappdev.com/graphql',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => await getAccessTokenJWT(),
      },
      region: 'us-east-1',
    },
    rest: {
      url: 'https://restapibe.openstockappdev.com',
    },
  },
  development: {
    amplify: {
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_I0iV2JTQs',
      aws_user_pools_web_client_id: '33odbdeb75qbcmkundag7djg6j',
    },
    appSync: {
      url: 'https://dev.openstockappdev.com/graphql',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => await getAccessTokenJWT(),
      },
      region: 'us-east-1',
    },
    rest: {
      url: 'https://restapidev.openstockappdev.com',
    },
  },
  staging: {
    amplify: {
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_UtwXIpANm',
      aws_user_pools_web_client_id: '28v5herqsjsmugv869eaus2eot',
    },
    appSync: {
      url: 'https://staging.openstockappdev.com/graphql',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => await getAccessTokenJWT(),
      },
      region: 'us-east-1',
    },
    rest: {
      url: 'https://restapistaging.openstockappdev.com',
    },
  },
  production: {
    amplify: {
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_GkwGk2H1G',
      aws_user_pools_web_client_id: 'lg1fq157jk2s23accrepo062n',
    },
    appSync: {
      url: 'https://prod.openstockappdev.com/graphql',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => await getAccessTokenJWT(),
      },
      region: 'us-east-1',
    },
    rest: {
      url: 'https://restapiprod.openstockappdev.com',
    },
  },
}

const defaultAwsEnvironment = 'staging'

const { REACT_APP_AWS_ENVIRONMENT } = process.env

export const CURRENT_ENVIRONMENT =
  REACT_APP_AWS_ENVIRONMENT === 'be' ||
  REACT_APP_AWS_ENVIRONMENT === 'development' ||
  REACT_APP_AWS_ENVIRONMENT === 'staging' ||
  REACT_APP_AWS_ENVIRONMENT === 'production'
    ? REACT_APP_AWS_ENVIRONMENT
    : defaultAwsEnvironment

const currentAwsEnvironment = awsEnvironments[CURRENT_ENVIRONMENT]

export const AWS_AMPLIFY = currentAwsEnvironment.amplify

//APOLLO
export const APOLLO_CONFIG = currentAwsEnvironment.appSync

//REST
export const REST_CONFIG = currentAwsEnvironment.rest

//UPLOAD DOCUMENTS
export const MAX_FILE_SIZE = 10 * 1024 * 1024 //10mb
export const ALL_SUPPORTED_FILE_TYPES = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
}
export const SPREADSHEET_SUPPORTED_FILE_TYPES = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
}
export const DOC_SUPPORTED_FILE_TYPES = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
}
export const XLSX_SPREADSHEET_SUPPORTED_FILE_TYPES = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
}
export const FILE_EXPIRY_MS = 15 * 60 * 1000 // 15 minutes

//REGEX
//Matches https://company.com, company.com, www.company.com, company-link.company.app
export const REGEX_URL =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?\w+=\w+(&\w+=\w+)*)?$/gm
//Matches to contain atleast one special character (AWS Cognito req)
export const REGEX_SPECIAL_CHAR = /^(?=.*[\^$*.[\]{}?\-“!@#%&/,><':;|_~`])/
//Matches to contain atleast one uppercase and lowercase letter (AWS Cognito req)
export const REGEX_UPPER_LOWERCASE = /^(?=.*[a-z])(?=.*[A-Z])/
//Matches to contain atleast one number (AWS Cognito req)
export const REGEX_CONTAIN_NUMBER = /^(?=.*\d)/
//Matches to contain all numbers and optional decimal
export const REGEX_CONTAIN_NUMBER_OPTIONAL_DECIMAL = /^[1-9]\d*(\.\d+)?$/
//Matches to contain no whitespace
export const REGEX_NO_WHITESPACE = /^\S*$/
//Matches E164 format
export const REGEX_E164_FORMAT = /^\+?\d{10,14}$/
//Removes link protocol
export const REGEX_LINK_PROTOCOL = /^(?:https?:\/\/)?(?:www\.)?/i

// GENERAL
export const SUPPORTED_LANGUAGES = ['en']
export const KEY_USER = 'BACKEND_USER'
export const KEY_NEW_USER = 'NEW_USER'
export const KEY_USER_ACTIVE_ROLE = 'USER_ACTIVE_ROLE'
export const KEY_CALCULATOR_USER_DETAILS = 'CALCULATOR_USER_DETAILS'
export const OPENSTOCK_COMMUNITY_URL = 'https://airtable.com/shrIwqcoPbPqp1Xgg'
export const OPENSTOCK_MARKETING_URL = 'https://www.getopenstock.com'
export const OPENSTOCK_SUPPORT_EMAIL = 'partners@getopenstock.com'

// THIRD PARTY
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ??
  'https://d78a819fe6f7436ebd031b248ab4bb3a@o89929.ingest.sentry.io/6712552'
export const SOFR_RATE_API_URL =
  'https://54gffvh2xve4g4kfythjtk2e4i0oretj.lambda-url.us-east-1.on.aws/'
export const SEND_CALCULATOR_EMAIL_LINK_URL =
  'https://tva43e4uz3.execute-api.us-east-1.amazonaws.com/https/notifications/sendcalculatoremaillink'
export const SEND_CALCULATOR_EMAIL_LINK_API_KEY =
  '631b3f7b4bec34000005009d6d348f23000000'
// Airtable base and write only token for base
export const AIRTABLE_BASE_ID = 'appgH0fillr82dQlY'
export const AIRTABLE_ACCESS_TOKEN =
  process.env.AIRTABLE_ACCESS_TOKEN ??
  'patyXlKbrQMKnnhm2.fdab8c1e411a96fc236a9cecea72eac27b510a4927d866f41eb3f75fdb584e4a'

//FLAGS
export const FEATURE_FLAGS = {
  TEST_UPLOADS: CURRENT_ENVIRONMENT !== 'production',
  TEST_ALERTS: CURRENT_ENVIRONMENT !== 'production',
  LOG_DATA_AIRTABLE: process.env.REACT_APP_LOG_DATA_AIRTABLE
    ? process.env.REACT_APP_LOG_DATA_AIRTABLE === 'true'
    : CURRENT_ENVIRONMENT === 'production',
  DEMO_DATA_INSIGHTS: process.env.REACT_APP_DEMO_DATA_INSIGHTS
    ? process.env.REACT_APP_DEMO_DATA_INSIGHTS === 'true'
    : true,
  DEMO_TENDER_OFFER: process.env.REACT_APP_DEMO_TENDER_OFFER
    ? process.env.REACT_APP_DEMO_TENDER_OFFER === 'true'
    : CURRENT_ENVIRONMENT === 'development',
  DEMO_INVITE_USERS: process.env.REACT_APP_DEMO_INVITE_USERS
    ? process.env.REACT_APP_DEMO_INVITE_USERS === 'true'
    : CURRENT_ENVIRONMENT !== 'production',
  RESET_COMPANY_OFFERS: process.env.REACT_APP_RESET_COMPANY_OFFERS
    ? process.env.REACT_APP_RESET_COMPANY_OFFERS === 'true'
    : CURRENT_ENVIRONMENT !== 'production',
  SIGN_COMPANY_AGREEMENT_LETTER: process.env
    .REACT_APP_SIGN_COMPANY_AGREEMENT_LETTER
    ? process.env.REACT_APP_SIGN_COMPANY_AGREEMENT_LETTER === 'true'
    : CURRENT_ENVIRONMENT !== 'production',
}

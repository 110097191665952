import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Offer } from 'common/types'
import OfferCard from 'components/offerings/CompanyOfferCard'

type Props = {
  offers: Offer[]
  isDisplayHeading?: boolean
  isAllOffers?: boolean
}

function Offerings({
  offers,
  isDisplayHeading = true,
  isAllOffers = true,
}: Props) {
  const { t } = useTranslation()

  const loanOfferings = useMemo(
    () => offers?.filter((offer: Offer) => offer?.kind === 'NON_RECOURSE_LOAN'),
    [offers]
  )

  const tenderOfferings = useMemo(
    () => offers?.filter((offer: Offer) => offer?.kind === 'TENDER_OFFER'),
    [offers]
  )

  const offeringsGroupedByKind = [
    {
      title: t(
        `dashboard.offers.sortOffersTabs.${loanOfferings[0].status.toLowerCase()}`
      ),
      values: loanOfferings,
    },
    {
      title: t('dashboard.offers.tenderOffers'),
      values: tenderOfferings,
    },
  ]

  const offeringsGroupedAll = [
    {
      title: t('openstockAdmin.company.offers.allOffers'),
      values: offers,
    },
  ]

  const offeringsList = isAllOffers
    ? offeringsGroupedAll
    : offeringsGroupedByKind

  return (
    <Grid container columnGap={2} rowSpacing={2} justifyContent="center">
      {offeringsList.map(
        (offering) =>
          offering.values.length > 0 && (
            <Grid key={offering.title} item xs={12}>
              {isDisplayHeading && (
                <Typography variant="h3" component="h2" my={2}>
                  {offering.title}
                </Typography>
              )}
              <Grid container rowSpacing={2}>
                {offering.values.map((offer: Offer) => (
                  <Grid item key={offer._id} xs={12}>
                    <OfferCard offer={offer} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
      )}
    </Grid>
  )
}

export default Offerings

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

// import { optionsCalculatorSchema } from 'lib/validation'
import { GenericObject } from 'common/types'
import { getChartScalingArray } from 'lib/data'
import { optionsAssumedValues } from 'lib/formConfig'
import statesTaxTable from 'lib/taxTable.json'
import Chart from 'components/Chart'
import ResponsiveTable, { HeadCells } from 'components/mui/ResponsiveTable'

const calculateData = (values: any) => {
  const data: any[] = []
  const {
    federalTax,
    federalLtCapitalGains,
    alternativeMinTax,
    annualInterestRate,
    originationFee,
    termInYears,
  } = optionsAssumedValues

  const { incomeTax: stateIncomeTax, capitalGains: stateLtCapitalGains } = (
    statesTaxTable.data as GenericObject
  )[values.state]

  const sharePriceAtSaleArray = getChartScalingArray(
    Number(values.sharePriceAtSale)
  )

  sharePriceAtSaleArray.forEach((sharePrice, i) => {
    // Funding Amount
    const exercisePrice = Number(values.options) * Number(values.strikePrice)

    // Difference between option type NSO (federalTax + stateIncomeTax) vs ISO (alternative min tax) calculation
    const taxDue =
      Number(values.options) *
      (Number(values.fmvAtExercise) - Number(values.strikePrice)) *
      ((values.type === 'NSO'
        ? federalTax + stateIncomeTax
        : alternativeMinTax) /
        100)

    const subtotalFundingAmount = exercisePrice + taxDue
    const calculatedOriginationFee =
      subtotalFundingAmount / (1 - originationFee / 100) - subtotalFundingAmount

    const totalFundingRequired =
      subtotalFundingAmount + calculatedOriginationFee

    //Recipient Cash In
    const upfrontOptionExerciseProceeds = subtotalFundingAmount
    const proceedsOfSale = sharePrice * Number(values.options)
    const totalCashIn = upfrontOptionExerciseProceeds + proceedsOfSale

    // Recipient Cash Out
    const costToExercise = subtotalFundingAmount
    const repaymentOfContract = totalFundingRequired
    const accruedInvestmentReturnPaid =
      Math.pow(1 + annualInterestRate / 100, termInYears) *
        totalFundingRequired -
      totalFundingRequired
    const totalCashOut =
      costToExercise + repaymentOfContract + accruedInvestmentReturnPaid

    // Taxes
    const netTaxPaid = Math.round(
      (proceedsOfSale - Number(values.fmvAtExercise) * Number(values.options)) *
        ((federalLtCapitalGains + stateLtCapitalGains) / 100)
    )

    // Future Simultaneous Exercise and Sale
    const taxesOwedUponExercise =
      Number(values.options) *
      (sharePrice - Number(values.strikePrice)) *
      ((federalTax + stateIncomeTax) / 100)
    // --| Ending Recipient After-Tax Net Worth
    const exercisingToday = totalCashIn - (totalCashOut + netTaxPaid)
    const waitToExercise = Math.round(
      proceedsOfSale - (exercisePrice + taxesOwedUponExercise)
    )

    data[i] = {
      id: 'options-row' + i,
      col1: sharePrice,
      col2: exercisingToday,
      col3: waitToExercise,
      col4: exercisingToday - waitToExercise, // Net Benefit (Cost) of Exercising today vs. Future Cashless Exercise
      col5: Math.round(
        ((exercisingToday - waitToExercise) / waitToExercise) * 100
      ), // Benefit as a %
    }
  })

  return data
}

function OptionsChartTable({
  values,
}: {
  values: any //typeof optionsCalculatorSchema
}) {
  const { t } = useTranslation()

  const calculateDataRows = useMemo(() => calculateData(values), [values])

  const xAxisData = useMemo(
    () => calculateDataRows.map(({ col1 }) => col1),
    [calculateDataRows]
  )
  const series1Data = useMemo(
    () => calculateDataRows.map(({ col2 }) => col2),
    [calculateDataRows]
  )
  const series2Data = useMemo(
    () => calculateDataRows.map(({ col3 }) => col3),
    [calculateDataRows]
  )
  const yAxisMaxValue = series1Data[series1Data.length - 1]

  const headCells: HeadCells = useMemo(
    () => [
      {
        id: 'options-col1',
        label: t('calculator.tab.options.table.tableHeadCell1'),
        width: '15%',
      },
      {
        id: 'options-col2',
        label: t('calculator.tab.options.table.tableHeadCell2'),
        width: '24%',
        headCellBorderColor: '#4964D2',
      },
      {
        id: 'options-col3',
        label: t('calculator.tab.options.table.tableHeadCell3'),
        width: '24%',
        headCellBorderColor: '#D69886',
      },
      {
        id: 'options-col4',
        label: t('calculator.tab.options.table.tableHeadCell4'),
        width: '22%',
      },
      {
        id: 'options-col5',
        label: t('calculator.tab.options.table.tableHeadCell5'),
        width: '15%',
      },
    ],
    [t]
  )

  return (
    <Grid
      item
      container
      justifyContent="center"
      sx={{ border: 1, borderColor: 'secondary.light' }}
    >
      <Grid
        item
        xs={12}
        bgcolor="#fff"
        sx={{ borderBottom: 1, borderColor: 'secondary.light' }}
      >
        <Chart
          id="options-liquidity-calculator-chart"
          chartTitle={t('calculator.tab.options.chart.title')}
          colors={['#4964D2', '#D69886']}
          series={[
            {
              name: t('calculator.tab.options.chart.seriesName1'),
              data: series1Data,
            },
            {
              name: t('calculator.tab.options.chart.seriesName2'),
              data: series2Data,
            },
          ]}
          xAxisData={xAxisData}
          xAxisLabel={t('calculator.tab.options.chart.xAxis')}
          xAxisLabelFormatter={(value: number) =>
            t('common.intlCurrencySimplifiedFormat', { value })
          }
          yAxisLabel={t('calculator.tab.options.chart.yAxis')}
          yAxisLabelFormatter={(value: number) =>
            t('common.intlCurrencySimplifiedFormat', {
              value,
            })
          }
          yAxisMaxValue={Number(yAxisMaxValue)}
        />
      </Grid>

      <ResponsiveTable
        ariaLabel={t('calculator.tab.options.table.ariaLabel')}
        headCells={headCells}
        isPaperComponent={false}
      >
        <TableBody>
          {calculateDataRows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col1,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col3,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: row.col4,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {t('common.percentage', {
                    value: row.col5,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ResponsiveTable>
    </Grid>
  )
}

export default OptionsChartTable

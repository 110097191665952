import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useAuth } from 'app/Auth'
import { Form, Formik } from 'lib/formik'
import { changePasswordSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import TextInput from 'components/mui/TextInput'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

const initialValues = {
  currentPassword: '',
  profilePassword: '',
  profileConfirmPassword: '',
}

function AccountSettings() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changePasswordMutation } = useAuth()

  const handleSubmit = async (
    values: typeof initialValues,
    formik?: FormikHelpers<typeof initialValues>
  ) => {
    await changePasswordMutation.mutateAsync({
      currentPassword: values.currentPassword,
      password: values.profilePassword,
    })

    formik?.setTouched({}, false)
    formik?.resetForm()

    window.scrollTo(0, 0)
  }

  const classifyErrorMessage = (error: Error) => {
    if (!error) return

    if (error.name === 'NotAuthorizedException') {
      return t('accountSettings.changePassword.errors.incorrect')
    }

    return error.message || t('common.errorUnknown')
  }

  return (
    <>
      <Typography variant="h3" component="h2" mb={3}>
        {t('accountSettings.changePassword.title')}
      </Typography>

      {changePasswordMutation.isSuccess && (
        <Grid item xs={12} mb={4}>
          <Alert severity="success" variant="standard">
            <Typography>
              {t('accountSettings.changePassword.success')}
            </Typography>
          </Alert>
        </Grid>
      )}

      <RequiredNote />

      <Formik
        initialValues={initialValues}
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Grid container rowSpacing={3} mt={2}>
              <Grid item xs={12}>
                <TextInput
                  id="current-password"
                  name="currentPassword"
                  label={t('accountSettings.changePassword.currentPassword')}
                  placeholder={t(
                    'accountSettings.changePassword.currentPasswordPlaceholder'
                  )}
                  helperText={
                    <Typography
                      variant="subtitle1"
                      component={'span'}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'accountSettings.changePassword.currentPasswordHelperText'
                        ),
                      }}
                    />
                  }
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="profile-password"
                  name="profilePassword"
                  label={t('accountSettings.changePassword.newPassword')}
                  placeholder={t(
                    'accountSettings.changePassword.newPasswordPlaceholder'
                  )}
                  helperText={
                    <Typography
                      variant="subtitle1"
                      component={'span'}
                      dangerouslySetInnerHTML={{
                        __html: t('common.passwordHelperText'),
                      }}
                    />
                  }
                  type="password"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="profile-confirm-password"
                  name="profileConfirmPassword"
                  label={t('accountSettings.changePassword.confirmNewPassword')}
                  placeholder={t(
                    'accountSettings.changePassword.newPasswordPlaceholder'
                  )}
                  type="password"
                  required
                />
              </Grid>
              {changePasswordMutation.isError && (
                <Grid item>
                  <Alert title={t('common.error')}>
                    {classifyErrorMessage(changePasswordMutation.error)}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid smd={6} rowGap={5} mt={5}>
              <Grid item xs={12}>
                <ResponsiveStepperButtons
                  handleBack={() => navigate('/')}
                  isLastStep
                  isSubmitting={changePasswordMutation.isLoading}
                  nextStepText={t(
                    'accountSettings.changePassword.submitButton'
                  )}
                  previousStepText={t(
                    'accountSettings.changePassword.backButton'
                  )}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AccountSettings

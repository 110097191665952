import { useEffect, useState } from 'react'
import { useField } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, {
  BaseTextFieldProps,
} from '@mui/material/TextField/TextField'

type Props = Omit<
  BaseTextFieldProps,
  'value' | 'onChange' | 'onBlur' | 'onFocus'
> & {
  onChangeCallback?: () => void
  options: string[]
  name: string
}

function AutocompleteInput({
  id,
  disabled = false,
  onChangeCallback,
  name,
  options,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(name)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isAutocomplete, setIsAutocomplete] = useState(false)

  const { value } = field
  const { setValue } = helpers

  const config = { ...props }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  const handleOnChange = (_event: any, newValue: any) => {
    onChangeCallback?.()

    setValue(newValue)
  }

  const handleInputValueChange = (_event: any, newValue: any) => {
    // Trigger autocomplete side effect
    // This will capture most autofill's, but likely not all
    if (value === '' && inputValue === '' && options.includes(newValue)) {
      setValue(newValue)
      setIsAutocomplete(true)
    }

    setInputValue(newValue)
  }

  useEffect(() => {
    // By default inputValue changes will open the autocomplete
    // Override this when autocomplete happened
    if (isAutocomplete && isOpen) {
      setIsAutocomplete(false)
      setIsOpen(false)
    }
  }, [isAutocomplete, isOpen])

  return (
    <Autocomplete
      open={isOpen && !isAutocomplete}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      autoHighlight
      autoSelect
      disabled={disabled}
      id={id}
      options={options}
      // value and onChange represent actual form values
      value={value || null}
      onChange={handleOnChange}
      // inputValue and onInputChange represent temporary state (typing autocomplete)
      inputValue={inputValue}
      onInputChange={handleInputValueChange}
      renderInput={(params) => (
        <TextField
          {...config}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...props.inputProps,
          }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
        />
      )}
    />
  )
}

export default AutocompleteInput

import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'
import { Role } from 'common/types'
import PermissionDenied from 'views/PermissionDenied'

interface Props {
  children: JSX.Element
  invert?: boolean
  permittedRoles?: Role[]
}

export function ProtectedRoute({
  children,
  invert = false,
  permittedRoles,
}: Props) {
  const { isLoggedIn, redirectPathOnAuth, dispatch } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const {
    data,
    roles: { userRoles },
  } = useUser()
  const company = data?.company

  const userIsPermitted =
    !permittedRoles || permittedRoles?.some((role) => userRoles?.includes(role))

  useEffect(() => {
    // Track origin state so we can redirect after login, we do this through context since they may visit multiple urls (email validation) before ready for redirect
    if (!isLoggedIn && !invert) {
      return dispatch({ type: 'set_redirect', payload: location.pathname })
    }

    if (isLoggedIn && invert) {
      return dispatch({ type: 'set_redirect', payload: null })
    }
  }, [
    company,
    dispatch,
    invert,
    isLoggedIn,
    location.pathname,
    navigate,
    redirectPathOnAuth,
  ])

  if (!isLoggedIn && !invert) {
    return <Navigate to="/login" replace />
  }

  if (isLoggedIn && invert) {
    return <Navigate to={redirectPathOnAuth ?? '/'} replace />
  }

  if (!userIsPermitted && !invert) {
    return <PermissionDenied />
  }

  return children
}

import { useMemo } from 'react'
import i18n from 'locale/i18n'
import { useTranslation } from 'react-i18next'

import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import {
  CaptableShareclass,
  CaptableShareholderGroupedTableRow,
  DerivedShareclassGroupedTable,
} from 'common/types'
import { useGetCaptableQuery } from 'lib/apollo/hooks'
import { getDerivedShareclassGroupedTable } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

import QueryBoundary from 'app/QueryBoundary'
import ResponsiveTable, {
  HeadCells,
  ResponsiveTableFooter,
  ResponsiveTableRow,
} from 'components/mui/ResponsiveTable'

const headCells: HeadCells = [
  {
    id: 'shareholders',
    label: i18n.t('ingestedCapTable.review.table.tableHeadCell1'),
  },
  {
    id: 'employmentStatus',
    label: i18n.t('ingestedCapTable.review.table.tableHeadCell2'),
  },
  {
    id: 'email',
    label: i18n.t('ingestedCapTable.review.table.tableHeadCell3'),
  },
  {
    id: 'outstandingShares',
    label: i18n.t('ingestedCapTable.review.table.tableHeadCellLast'),
    backgroundColor: 'primary.lightest',
  },
]

const getHeadCells = ({
  shareclasses,
  headCells,
}: {
  shareclasses: CaptableShareclass[]
  headCells: HeadCells
}) => {
  const shareClassCells = shareclasses.map((shareclass) => ({
    id: `shareClass-${shareclass.name}`,
    label: shareclass.name,
    width: '5%',
  }))

  const allHeadCells = [...headCells]
  allHeadCells.splice(-1, 0, ...shareClassCells)

  return allHeadCells
}

function CapTableRow({ row }: { row: CaptableShareholderGroupedTableRow }) {
  const { t } = useTranslation()
  const { shareholder, columns } = row

  const rowTotal = columns.reduce(
    (acc, curr) => acc + (curr !== null ? curr.number : 0),
    0
  )

  return (
    <ResponsiveTableRow>
      <TableCell component="th" id={shareholder._id} scope="row">
        <Typography>{shareholder.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {shareholder.isEmployee
            ? t('ingestedCapTable.review.table.currentEmployee')
            : t('ingestedCapTable.review.table.nonEmployee')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{shareholder.emailAddress}</Typography>
      </TableCell>
      {columns?.map((column, index) => (
        <TableCell key={index}>
          {column?.number && (
            <Typography>
              {t('common.intlNumberFormat', {
                value: column?.number,
              })}
            </Typography>
          )}
        </TableCell>
      ))}
      <TableCell sx={{ backgroundColor: 'primary.lightest' }}>
        <Typography>
          {t('common.intlNumberFormat', {
            value: rowTotal,
          })}
        </Typography>
      </TableCell>
    </ResponsiveTableRow>
  )
}

function IngestedCapTable({
  maxHeight,
  showProcessedDate = false,
  captableId,
}: {
  maxHeight?: number
  showProcessedDate?: boolean
  captableId?: string
}) {
  const { t } = useTranslation()

  const captableQuery = useGetCaptableQuery(captableId)
  const captable = captableQuery?.data?.getCaptable

  const shareholderGroupedTable = captable?.shareholderGroupedTable

  const shareclasses = shareholderGroupedTable?.shareclasses
  const rows = shareholderGroupedTable?.rows

  const allHeadCells = useMemo(
    () => shareclasses && getHeadCells({ shareclasses, headCells }),
    [shareclasses]
  )

  const derivedShareclassGroupedTable: DerivedShareclassGroupedTable = useMemo(
    () =>
      shareholderGroupedTable &&
      getDerivedShareclassGroupedTable({
        shareholderGroupedTable,
      }),
    [shareholderGroupedTable]
  )

  const totalSharesOutstanding = useMemo(
    () =>
      derivedShareclassGroupedTable?.reduce(
        (acc, curr) => acc + curr.numberOfShares,
        0
      ),
    [derivedShareclassGroupedTable]
  )

  return (
    <QueryBoundary queries={[captableQuery]}>
      <>
        <ResponsiveTable
          ariaLabel={t('ingestedCapTable.review.table.ariaLabel')}
          headCells={allHeadCells}
          maxHeight={maxHeight}
        >
          <TableBody>
            {rows?.map((row: CaptableShareholderGroupedTableRow) => (
              <CapTableRow key={row.shareholder._id} row={row} />
            ))}
          </TableBody>

          <ResponsiveTableFooter>
            <TableCell colSpan={3}>
              <Typography variant="body2" color="secondary.darkest">
                {t('ingestedCapTable.review.table.tableFooter')}
              </Typography>
            </TableCell>
            {derivedShareclassGroupedTable?.map(({ name, numberOfShares }) => (
              <TableCell key={name}>
                <Typography variant="body2" color="secondary.darkest">
                  {t('common.intlNumberFormat', {
                    value: numberOfShares,
                  })}
                </Typography>
              </TableCell>
            ))}
            <TableCell sx={{ backgroundColor: 'primary.lightest' }}>
              <Typography variant="body2" color="secondary.darkest">
                {t('common.intlNumberFormat', {
                  value: totalSharesOutstanding,
                })}
              </Typography>
            </TableCell>
          </ResponsiveTableFooter>
        </ResponsiveTable>
        {showProcessedDate && (
          <Typography
            variant="subtitle1"
            color="secondary.dark"
            mt={2}
            dangerouslySetInnerHTML={{
              __html: t('common.processedDate', {
                date: dayjs(captable?.createdAt).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }),
            }}
          />
        )}
      </>
    </QueryBoundary>
  )
}

export default IngestedCapTable

import { useTranslation } from 'react-i18next'
import { useUnmount } from 'react-use'

import Box from '@mui/material/Box'
// import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ReactComponent as OpenstockLogo } from 'assets/OpenstockLogo.svg'

import { Form, Formik } from 'lib/formik'
import { useAuth } from 'app/Auth'
import { LoginDetails } from 'common/types'
import { OPENSTOCK_COMMUNITY_URL } from 'lib/config'
import { loginDetailsSchema } from 'lib/validation'
import Button from 'components/mui/Button'
import LoadingButton from 'components/mui/LoadingButton'
// import CheckboxInput from 'components/mui/CheckboxInput'
import Container from 'components/mui/Container/Container'
import Link from 'components/mui/Link'
import TextInput from 'components/mui/TextInput'
import Alert from 'components/mui/Alert'
import styles from './login.module.scss'
import theme from 'styles/customTheme'

const initialValues = {
  email: '',
  password: '',
} as LoginDetails

function Login() {
  const { t } = useTranslation()
  const { loginMutation, sessionStatus, dispatch } = useAuth()

  const handleSubmit = (values: typeof initialValues) => {
    loginMutation.mutate(values)
  }

  // Reset session status on unmount
  useUnmount(
    () =>
      sessionStatus !== null &&
      dispatch({ type: 'set_session_status', payload: null })
  )

  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container maxWidth="lg">
      <Grid container className={styles.gridContainer}>
        {/* Login form */}
        <Grid item sm={6} display="flex" justifyContent="center">
          <Box
            sx={{
              maxWidth: { smd: 300 },
              mx: { sm: 1, smd: 3 },
              mt: { xs: 4, sm: 10 },
            }}
          >
            <Typography variant="h2" mt={5} mb={5}>
              {t('user.login.logInOpenstock')}
            </Typography>
            {sessionStatus === 'expired' && (
              <Alert
                severity="warning"
                title={t('user.login.sessionExpiredTitle')}
                sx={{ mb: 3 }}
                icon={false}
              >
                {t('user.login.sessionExpired')}
              </Alert>
            )}
            {/* TODO: Enable fenderated login */}
            {/* <Button variant="outlined" fullWidth sx={{ borderRadius: '10px' }}>
              {t('user.login.signInGoogle')}
            </Button>
            <Divider className={styles.divider} sx={{ my: 4 }}>
              {t('user.login.or')}
            </Divider> */}
            <Formik
              initialValues={initialValues}
              validationSchema={loginDetailsSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <TextInput
                        id="email"
                        name="email"
                        label={t('user.login.email')}
                        placeholder={t('user.login.emailPlaceholder')}
                        type="email"
                        required
                        inputMode="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        id="password"
                        name="password"
                        label={t('user.login.password')}
                        placeholder={t('user.login.passwordPlaceholder')}
                        type="password"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/* TODO: Build/enable remember me functionality, cognito remembers by default so this is extra functionality
                      <CheckboxInput
                        name="rememberMe"
                        label={
                          <Typography variant="h5">
                            {t('user.login.rememberMe')}
                          </Typography>
                        }
                      />*/}
                    </Grid>
                    {loginMutation.isError && (
                      <Grid item xs={12}>
                        <Alert title={t('common.error')} sx={{ mb: 1.5 }}>
                          {(loginMutation.error as Error).message ??
                            t('common.errorUnknown')}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoadingButton
                        type="submit"
                        loading={
                          loginMutation.isLoading || loginMutation.isSuccess
                        }
                      >
                        {t('user.login.logIn')}
                      </LoadingButton>
                      <Button variant="text" to="/login/create" sx={{ ml: 2 }}>
                        {t('user.login.signUp')}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Grid container rowSpacing={2} mt={1}>
              <Grid item xs={12}>
                <Link to="/login/forgot" variant="subtitle2">
                  {t('user.login.forgotPassword')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            className={styles.sideContainer}
            sx={{
              mx: { sm: 1, smd: 3 },
              my: { xs: 3 },
              height: { sm: 'calc(100% - 3rem)' },
            }}
          >
            <Grid
              container
              columns={{ xs: 2, sm: 1 }}
              alignItems={'left'}
              p={2}
            >
              <Grid item xs={2}>
                <OpenstockLogo />
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant="h1"
                  color="white"
                  my={4}
                  sx={{ fontWeight: 400 }}
                >
                  {t('user.login.header')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  isLightHover
                  mode={isSmallDown ? undefined : 'forward'}
                  sx={{ py: 1, mb: { xs: 8, sm: 0 } }}
                  href={OPENSTOCK_COMMUNITY_URL}
                >
                  {t('user.login.joinCommunity')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Login

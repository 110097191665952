import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  Company,
  CreateOfferMultiForms,
  OfferTypeConfig,
  CaptableShareholderGroupedTable,
  DerivedShareclassGroupedTable,
  ShareholderShareInfo,
} from 'common/types'
import {
  getFinancingAvailable,
  getIneligibleShareholders,
  getShareclassesByIds,
  getShareholderShareInfo,
} from 'lib/data'
import { maximumLoanAmount, minimumLoanAmount } from 'lib/formConfig'
import Alert from 'components/mui/Alert'
import LoanOfferAmount from 'components/offerings/LoanOfferAmount'
import OfferInformation from 'components/offerings/OfferInformation'
import PercentageSlider from 'components/PercentageSlider'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import SelectShareholdersTable from 'components/tables/SelectShareholdersTable'
import TenderOfferAmount from 'components/offerings/TenderOfferAmount'
import List from 'components/mui/List'

interface Props {
  company: Company
  shareholderGroupedTable: CaptableShareholderGroupedTable
  derivedShareclassGroupedTable: DerivedShareclassGroupedTable
  selectedEligibleShareholders: ShareholderShareInfo
  setSelectedEligibleShareholders: (shareholders: ShareholderShareInfo) => void
  handleBack: () => void
  values: CreateOfferMultiForms
  offerTypeConfig: OfferTypeConfig
}

function CalculateLiquidity({
  company,
  shareholderGroupedTable,
  derivedShareclassGroupedTable,
  selectedEligibleShareholders,
  setSelectedEligibleShareholders,
  handleBack,
  offerTypeConfig,
  values,
}: Props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()

  const offerConstraints: string[] = t(
    'offer.calculateLiquidity.offerConstraintsList',
    {
      minLoanAmount: minimumLoanAmount,
      maxLoanAmount: maximumLoanAmount,
      returnObjects: true,
    }
  )

  // Set form values to shareholders from previous step that are selected & eligible
  useEffect(() => {
    setFieldValue('shareholders', selectedEligibleShareholders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { offerTypeTranslationKey, type } = offerTypeConfig
  const percentageOfAssets = values.percentageOfAssets
  const maxCreditAvailable = company.financialTermsCurrent?.maxCreditAvailable

  const shareholderShareInfo = useMemo(
    () =>
      getShareholderShareInfo({
        shareclassIds: values.shareclasses,
        shareholderGroupedTable,
        sharePrice: company?.financialTermsCurrent?.sharePrice,
      }),
    [
      company?.financialTermsCurrent?.sharePrice,
      shareholderGroupedTable,
      values.shareclasses,
    ]
  )

  const { totalShares, totalSharesByPercentage } = useMemo(
    () =>
      selectedEligibleShareholders.reduce(
        (acc, value) => {
          // Total number of staked shares based on shareholders selected/deselected in the table
          acc.totalShares += value.shares.maxAvailableShares

          // Total shares available based on shareholders selected AND the percentage slider
          acc.totalSharesByPercentage +=
            value.shares.maxAvailableShares * (percentageOfAssets / 100)

          return acc
        },
        {
          totalShares: 0,
          totalSharesByPercentage: 0,
        }
      ),
    [percentageOfAssets, selectedEligibleShareholders]
  )

  // Total liquidity offered based on shareholders selected AND the percentage slider
  const totalLiquidity = useMemo(
    () =>
      totalSharesByPercentage *
      (company?.financialTermsCurrent?.sharePrice ?? 0),
    [company?.financialTermsCurrent?.sharePrice, totalSharesByPercentage]
  )

  const selectedShareclasses = useMemo(
    () =>
      getShareclassesByIds({
        shareclasses: shareholderGroupedTable.shareclasses,
        shareclassIds: values.shareclasses,
      }),
    [shareholderGroupedTable.shareclasses, values.shareclasses]
  )

  const liquidityAvailable = useMemo(
    () =>
      maxCreditAvailable
        ? getFinancingAvailable(maxCreditAvailable, company?.offers)
        : 0,
    [company?.offers, maxCreditAvailable]
  )

  const isLiquidityOfferedExceedsLoanAmount =
    liquidityAvailable < totalLiquidity

  const ineligibleShareholderIds = getIneligibleShareholders(
    shareholderShareInfo,
    percentageOfAssets
  ).map((shareholder) => shareholder.shareholder._id)

  useEffect(() => {
    // On percentage change, update selectedEligible to use for calculations
    const newSelected = values.shareholders.filter(
      (shareholderId) =>
        !ineligibleShareholderIds.includes(shareholderId.shareholder._id)
    )

    setSelectedEligibleShareholders(newSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentageOfAssets, values.shareholders])

  useEffect(() => {
    setFieldValue('totalLiquidity', totalLiquidity)
  }, [setFieldValue, totalLiquidity])

  return (
    <Grid container spacing={2}>
      {/* Shareholders */}
      <Grid item container alignContent="start" xs={12} lg={9} rowGap={5}>
        <Grid item container rowGap={2}>
          <Grid item>
            <Typography variant="h3">
              {t('offer.calculateLiquidity.heading1')}
            </Typography>
            <Typography variant="body2" mt={2} mb={1}>
              {selectedShareclasses.map(({ _id, name }, index) => (
                <span key={_id}>
                  {name}
                  {index < values.shareclasses.length - 1 ? ', ' : ''}{' '}
                </span>
              ))}
            </Typography>
            <Typography>{t('offer.calculateLiquidity.subHeading1')}</Typography>
            <Typography mt={2}>
              {t('offer.calculateLiquidity.offerConstraints')}
            </Typography>
            <List list={offerConstraints} isCondensedList />
          </Grid>
          <Grid item>
            <SelectShareholdersTable
              shareholderShareInfo={shareholderShareInfo}
              ineligibleShareholderIds={ineligibleShareholderIds}
              values={values}
            />
          </Grid>
        </Grid>

        {/* Percentage of assets */}
        <Grid item xs={12}>
          <Typography variant="h3">
            {t(`offer.calculateLiquidity.${offerTypeTranslationKey}.heading2`)}
          </Typography>

          <Typography mt={2} mb={3}>
            {t(
              `offer.calculateLiquidity.${offerTypeTranslationKey}.subHeading2`
            )}
          </Typography>
          <PercentageSlider
            name="percentageOfAssets"
            aria-label={t(
              `offer.calculateLiquidity.${offerTypeTranslationKey}.heading2`
            )}
            value={percentageOfAssets}
            maxValue={
              type === 'NON_RECOURSE_LOAN'
                ? company.financialTermsCurrent?.loan?.valueRatio
                : 100
            }
          />
          {/* Ineligible shareholders warning */}
          {ineligibleShareholderIds.length > 0 && (
            <Alert
              variant="standard"
              severity="warning"
              icon={<HighlightOffIcon />}
              sx={{ mt: 2 }}
            >
              <Typography>
                {t(
                  `offer.calculateLiquidity.ineligibleShareholderWarning${
                    ineligibleShareholderIds.length === 1 ? '_one' : '_other'
                  }`,
                  {
                    value: ineligibleShareholderIds.length,
                    minLoanAmount: minimumLoanAmount,
                  }
                )}
              </Typography>
            </Alert>
          )}
        </Grid>

        {/* Loan amount */}
        <Grid item xs={12} mb={6}>
          <Typography variant="h3">
            {t(`offer.calculateLiquidity.${offerTypeTranslationKey}.heading3`)}
          </Typography>
          <Typography mt={2} mb={3}>
            {t(
              `offer.calculateLiquidity.${offerTypeTranslationKey}.subHeading3`
            )}
          </Typography>
          {type === 'NON_RECOURSE_LOAN' && (
            <>
              <LoanOfferAmount
                totalLiquidity={totalLiquidity}
                totalShares={totalShares}
              />
              {isLiquidityOfferedExceedsLoanAmount && (
                <Grid item container justifyContent="flex-end" mt={2}>
                  <Grid item xs={12} sm={5.8}>
                    <Alert variant="standard" icon={<WarningAmberIcon />}>
                      <Typography>
                        {t(
                          `offer.calculateLiquidity.${offerTypeTranslationKey}.loanAmount.amountExceeded`,
                          {
                            value: liquidityAvailable,
                          }
                        )}
                      </Typography>
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {type === 'TENDER_OFFER' && (
            <TenderOfferAmount
              totalLiquidity={totalLiquidity}
              totalShares={totalSharesByPercentage}
            />
          )}
        </Grid>
      </Grid>

      {/* Sidebar information */}
      <OfferInformation
        company={company}
        hasSelectedShareClassInfo
        derivedShareclassGroupedTable={derivedShareclassGroupedTable}
        selectedShareClasses={values.shareclasses}
        offerTypeConfig={offerTypeConfig}
      />

      <Grid item container xs={12} lg={9}>
        <ResponsiveStepperButtons
          handleBack={handleBack}
          isForwardDisabled={
            values.shareholders.length === 0 ||
            isLiquidityOfferedExceedsLoanAmount === true ||
            totalLiquidity === 0
          }
        />
      </Grid>
    </Grid>
  )
}

export default CalculateLiquidity

import * as yup from 'yup'
import i18n from 'i18next'

import {
  REGEX_URL,
  REGEX_SPECIAL_CHAR,
  REGEX_UPPER_LOWERCASE,
  REGEX_CONTAIN_NUMBER,
  REGEX_E164_FORMAT,
  REGEX_NO_WHITESPACE,
} from 'lib/config'
import dayjs from 'lib/dayjs'

export const loginDetailsSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
  password: yup.string().required(i18n.t('common.validation.passwordRequired')),
})

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
})

export const resetPasswordSchema = yup.object({
  code: yup
    .string()
    .trim()
    .required(i18n.t('account.verification.verificationCodeRequired')),
  profilePassword: yup
    .string()
    .required(i18n.t('account.verification.passwordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber')),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
})

export const changePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .required(i18n.t('accountSettings.changePassword.currentPasswordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace')),
  profilePassword: yup
    .string()
    .required(i18n.t('accountSettings.changePassword.newPasswordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace'))
    .notOneOf(
      [yup.ref('currentPassword')],
      i18n.t('accountSettings.changePassword.cannotMatchCurrentPassword')
    ),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
})

export const profileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.firstNameRequired')),
  lastName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.lastNameRequired')),
  profilePassword: yup
    .string()
    .required(i18n.t('account.profile.labels.passwordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace')),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
  workEmail: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
  mobileNumber: yup
    .string()
    .matches(
      REGEX_E164_FORMAT,
      i18n.t('account.profile.labels.mobileNumberInvalid')
    )
    .required(i18n.t('common.validation.phoneNumberRequired')),
})

export const verificationCodeDetailsSchema = yup.object({
  code: yup
    .string()
    .trim()
    .min(6, i18n.t('account.verification.exactCodeLength'))
    .max(6, i18n.t('account.verification.exactCodeLength'))
    .required(i18n.t('account.verification.verificationCodeRequired')),
})

export const companyDetailsSchema = yup.object({
  isSigningOfficer: yup
    .boolean()
    .nullable()
    .required(i18n.t('common.required')),
  name: yup
    .string()
    .trim()
    .required(i18n.t('account.company.labels.nameRequired')),
  websiteAddress: yup
    .string()
    .trim()
    .matches(REGEX_URL, i18n.t('account.company.labels.websiteAddressInvalid')),
  streetAddress: yup
    .string()
    .trim()
    .required(i18n.t('account.company.labels.streetAddressRequired')),
  streetAddress2: yup.string().trim(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.zipCodeRequired')),
  ein: yup.string().trim(),
  countryOfIncorporation: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  stateOfIncorporation: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
})

export const companyAgreementLetterSchema = yup.object({
  signedDate: yup.string().required(i18n.t('common.required')),
  companyName: yup.string().required(i18n.t('common.required')),
  companySigneeSignature: yup.string().required(i18n.t('common.required')),
  companySigneeName: yup.string().required(i18n.t('common.required')),
  companySigneeTitle: yup.string().required(i18n.t('common.required')),
  hasIncorporationDetails: yup.bool(),
  companyCountryOfIncorporation: yup.string().when('hasIncorporationDetails', {
    is: true,
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .nullable()
      .required(
        i18n.t(
          'account.company.agreementLetter.forms.countryOfIncorporationPlaceholder'
        )
      ),
  }),
  companyStateOfIncorporation: yup.string().when('hasIncorporationDetails', {
    is: true,
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .nullable()
      .required(
        i18n.t(
          'account.company.agreementLetter.forms.stateOfIncorporationPlaceholder'
        )
      ),
  }),
})

export const offeringsInterestsSchema = yup.object({
  productOfferings: yup.array(yup.string()),
  financialPartners: yup.object({
    bank: yup.array(yup.string()),
    fund: yup.array(yup.string()),
  }),
  otherPartners: yup.string(),
})

export const tenderOfferWaitlistSchema = yup.object({
  financialPartners: yup.object({
    bank: yup.array(yup.string()),
    fund: yup.array(yup.string()),
  }),
  otherPartners: yup.string(),
})

export const recentCapTableSchema = () =>
  yup.object().shape({
    isRecentCapTable: yup
      .bool()
      .oneOf(
        [true],
        i18n.t('dashboard.offers.createOffer.setup.checkboxRequired')
      ),
  })

export const selectedShareClassSchema = yup.object({
  type: yup.string(),
  shareclasses: yup.array().min(1).required(i18n.t('common.required')),
})

export const calculateLiquiditySchema = yup.object({
  shareholders: yup.array().min(1).required(i18n.t('common.required')),
  percentageOfAssets: yup.number().required(),
  totalShares: yup.number(),
  totalLiquidity: yup.number(),
})

export const uploadDocumentsSchema = yup.object({
  companyOverviewFile: yup.mixed(),
  financialStatementsFile: yup.mixed(),
  historyOfValuationsFile: yup.mixed(),
  listOfPrimaryInvestorsFile: yup.mixed(),
  employeeOutstandingFile: yup.mixed(),
  recent409AValuationFile: yup.mixed(),
})

export const companyUploadedDocumentsSchema = () =>
  yup.object().shape({
    documentsComplete: yup
      .bool()
      .required(i18n.t('common.required'))
      .oneOf([true, false]),
  })

export const companyAdminUploadCapTableSchema = yup.object({
  capitalizationTable: yup
    .mixed()
    .required(
      i18n.t('dashboard.capTable.import.uploadCapTable.capTableRequired')
    ),
})

export const uploadIngestionSpreadsheetSchema = () =>
  yup.object().shape({
    ingestionSpreadsheet: yup.mixed().required(i18n.t('common.required')),
  })

export const shareholderInformationSchema = yup.object({
  shareholderInformation: yup.mixed().required(i18n.t('common.required')),
})

export const loanInfoSchema = () =>
  yup.object().shape({
    isApproved: yup.string(),
    // Conditional validations when isApproved is 'false'
    companyValuation: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t(
            'openstockAdmin.company.loanInfo.inputs.companyValuationRequired'
          )
        ),
    }),

    loanAmount: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.loanAmountRequired')
        ),
    }),
    loanValue: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.loanValueRequired')
        ),
    }),
    sharePrice: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.sharePriceRequired')
        ),
    }),
    originationFee: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t(
            'openstockAdmin.company.loanInfo.inputs.originationFeeRequired'
          )
        ),
    }),
    platformFee: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.platformFeeRequired')
        ),
    }),
    loanTermInMonths: yup.number().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .typeError(i18n.t('common.numberInvalid'))
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.loanTermRequired')
        ),
    }),
    startDate: yup.date().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.date().nullable(),
      otherwise: yup
        .date()
        .nullable()
        .min(
          dayjs().subtract(1, 'day').toDate(),
          i18n.t('openstockAdmin.company.loanInfo.inputs.startDateHelperText')
        )
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.startDateRequired')
        )
        .typeError(
          i18n.t('openstockAdmin.company.loanInfo.inputs.startDateRequired')
        ),
    }),

    expiryDate: yup.date().when('isApproved', {
      is: (isApproved: string) => isApproved === 'false',
      then: yup.date().nullable(),
      otherwise: yup
        .date()
        .nullable()
        .min(
          yup.ref('startDate'),
          i18n.t('openstockAdmin.company.loanInfo.inputs.endDateHelperText')
        )
        .typeError(
          i18n.t('openstockAdmin.company.loanInfo.inputs.endDateHelperText')
        )
        .required(
          i18n.t('openstockAdmin.company.loanInfo.inputs.endDateRequired')
        ),
    }),
  })

export const approveIngestedCapTableSchema = yup.object().shape({
  isCapTableErrors: yup
    .string()
    .required(i18n.t('common.required'))
    .oneOf(['yes', 'no']),
})

export const shareholderPaymentInfoSchema = yup.object().shape({
  streetAddress: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.homeAddressRequired')),
  streetAddress2: yup.string().trim(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.yourZipCodeRequired')),
  socialSecurityNumber: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.socialSecurityNumberRequired')),
  bankAccount: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.bankAccountNumberRequired')),
  routingNumber: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.routingNumberRequired')),
})

export const sharesCalculatorSchema = yup.object({
  shares: yup.number().positive().required(),
  sharePrice: yup.number().positive().required(),
  growthRate: yup.number().positive().required(),
  percentValueOfShares: yup.number().positive().required(),
  state: yup
    .string()
    .nullable(false)
    .required(i18n.t('common.validation.stateRequired')),
})

export const optionsCalculatorSchema = yup.object({
  options: yup.number().positive().required(),
  strikePrice: yup.number().positive().required(),
  fmvAtExercise: yup.number().positive().required(),
  marketValueOfCommon: yup.number().positive().required(),
  sharePriceAtSale: yup.number().positive().required(),
  state: yup
    .string()
    .nullable(false)
    .required(i18n.t('common.validation.stateRequired')),
})

export const calculatorUserDetailsSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('calculator.details.form.emailRequired')),
  company: yup
    .string()
    .trim()
    .required(i18n.t('calculator.details.form.companyRequired')),
  financialProducts: yup.array(yup.string()).min(1),
})

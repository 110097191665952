import { useTranslation } from 'react-i18next'

import DownloadIcon from '@mui/icons-material/Download'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'

interface Props {
  file: {
    name: string
    path: string
  }
  title?: string
  icon?: React.ReactNode
}

function DownloadStaticFile({ file, title, icon }: Props) {
  const { t } = useTranslation()

  const { name, path } = file

  return (
    <Grid container rowGap={1}>
      {title && (
        <Grid item>
          <Typography noWrap variant="h6">
            {title}
          </Typography>
        </Grid>
      )}
      <Grid item container mt={0.5}>
        <Paper sx={{ width: { xs: 1, sm: 0.8 } }}>
          <Grid item container alignItems="center" rowGap={1}>
            <Grid item container xs={1}>
              {icon ?? <DownloadIcon fontSize="medium" />}
            </Grid>
            <Grid
              item
              container
              xs={file ? 11 : 12}
              display="flex"
              alignItems="center"
              justifyItems="space-between"
            >
              <Grid item xs={9}>
                <Typography
                  noWrap
                  variant="subtitle1"
                  sx={{ px: 2, py: 0.4 }}
                  color={file ? 'secondary.darkest' : 'secondary'}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item container xs={3} justifyContent="flex-end">
                <Link
                  href={path}
                  underline="none"
                  variant="subtitle2"
                  download={name}
                  disabled={!path}
                  target="_blank"
                >
                  {t('openstockAdmin.documents.download')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DownloadStaticFile

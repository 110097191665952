import axios, { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import {
  SEND_CALCULATOR_EMAIL_LINK_API_KEY,
  SEND_CALCULATOR_EMAIL_LINK_URL,
} from 'lib/config'
import { captureWarning } from 'lib/monitoring'

interface IArgs {
  email: string
  company: string
  link: string
}

const useSendCalculatorEmailLink = () =>
  useMutation<void, AxiosError, IArgs>(
    async ({ email, company, link }) =>
      axios
        .post(
          SEND_CALCULATOR_EMAIL_LINK_URL,
          `email=${encodeURIComponent(email)}&companyName=${encodeURIComponent(
            company
          )}&linkToCalculator=${encodeURIComponent(
            link
          )}&API_KEY=${encodeURIComponent(SEND_CALCULATOR_EMAIL_LINK_API_KEY)}`
        )
        .then((response) => response.data),
    {
      onError: (e: any) => captureWarning(e),
    }
  )

export default useSendCalculatorEmailLink

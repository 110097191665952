import { useField } from 'formik'
import { GenericObject } from 'common/types'
import MenuItem from '@mui/material/MenuItem'
import TextField, { BaseTextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

type Props = BaseTextFieldProps & {
  options: GenericObject[]
  name: string
  onChangeCallback?: (value: any) => void
}

function SelectInput({ name, options, onChangeCallback, ...props }: Props) {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: any) => {
    const { value } = e.target
    onChangeCallback?.(value)
    helpers.setValue(value)
  }

  const config = {
    ...field,
    ...props,
    value: field.value ?? '',
  }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  return (
    <TextField
      {...config}
      select
      fullWidth
      variant="standard"
      onChange={handleChange}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        displayEmpty: true,
      }}
    >
      <MenuItem disabled value={''}>
        <Typography variant="placeholder">{props.placeholder}</Typography>
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectInput

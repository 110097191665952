import { useState } from 'react'
import { useApolloClient, RefetchQueriesInclude } from '@apollo/client'

const useRefetchQueries = () => {
  const client = useApolloClient()
  const [isRefetching, setIsRefetching] = useState(false)

  async function refetchQueries(include: RefetchQueriesInclude = 'active') {
    setIsRefetching(true)

    try {
      await client.refetchQueries({
        include,
      })
    } finally {
      setIsRefetching(false)
    }
  }

  return { isRefetching, refetchQueries }
}

export default useRefetchQueries

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Company } from 'common/types'
import {
  useCompanySetIsApproved,
  useSetCompanyFinancialTermsMutation,
} from 'lib/apollo/hooks'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { Form, Formik } from 'lib/formik'
import {
  defaultInterestRate,
  defaultLoanSettings,
  loanApprovalOptions,
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
} from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'
import { loanInfoSchema } from 'lib/validation'

import Button from 'components/mui/Button'
import DateInput from 'components/mui/DateInput'
import MutationModal from 'components/MutationModal'
import RequiredNote from 'components/RequiredNote'
import SelectInput from 'components/mui/SelectInput'
import TextInput from 'components/mui/TextInput'

import theme from 'styles/customTheme'

export interface LoanInfoFormProps {
  isApproved: string
  companyValuation: number
  loanAmount: number
  loanValue: number
  sharePrice: number
  originationFee: number
  platformFee: number
  loanTermInMonths: number
  startDate: string
  expiryDate: string
  loanType: string
  loanStructure: string
  interestRate: number
  loanRepaymentSchedule: string
}

function LoanInfoForm({ company }: { company: Company }) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [setCompanyFinancialTerms, setCompanyFinancialTermsMutation] =
    useSetCompanyFinancialTermsMutation(company._id)
  const [companySetIsApproved, companySetIsApprovedMutation] =
    useCompanySetIsApproved(company._id)

  const isCompanyApprovedMutationSuccess =
    companySetIsApprovedMutation.data &&
    !companySetIsApprovedMutation.error &&
    setCompanyFinancialTermsMutation.data &&
    !setCompanyFinancialTermsMutation.error

  const isCompanyPendingMutationSuccess =
    companySetIsApprovedMutation.data &&
    !companySetIsApprovedMutation.error &&
    !setCompanyFinancialTermsMutation.data &&
    !setCompanyFinancialTermsMutation.error

  const financialTerms = company?.financialTermsCurrent

  const { loanType, loanStructure, loanRepaymentSchedule } = defaultLoanSettings

  const handleCancel = useCallback(() => {
    searchParams.delete('status')
    setSearchParams(searchParams, {
      replace: true,
    })
  }, [searchParams, setSearchParams])

  const handleOnClose = useCallback(() => {
    setShowConfirmDialog(false)
    setCompanyFinancialTermsMutation.reset()
    companySetIsApprovedMutation.reset()
  }, [companySetIsApprovedMutation, setCompanyFinancialTermsMutation])

  const handleSubmit = (values: LoanInfoFormProps) => {
    const financialTermDetails = {
      companyId: company._id,
      properties: {
        companyValuation: values.companyValuation,
        interestRate: values.interestRate,
        loan: {
          kind: values.loanType,
          repaymentSchedule: values.loanRepaymentSchedule,
          structure: values.loanStructure,
          termInMonths: values.loanTermInMonths,
          valueRatio: values.loanValue,
        },
        originationFee: values.originationFee,
        platformFee: values.platformFee,
        sharePrice: values.sharePrice,
        maxCreditAvailable: values.loanAmount,
        startDate: dayjs(values.startDate).toISOString(),
        expiryDate: dayjs(values.expiryDate).toISOString(),
      },
    }

    if (values.isApproved === 'false')
      //Sets isCompanyPendingMutationSuccess true if mutation success
      return companySetIsApproved({
        variables: { companyId: company._id, isApproved: false },
      })

    //Sets isCompanyApprovedMutationSuccess true if mutation success
    setCompanyFinancialTerms({ variables: financialTermDetails })
    companySetIsApproved({
      variables: { companyId: company._id, isApproved: true },
    })
  }

  const handleSave = () => setShowConfirmDialog(true)

  useEffect(() => {
    if (isCompanyApprovedMutationSuccess || isCompanyPendingMutationSuccess) {
      handleOnClose()
      handleCancel()
    }
  }, [
    handleCancel,
    handleOnClose,
    isCompanyApprovedMutationSuccess,
    isCompanyPendingMutationSuccess,
  ])

  const defaultInputs = [
    {
      name: 'loanType',
      label: getLabelByValue(financialTerms?.loan?.kind ?? loanType, loanTypes),
    },
    {
      name: 'loanStructure',
      label: getLabelByValue(
        financialTerms?.loan?.structure ?? loanStructure,
        loanStructures
      ),
    },
    {
      name: 'loanInterestRate',
      label: t(
        'openstockAdmin.company.loanInfo.inputs.loanInterestRate.value',
        {
          interestRate: financialTerms?.interestRate ?? defaultInterestRate,
        }
      ),
    },

    {
      name: 'loanRepaymentSchedule',
      label: getLabelByValue(
        financialTerms?.loan?.repaymentSchedule ?? loanRepaymentSchedule,
        loanRepaymentSchedules
      ),
    },
  ]

  const initialFormData = {
    companyValuation: financialTerms?.companyValuation ?? '',
    loanAmount: financialTerms?.maxCreditAvailable ?? '',
    loanValue: financialTerms?.loan.valueRatio ?? '',
    sharePrice: financialTerms?.sharePrice ?? '',
    originationFee: financialTerms?.originationFee ?? '',
    platformFee: financialTerms?.platformFee ?? '',
    loanTermInMonths: financialTerms?.loan.termInMonths ?? '',
    startDate: financialTerms?.startDate
      ? dayjs(financialTerms?.startDate).format(
          monthDayFullYearSimplifiedFormat
        )
      : '',
    expiryDate: financialTerms?.expiryDate
      ? dayjs(financialTerms?.expiryDate).format(
          monthDayFullYearSimplifiedFormat
        )
      : '',
    loanType: financialTerms?.loan?.kind ?? loanType,
    loanStructure: financialTerms?.loan?.structure ?? loanStructure,
    interestRate: financialTerms?.interestRate ?? defaultInterestRate,
    loanRepaymentSchedule:
      financialTerms?.loan?.repaymentSchedule ?? loanRepaymentSchedule,
    // Form default to show company is approved when updating, this is independent from company `isApproved`
    isApproved: 'true',
  } as LoanInfoFormProps

  return (
    <Grid item container xs={12} rowSpacing={2}>
      <Grid
        item
        container
        columnSpacing={4}
        rowSpacing={{ xs: 2, sm: 0 }}
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h3">
            {t('openstockAdmin.company.loanInfo.heading')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container maxWidth="370px">
        <Formik
          initialValues={initialFormData}
          validationSchema={loanInfoSchema}
          onSubmit={handleSave}
        >
          {({ values }) => {
            const isCompanyApproved = values.isApproved === 'true'

            return (
              <Form>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12} mb={2}>
                    <SelectInput
                      id="is-approved"
                      name="isApproved"
                      label={t(
                        'openstockAdmin.company.loanInfo.inputs.approval.label'
                      )}
                      options={loanApprovalOptions}
                      value={values.isApproved}
                    />
                  </Grid>

                  {isCompanyApproved && (
                    <>
                      <Grid item xs={12}>
                        <RequiredNote />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="company-valuation"
                          name="companyValuation"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.companyValuation'
                          )}
                          placeholder={t('common.decimalPlaceholder')}
                          delimiter="$"
                          inputMode="decimal"
                          isNumericFormat
                          hasDecimalScale={false}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="loan-amount"
                          name="loanAmount"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.loanAmount'
                          )}
                          placeholder={t('common.decimalPlaceholder')}
                          delimiter="$"
                          inputMode="decimal"
                          isNumericFormat
                          hasDecimalScale={false}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="loan-value"
                          name="loanValue"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.loanValue'
                          )}
                          placeholder={t('common.numberPlaceholder')}
                          delimiter="%"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="share-price"
                          name="sharePrice"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.sharePrice'
                          )}
                          placeholder={t('common.decimalPlaceholder')}
                          delimiter="$"
                          inputMode="decimal"
                          isNumericFormat
                          hasDecimalScale={false}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="origination-fee"
                          name="originationFee"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.originationFee'
                          )}
                          placeholder={t('common.numberPlaceholder')}
                          delimiter="%"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="platform-fee"
                          name="platformFee"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.platformFee'
                          )}
                          placeholder={t('common.numberPlaceholder')}
                          delimiter="%"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="loan-term"
                          name="loanTermInMonths"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.loanTerm'
                          )}
                          placeholder={t('common.numberPlaceholder')}
                          suffixText={t('common.months')}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DateInput
                          id="start-date"
                          name="startDate"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.startDate'
                          )}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} mb={2}>
                        <DateInput
                          id="expiry-date"
                          name="expiryDate"
                          label={t(
                            'openstockAdmin.company.loanInfo.inputs.endDate'
                          )}
                          required
                        />
                      </Grid>

                      {/* Predetermined data points by Openstock */}
                      {defaultInputs.map(({ name, label }) => (
                        <Grid key={name} item xs={12}>
                          <Typography variant="subtitle2">
                            {t(
                              `openstockAdmin.company.loanInfo.inputs.${name}.label`
                            )}{' '}
                            <span
                              style={{
                                color: theme.palette.error.main,
                              }}
                            >
                              *
                            </span>
                          </Typography>
                          <Typography variant="subtitle1">{label}</Typography>
                        </Grid>
                      ))}
                    </>
                  )}

                  <Grid item container mt={4}>
                    <Box
                      width={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        onClick={handleCancel}
                        variant="outlined"
                        sx={{ mr: 0.5 }}
                      >
                        {t('common.cancel')}
                      </Button>

                      <Button type="submit" sx={{ ml: 0.5 }}>
                        {t('common.save')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                {/* Shows confirmation dialog to submit form  */}
                <MutationModal
                  mode="info"
                  isError={
                    setCompanyFinancialTermsMutation.error !== undefined ||
                    companySetIsApprovedMutation.error !== undefined
                  }
                  isLoading={
                    setCompanyFinancialTermsMutation.loading ||
                    companySetIsApprovedMutation.loading
                  }
                  isOpen={showConfirmDialog}
                  onClose={handleOnClose}
                  title={t(
                    `openstockAdmin.company.loanInfo.confirmation.${
                      isCompanyApproved ? 'submitTitle' : 'pendingTitle'
                    }`
                  )}
                  primaryButtonAction={() => handleSubmit(values)}
                  primaryButtonText={t('common.confirm')}
                  secondaryButtonAction={handleOnClose}
                  secondaryButtonText={t('common.cancel')}
                >
                  <Typography>
                    {t(
                      `openstockAdmin.company.loanInfo.confirmation.${
                        isCompanyApproved
                          ? 'submitDescription'
                          : 'pendingDescription'
                      }`
                    )}
                  </Typography>
                </MutationModal>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default LoanInfoForm

import { useMemo, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useUser } from 'app/User'
import { OfferCaptableShareholder, OfferTo } from 'common/types'
import {
  useGetOfferCaptableShareholders,
  useGetOfferQuery,
} from 'lib/apollo/hooks'
import { getOfferTypeConfig } from 'lib/formConfig'
import { getDerivedCompanyStatus } from 'lib/data'
import { DEFAULT_ADMIN_DASHBOARD_ROUTE } from 'routes/AdminDashboard'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

import SendIcon from '@mui/icons-material/Send'
import InfoIcon from '@mui/icons-material/Info'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Alert from 'components/mui/Alert'
import Link from 'components/mui/Link'

import QueryBoundary from 'app/QueryBoundary'
import BackLink from 'components/BackLink'
import Button from 'components/mui/Button'
import ButtonMenu, { MenuItem } from 'components/mui/ButtonMenu'
import EligibleShareholdersTable from 'components/tables/EligibleShareholdersTable'
import Layout from 'components/Layout/Layout'
import OfferDetails from 'components/offerings/OfferDetails'
import OfferProgress from 'components/offerings/OfferProgress'
import StatusIndicator from 'components/StatusIndicator'
import CancelOfferModal from './modals/CancelOfferModal'
import DeleteOfferModal from './modals/DeleteOfferModal'
import ExtendOfferModal from './modals/ExtendOfferModal'
import PublishOfferModal from './modals/PublishOfferModal'

export type ManageOfferAction = 'publish' | 'delete' | 'cancel' | 'extend'

function ManageOffer() {
  const { t } = useTranslation()
  const { companyId, offerId } = useParams()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<null | ManageOfferAction>(null)
  const closeModal = () => setShowModal(null)

  const offerQuery = useGetOfferQuery(offerId)
  const offer = offerQuery?.data?.getOffer

  const eligibleShareholdersQuery = useGetOfferCaptableShareholders(offerId)
  const eligibleShareholders =
    eligibleShareholdersQuery?.data?.getOfferCaptableShareholders

  const {
    roles: { isOpenstockAdmin },
  } = useUser()

  const status = offer?.status

  const offerTypeConfig = offer && getOfferTypeConfig(offer?.kind)

  const name = offerTypeConfig?.label

  //Sum of staked shares for shareholders who subscribed
  const totalNumberOfStakedShares = eligibleShareholders
    ?.filter(
      (shareholder: OfferCaptableShareholder) =>
        shareholder?.subscriptionStatuses.includes('SUBSCRIBED' as any)
    )
    .reduce(
      (acc: number, curr: OfferCaptableShareholder) =>
        acc + curr.maxAvailableShares,
      0
    )

  const { termsIsStarted, termsIsValid, termsIsExpired } = useMemo(
    () => getDerivedCompanyStatus(offer?.financialTerms, offer?.company),
    [offer]
  )

  const isPublishDisabled = useMemo(
    () => status === 'SAVED' && !termsIsValid,
    [status, termsIsValid]
  )

  const { totalMaxAvailableShares, totalLiquidityOffered } = useMemo(
    () =>
      offer
        ? offer?.offerTo?.reduce(
            (
              acc: {
                totalMaxAvailableShares: number
                totalLiquidityOffered: number
              },
              row: OfferTo
            ) => {
              acc.totalMaxAvailableShares += row.maxAvailableShares
              acc.totalLiquidityOffered += row.liquidityAvailable
              return acc
            },
            {
              totalMaxAvailableShares: 0,
              totalLiquidityOffered: 0,
            }
          )
        : 0,
    [offer]
  )

  // Sum total liquidity requested for shareholders who subscribed
  const totalLiquidityRequested = useMemo(
    () =>
      eligibleShareholders?.reduce(
        (acc: number, curr: { loanAmountRequested: null }) =>
          acc +
          (curr?.loanAmountRequested !== null ? curr?.loanAmountRequested : 0),
        0
      ),
    [eligibleShareholders]
  )

  const menuItemsConfig: { [key: string]: MenuItem[] } = {
    SAVED: [
      {
        mode: 'edit',
        label: t('offer.manage.edit.editOffer'),
        action: () =>
          navigate(
            `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/edit-offer/${offer?._id}`
          ),
      },
      {
        mode: 'delete',
        label: t('offer.manage.delete.deleteOffer'),
        action: () => setShowModal('delete'),
      },
    ],
    ACTIVE: [
      {
        mode: 'extend',
        label: t('offer.manage.extend.extendOffer'),
        action: () => setShowModal('extend'),
      },
      {
        mode: 'cancel',
        label: t('offer.manage.cancel.cancelOffer'),
        action: () => setShowModal('cancel'),
      },
    ],
  }

  const statusUrl = status?.toLowerCase()
  const unsupportedStatuses = ['deleted']
  const parsedStatusUrl = unsupportedStatuses.includes(statusUrl)
    ? 'active'
    : statusUrl

  const isOfferActions = status in menuItemsConfig
  const backLinkNavigateTo = isOpenstockAdmin
    ? `${DEFAULT_ADMIN_DASHBOARD_ROUTE}/${companyId}/offers`
    : `${DEFAULT_COMPANY_DASHBOARD_ROUTE}/offers/${parsedStatusUrl}`

  return (
    <QueryBoundary queries={[offerQuery, eligibleShareholdersQuery]}>
      <Layout maxWidth="lg">
        <BackLink to={backLinkNavigateTo} />
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={4}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
            columnGap={{ xs: 3 }}
            rowGap={2}
            width={{ sm: 'auto' }}
          >
            <Grid item>
              <Typography variant="h1">{name}</Typography>
            </Grid>
            <Grid item>
              <StatusIndicator status={status} hasBorder />
            </Grid>
          </Grid>

          {!isOpenstockAdmin && isOfferActions && (
            <Grid container gap={2} width={{ sm: 'auto' }} alignItems="center">
              {status === 'SAVED' && (
                <Grid item>
                  <Button
                    size="small"
                    startIcon={<SendIcon />}
                    onClick={() => setShowModal('publish')}
                    disabled={isPublishDisabled}
                  >
                    {t('offer.manage.publish.publishOffer')}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <ButtonMenu
                  buttonLabel={t('offer.manage.manageOffer')}
                  menuItems={menuItemsConfig[status]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {isPublishDisabled && termsIsExpired && (
          <Alert
            severity="info"
            variant="standard"
            icon={<InfoIcon />}
            sx={{ mb: 4 }}
          >
            <Typography>
              <Trans i18nKey="dashboard.offers.alerts.financingExpired">
                You can no longer create or publish offers because your
                financing term has ended. If you’re interested in getting
                additional financing, please contact
                <Link
                  href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                    'dashboard.offers.alerts.financingExpiredEmailSubject'
                  )}`}
                  variant="body2"
                >
                  partners@getopenstock.com
                </Link>
                .
              </Trans>
            </Typography>
          </Alert>
        )}
        {isPublishDisabled && !termsIsStarted && (
          <Alert
            severity="info"
            variant="standard"
            icon={<InfoIcon />}
            sx={{ mb: 4 }}
          >
            <Typography>
              <Trans
                i18nKey="offer.manage.alerts.financingStart"
                shouldUnescape
              >
                You are not able to publish offers until your financing start
                date (
                {{
                  startDate: dayjs(offer?.financialTerms.startDate).format(
                    monthDayFullYearSimplifiedFormat
                  ),
                }}
                ). Until then, you can create, edit, and delete offers, but not
                publish them. If you have any questions or believe you are
                seeing this message in error, please contact us at
                <Link
                  href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${t(
                    'offer.manage.alerts.financingStartEmailSubject'
                  )}`}
                  variant="body2"
                >
                  partners@getopenstock.com
                </Link>
                .
              </Trans>
            </Typography>
          </Alert>
        )}

        <Grid container rowGap={6}>
          <OfferDetails
            offer={offer}
            offerTypeConfig={offerTypeConfig}
            showLoanInformation
          />

          <OfferProgress
            offer={offer}
            eligibleShareholders={eligibleShareholders}
            totalNumberOfStakedShares={totalNumberOfStakedShares}
            totalLiquidityRequested={totalLiquidityRequested}
          />

          <EligibleShareholdersTable
            offer={offer}
            eligibleShareholders={eligibleShareholders}
            isOpenstockAdmin={isOpenstockAdmin}
            offerTypeConfig={offerTypeConfig}
            totalLiquidityRequested={totalLiquidityRequested}
            totalMaxAvailableShares={totalMaxAvailableShares}
            totalLiquidityOffered={totalLiquidityOffered}
          />
        </Grid>

        <PublishOfferModal
          isOpen={showModal === 'publish'}
          closeModal={closeModal}
          offer={offer}
        />

        <DeleteOfferModal
          isOpen={showModal === 'delete'}
          closeModal={closeModal}
          offerId={offer?._id}
        />

        <CancelOfferModal
          isOpen={showModal === 'cancel'}
          closeModal={closeModal}
          offerId={offer?._id}
        />

        <ExtendOfferModal
          isOpen={showModal === 'extend'}
          closeModal={closeModal}
          offerId={offer?._id}
        />
      </Layout>
    </QueryBoundary>
  )
}

export default ManageOffer

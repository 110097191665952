import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs, { monthDayYearSimplifiedFormat } from 'lib/dayjs'
import { useInterval } from 'react-use'
import CircularProgress from '@mui/material/CircularProgress'
import axios, { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { useCreateDownloadLinkQuery } from 'lib/apollo/hooks'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Alert from 'components/mui/Alert'

import { captureWarning } from 'lib/monitoring'
import { CompanyFile } from 'common/types'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import { FILE_EXPIRY_MS } from 'lib/config'
import { getAccessTokenJWT } from 'lib/amplify'

interface Props {
  file: CompanyFile | undefined
  title?: string
  icon?: React.ReactNode
}

function DownloadFile({ file, title, icon }: Props) {
  const { t } = useTranslation()
  const currFile = file?.current

  const [getDownloadLink, getDownloadLinkMutation] =
    useCreateDownloadLinkQuery()

  const downloadMutation = useMutation<void, AxiosError>(
    async () => {
      const token = await getAccessTokenJWT()
      return axios({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `${token}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', currFile?.nameOriginal ?? '')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      })
    },
    {
      onError: (e: Error) => captureWarning(e),
    }
  )

  const handleGetDownloadLink = useCallback(() => {
    file &&
      currFile &&
      getDownloadLink({ variables: { companyFileId: file._id } })
  }, [currFile, file, getDownloadLink])

  useEffect(() => {
    handleGetDownloadLink()
  }, [handleGetDownloadLink])

  useInterval(
    () => {
      handleGetDownloadLink()
    },
    currFile ? FILE_EXPIRY_MS - 1000 : null
  )

  // TODO: Generate blob with auth header
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateDownload = async () => {
    downloadMutation.mutate()
  }

  const fileUrl =
    getDownloadLinkMutation?.data?.createCompanyFileDownloadLink?.url

  return (
    <Grid container rowGap={1}>
      {title && (
        <Typography noWrap variant="h6">
          {title}
        </Typography>
      )}
      <Grid item container mt={0.5}>
        <Paper sx={{ width: { xs: 1, sm: 0.8 } }}>
          <Grid item container alignItems="center" rowGap={1}>
            {getDownloadLinkMutation.error || downloadMutation.isError ? (
              <Alert>{t('openstockAdmin.documents.downloadError')}</Alert>
            ) : (
              <>
                {currFile && (
                  <Grid item container xs={1}>
                    {icon ?? (
                      <CloudDoneIcon
                        fontSize="medium"
                        sx={{ color: 'green' }}
                      />
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  container
                  xs={currFile ? 11 : 12}
                  display="flex"
                  alignItems="center"
                  justifyItems="space-between"
                >
                  <Grid item xs={9}>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      sx={{ px: 2, py: 0.4 }}
                      color={file ? 'secondary.darkest' : 'secondary'}
                    >
                      {currFile
                        ? currFile.nameOriginal
                        : t('openstockAdmin.documents.fileEmpty')}
                    </Typography>
                  </Grid>
                  <Grid item container xs={3} justifyContent="flex-end">
                    {getDownloadLinkMutation.loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : (
                      <Link
                        href={fileUrl}
                        // onClick={() => fileUrl && generateDownload()}
                        underline="none"
                        variant="subtitle2"
                        download={currFile?.nameOriginal}
                        disabled={!fileUrl}
                        target="_blank"
                      >
                        {t('openstockAdmin.documents.download')}
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item container alignSelf="end" mb={2}>
        {currFile && (
          <Typography
            variant="subtitle1"
            color={currFile ? 'secondary.darkest' : 'secondary'}
          >
            {t('openstockAdmin.documents.createdAt') +
              dayjs(currFile.createdAt).format(monthDayYearSimplifiedFormat)}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default DownloadFile

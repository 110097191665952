import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import Grid from '@mui/material/Grid/Grid'
import Layout from 'components/Layout/Layout'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useUser } from 'app/User'
import { ShareholderPaymentInfo } from 'common/types'
import { useSteps } from 'hooks/useSteps'
import useSofrRate from 'hooks/useSofrRate'
import { useCreateLoan, useGetOfferQuery } from 'lib/apollo/hooks'
import {
  calculateLoanDetails,
  getInterestAndPlatformFee,
  getShareholderOffer,
} from 'lib/data'
import { minimumLoanAmount } from 'lib/formConfig'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import { shareholderPaymentInfoSchema } from 'lib/validation'
import { DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE } from 'routes/ShareholderDashboard'
import theme from 'styles/customTheme'

import QueryBoundary from 'app/QueryBoundary'
import AlertDialog from 'components/AlertDialog'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import PaymentInformation from './acceptOffer/PaymentInformation'
import SetLoanAmount from './acceptOffer/SetLoanAmount'
import ReviewLoanAndPayment from './acceptOffer/ReviewLoanAndPayment'
import ConfirmationOfferAcceptance from './acceptOffer/ConfirmationOfferAcceptance'
import BackLink from 'components/BackLink'

export type ApproveShareholderOffer = ShareholderPaymentInfo & {
  requestedLoanAmount: number
  shareholderLoanOfferDetails: {
    stakedShares: number
    originationFee: number
    loanAmountReceived: number
    totalInterest: number
    quarterlyPayments: number
    principalRepayment: number
  }
}

function ShareholderReviewAcceptOffer() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { offerId } = useParams()
  const sofrRateQuery = useSofrRate()
  const { activeStep, handleBack, handleNext } = useSteps()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const [createLoan, createLoanMutation] = useCreateLoan(offerId)

  const {
    data: { _id: userId, emailAddress: userEmail },
  } = useUser()

  const offerQuery = useGetOfferQuery(offerId)
  const offer = offerQuery?.data?.getOffer

  const shareholderOffer = useMemo(
    () => offer && getShareholderOffer(offer, userEmail),
    [offer, userEmail]
  )

  const requestedLoanAmountSchema = yup.object({
    requestedLoanAmount: yup
      .number()
      .min(minimumLoanAmount)
      .max(shareholderOffer?.liquidityAvailable)
      .required(),
  })

  const validationSchema = [
    requestedLoanAmountSchema,
    shareholderPaymentInfoSchema,
  ]

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleNavigate = () =>
    navigate(`${DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE}`, {
      state: {
        unblockPrompt: true,
      },
    })

  const handleAction = () => navigate(`../offer/${offerId}/review`)

  const handleSubmit = async (
    values: ApproveShareholderOffer,
    formik?: FormikHelpers<ApproveShareholderOffer>
  ) => {
    if (activeStep !== 3) {
      formik?.setTouched({}, false)
      return handleNext()
    }

    if (!createLoanMutation.data) {
      const { errors } = await createLoan({
        variables: {
          userId,
          offerId: offer?._id,
          loanInfo: {
            loanAmountRequested: values.requestedLoanAmount,
            stakedShares: values.shareholderLoanOfferDetails.stakedShares,
            originationFee: values.shareholderLoanOfferDetails.originationFee,
            loanAmountReceived:
              values.shareholderLoanOfferDetails.loanAmountReceived,
            interestTotal: values.shareholderLoanOfferDetails.totalInterest,
            quarterlyInterestOnlyPayments:
              values.shareholderLoanOfferDetails.quarterlyPayments,
            principalRepayment:
              values.shareholderLoanOfferDetails.principalRepayment,
            sofr: sofrRate,
          },
          userInfo: {
            address: {
              country: values.country,
              state: values.state,
              city: values.city,
              streetAddress: values.streetAddress,
              streetAddress2: values.streetAddress2
                ? values.streetAddress2
                : null,
              zipCode: values.zipCode,
            },
            bankingInfo: {
              accountNumber: values.bankAccount,
              routingNumber: values.routingNumber,
            },
            ssn: values.socialSecurityNumber,
          },
        },
      })
      if (errors) return
    }
    return handleNavigate()
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    createLoanMutation.reset()
  }

  const handleOnClose = () => setShowAlertDialog(false)

  useEffect(() => {
    if (createLoanMutation.error) {
      setShowAlertDialog(true)
    }
  }, [createLoanMutation.error])

  const pages: { title: string; description: string }[] = t(
    'shareholder.offer.nonRecourseLoan.accept.pages',
    {
      returnObjects: true,
    }
  )

  const steps: string[] = pages.map(({ title }) => title)

  const sofrRate = sofrRateQuery.data?.percentRate
  const interestAndPlatformFee = useMemo(
    () =>
      offer?.financialTerms && getInterestAndPlatformFee(offer?.financialTerms),
    [offer?.financialTerms]
  )

  const calculatedLoanDetails = calculateLoanDetails({
    loanDetails: {
      stakedShares: 0,
      originationFee: 0,
      loanAmountReceived: 0,
      totalInterest: 0,
      quarterlyPayments: 0,
      principalRepayment: 0,
    },
    offer,
    shareholderOffer,
    requestedLoanAmount: shareholderOffer?.liquidityAvailable,
    sofrRate,
  })

  const initialFormData = {
    requestedLoanAmount: shareholderOffer?.liquidityAvailable,
    shareholderLoanOfferDetails: calculatedLoanDetails,
    country: '',
    state: '',
    city: '',
    streetAddress: '',
    streetAddress2: '',
    zipCode: '',
    socialSecurityNumber: '',
    bankAccount: '',
    routingNumber: '',
  } as ApproveShareholderOffer

  return (
    <QueryBoundary queries={[offerQuery, sofrRateQuery]}>
      <Grid container sx={{ flex: 1 }}>
        {isMediumUp && (
          <Grid item xs={12} md={3} lg={2.5}>
            <SideBarStepper
              activeStep={activeStep}
              steps={steps}
              title={t('shareholder.offer.nonRecourseLoan.accept.title')}
              action={handleAction}
              actionTitle={t('common.back')}
            />
          </Grid>
        )}

        <Grid item xs={12} md={9} lg={9.5}>
          <Layout maxWidth="md" isSideBarStepper>
            {isMediumDown && <BackLink onClick={handleAction} />}
            <Grid container rowGap={3} mb={3} maxWidth={{ smd: 0.6 }}>
              <Grid item>
                <Typography variant="h2" component="h1">
                  {pages[activeStep].title}
                </Typography>
              </Grid>

              {pages[activeStep].description && (
                <Grid item>
                  <Typography mb={1}>
                    {pages[activeStep].description}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Formik
              initialValues={initialFormData}
              validationSchema={validationSchema[activeStep]}
              onSubmit={handleSubmit}
            >
              {({ values, submitForm }) => {
                const caseProps = {
                  handleBack,
                  handleNext,
                  isSubmitting: createLoanMutation.loading,
                  offer,
                  shareholderOffer,
                  interestAndPlatformFee,
                  sofrRate,
                  values,
                }
                return (
                  <Form promptOnLeave>
                    {(() => {
                      switch (activeStep) {
                        case 0:
                          return <SetLoanAmount {...caseProps} />
                        case 1:
                          return <PaymentInformation {...caseProps} />
                        case 2:
                          return <ReviewLoanAndPayment {...caseProps} />
                        case 3:
                          return <ConfirmationOfferAcceptance {...caseProps} />
                        default:
                          return
                      }
                    })()}
                    {/* Shows mutation error when submitting form  */}
                    <AlertDialog
                      isOpen={showAlertDialog}
                      onClose={handleOnClose}
                      description={t('common.errorSubmitForm')}
                      primaryButtonAction={() => {
                        submitForm()
                        setShowAlertDialog(false)
                      }}
                      secondaryButtonAction={handleResetMutation}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Layout>
        </Grid>
      </Grid>
    </QueryBoundary>
  )
}

export default ShareholderReviewAcceptOffer

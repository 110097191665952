import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { KEY_CALCULATOR_USER_DETAILS } from 'lib/config'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import CalculatorUserDetails from 'components/calculator/CalculatorUserDetails'
import Layout from 'components/Layout/Layout'
import Options from 'components/calculator/Options'
import Shares from 'components/calculator/Shares'
import TabBar from 'components/mui/TabBar'

export interface CalculatorUser {
  email: string
  company: string
}

function CalculatorPage() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const emailParams = searchParams.get('email')
  const companyParams = searchParams.get('company')

  const [calculatorUser, setCalculatorUser] = useState({
    email: '',
    company: '',
  })

  const isCalculatorLocked =
    calculatorUser.email === '' && calculatorUser.company === ''

  const handleSearchParams = useCallback(
    (values: any, handleResetMutation: any) => {
      const params = new URLSearchParams(values) as any

      setSearchParams(params, { replace: true })
      handleResetMutation()
    },
    [setSearchParams]
  )

  useEffect(() => {
    // Get user from localStorage if it exists
    const getUser = localStorage.getItem(KEY_CALCULATOR_USER_DETAILS)
    if (getUser) {
      const parsedGetUser = JSON.parse(getUser)
      setCalculatorUser(parsedGetUser)
    }

    // Get user from query params if it exists
    if (emailParams && companyParams) {
      localStorage.setItem(
        KEY_CALCULATOR_USER_DETAILS,
        JSON.stringify({
          email: emailParams,
          company: companyParams,
        })
      )
    }
  }, [companyParams, emailParams, isCalculatorLocked])

  const tabList = ['shares', 'options'].map((name: string) => ({
    label: t('calculator.tabName', { name }),
    path: name,
    element: (
      <>
        {(() => {
          switch (name) {
            case 'shares':
              return (
                <Shares
                  calculatorUser={calculatorUser}
                  handleSearchParams={handleSearchParams}
                />
              )
            case 'options':
              return (
                <Options
                  calculatorUser={calculatorUser}
                  handleSearchParams={handleSearchParams}
                />
              )
            default:
              return
          }
        })()}
      </>
    ),
  }))

  return (
    <Layout maxWidth="lg">
      <Grid item container xs={12} sm={9} md={5}>
        <Typography variant="h1" mb={5}>
          {t('calculator.heading')}
        </Typography>
        <Typography variant="h3" mb={2} width={1}>
          {t('calculator.subHeading')}
        </Typography>
        <Typography>{t('calculator.description')}</Typography>
      </Grid>

      <TabBar tabList={tabList} fallbackPath="shares" />
      <CalculatorUserDetails
        isCalculatorLocked={isCalculatorLocked}
        setCalculatorUser={setCalculatorUser}
      />
    </Layout>
  )
}

export default CalculatorPage

import { useState } from 'react'
import LoadingButton from 'components/mui/LoadingButton'
import Layout from 'components/Layout/Layout'
import TextField from '@mui/material/TextField/TextField'

import { useUser } from 'app/User'
import { useResetCompanyOffers } from 'lib/apollo/hooks'

function ResetCompanyOffers() {
  const {
    data: { company },
  } = useUser()
  const [resetOffers, resetOffersMutation] = useResetCompanyOffers()

  const [companyId, setCompanyId] = useState(company?._id)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyId(event.target.value)
  }

  if (!company) {
    throw Error('No company found')
  }

  return (
    <Layout>
      Internal use only. Reset offers for {company.name} ({company._id}), or
      override with a different company ID:
      <br />
      <br />
      <TextField value={companyId} onChange={handleChange} />
      <br />
      <br />
      <LoadingButton
        loading={resetOffersMutation.loading}
        onClick={() =>
          resetOffers({
            variables: { companyId },
          })
        }
      >
        Reset
      </LoadingButton>
    </Layout>
  )
}

export default ResetCompanyOffers

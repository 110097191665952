import andreessenHorowitz from 'assets/investorLogos/andreessenHorowitz.svg'
import apollo from 'assets/investorLogos/apollo.png'
import bankOfAmerica from 'assets/investorLogos/bankOfAmerica.svg'
import blackRock from 'assets/investorLogos/blackRock.png'
import citibank from 'assets/investorLogos/citibank.svg'
import goldmanSachs from 'assets/investorLogos/goldmanSachs.png'
import hsbc from 'assets/investorLogos/hsbc.png'
import industryVenture from 'assets/investorLogos/industryVenture.svg'
import investX from 'assets/investorLogos/investX.png'
import manhattanVenturePartners from 'assets/investorLogos/manhattanVenturePartners.png'
import morganStanley from 'assets/investorLogos/morganStanley.png'
import oceanicPartners from 'assets/investorLogos/oceanicPartners.png'
import sequoiaCapital from 'assets/investorLogos/sequoiaCapital.png'
import thriveCapital from 'assets/investorLogos/thriveCapital.png'
import ubs from 'assets/investorLogos/ubs.svg'
import wCapitalPartners from 'assets/investorLogos/wCapitalPartners.svg'
import williamBlair from 'assets/investorLogos/williamBlair.svg'

export const investors = [
  { name: 'Bank Of America', logo: bankOfAmerica, category: ['bank'] },
  { name: 'Citibank', logo: citibank, category: ['bank'] },
  { name: 'Goldman Sachs', logo: goldmanSachs, category: ['bank'] },
  { name: 'Morgan Stanley', logo: morganStanley, category: ['bank'] },
  { name: 'UBS', logo: ubs, category: ['bank'] },
  { name: 'HSBC', logo: hsbc, category: ['bank'] },
  { name: 'William Blair & Company', logo: williamBlair, category: ['bank'] },
  {
    name: 'Andreessen Horowitz',
    logo: andreessenHorowitz,
    category: ['fund'],
  },
  { name: 'Apollo Global Management', logo: apollo, category: ['fund'] },
  { name: 'BlackRock', logo: blackRock, category: ['fund'] },
  {
    name: 'Industry Ventures',
    logo: industryVenture,
    category: ['fund'],
  },
  { name: 'InvestX Capital', logo: investX, category: ['fund'] },
  {
    name: 'Manhattan Venture Partners',
    logo: manhattanVenturePartners,
    category: ['fund'],
  },
  {
    name: 'Oceanic Partners',
    logo: oceanicPartners,
    category: ['fund'],
  },
  {
    name: 'Sequoia Capital',
    logo: sequoiaCapital,
    category: ['fund'],
  },
  { name: 'Thrive Capital', logo: thriveCapital, category: ['fund'] },
  {
    name: 'W Capital Partners',
    logo: wCapitalPartners,
    category: ['fund'],
  },
]

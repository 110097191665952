import i18n from 'locale/i18n'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Button from 'components/mui/Button'
import Paper from 'components/mui/Paper/Paper'
import StatusIndicator from 'components/StatusIndicator'

function OfferCard({
  contents,
  name,
  status,
  statusLabel,
  showButton = true,
  buttonNavigateTo,
  buttonLabel = i18n.t('dashboard.offers.viewDetails'),
}: {
  contents: {
    label: string
    value?: string | number
    values?: { _id: string; label?: string; name?: string }[]
  }[]
  name: string
  status: string
  statusLabel?: string
  showButton?: boolean
  buttonNavigateTo?: string
  buttonLabel?: string
}) {
  return (
    <Paper>
      <Grid container rowGap={2}>
        {/* Status & Name */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Grid item>
            <Typography variant="h4" color="secondary.darkest">
              {name}
            </Typography>
          </Grid>
          <StatusIndicator status={status} label={statusLabel} />
        </Grid>

        {/* Details & Button */}
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item container spacing={2} flex={{ sm: 1 }}>
            {contents.map((field) => (
              <Grid item key={field.label} xs={12} sm={6} smd={4} lg={12 / 5}>
                <Typography variant="body2" color="secondary.darkest">
                  {field.label}
                </Typography>
                {field.value && (
                  <Typography color="secondary.darkest" my={{ xs: 0.3, md: 1 }}>
                    {field.value}
                  </Typography>
                )}
                {field.values?.map((values) => (
                  <Typography
                    key={values._id}
                    color="secondary.darkest"
                    my={{ xs: 0.3, md: 1 }}
                  >
                    {values.name ?? values.label}
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
          {showButton && (
            <Grid
              item
              xs="auto"
              sx={{ ml: { xs: -2, sm: 0 }, mr: -1.5 }}
              mt={{ xs: 1, sm: 0 }}
              alignSelf="end"
            >
              <Button mode="forward" variant="text" to={buttonNavigateTo}>
                {buttonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default OfferCard

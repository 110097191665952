import Airtable from 'airtable'

import { captureError, setErrorContext } from 'lib/monitoring'

import { Company, GenericObject } from 'common/types'
import {
  AIRTABLE_ACCESS_TOKEN,
  AIRTABLE_BASE_ID,
  CURRENT_ENVIRONMENT,
  FEATURE_FLAGS,
} from 'lib/config'
import { UPLOAD_DOCUMENTS_LIST } from 'lib/formConfig'

const AIRTABLE_USER_TABLE = 'Users'
const AIRTABLE_COMPANY_TABLE = 'Companies'
const AIRTABLE_OFFERINGS_SURVEY_TABLE = 'Offerings survey'
const AIRTABLE_CAPTABLE_TABLE = 'Cap tables'
const AIRTABLE_APPROVED_CAPTABLE_TABLE = 'Approved cap tables'
const AIRTABLE_UNDERWRITING_DOCUMENTS_TABLE = 'Underwriting documents'
const AIRTABLE_OFFERS_TABLE = 'Offers'
const AIRTABLE_PUBLISHED_OFFERS_TABLE = 'Published offers'
const AIRTABLE_DATA_INSIGHTS_TABLE = 'Data & insights waitlist'
const AIRTABLE_TENDER_OFFERS_TABLE = 'Tender offers waitlist'
const AIRTABLE_CALCULATOR_USER_TABLE = 'Calculator user details'
const AIRTABLE_CALCULATOR_USER_LINK_INFO_TABLE = 'Calculator user link info'

export interface AirtableUser {
  given_name: string
  family_name: string
  email: string
  phone_number: string
  'custom:companyInvite': string
}

export const createUserAirtableRecord = async (user: AirtableUser) => {
  if (!user || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Email: user.email,
    'First Name': user.given_name,
    'Last Name': user.family_name,
    Phone: user.phone_number,
    'Created through company invite': user['custom:companyInvite'] === 'true',
    Environment: CURRENT_ENVIRONMENT,
  }

  try {
    await base(AIRTABLE_USER_TABLE).create([{ fields: fields }])
  } catch (e: any) {
    setErrorContext('user', fields)
    captureError(e)
  }
}

export const createCompanyAirtableRecord = async (
  name: string,
  email: string,
  companyId: string,
  userName: string
) => {
  if (!name || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Name: name,
    'Created by': email,
    'Created by name': userName,
    Environment: CURRENT_ENVIRONMENT,
    companyId,
  }

  try {
    await base(AIRTABLE_COMPANY_TABLE).create([{ fields: fields }])
  } catch (e: any) {
    setErrorContext('company', fields)
    captureError(e)
  }
}

export const createOfferingsSurveyAirtableRecord = async ({
  company,
  email,
  userName,
  formData,
}: {
  company: string
  email: string
  userName: string
  formData: GenericObject
}) => {
  if (!company) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: company,
    Products: formData.productOfferings.length
      ? formData.productOfferings.join(', ')
      : 'None selected',
    'Partners: bank': formData.financialPartners.bank.length
      ? formData.financialPartners.bank.join(', ')
      : 'None selected',
    'Partners: fund': formData.financialPartners.fund.length
      ? formData.financialPartners.fund.join(', ')
      : 'None selected',
    'Partners: other': formData.otherPartners || 'None entered',
    'Created by': email,
    'Created by name': userName,
    Environment: CURRENT_ENVIRONMENT,
  }

  try {
    await base(AIRTABLE_OFFERINGS_SURVEY_TABLE).create([{ fields: fields }])
  } catch (e: any) {
    setErrorContext('offerings survey', fields)
    captureError(e)
  }
}

export const createCaptableAirtableRecord = async (
  companyName: Company['name'],
  companyId: string
) => {
  if (!companyName || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: companyName,
    Environment: CURRENT_ENVIRONMENT,
    companyId,
  }

  try {
    await base(AIRTABLE_CAPTABLE_TABLE).create([{ fields }])
  } catch (e: any) {
    setErrorContext('captable', fields)
    captureError(e)
  }
}

export const createApprovedCaptableAirtableRecord = async (
  companyName: Company['name']
) => {
  if (!companyName || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: companyName,
    Environment: CURRENT_ENVIRONMENT,
  }

  try {
    await base(AIRTABLE_APPROVED_CAPTABLE_TABLE).create([{ fields }])
  } catch (e: any) {
    setErrorContext('approved captable', fields)
    captureError(e)
  }
}

export const createUnderwritingDocumentsAirtableRecord = async (
  companyName: Company['name'],
  files: { file: any; name: string }[],
  companyId: string
) => {
  if (!companyName || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const listOfFiles = files
    ?.map(
      (file) =>
        (UPLOAD_DOCUMENTS_LIST as any).find(
          (doc: { name: string }) => doc.name === file.name
        ).title
    )
    .join(', ')

  const fields = {
    Company: companyName,
    Files: listOfFiles,
    Environment: CURRENT_ENVIRONMENT,
    companyId,
  }

  try {
    await base(AIRTABLE_UNDERWRITING_DOCUMENTS_TABLE).create([{ fields }])
  } catch (e: any) {
    setErrorContext('underwriting documents', fields)
    captureError(e)
  }
}

export const createOfferAirtableRecord = async ({
  companyName,
  kind,
  status,
  companyId,
  offerId,
}: {
  companyName: string
  kind: string
  status: string
  companyId: string
  offerId: string
}) => {
  if (!companyName || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: companyName,
    Kind: kind,
    Status: status,
    Environment: CURRENT_ENVIRONMENT,
    companyId,
    offerId,
  }

  try {
    await base(AIRTABLE_OFFERS_TABLE).create([{ fields }])
  } catch (e: any) {
    setErrorContext('offers', fields)
    captureError(e)
  }
}

export const createPublishedOfferAirtableRecord = async ({
  companyName,
  kind,
  status,
  companyId,
  offerId,
}: {
  companyName: string
  kind: string
  status: string
  companyId: string
  offerId: string
}) => {
  if (!companyName || !FEATURE_FLAGS.LOG_DATA_AIRTABLE) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: companyName,
    Kind: kind,
    Status: status,
    Environment: CURRENT_ENVIRONMENT,
    companyId,
    offerId,
  }

  try {
    await base(AIRTABLE_PUBLISHED_OFFERS_TABLE).create([{ fields }])
  } catch (e: any) {
    setErrorContext('published offers', fields)
    captureError(e)
  }
}

export const createDataInsightsWaitlistAirtableRecord = async (
  name: string,
  email: string,
  company: string
) => {
  if (!name) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Name: name,
    Email: email,
    Company: company,
    Environment: CURRENT_ENVIRONMENT,
  }

  return await base(AIRTABLE_DATA_INSIGHTS_TABLE).create([{ fields: fields }])
}

export const createTenderOfferWaitlistAirtableRecord = async (
  name: string,
  email: string,
  company: string,
  formData: GenericObject
) => {
  if (!name) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Name: name,
    Email: email,
    Company: company,
    'Partners: bank': formData.financialPartners.bank.length
      ? formData.financialPartners.bank.join(', ')
      : 'None selected',
    'Partners: fund': formData.financialPartners.fund.length
      ? formData.financialPartners.fund.join(', ')
      : 'None selected',
    'Partners: other': formData.otherPartners || 'None entered',
    Environment: CURRENT_ENVIRONMENT,
  }

  return await base(AIRTABLE_TENDER_OFFERS_TABLE).create([{ fields: fields }])
}

export const createCalculatorUserDetailsAirtableRecord = async ({
  email,
  company,
  financialProducts,
}: {
  email: string
  company: string
  financialProducts: string[]
}) => {
  if (!company) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: company,
    Email: email,
    'Interested in these financial products': financialProducts.join(', '),
    Environment: CURRENT_ENVIRONMENT,
  }

  return await base(AIRTABLE_CALCULATOR_USER_TABLE).create([{ fields }])
}

export const createCalculatorUserLinkInfoAirtableRecord = async ({
  email,
  company,
  link,
  type,
}: {
  email: string
  company: string
  link: string
  type: string
}) => {
  if (!company) return

  const base = new Airtable({ apiKey: AIRTABLE_ACCESS_TOKEN }).base(
    AIRTABLE_BASE_ID
  )

  const fields = {
    Company: company,
    Email: email,
    Type: type,
    'Link to calculator': link,
  }

  return await base(AIRTABLE_CALCULATOR_USER_LINK_INFO_TABLE).create([
    { fields },
  ])
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs, { monthDayYearTimeTimezoneFormat } from 'lib/dayjs'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  CaptableShareholderGroupedTable,
  CreateOfferMultiForms,
  Offer,
  OfferTypeConfig,
} from 'common/types'
import { getInterestAndPlatformFee, getShareclassesByIds } from 'lib/data'
import Paper from 'components/mui/Paper/Paper'

function OfferDetails({
  offer,
  offerDetailsForm,
  offerTypeConfig,
  shareholderGroupedTable,
  showLoanInformation = false,
}: {
  offer?: Offer
  offerDetailsForm?: CreateOfferMultiForms
  offerTypeConfig: OfferTypeConfig
  shareholderGroupedTable?: CaptableShareholderGroupedTable
  showLoanInformation?: boolean
}) {
  const { t } = useTranslation()

  const { offerTypeTranslationKey } = offerTypeConfig

  const interestRate = useMemo(
    () =>
      offer?.financialTerms && getInterestAndPlatformFee(offer?.financialTerms),
    [offer?.financialTerms]
  )

  const selectedShareclasses = useMemo(
    () =>
      offerDetailsForm &&
      shareholderGroupedTable &&
      getShareclassesByIds({
        shareclasses: shareholderGroupedTable?.shareclasses,
        shareclassIds: offerDetailsForm.shareclasses,
      }),
    [offerDetailsForm, shareholderGroupedTable]
  )

  const isNonRecourseLoan =
    offer?.kind === 'NON_RECOURSE_LOAN' ||
    offerDetailsForm?.type === 'NON_RECOURSE_LOAN'

  const isTenderOffer =
    offer?.kind === 'TENDER_OFFER' || offerDetailsForm?.type === 'TENDER_OFFER'

  const offerDetails = [
    {
      title: t(`offer.review.${offerTypeTranslationKey}.offerOverview.heading`),
      show: true,
      fields: [
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.offerOverview.offerType`
          ),
          value: t(`offer.review.${offerTypeTranslationKey}.offerType`),
        },
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.offerOverview.startDate`
          ),
          value: offer?.startDate
            ? dayjs(offer?.startDate).format(monthDayYearTimeTimezoneFormat)
            : '-',
        },
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.offerOverview.endDate`
          ),
          value: offer?.expiryDate
            ? dayjs(offer?.expiryDate).format(monthDayYearTimeTimezoneFormat)
            : '-',
        },
      ],
    },
    {
      title: t(
        `offer.review.${offerTypeTranslationKey}.liquidityInformation.heading`
      ),
      show: true,
      fields: [
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.liquidityInformation.totalLiquidity`
          ),
          value: t('common.intlCurrencySimplifiedFormat', {
            value:
              offerDetailsForm?.totalLiquidity ?? offer?.liquidityAvailable,
          }),
        },
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.liquidityInformation.maxNumOfShares`
          ),
          value: t('common.intlNumberFormat', {
            value: offerDetailsForm?.totalShares ?? offer?.maxAvailableShares,
          }),
        },
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.liquidityInformation.percentageOfAssets`
          ),
          value: `${
            offerDetailsForm?.percentageOfAssets ??
            offer?.liquidityPercentPerShareholder
          }%`,
        },
      ],
    },
    {
      title: t(
        `offer.review.${offerTypeTranslationKey}.shareholderEligibility.heading`
      ),
      show: true,
      fields: [
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.shareholderEligibility.eligibleShareClasses`
          ),
          values: selectedShareclasses ?? offer?.shareclasses,
        },
        {
          label: t(
            `offer.review.${offerTypeTranslationKey}.shareholderEligibility.numOfEligibleShareholders`
          ),
          value: offerDetailsForm?.shareholders.length ?? offer?.offerTo.length,
        },
      ],
    },
    {
      title: t('offer.information.loanInfo.title'),
      show: showLoanInformation,
      fields: [
        {
          label: t('offer.information.loanInfo.loanTerm'),
          value: t('common.monthWithCount', {
            count: offer?.financialTerms.loan.termInMonths,
          }),
        },
        {
          label: t('offer.information.loanInfo.originationFee'),
          value: `${offer?.financialTerms.originationFee}%`,
        },
        {
          label: t('offer.information.loanInfo.interestRate'),
          value: t('offer.information.loanInfo.interestRateValue', {
            value: interestRate,
          }),
        },
      ],
    },
  ]

  const tenderOfferDetails = [
    {
      label: t('offer.review.tenderOffer.offerDetails.totalLiquidity'),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: offerDetailsForm?.totalLiquidity ?? offer?.liquidityAvailable,
      }),
    },
    {
      label: t(
        'offer.review.tenderOffer.offerDetails.numOfEligibleShareholders'
      ),
      value: offerDetailsForm?.shareholders.length ?? offer?.offerTo.length,
    },
    {
      label: t('offer.review.tenderOffer.offerDetails.eligibleShareClasses'),
      values: selectedShareclasses ?? offer?.shareclasses,
    },
    {
      label: t('offer.review.tenderOffer.offerDetails.maxNumOfShares'),
      value: t('common.intlNumberFormat', {
        value: offerDetailsForm?.totalShares ?? offer?.maxAvailableShares,
      }),
    },
    {
      label: t('offer.review.tenderOffer.offerDetails.percentageOfAssets'),
      value: `${
        offerDetailsForm?.percentageOfAssets ??
        offer?.liquidityPercentPerShareholder
      }%`,
    },
  ]

  return (
    <Grid container rowGap={2}>
      <Typography variant="h3" mb={1}>
        {t('offer.review.subHeading2')}
      </Typography>

      {isNonRecourseLoan &&
        offerDetails.map(
          (offerDetail) =>
            offerDetail.show && (
              <Paper key={offerDetail.title} sx={{ width: 1 }}>
                <Grid container rowGap={3}>
                  <Grid item>
                    <Typography variant="h4" color="secondary.darkest">
                      {offerDetail.title}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {offerDetail.fields.map(
                      (field: {
                        label: string
                        value?: string | number | null
                        values?: any[] // Captable_Shareclass[] | CompanyShareClasses
                      }) => (
                        <Grid key={field.label} item xs={12} sm={4}>
                          <Typography variant="body2" color="secondary.darkest">
                            {field.label}
                          </Typography>
                          {field.value && (
                            <Typography
                              color="secondary.darkest"
                              my={{ xs: 0.3, md: 1 }}
                            >
                              {field.value}
                            </Typography>
                          )}
                          {field.values?.map((values) => (
                            <Typography
                              key={values._id}
                              color="secondary.darkest"
                              my={{ xs: 0.3, md: 1 }}
                            >
                              {values.name ?? values.label}
                            </Typography>
                          ))}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )
        )}

      {isTenderOffer && (
        <Paper sx={{ width: 1 }}>
          <Grid container spacing={3}>
            {tenderOfferDetails.map(
              (field: {
                label: string
                value?: string | number | null
                values?: any[] // Captable_Shareclass[] | CompanyShareClasses
              }) => (
                <Grid key={field.label} item xs={12} sm={4}>
                  <Typography variant="body2">{field.label}</Typography>
                  {field.value && (
                    <Typography my={{ xs: 0.3, md: 1 }}>
                      {field.value}
                    </Typography>
                  )}
                  {field.values?.map((values) => (
                    <Typography key={values._id} my={{ xs: 0.3, md: 1 }}>
                      {values.name ?? values.label}
                    </Typography>
                  ))}
                </Grid>
              )
            )}
          </Grid>
        </Paper>
      )}
    </Grid>
  )
}

export default OfferDetails

import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Button from 'components/mui/Button'

interface Props {
  handleNavigate: () => void
}

function Confirmation({ handleNavigate }: Props) {
  const { t } = useTranslation()

  const steps: string[] = t(
    'dashboard.capTable.import.confirmation.nextStepsDescription',
    {
      returnObjects: true,
    }
  )
  return (
    <Grid container rowSpacing={3}>
      <Typography variant="h3" my={4}>
        <Trans i18nKey="dashboard.capTable.import.confirmation.heading" />
      </Typography>

      <Grid item mb={4}>
        <Typography variant="h3" mb={2}>
          <Trans i18nKey="dashboard.capTable.import.confirmation.nextStepsHeading" />
        </Typography>

        {steps.map((step, index) => (
          <Typography key={`step-${index}`} mb={1}>
            {step}
          </Typography>
        ))}
      </Grid>

      <Button onClick={handleNavigate} sx={{ ml: 0.5 }}>
        {t('common.finish')}
      </Button>
    </Grid>
  )
}

export default Confirmation

import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Offer, OfferTo } from 'common/types'
import { calculateLoanDetails } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import {
  loanRepaymentSchedules,
  loanStructures,
  loanTypes,
  minimumLoanAmount,
} from 'lib/formConfig'
import { useFormikContext } from 'lib/formik'
import { getLabelByValue } from 'lib/helpers'

import CurrencySlider from 'components/CurrencySlider'
import Paper from 'components/mui/Paper/Paper'
import {
  LabelValueContainer,
  LabelValueContainerProps,
} from 'components/offerings/OfferInformation'
import LoanOfferDetailsCard, {
  LoanDetails,
} from 'components/shareholderOffer/LoanOfferDetailsCard'
import { ApproveShareholderOffer } from 'views/shareholder/ShareholderReviewAcceptOffer'
import LoadingButton from 'components/mui/LoadingButton'

interface Props {
  isSubmitting: boolean
  offer: Offer
  shareholderOffer: OfferTo
  values: ApproveShareholderOffer
  interestAndPlatformFee: number
  sofrRate: number
}

function SetLoanAmount({
  isSubmitting,
  offer,
  shareholderOffer,
  values,
  interestAndPlatformFee,
  sofrRate,
}: Props) {
  const { t } = useTranslation()
  const { setFieldValue, errors } = useFormikContext()

  const { financialTerms } = offer

  const loanDetails: LoanDetails = [
    // Staked shares
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.stakedShares.label'
      ),
      data: t('shareholder.offer.nonRecourseLoan.details.stakedShares.value', {
        value: t('common.intlNumberFormat', {
          value: values.shareholderLoanOfferDetails.stakedShares,
        }),
      }),
      notes: t('shareholder.offer.nonRecourseLoan.details.stakedShares.notes'),
    },
    // Origination fee
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.label'
      ),
      data: t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.originationFee,
      }),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.notes'
      ),
    },
    // Loan amount received
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanAmountReceived.label'
      ),
      data: t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.loanAmountReceived,
      }),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanAmountReceived.notes'
      ),
    },
    // Total interest
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.totalInterest.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.totalInterest,
      })}*`,
      notes: t('shareholder.offer.nonRecourseLoan.details.totalInterest.notes'),
    },
    // Quarterly interest-only
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.quarterlyInterestOnlyPayments.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.quarterlyPayments,
      })}*`,
    },
    // Principal repayment
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.principalRepayment.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.principalRepayment,
      })}*`,
      notesElement: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: t(
              'shareholder.offer.nonRecourseLoan.details.principalRepayment.notes',
              {
                value: dayjs(financialTerms?.expiryDate).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }
            ),
          }}
        />
      ),
    },
  ]

  const staticLoanOfferInfo: LabelValueContainerProps = {
    title: t('shareholder.offer.nonRecourseLoan.title'),
    fields: [
      // Maximum loan amount
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.maximumLoanAmount.label'
        ),
        value: t('common.intlCurrencySimplifiedFormat', {
          value: shareholderOffer?.liquidityAvailable,
        }),
      },
      // Offer available until
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.offerAvailableUntil.label'
        ),
        value: dayjs(offer?.expiryDate).format(
          monthDayFullYearSimplifiedFormat
        ),
      },
      // Loan type
      {
        label: t('shareholder.offer.nonRecourseLoan.details.loanType.label'),
        value: getLabelByValue(financialTerms?.loan.kind, loanTypes),
      },
      // Staked shares
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.stakedShares.label'
        ),
        value: t(
          'shareholder.offer.nonRecourseLoan.details.stakedShares.value',
          {
            value: '100%',
          }
        ),
      },
      // Origination fee
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.label'
        ),
        value: `${financialTerms?.originationFee}%`,
      },
      // Interest rate
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanInterestRate.label'
        ),
        value: t(
          'shareholder.offer.nonRecourseLoan.details.loanInterestRate.options.variable.value',
          {
            sofrRate,
            interestRate: interestAndPlatformFee,
          }
        ),
      },
      // Repayment plan
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanRepaymentPlan.label'
        ),
        value: getLabelByValue(financialTerms?.loan.structure, loanStructures),
      },
      // Repayment schedule
      {
        label: t(
          'shareholder.offer.nonRecourseLoan.details.loanRepaymentSchedule.label'
        ),
        value: getLabelByValue(
          financialTerms?.loan.repaymentSchedule,
          loanRepaymentSchedules
        ),
      },
    ],
  }

  const handleCalculateLoanDetails = () => {
    if (errors.hasOwnProperty('requestedLoanAmount')) return
    const calculatedLoanDetails = calculateLoanDetails({
      loanDetails: {
        stakedShares: values.shareholderLoanOfferDetails.stakedShares,
        originationFee: values.shareholderLoanOfferDetails.originationFee,
        loanAmountReceived:
          values.shareholderLoanOfferDetails.loanAmountReceived,
        totalInterest: values.shareholderLoanOfferDetails.totalInterest,
        quarterlyPayments: values.shareholderLoanOfferDetails.quarterlyPayments,
        principalRepayment:
          values.shareholderLoanOfferDetails.principalRepayment,
      },
      offer,
      shareholderOffer,
      requestedLoanAmount: values.requestedLoanAmount,
      sofrRate,
    })

    setFieldValue('shareholderLoanOfferDetails', calculatedLoanDetails)
  }

  useDebounce(
    () => {
      handleCalculateLoanDetails()
    },
    10,
    [values.requestedLoanAmount]
  )

  return (
    <>
      <Grid container justifyContent="space-between" rowGap={3}>
        {/* Set loan amount calculator */}
        <Grid
          item
          container
          alignContent="flex-start"
          xs={12}
          smd={7.5}
          rowGap={3}
        >
          {/* Subheading */}
          <Grid item xs={12}>
            <Typography
              variant="h3"
              dangerouslySetInnerHTML={{
                __html: t(
                  'shareholder.offer.nonRecourseLoan.accept.setLoanAmount.subheading1'
                ),
              }}
            />
          </Grid>
          {/* Set requested loan amount slider */}
          <Grid item xs={12}>
            <Paper>
              <Grid container rowGap={2.5}>
                <Grid item xs={12}>
                  <CurrencySlider
                    fieldName="requestedLoanAmount"
                    minValue={minimumLoanAmount}
                    maxValue={shareholderOffer?.liquidityAvailable}
                    value={values?.requestedLoanAmount}
                    helperText={t(
                      'shareholder.offer.nonRecourseLoan.accept.setLoanAmount.sliderInputHelperText',
                      {
                        minValue: t('common.intlCurrencySimplifiedFormat', {
                          value: minimumLoanAmount,
                        }),
                        maxValue: t('common.intlCurrencySimplifiedFormat', {
                          value: shareholderOffer?.liquidityAvailable,
                        }),
                      }
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Calculated details */}
          <Grid item>
            <Typography variant="h3">
              {t(
                'shareholder.offer.nonRecourseLoan.accept.setLoanAmount.subHeading2'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <LoanOfferDetailsCard loanDetails={loanDetails} />
            <Typography variant="subtitle1" mt={2} color="secondary.dark">
              {t('shareholder.offer.sofrDisclaimer')}
            </Typography>
          </Grid>
        </Grid>

        {/* Non-recourse loan offer details */}
        <Grid item container xs={12} smd={4} alignContent="flex-start">
          <Grid item xs={12}>
            <LabelValueContainer details={staticLoanOfferInfo}>
              <Typography variant="subtitle1" color="secondary.dark">
                {t('shareholder.offer.sofrDisclaimer')}
              </Typography>
            </LabelValueContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid container smd={7.5} rowGap={5} mt={5}>
        <Grid item>
          <Typography>
            {t(
              'shareholder.offer.nonRecourseLoan.accept.setLoanAmount.nextConfirmation'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <LoadingButton type="submit" mode="forward" loading={isSubmitting}>
            {t('common.next')}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  )
}

export default SetLoanAmount

import { useEffect, useState, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Form, Formik } from 'lib/formik'
import { XLSX_SPREADSHEET_SUPPORTED_FILE_TYPES } from 'lib/config'
import { UploadDocuments } from 'common/types'
import { UPLOAD_INGESTION_SPREADSHEET } from 'lib/formConfig'
import { uploadIngestionSpreadsheetSchema } from 'lib/validation'

import UploadFile from 'components/files/UploadFile'
import Alert from 'components/mui/Alert'
import LoadingButton from 'components/mui/LoadingButton'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import List from 'components/mui/List'
import Link from 'components/mui/Link'
import AlertDialog from 'components/AlertDialog'

const initialIngestTemplateFormData = {
  ingestionSpreadsheet: null,
} as UploadDocuments

interface Props {
  fileUploadMutation: any
  handleBack: () => void
  handleNext: () => void
  handleCheckFilesForErrors: (values: UploadDocuments) => void
}

function UploadSpreadsheet({
  fileUploadMutation,
  handleBack,
  handleNext,
  handleCheckFilesForErrors,
}: Props) {
  const { t } = useTranslation()
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const uploadedIngestionSpreadsheet = useMemo(() => {
    if (fileUploadMutation.variables?.documents.length > 0) {
      return fileUploadMutation.variables?.documents?.reduce(
        (acc: any, document: any) => {
          acc[document.name] = [{ name: document.name }]
          return acc
        },
        {}
      )
    }

    return null
  }, [fileUploadMutation.variables?.documents])

  const fixErrorsSteps: string[] = t(
    'openstockAdmin.company.capTable.uploadSpreadsheet.fixErrorsSteps',
    {
      returnObjects: true,
    }
  )

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    fileUploadMutation.reset()
  }

  const isCapTableErrors = fileUploadMutation?.captableErrors.length > 0
  const capTableErrors = fileUploadMutation?.captableErrors
  const numErrors = capTableErrors.length

  const isCheckForErrorsDisabled =
    !!uploadedIngestionSpreadsheet &&
    (isCapTableErrors || fileUploadMutation.isSuccess)

  useEffect(() => {
    if (fileUploadMutation.isError && !isCapTableErrors) {
      setShowAlertDialog(true)
    }
  }, [fileUploadMutation.isError, isCapTableErrors])

  return (
    <Grid container rowGap={2}>
      {/* Upload & check */}
      <Formik
        initialValues={{
          ...initialIngestTemplateFormData,
          ...uploadedIngestionSpreadsheet,
        }}
        validationSchema={uploadIngestionSpreadsheetSchema}
        onSubmit={handleCheckFilesForErrors}
      >
        {({ submitForm }) => (
          <Form>
            <Grid container rowGap={6}>
              <UploadFile
                document={UPLOAD_INGESTION_SPREADSHEET}
                onRemoveCallback={handleResetMutation}
                supportedFileTypes={XLSX_SPREADSHEET_SUPPORTED_FILE_TYPES}
              />
              <LoadingButton
                variant="outlined"
                disabled={isCheckForErrorsDisabled}
                loading={fileUploadMutation.isLoading}
                type="submit"
              >
                {t('documents.checkForErrors')}
              </LoadingButton>
            </Grid>
            {/* Shows mutation error when submitting form  */}
            <AlertDialog
              isOpen={showAlertDialog}
              onClose={() => setShowAlertDialog(false)}
              description={t('common.errorSubmitForm')}
              primaryButtonAction={() => {
                submitForm()
                setShowAlertDialog(false)
              }}
              secondaryButtonAction={handleResetMutation}
            />
          </Form>
        )}
      </Formik>

      {/* Error check confirmation */}
      {uploadedIngestionSpreadsheet &&
        (fileUploadMutation.isSuccess || isCapTableErrors) && (
          <Grid container rowGap={6} mt={4}>
            <Grid item xs={12}>
              {isCapTableErrors ? (
                <Alert severity="error" variant="standard">
                  <Grid container rowGap={1.5}>
                    <Grid item width={1}>
                      <Typography>
                        {t(
                          'openstockAdmin.company.capTable.uploadSpreadsheet.numberErrorsFound',
                          { numErrors }
                        )}
                      </Typography>
                      <List list={capTableErrors} />
                    </Grid>
                    <Grid item width={1}>
                      <Typography>
                        {t(
                          'openstockAdmin.company.capTable.uploadSpreadsheet.fixErrors'
                        )}
                      </Typography>
                      <List list={fixErrorsSteps} />
                    </Grid>
                    <Grid item width={1}>
                      <Typography>
                        <Trans i18nKey="openstockAdmin.company.capTable.uploadSpreadsheet.nextSteps">
                          If you need to start fresh, you can
                          <Link
                            onClick={handleBack}
                            color="inherit"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            download a new, blank cap table ingestion template
                          </Link>
                          You will not be able to proceed until all errors are
                          resolved.
                        </Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Alert>
              ) : (
                fileUploadMutation.isSuccess && (
                  <Alert severity="success" variant="standard">
                    <Typography>{t('documents.noErrorsFound')}</Typography>
                  </Alert>
                )
              )}
            </Grid>

            {/* Buttons */}
            <Grid item xs={12}>
              <ResponsiveStepperButtons
                handleBack={handleBack}
                handleForward={handleNext}
                isForwardDisabled={isCapTableErrors}
              />
            </Grid>
          </Grid>
        )}
    </Grid>
  )
}

export default UploadSpreadsheet

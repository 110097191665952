import { Trans, useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'

import { OPENSTOCK_SUPPORT_EMAIL } from 'lib/config'
import Link from 'components/mui/Link'

export default function ContactUs({ emailSubject }: { emailSubject?: string }) {
  const { t } = useTranslation()

  return (
    <Typography variant="h6" mb={4} mt={6}>
      <Trans i18nKey="account.setup.contact">
        Have questions? Contact us at
        <Link
          href={`mailto:${OPENSTOCK_SUPPORT_EMAIL}?subject=${
            emailSubject ?? t('common.emailSubject')
          }`}
        >
          partners@getopenstock.com
        </Link>
      </Trans>
    </Typography>
  )
}

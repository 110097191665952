import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import Layout from 'components/Layout/Layout'

import ChangePassword from 'views/account/ChangePassword'
import BackLink from 'components/BackLink'

function AccountSettings() {
  const { t } = useTranslation()

  return (
    <Layout sx={{ px: 0 }}>
      <BackLink />
      <Typography variant="h2" component="h1" mb={5}>
        {t('accountSettings.title')}
      </Typography>

      <ChangePassword />
    </Layout>
  )
}

export default AccountSettings

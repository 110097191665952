import { useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { ProtectedRoute } from 'app/ProtectedRoute'
import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'

import Account from 'routes/Account'
import AdminDashboard, {
  DEFAULT_ADMIN_DASHBOARD_ROUTE,
} from 'routes/AdminDashboard'
import Dashboard, {
  DEFAULT_COMPANY_DASHBOARD_ROUTE,
} from 'routes/CompanyDashboard'
import ShareholderDashboard, {
  DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE,
} from 'routes/ShareholderDashboard'
import { SIGN_COMPANY_AGREEMENT_ROUTE } from 'views/companyAdmin/CompanyAgreementLetter'
import User from 'routes/User'
import CalculatorPage from 'views/CalculatorPage'
import LandingPage from 'views/LandingPage'
import LandingPageCommunity from 'views/LandingPageCommunity'
import AccountSettings from 'views/account/AccountSettings'
import MarkdownContent from 'views/MarkdownContent/MarkdownContent'
import PageNotFound from 'views/PageNotFound'
import InviteCompany from 'views/InviteCompany'
import AccountCreationConfirmation from 'views/AccountCreationConfirmation/AccountCreationConfirmation'
import UploadFilesTest from 'views/UploadFilesTest'
import ResponsiveHeaderBar from 'components/headerBar/ResponsiveHeaderBar'
import { FEATURE_FLAGS } from 'lib/config'
import RoleSelect from 'views/RoleSelect'
import TestAlerts from 'views/TestAlerts'
import ResetCompanyOffers from 'views/ResetCompanyOffers'

export const DEFAULT_ROOT_ROUTE = '/'

function Routing() {
  const { isLoggedIn } = useAuth()
  const {
    hasSignedCompanyAgreement,
    state: { isNewUser, activeRole },
    roles: { userRoles, isOpenstockAdmin, isCompanyAdmin },
  } = useUser()

  const homeRoute = useMemo(() => {
    if (activeRole === null && userRoles && userRoles.length > 1) {
      return '/role'
    } else if (isOpenstockAdmin) {
      return DEFAULT_ADMIN_DASHBOARD_ROUTE
    } else if (isCompanyAdmin) {
      return DEFAULT_COMPANY_DASHBOARD_ROUTE
    }

    return DEFAULT_SHAREHOLDER_DASHBOARD_ROUTE
  }, [activeRole, isCompanyAdmin, isOpenstockAdmin, userRoles])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={isLoggedIn ? '/home' : isNewUser ? '/get-started' : '/login'}
            replace
          />
        }
      />
      <Route path="home" element={<Navigate to={homeRoute} replace />} />
      <Route
        path="confirmation"
        element={
          <ProtectedRoute permittedRoles={['COMPANY_ADMIN']}>
            <ResponsiveHeaderBar>
              <AccountCreationConfirmation role="COMPANY_ADMIN" />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="account-confirmation"
        element={
          <ProtectedRoute permittedRoles={['SHAREHOLDER']}>
            <ResponsiveHeaderBar>
              <AccountCreationConfirmation role="SHAREHOLDER" />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/login/*"
        element={
          <ProtectedRoute invert>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path="account/*"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              <Account />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route path="invite/company" element={<InviteCompany />} />
      {FEATURE_FLAGS.TEST_UPLOADS && (
        <Route
          path="upload-files-test"
          element={
            <ProtectedRoute>
              <UploadFilesTest />
            </ProtectedRoute>
          }
        />
      )}
      {FEATURE_FLAGS.TEST_ALERTS && (
        <Route path="test-alerts" element={<TestAlerts />} />
      )}
      {FEATURE_FLAGS.RESET_COMPANY_OFFERS && (
        <Route path="reset-company-offers" element={<ResetCompanyOffers />} />
      )}
      <Route
        path="role"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              {userRoles && userRoles.length > 1 ? (
                <RoleSelect />
              ) : (
                <Navigate to={DEFAULT_ROOT_ROUTE} replace />
              )}
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="company/dashboard/*"
        element={
          <ProtectedRoute permittedRoles={['COMPANY_ADMIN']}>
            <ResponsiveHeaderBar>
              {!FEATURE_FLAGS.SIGN_COMPANY_AGREEMENT_LETTER ||
              hasSignedCompanyAgreement ? (
                <Dashboard />
              ) : (
                <Navigate to={SIGN_COMPANY_AGREEMENT_ROUTE} replace />
              )}
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="admin/dashboard/*"
        element={
          <ProtectedRoute permittedRoles={['OPENSTOCK_ADMIN']}>
            <ResponsiveHeaderBar>
              <AdminDashboard />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="shareholder/dashboard/*"
        element={
          <ProtectedRoute permittedRoles={['SHAREHOLDER']}>
            <ResponsiveHeaderBar>
              <ShareholderDashboard />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="account-settings"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              <AccountSettings />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="get-started"
        element={
          <ProtectedRoute invert>
            <LandingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="calculator/*"
        element={
          <ResponsiveHeaderBar showNavItems={false}>
            <CalculatorPage />
          </ResponsiveHeaderBar>
        }
      />
      <Route path="community" element={<LandingPageCommunity />} />
      <Route path="privacy" element={<MarkdownContent type="privacy" />} />
      <Route path="terms" element={<MarkdownContent type="terms" />} />
      <Route path="glba-policy" element={<MarkdownContent type="glba" />} />
      <Route
        path="*"
        element={
          <ResponsiveHeaderBar>
            <PageNotFound />
          </ResponsiveHeaderBar>
        }
      />
    </Routes>
  )
}

export default Routing

import { Route, Routes } from 'react-router-dom'

import Companies from 'views/openstockAdmin/companies/Companies'
import Company from 'views/openstockAdmin/company/Company'
import IngestCapTable from 'views/openstockAdmin/company/capTable/IngestCapTable'
import ManageOffer from 'views/companyAdmin/manageOffer/ManageOffer'
import PageNotFound from 'views/PageNotFound'

export const DEFAULT_ADMIN_DASHBOARD_ROUTE = '/admin/dashboard'

function AdminDashboard() {
  return (
    <Routes>
      <Route path="/" element={<Companies />} />
      <Route path="/:companyId/*" element={<Company />} />
      <Route path="/:companyId/cap-table/ingest" element={<IngestCapTable />} />
      <Route
        path="/:companyId/offers/offer/:offerId"
        element={<ManageOffer />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default AdminDashboard

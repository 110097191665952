import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Offer } from 'common/types'
import { loanTypes } from 'lib/formConfig'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { getLabelByValue } from 'lib/helpers'
import { ApproveShareholderOffer } from '../ShareholderReviewAcceptOffer'
import { RequestedLoanDetails } from './PaymentInformation'

import LabelValueCard from 'components/LabelValueCard'
import LoanOfferDetailsCard, {
  LoanDetails,
} from 'components/shareholderOffer/LoanOfferDetailsCard'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  handleBack: () => void
  offer: Offer
  values: ApproveShareholderOffer
}

function ReviewLoanAndPayment({ handleBack, offer, values }: Props) {
  const { t } = useTranslation()

  const { financialTerms } = offer

  const loanDetails: LoanDetails = [
    // Requested loan amount
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.accept.reviewLoan.loanDetails.requestedLoan'
      ),
      isLargeHeading: true,
      data: t('common.intlCurrencySimplifiedFormat', {
        value: values.requestedLoanAmount,
      }),
    },
    // Loan type
    {
      heading: t('shareholder.offer.nonRecourseLoan.details.loanType.label'),
      data:
        financialTerms?.loan.kind &&
        getLabelByValue(financialTerms?.loan.kind, loanTypes),
      notes: t('shareholder.offer.nonRecourseLoan.details.loanType.notes'),
    },
    // Loan term
    {
      heading: t('shareholder.offer.nonRecourseLoan.details.loanTerm.label'),
      data: t('common.monthWithCount', {
        count: financialTerms?.loan.termInMonths,
      }),
      notes: `${dayjs(financialTerms?.startDate).format(
        monthDayFullYearSimplifiedFormat
      )} - ${dayjs(financialTerms?.expiryDate).format(
        monthDayFullYearSimplifiedFormat
      )}`,
    },
    // Staked shares
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.stakedShares.label'
      ),
      data: t('shareholder.offer.nonRecourseLoan.details.stakedShares.value', {
        value: t('common.intlNumberFormat', {
          value: values.shareholderLoanOfferDetails.stakedShares,
        }),
      }),
      notes: t('shareholder.offer.nonRecourseLoan.details.stakedShares.notes'),
    },
    // Origination fee
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.label'
      ),
      data: t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.originationFee,
      }),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanOriginationFee.notes'
      ),
    },
    // Loan amount received
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.loanAmountReceived.label'
      ),
      data: t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.loanAmountReceived,
      }),
      notes: t(
        'shareholder.offer.nonRecourseLoan.details.loanAmountReceived.notes'
      ),
    },
    // Total interest
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.totalInterest.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.totalInterest,
      })}*`,
      notes: t('shareholder.offer.nonRecourseLoan.details.totalInterest.notes'),
    },
    // Quarterly interest-only
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.quarterlyInterestOnlyPayments.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.quarterlyPayments,
      })}*`,
    },
    // Principal repayment
    {
      heading: t(
        'shareholder.offer.nonRecourseLoan.details.principalRepayment.label'
      ),
      data: `${t('common.intlCurrencySimplifiedFormat', {
        value: values.shareholderLoanOfferDetails.principalRepayment,
      })}*`,
      notesElement: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: t(
              'shareholder.offer.nonRecourseLoan.details.principalRepayment.notes',
              {
                value: dayjs(financialTerms?.expiryDate).format(
                  monthDayFullYearSimplifiedFormat
                ),
              }
            ),
          }}
        />
      ),
    },
  ]

  const paymentDetails = [
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.shareholderAddress'
      ),
      value: values.streetAddress,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.apartment'
      ),
      value:
        values.streetAddress2 === '' ? '-' : (values.streetAddress2 as string),
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.city'
      ),
      value: values.city,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.country'
      ),
      value: values.country,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.state'
      ),
      value: values.state,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.zipCode'
      ),
      value: values.zipCode,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.shareholderInformation.socialSecurityNumber'
      ),
      value: values.socialSecurityNumber,
    },
  ]

  const bankDetails = [
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.bankAccount'
      ),
      value: values.bankAccount,
    },
    {
      label: t(
        'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.routing'
      ),
      value: values.routingNumber,
    },
  ]

  return (
    <>
      <Grid container justifyContent="space-between" rowGap={3}>
        {/* Forms */}
        <Grid
          item
          container
          alignContent="flex-start"
          xs={12}
          smd={7.5}
          rowGap={3}
        >
          <Grid item>
            <Typography variant="h3">
              {t(
                'shareholder.offer.nonRecourseLoan.accept.reviewLoan.loanDetails.title'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <LoanOfferDetailsCard loanDetails={loanDetails} />
            <Typography variant="subtitle1" mt={2} color="secondary.dark">
              {t('shareholder.offer.sofrDisclaimer')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3" my={3}>
              {t(
                'shareholder.offer.nonRecourseLoan.accept.reviewLoan.paymentInfo'
              )}
            </Typography>
            <Grid item mb={3}>
              <LabelValueCard
                title={t(
                  'shareholder.offer.nonRecourseLoan.accept.paymentInformation.identificationInformation'
                )}
                details={paymentDetails}
              />
            </Grid>
            <Grid item>
              <LabelValueCard
                title={t(
                  'shareholder.offer.nonRecourseLoan.accept.paymentInformation.bankInformation.heading'
                )}
                details={bankDetails}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Requested loan details */}
        <Grid
          item
          container
          xs={12}
          smd={4}
          mt={{ xs: 4, smd: 7 }}
          alignContent="flex-start"
        >
          <RequestedLoanDetails />
        </Grid>
      </Grid>
      <Grid container smd={7.5} rowGap={5} mt={5}>
        <Grid item xs={12}>
          <ResponsiveStepperButtons handleBack={handleBack} />
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewLoanAndPayment

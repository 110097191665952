import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import QueryBoundary from 'app/QueryBoundary'
import { useGetCaptableQuery, useGetCompanyQuery } from 'lib/apollo/hooks'
import { getIsCapTableProcessing } from 'lib/data'
import Button from 'components/mui/Button'
import EmptyStateWithButton from 'components/EmptyStateWithButton'
import IngestedCapTable from 'components/tables/IngestedCapTable'
import Layout from 'components/Layout/Layout'

function CompanyCapTable() {
  const { t } = useTranslation()

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery?.data?.getCompany
  const companyFiles = company?.files

  const captableQuery = useGetCaptableQuery()
  const captable = captableQuery?.data?.getCaptable

  const hasApprovedCapTable = captable?.isApprovedBy?.companyAdmin
  const isReadyForReview = captable?.isApprovedBy?.openstockAdmin

  const isProcessing = useMemo(
    () => companyFiles && getIsCapTableProcessing(companyFiles),
    [companyFiles]
  )

  const config = useMemo(() => {
    let title = t('dashboard.capTable.view.noCapTable.title')
    let description = t('dashboard.capTable.view.noCapTable.description')
    let buttonLabel = t('dashboard.capTable.view.noCapTable.button')
    let buttonNavigateTo = 'import'
    let note = ''

    if (isReadyForReview) {
      title = t('dashboard.capTable.view.readyReview.title')
      description = t('dashboard.capTable.view.readyReview.description')
      buttonLabel = t('dashboard.capTable.view.readyReview.button')
      buttonNavigateTo = 'review'
    } else if (isProcessing) {
      title = t('dashboard.capTable.view.processing.title')
      description = t('dashboard.capTable.view.processing.description')
      buttonLabel = t('dashboard.capTable.view.processing.button')
      note = t('dashboard.capTable.view.processing.note')
    }

    return { title, description, buttonLabel, buttonNavigateTo, note }
  }, [isProcessing, isReadyForReview, t])

  return (
    <QueryBoundary queries={[companyQuery, captableQuery]}>
      <Layout maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h1" mr={4}>
            {t('dashboard.capTable.view.heading')}
          </Typography>
          {hasApprovedCapTable && (
            <Button
              mode="forward"
              size="small"
              variant="outlined"
              to="import-preview"
              sx={{ my: { xs: 2, sm: 0 } }}
            >
              {t('dashboard.capTable.view.button')}
            </Button>
          )}
        </Grid>

        {hasApprovedCapTable ? (
          <Grid mt={6}>
            <IngestedCapTable showProcessedDate />
          </Grid>
        ) : (
          <EmptyStateWithButton
            {...config}
            imageReference="table"
            buttonProps={{
              variant:
                isReadyForReview || (!hasApprovedCapTable && !isProcessing)
                  ? 'contained'
                  : 'outlined',
            }}
          />
        )}
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyCapTable

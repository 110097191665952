import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Offer } from 'common/types'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import List from 'components/mui/List'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import { RequestedLoanDetails } from './PaymentInformation'

interface Props {
  handleBack: () => void
  isSubmitting: boolean
  offer: Offer
}

function ConfirmationOfferAcceptance({
  handleBack,
  isSubmitting,
  offer,
}: Props) {
  const { t } = useTranslation()

  const steps: string[] = t(
    'shareholder.offer.nonRecourseLoan.accept.confirmation.nextStepsDescription',
    {
      endDate: dayjs(offer.expiryDate).format(monthDayFullYearSimplifiedFormat),
      returnObjects: true,
    }
  )

  return (
    <Grid container justifyContent="space-between">
      <Grid item container xs={12} smd={7.5} mb={4}>
        <Grid item>
          <Typography mb={4}>
            {t(
              'shareholder.offer.nonRecourseLoan.accept.confirmation.description'
            )}
          </Typography>
          <Typography variant="h3" mb={1}>
            {t(
              'shareholder.offer.nonRecourseLoan.accept.confirmation.nextStepsHeading'
            )}
          </Typography>

          <List list={steps} />
        </Grid>
      </Grid>

      {/* Requested loan details */}
      <Grid item container xs={12} smd={4} alignContent="flex-start">
        <RequestedLoanDetails />
      </Grid>
      <Grid container smd={7.5} rowGap={5} mt={{ xs: 5, smd: 2 }}>
        <Grid item xs={12}>
          <ResponsiveStepperButtons
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            nextStepText={t(
              'shareholder.offer.nonRecourseLoan.accept.confirmation.submitButton'
            )}
            isLastStep
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConfirmationOfferAcceptance

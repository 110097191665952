import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import QueryBoundary from 'app/QueryBoundary'
import { useUser } from 'app/User'
import { CompanyAgreementDetails } from 'common/types'
import {
  useCreateCompanyAgreementDownloadLinkQuery,
  useGetCompanyQuery,
  useSignCompanyAgreement,
} from 'lib/apollo/hooks'
import { getRegionByCountry, priorityCountryList } from 'lib/countryRegion'
import dayjs, { fullMonthDayYearDateFormat } from 'lib/dayjs'
import { Form, Formik } from 'lib/formik'
import { companyAgreementLetterSchema } from 'lib/validation'
import theme from 'styles/customTheme'

import Grid from '@mui/material/Grid/Grid'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import SignIcon from '@mui/icons-material/DriveFileRenameOutline'
import SouthIcon from '@mui/icons-material/South'
import Typography from '@mui/material/Typography/Typography'

import AlertDialog from 'components/AlertDialog'
import AutocompleteInput from 'components/mui/AutocompleteInput'
import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import Link from 'components/mui/Link'
import List from 'components/mui/List'
import LoadingButton from 'components/mui/LoadingButton'
import TextInput from 'components/mui/TextInput'

export const SIGN_COMPANY_AGREEMENT_ROUTE = '/account/agreement'

function CompanyAgreementLetter() {
  const { t } = useTranslation()
  const { data } = useUser()
  const navigate = useNavigate()
  const ref = useRef<null | HTMLDivElement>(null)
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [signCompanyAgreement, signCompanyAgreementMutation] =
    useSignCompanyAgreement()
  const [getDownloadLink, getDownloadLinkMutation] =
    useCreateCompanyAgreementDownloadLinkQuery()

  const companyQuery = useGetCompanyQuery()
  const company = companyQuery?.data?.getCompany

  const todayDate = dayjs().format(fullMonthDayYearDateFormat)
  const isSubmitting =
    signCompanyAgreementMutation.loading || getDownloadLinkMutation.loading
  const isSigned =
    signCompanyAgreementMutation.data && getDownloadLinkMutation.data
  const hasIncorporationDetails = company?.incorporationDetailsProvided

  const fileUrl =
    getDownloadLinkMutation?.data?.createCompanyAgreementDownloadLink?.url

  //TODO: Replace hardcoded Openstock details, should come from BE
  const openstockDetails = {
    companyName: 'Openstock Inc',
    name: 'Alex Simpson',
    jobTitle: 'CEO',
    stateOfIncorporation: 'Delaware',
    countryOfIncorporation: 'United States',
    emailAddress: 'alex@openstock.com',
  }

  //TODO: Navigate to or show modal to view form letter pdf
  const handleNavigate = () =>
    navigate('../questions', {
      replace: true,
    })

  const handleSubmit = async (values: CompanyAgreementDetails) => {
    const companyAgreementDetails = {
      version: 0,
      userInfo: {
        id: data?._id,
        name: values.companySigneeName,
        emailAddress: data.emailAddress,
        jobTitle: values.companySigneeTitle,
      },
      companyInfo: {
        id: company?._id,
        name: values.companyName,
        address: {
          country: company?.address.country,
          state: company?.address.state,
          city: company?.address.city,
          streetAddress: company?.address.streetAddress,
          streetAddress2: company?.address.streetAddress2,
          zipCode: company?.address.zipCode,
        },
      },
      adminInfo: {
        companyName: openstockDetails.companyName,
        name: openstockDetails.name,
        jobTitle: openstockDetails.jobTitle,
        stateOfIncorporation: openstockDetails.stateOfIncorporation,
        countryOfIncorporation: openstockDetails.countryOfIncorporation,
        emailAddress: openstockDetails.emailAddress,
      },
      incorporationInfo: {
        countryOfIncorporation: values.companyCountryOfIncorporation,
        stateOfIncorporation: values.companyStateOfIncorporation,
      },
      userSignature: values.companySigneeSignature,
      submissionDate: dayjs(),
    }

    if (!signCompanyAgreementMutation.data) {
      const { errors } = await signCompanyAgreement({
        variables: companyAgreementDetails,
        onCompleted: (data) => {
          handleDownloadLinkMutation(data.signCompanyAgreement._id)
        },
      })

      if (errors) return
    }
  }

  const handleDownloadLinkMutation = async (id: string) => {
    if (!getDownloadLinkMutation.data) {
      const { errors } = await getDownloadLink({
        variables: {
          id,
        },
      })

      if (errors) return
    }
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    signCompanyAgreementMutation.reset()
  }

  const handleScroll = (e: any) => {
    e.preventDefault()
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (!data?.company) {
      navigate('../company', {
        replace: true,
      })
    }
  }, [company, data?.company, navigate])

  useEffect(() => {
    if (signCompanyAgreementMutation.error || getDownloadLinkMutation.error) {
      setShowAlertDialog(true)
    }
  }, [getDownloadLinkMutation.error, signCompanyAgreementMutation.error])

  const initialValues = {
    signedDate: todayDate,
    companyName: company?.name,
    companySigneeSignature: '',
    companySigneeName: `${data.firstName} ${data.lastName}`,
    companySigneeTitle: '',
    hasIncorporationDetails,
    companyCountryOfIncorporation: data.company.countryOfIncorporation ?? '',
    companyStateOfIncorporation: data.company.stateOfIncorporation ?? '',
  }

  const companyDetails: { title: string; text: string; items?: string[] }[] = t(
    'account.company.agreementLetter.body',
    {
      company: company?.name,
      companyEmail: data.emailAddress,
      date: todayDate,
      openstockEmail: openstockDetails.emailAddress,
      returnObjects: true,
    }
  )

  return (
    <QueryBoundary queries={[companyQuery]}>
      <Layout maxWidth="md">
        <Formik
          initialValues={initialValues}
          validationSchema={companyAgreementLetterSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, submitForm }) => {
            const regionsOfIncorporation = getRegionByCountry(
              values?.companyCountryOfIncorporation
            )

            return (
              <Form>
                <Grid container>
                  <Button
                    endIcon={<SouthIcon />}
                    sx={{
                      backgroundColor: 'primary.lightest',
                      color: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                      },
                      mb: 2,
                    }}
                    fullWidth
                    type="submit"
                    onClick={handleScroll}
                  >
                    {t('account.company.agreementLetter.buttonHeader')}
                  </Button>

                  <Grid item container xs={12} justifyContent="end">
                    <Typography>{todayDate}</Typography>
                  </Grid>
                  {/* Letter header */}
                  <Grid item mb={8}>
                    <Typography>{`${data.firstName} ${data.lastName}`}</Typography>
                    <Typography>
                      {values.companySigneeTitle === ''
                        ? t(
                            'account.company.agreementLetter.forms.titlePlaceholder'
                          )
                        : values.companySigneeTitle}
                    </Typography>
                    <Typography>{company?.name}</Typography>
                    <Typography>{`${company?.address?.streetAddress}, ${
                      company?.address?.streetAddress2 ?? ''
                    }`}</Typography>
                    <Typography>{`${company?.address?.city}, ${company?.address?.state}, ${company?.address?.zipCode}`}</Typography>
                  </Grid>
                  <Typography>
                    {t('account.company.agreementLetter.greeting', {
                      company: values.companyName,
                      name: values.companySigneeName,
                      companyStateOfIncorporation: hasIncorporationDetails
                        ? company?.stateOfIncorporation
                        : values?.companyStateOfIncorporation === ''
                          ? '[*]'
                          : values.companyStateOfIncorporation,
                      openstockStateOfIncorporation:
                        openstockDetails.stateOfIncorporation,
                    })}
                  </Typography>
                  <List sx={{ pl: 0 }}>
                    {companyDetails.map(({ title, text, items }, index) => (
                      <Grid key={title} mb={2}>
                        <Typography>
                          {index + 1}.&nbsp;
                          <span style={{ ...theme.typography.body2 }}>
                            {title}.&nbsp;
                          </span>
                          {text}
                        </Typography>
                        {items && (
                          <List>
                            {items?.map((subItem, index) => (
                              <Typography key={`subItem-${index}`} mb={2}>
                                {subItem}
                              </Typography>
                            ))}
                          </List>
                        )}
                      </Grid>
                    ))}
                  </List>
                  <Grid
                    item
                    container
                    columnSpacing={2}
                    rowSpacing={6}
                    xs={12}
                    mt={4}
                  >
                    <Grid item container xs={12} sm={6} rowSpacing={3}>
                      <Typography>
                        {t('account.company.agreementLetter.forms.by')}
                      </Typography>
                      <Grid item>
                        <Typography sx={{ fontFamily: 'Cedarville Cursive' }}>
                          {openstockDetails.name}
                        </Typography>
                        <Typography>{`${openstockDetails.name}, ${openstockDetails.jobTitle}`}</Typography>
                        <Typography>{openstockDetails.companyName}</Typography>
                        <Typography mt={2}>{todayDate}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      rowSpacing={3}
                      ref={ref}
                    >
                      <Typography>
                        {t('account.company.agreementLetter.forms.agreed')}
                      </Typography>
                      <Grid item xs={12}>
                        <TextInput
                          id="companyName"
                          name="companyName"
                          label={t(
                            'account.company.agreementLetter.forms.company'
                          )}
                          disabled
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="companySigneeSignature"
                          name="companySigneeSignature"
                          label={t(
                            'account.company.agreementLetter.forms.signature'
                          )}
                          placeholder={t(
                            'account.company.agreementLetter.forms.signaturePlaceholder'
                          )}
                          isInputCursive
                          disabled={!!isSigned}
                          required
                          showSignHereIndicator={!isSigned}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="companySigneeName"
                          name="companySigneeName"
                          label={t(
                            'account.company.agreementLetter.forms.name'
                          )}
                          disabled
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          id="companySigneeTitle"
                          name="companySigneeTitle"
                          label={t(
                            'account.company.agreementLetter.forms.title'
                          )}
                          placeholder={t(
                            'account.company.agreementLetter.forms.titleInputPlaceholder'
                          )}
                          disabled={!!isSigned}
                          required
                          showSignHereIndicator={!isSigned}
                        />
                      </Grid>
                      {!hasIncorporationDetails && (
                        <>
                          <Grid item xs={12}>
                            <AutocompleteInput
                              id="companyCountryOfIncorporation"
                              name="companyCountryOfIncorporation"
                              label={t(
                                'account.company.labels.countryOfIncorporation'
                              )}
                              placeholder={t('common.selectPlaceholder')}
                              options={priorityCountryList}
                              onChangeCallback={() => {
                                setFieldValue('states', '')
                              }}
                              inputProps={{
                                autoComplete: 'country-name',
                              }}
                              disabled={isSigned}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutocompleteInput
                              id="companyStateOfIncorporation"
                              name="companyStateOfIncorporation"
                              label={t(
                                'account.company.labels.stateOfIncorporation'
                              )}
                              placeholder={t('common.selectPlaceholder')}
                              options={regionsOfIncorporation}
                              disabled={
                                !values.companyCountryOfIncorporation ||
                                isSigned
                              }
                              inputProps={{
                                autoComplete: 'address-level1',
                              }}
                              required
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <TextInput
                          id="signedDate"
                          name="signedDate"
                          label={t(
                            'account.company.agreementLetter.forms.date'
                          )}
                          disabled
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={12} mt={6} justifyContent="end">
                  {!isSigned ? (
                    <LoadingButton
                      type="submit"
                      endIcon={<SignIcon />}
                      loading={isSubmitting}
                    >
                      {t('account.company.agreementLetter.buttonSign')}
                    </LoadingButton>
                  ) : (
                    <Grid item container justifyContent="end" rowGap={2}>
                      <Link
                        href={fileUrl}
                        disabled={!fileUrl}
                        underline="none"
                        variant="subtitle2"
                        download={t('account.company.agreementLetter.filename')}
                        target="_blank"
                      >
                        <Button
                          variant="outlined"
                          endIcon={<FileDownloadIcon />}
                        >
                          {t('account.company.agreementLetter.buttonDownload')}
                        </Button>
                      </Link>

                      <Button
                        mode="forward"
                        onClick={handleNavigate}
                        sx={{ ml: 2 }}
                      >
                        {t('common.next')}
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <AlertDialog
                  isOpen={showAlertDialog}
                  onClose={() => setShowAlertDialog(false)}
                  primaryButtonAction={() => {
                    submitForm()
                    setShowAlertDialog(false)
                  }}
                  secondaryButtonAction={handleResetMutation}
                />
              </Form>
            )
          }}
        </Formik>
      </Layout>
    </QueryBoundary>
  )
}

export default CompanyAgreementLetter

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InferType } from 'yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useUser } from 'app/User'
import useRefetchQueries from 'hooks/useRefetchQueries'
import { createCaptableAirtableRecord } from 'lib/airtable'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import { useSteps } from 'hooks/useSteps'
import {
  UPLOAD_CAPITALIZATION_TABLE,
  UPLOAD_SHAREHOLDER_INFORMATION,
} from 'lib/formConfig'
import {
  companyAdminUploadCapTableSchema,
  shareholderInformationSchema,
} from 'lib/validation'
import { DEFAULT_COMPANY_DASHBOARD_ROUTE } from 'routes/CompanyDashboard'
import useFileUpload from 'hooks/useFileUpload'
import theme from 'styles/customTheme'

import AlertDialog from 'components/AlertDialog'
import BackLink from 'components/BackLink'
import Confirmation from './Confirmation'
import ContactUs from 'components/ContactUs'
import DownloadShareholder from './DownloadShareholder'
import Layout from 'components/Layout/Layout'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import UploadCapTable from './UploadCapTable'
import UploadShareholder from './UploadShareholder'
import { useGetCompanyQuery } from 'lib/apollo/hooks'

type CompanyAdminUploadCapTable = InferType<
  typeof companyAdminUploadCapTableSchema
>
type ShareholderInformation = InferType<typeof shareholderInformationSchema>
export type CapTableForm = CompanyAdminUploadCapTable & ShareholderInformation

const initialFormData = {
  capitalizationTable: null,
  shareholderInformation: null,
} as CapTableForm

function ImportCapTable() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { activeStep, handleBack, handleNext } = useSteps()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const { refetchQueries } = useRefetchQueries()
  // useGetCompanyQuery is called even though it is not leveraged so that the query is observable. Apollo only refetches observable (mounted) queries.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const companyQuery = useGetCompanyQuery()

  const {
    data: { company },
  } = useUser()

  const fileUploadMutation = useFileUpload()

  const validationSchema = [
    companyAdminUploadCapTableSchema,
    null,
    shareholderInformationSchema,
  ]

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleSubmit = async (
    values: CapTableForm,
    formik?: FormikHelpers<CapTableForm>
  ) => {
    if (activeStep === 2) {
      await fileUploadMutation.mutateAsync({
        endpoint: 'companyfilesupload',
        documents: [
          {
            file: values.capitalizationTable[0],
            name: UPLOAD_CAPITALIZATION_TABLE.name,
          },
          {
            file: values.shareholderInformation[0],
            name: UPLOAD_SHAREHOLDER_INFORMATION.name,
          },
        ],
      })

      createCaptableAirtableRecord(company?.name, company?._id)

      refetchQueries()
    }

    formik?.setTouched({}, false)
    return handleNext()
  }

  const handleNavigate = () =>
    navigate(`${DEFAULT_COMPANY_DASHBOARD_ROUTE}/cap-table`, {
      replace: true,
    })

  const handleAction = () => navigate('../cap-table/import-preview')

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    fileUploadMutation.reset()
  }

  const handleOnClose = () => setShowAlertDialog(false)

  useEffect(() => {
    if (fileUploadMutation.isError) {
      setShowAlertDialog(true)
    }
  }, [fileUploadMutation.isError])

  const pages: string[] = t('dashboard.capTable.import.pages', {
    returnObjects: true,
  })

  return (
    <Grid container sx={{ flex: 1 }}>
      {isMediumUp && (
        <Grid item xs={12} md={3} lg={2.5}>
          <SideBarStepper
            activeStep={activeStep}
            steps={pages}
            title={t('dashboard.capTable.import.title')}
            action={handleAction}
            actionTitle={t('common.back')}
          />
        </Grid>
      )}

      <Grid item xs={12} md={9} lg={9.5}>
        <Layout maxWidth="sm" isSideBarStepper>
          {isMediumDown && <BackLink onClick={handleAction} />}

          <Box>
            <Typography variant="h2" component="h1" mb={5}>
              {pages[activeStep]}
            </Typography>
          </Box>

          <Formik
            initialValues={initialFormData}
            validationSchema={validationSchema[activeStep]}
            onSubmit={handleSubmit}
          >
            {({ values, submitForm }) => {
              const formProps = {
                handleBack,
                handleNavigate,
                fileUploadMutation,
                values,
              }

              return (
                <Form promptOnLeave={activeStep !== 3}>
                  {(() => {
                    switch (activeStep) {
                      case 0:
                        return <UploadCapTable />
                      case 1:
                        return <DownloadShareholder {...formProps} />
                      case 2:
                        return <UploadShareholder {...formProps} />
                      case 3:
                        return <Confirmation {...formProps} />
                      default:
                        return
                    }
                  })()}
                  {/* Shows mutation error when submitting form  */}
                  <AlertDialog
                    isOpen={showAlertDialog}
                    onClose={handleOnClose}
                    description={t('common.errorSubmitForm')}
                    primaryButtonAction={() => {
                      submitForm()
                      setShowAlertDialog(false)
                    }}
                    secondaryButtonAction={handleResetMutation}
                  />
                </Form>
              )
            }}
          </Formik>

          <ContactUs
            emailSubject={t('dashboard.capTable.import.emailSubject')}
          />
        </Layout>
      </Grid>
    </Grid>
  )
}

export default ImportCapTable

import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import Slider from 'components/mui/Slider'

interface Props {
  fieldName: string
  helperText?: string
  isTextInput?: boolean
  label?: string
  maxValue?: number
  minValue?: number
  value: number
  sliderValue?: number
  sliderMinValueLabel?: string
  sliderMaxValueLabel?: string
}

function PercentValueSlider({
  fieldName,
  helperText,
  isTextInput = true,
  label,
  value,
  sliderValue,
  maxValue = 100,
  minValue = 0,
  ...props
}: Props) {
  const { t } = useTranslation()

  const handleSliderValueLabelFormat = (value: number) =>
    `${t('common.percentage', {
      value,
    })}`

  return (
    <Grid container>
      <Grid container mb={2}>
        <Typography variant="h4">{label}</Typography>
        <Grid item container my={1}>
          <Typography variant="h1">
            {t('common.intlCurrencySimplifiedFormat', {
              value,
            })}
          </Typography>
        </Grid>

        <Typography variant="subtitle1">{helperText}</Typography>
      </Grid>

      <Slider
        value={sliderValue ?? value ?? 0}
        valueLabelFormat={handleSliderValueLabelFormat}
        valueLabelDisplay={isTextInput ? 'off' : 'on'}
        name={fieldName}
        max={maxValue}
        min={minValue}
        {...props}
      />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>
            {t('common.percentage', {
              value: minValue,
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t('common.percentage', {
              value: maxValue,
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PercentValueSlider

import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from 'lib/monitoring'
import { ApolloProvider } from '@apollo/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import ScrollToTop from 'components/ScrollToTop'

import { AuthProvider } from 'app/Auth'
import { UserProvider } from 'app/User'
import Routing from 'app/Routing'
import { client } from 'lib/apollo'
import history from 'lib/history'

import customTheme from 'styles/customTheme'
import 'styles/style.scss'
import GlobalLayout from 'components/GlobalLayout/GlobalLayout'

const queryClient = new QueryClient()

function App() {
  const { t } = useTranslation()
  return (
    <ErrorBoundary
      fallback={() => (
        <ErrorFallback
          title={t('common.somethingWentWrong')}
          primaryActionText={t('common.reloadPage')}
          secondaryActionText={t('common.returnToDashboard')}
        />
      )}
    >
      <Router history={history as any}>
        <ScrollToTop />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={customTheme}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <ApolloProvider client={client}>
                  <UserProvider>
                    <GlobalLayout>
                      <Routing />
                    </GlobalLayout>
                  </UserProvider>
                </ApolloProvider>
              </AuthProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </ErrorBoundary>
  )
}

export default App

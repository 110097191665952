import { useField } from 'formik'

import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'

type Props = CheckboxProps & {
  name: string
  label: React.ReactNode
}

function CheckboxInput({ name, label, ...props }: Props) {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: any) => {
    const { checked } = e.target
    helpers.setValue(checked)
  }

  const configFormControl = {
    ...props,
  } as FormControlProps

  if (meta.touched && meta.error) {
    configFormControl.error = true
  }

  return (
    <FormControl {...configFormControl}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...field} onChange={handleChange} />}
          label={label}
        />
        {configFormControl.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxInput
